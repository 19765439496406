import React, { useEffect } from 'react';
import ViewGiftRequestDetailsPage from './components/viewGiftRequestDetailsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle, getLoggedInUser } from '../../utilities/helpers';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGiftById } from './redux/slice';
import LocalStorage from 'utilities/localStorage';
import CustomLayoutPublic from '@/common/LayoutPublic';

function ViewGiftRequestDetails() {
  TabTitle('Gift Register - View Gift Request Details');
  const dispatch = useDispatch();
  const { getGiftByIdLoading, getGiftDetailsById } = useSelector(
    (state) => state.view
  );

  const user_role1 = getLoggedInUser();
  const userDetails = LocalStorage.getItem('GiftReg_user');

  const giftType = LocalStorage.getItem('giftTypeRequest');
  console.log('GiftTyp%%%%%%%%%', giftType);

  const params = useParams();
  const url = window.location.search;
  const urlType = url.replace('?', '');
  console.log('urType', urlType);

  const urlParams = new URLSearchParams(window.location.search);

  // Read a specific query parameter
  const type = urlParams.get('giftType');
  const hashRoot = urlParams.get('hashRoot');
  const uniqueId = urlParams.get('uniqueId');
  const userId = urlParams.get('userId');

  console.log('paramsID', params?.id);
  console.log(type);
  console.log(hashRoot);
  console.log(uniqueId);
  console.log(userId);

  useEffect(() => {
    handleGetGiftById();
  }, []);
  const handleGetGiftById = () => {
    if (userDetails?.user === undefined || user_role1?.user === null) {
      const payload = {
        id: params.id,
        giftType: type,
        hashRoot: hashRoot,
        uniqueId: uniqueId,
        userId: userId
      };

      dispatch(getGiftById(payload));
    } else {
      const payload = {
        id: params.id,
        giftType: giftType,
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };
      dispatch(getGiftById(payload));
    }

    // const payload = { id: params.id, giftType: giftType };
    // dispatch(getGiftById(payload));
  };

  return userDetails?.user === undefined || user_role1?.user === null ? (
    <CustomLayoutPublic>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftRequestDetailsPage
          getGiftDetailsById={getGiftDetailsById}
          getGiftByIdLoading={getGiftByIdLoading}
          Id={params?.id}
          type={type}
          hashRoot={hashRoot}
          uniqueId={uniqueId}
          userId={userId}
        />
      </div>
    </CustomLayoutPublic>
  ) : (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftRequestDetailsPage
          getGiftDetailsById={getGiftDetailsById}
          getGiftByIdLoading={getGiftByIdLoading}
          Id={params?.id}
          type={type}
          hashRoot={hashRoot}
          uniqueId={uniqueId}
          userId={userId}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewGiftRequestDetails;

//import styles from './index.module.less';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Gifts Given Vs. Gifts Received (Unit-wise)'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

export default function StackedBarChart({ gag_vs_gr }) {
  const data = {
    labels: gag_vs_gr?.map((item) => item?.name),

    datasets: [
      {
        label: 'Gifts given',
        data: gag_vs_gr?.map((item) => item?.gagCount),
        backgroundColor: '#2264ae', //'#7673FF',
        barThickness: 30
      },
      {
        label: 'Gifts received',
        data: gag_vs_gr?.map((item) => item?.grCount),
        backgroundColor: '#C2C0FF', //#4059E5',
        barThickness: 30
      }
    ]
  };
  return (
    <div>
      <Bar options={options} data={data} width={600} height={220} />
    </div>
  );
}

import apiClient from 'utilities/apiClient';

export const declare_received_gift = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.declare_received_gift}`,
    payload,
    true,
    'file'
  );
};
export const gift_master = (payload) => {
  return apiClient.get(`${apiClient.Urls.gift_master}`, payload, true);
};

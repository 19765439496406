//import { useState } from 'react';
import { Image, Layout } from 'antd';
import { Link } from 'react-router-dom';
//import { history } from 'app/history';
import styles from './index.module.less';
import UserMenu from './components/UserMenu';

//import { getLoggedInUser } from '../../utilities/helpers';

import tataPro from './images/tatalogo.svg';

const { Header, Content } = Layout;

const CustomLayoutBU = ({ children, header = true }) => {
  // const [selectedMenuKeys] = useState(
  //   history.location.pathname.replace('/', '')
  // );
  // const user_role1 = getLoggedInUser();
  // const user_role = user_role1?.user?.roles[0];

  return (
    <Layout className={styles.customlayout_container}>
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <Link to="">
              <Image
                alt="tata-projects-logo"
                src={tataPro}
                preview={false}
                className={`${styles.header_tata_logo}`}
              />
            </Link>

            <UserMenu />
          </Header>
        ) : null}
        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayoutBU;

import React from 'react';
import { Chart } from 'react-google-charts';
function TreeChart({ allBUs }) {
  const prepareData = () => {
    //console.log('allBUs------------------', allBUs);

    const bb = allBUs.map((bu) => [
      `${bu.name},${bu.totalCount}`, // Location (BU name)
      'BU-wise Gifts', // Parent (All BU names will be under "Global")
      bu.totalCount, // Market trade volume (size)
      bu.totalValue, // Market trade volume (size)
      0 // Market increase/decrease (color) - set to 0 in this example
    ]);
    // console.log('bbbbbbbbbbbbbbbbbbbbbbbbb', bb);
    return bb;
  };
  const data = [
    [
      'Location',
      'Parent',
      'Market trade volume (size)',
      'Market trade volume (size)',
      'Market increase/decrease (color)'
    ],
    ['BU-wise Gifts', null, 0, 0, 0], // Root node
    ...prepareData() // Child nodes
  ];
  // console.log('data-------------', data);
  const options = {
    highlightOnMouseOver: true,
    maxDepth: 1,
    maxPostDepth: 2,
    minHighlightColor: '#5d73ba',
    midHighlightColor: '#439cf6',
    maxHighlightColor: '#15c2f1',
    minColor: '#2264ae',
    midColor: '#15aaea', //'#2264ae',
    maxColor: '#229705',
    headerHeight: 20,
    showScale: true,
    height: 300,
    useWeightedAverageForAggregation: true,
    fontColor: 'white'
  };

  return (
    <Chart
      chartType="TreeMap"
      data={data}
      /*  options={{
        minColor: '#f00',
        midColor: '#ddd',
        maxColor: '#0d0',
        headerHeight: 15,
        fontColor: 'white',
        showScale: true
      }} */
      options={options}
      width="100%"
      height="300px"
    />
  );
}
export default TreeChart;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
//import { LogoutOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Divider, Drawer, Image, Space, Typography, Menu } from 'antd';
import tataProjectsLogo from '../../images/tata-projects-logo.svg';
//import neevLogo from '../../images/neev_logo.svg';
import logoutIcon from '../../images/logout.svg';
//import team from '../../images/menu_icons/users.svg';
//import CustomText from 'common/CustomText';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { logout } from 'features/login/redux/slice';
//import styles from './left_drawer.module.less';
//import { menuData } from 'utilities/helpers';
import { Link } from 'react-router-dom';
import styles from './index.module.less';

import tataPro from '../../images/tatalogo.svg';
//import gift_returns from '../../../../assets/images/gift_returns.png';
//import settings from '../../../../assets/images/settings.png';
import { getLoggedInUser } from 'utilities/helpers';
import {
  HomeOutlined,
  GiftOutlined,
  FileTextOutlined,
  DashboardOutlined
} from '@ant-design/icons';

const { Text } = Typography;

const LeftDrawer = ({ open, onClose }) => {
  // const { instance } = useMsal();
  // const dispatch = useDispatch();
  // const user_details = LocalStorage.getItem('neev_login_user');

  // const [selectedMenuKey] = useState(
  //   history.location.pathname.replace('/', '')
  // );

  // const signOutClickHandler = () => {
  //   instance.logoutRedirect({
  //     account: LocalStorage.homeAccountId,
  //     postLogoutRedirectUri: '/',
  //     mainWindowRedirectUri: '/',
  //     onRedirectNavigate: () => {
  //       dispatch(logout());
  //       return true;
  //     }
  //   });
  // };
  // export const menuData = [
  //   {
  //     label: 'Home',
  //     key: 'home',
  //     path: '/home',
  //     icon: home,
  //     desktop: true,
  //     mobile: true
  //   },
  //   {
  //     label: 'Queries & Suggestions',
  //     key: 'ideas/list',
  //     path: '/ideas/list',
  //     icon: queries,
  //     desktop: true,
  //     mobile: true
  //   },
  //   {
  //     label: 'Knowledge Hub',
  //     key: 'knowledgeHub',
  //     path: '/knowledgeHub',
  //     icon: knowledge_hub,
  //     desktop: true,
  //     mobile: true
  //   },
  //   {
  //     label: 'Feel the Change',
  //     key: 'feelTheChange',
  //     path: '/feelTheChange',
  //     icon: feel_the_change,
  //     desktop: true,
  //     mobile: true
  //   },
  //   // {
  //   //   label: 'Quiz',
  //   //   key: 'quiz',
  //   //   path: '/quiz',
  //   //   icon: null,
  //   //   desktop: true,
  //   //   mobile: true
  //   // },
  //   {
  //     label: 'Hall of Fame',
  //     key: 'hallOfFame',
  //     path: '/hallOfFame',
  //     icon: hof,
  //     desktop: true,
  //     mobile: true
  //   }
  // ];
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user?.roles[0];

  console.log('user_role', user_role);

  const itemsAdmin = [
    {
      key: 'admin',
      path: '/admin',
      icon: <FileTextOutlined />,
      //  (
      //   <Image
      //     className={`${styles.gift_icon_size}`}
      //     alt="gift_requests-icon"
      //     src={settings}
      //     preview={false}
      //   />
      // ),
      label: 'Settings'
    },
    {
      key: 'reports',
      path: '/reports',
      icon: <FileTextOutlined />,
      label: 'Reports'
    },
    {
      key: 'dashboard',
      path: '/dashboard',
      icon: <DashboardOutlined />,
      label: 'Dashboard'
    }
  ];
  const items = [
    {
      key: 'welcome',
      path: '/welcome',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'My Requests',
      icon: <GiftOutlined />,
      label: 'My Requests',

      children: [
        {
          key: 'view-past-gift',
          path: '/view-past-gift',
          icon: <GiftOutlined />,
          label: 'Gifts given or to be given' // 'Giving Gifts'
        },
        {
          key: 'view-gift-received',
          path: '/view-gift-received',
          icon: <GiftOutlined />,
          label: 'Gifts received' // 'Receiving Gifts'
        }
      ]
    },
    user_role === 'requester'
      ? ''
      : {
          key: 'For Action',
          icon: <GiftOutlined />,
          label: 'For Action',

          children: [
            {
              key: 'view-gift-requests',
              path: '/view-gift-requests',
              icon: <GiftOutlined />,
              label: 'Review gift given requests'
            },
            {
              key: 'view-gift-received-request',
              path: '/view-gift-received-request',
              icon: <GiftOutlined />,
              label: 'Review gift received requests'
            }
          ]
        }
  ];
  const itemsCEC = [
    {
      key: 'welcome',
      path: '/welcome',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'My Requests',
      icon: <GiftOutlined />,
      label: 'My Requests',

      children: [
        {
          key: 'view-past-gift',
          path: '/view-past-gift',
          icon: <GiftOutlined />,
          label: 'Gifts given or to be given' // 'Giving Gifts'
        },
        /* {
              key: 'gift_yet_to_be_given',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gift Yet to be Given'
            }, */
        {
          key: 'view-gift-received',
          path: '/view-gift-received',
          icon: <GiftOutlined />,
          //  (
          //   <Image
          //     className={`${styles.gift_received_size}`}
          //     alt="gift_received-icon"
          //     src={gift_received}
          //     preview={false}
          //   />
          // ),
          label: 'Gifts received' // 'Receiving Gifts'
        }
      ]
    },
    {
      key: 'For Action',
      icon: <GiftOutlined />,
      label: 'For Action',

      children: [
        {
          key: 'view-gift-requests',
          path: '/view-gift-requests',
          icon: <GiftOutlined />,
          //  (
          //   <Image
          //     className={`${styles.gift_icon_size}`}
          //     alt="gift_requests-icon"
          //     src={gift_requests}
          //     preview={false}
          //   />
          // ),
          label: 'Review gift given requests'
        },
        {
          key: 'view-gift-received-request',
          path: '/view-gift-received-request',
          icon: <GiftOutlined />,
          // (
          //   <Image
          //     className={`${styles.gift_icon_size}`}
          //     alt="gift_requests-icon"
          //     src={gift_requests}
          //     preview={false}
          //   />
          // ),
          label: 'Review gift received requests'
        }
      ]
    },
    {
      key: 'admin',
      path: '/admin',
      icon: <FileTextOutlined />,
      /* (
        <Image
          className={`${styles.gift_icon_size}`}
          alt="gift_requests-icon"
          src={settings}
          preview={false}
        />
      ), */
      label: 'Settings'
    },
    {
      key: 'reports',
      path: '/reports',
      icon: <FileTextOutlined />,
      label: 'Reports'
    }
  ];
  const itemsRo = [
    {
      key: 'welcome',
      path: '/welcome',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'My Requests',
      icon: <GiftOutlined />,
      label: 'My Requests',

      children: [
        {
          key: 'view-past-gift',
          path: '/view-past-gift',
          icon: <GiftOutlined />,
          label: 'Gifts given or to be given' // 'Giving Gifts'
        },
        /* {
              key: 'gift_yet_to_be_given',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gift Yet to be Given'
            }, */
        {
          key: 'view-gift-received',
          path: '/view-gift-received',
          icon: <GiftOutlined />,
          //  (
          //   <Image
          //     className={`${styles.gift_received_size}`}
          //     alt="gift_received-icon"
          //     src={gift_received}
          //     preview={false}
          //   />
          // ),
          label: 'Gifts received' // 'Receiving Gifts'
        }
      ]
    },
    {
      key: 'For Action',
      icon: <GiftOutlined />,
      label: 'For Action',

      children: [
        {
          key: 'view-gift-requests',
          path: '/view-gift-requests',
          icon: <GiftOutlined />,
          //  (
          //   <Image
          //     className={`${styles.gift_icon_size}`}
          //     alt="gift_requests-icon"
          //     src={gift_requests}
          //     preview={false}
          //   />
          // ),
          label: 'Review gift given requests'
        },
        {
          key: 'view-gift-received-request',
          path: '/view-gift-received-request',
          icon: <GiftOutlined />,
          // (
          //   <Image
          //     className={`${styles.gift_icon_size}`}
          //     alt="gift_requests-icon"
          //     src={gift_requests}
          //     preview={false}
          //   />
          // ),
          label: 'Review gift received requests'
        }
      ]
    },
    {
      key: 'view-gift-returns',
      path: '/view-gift-returns',
      icon: <GiftOutlined />,
      //   <Image
      //     className={`${styles.gift_icon_size}`}
      //     alt="gift_returns-icon"
      //     src={gift_returns}
      //     preview={false}
      //   />
      // ),
      label: 'Review Gift Returns'
    }
  ];
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const userDetails = LocalStorage.getItem('GiftReg_user');
  // const userFullName = userDetails?.user?.name ? (
  //   <span>{userDetails.user?.name.slice(0, 1)}</span>
  // ) : (
  //   'U'
  // );

  const [selectedMenuKey] = useState(
    history.location.pathname.replace('/', '')
  );

  const [current, setCurrent] = useState(
    history.location.pathname.replace('/', '')
  );

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log('url', url);
        // Return false if you would like to stop navigation after local logout
        dispatch(logout());
        return true;
      }
    });
  };
  const onClick = (e) => {
    console.log('click ', e);
    if (user_role === 'admin' || user_role === 'sys-admin') {
      if (e?.key === 'admin') {
        history.push('/admin');
      } else if (e?.key === 'entry-delegator-flows') {
        history.push('/entry-delegator-flows');
      } else if (e?.key === 'review-entry-delegator-flows') {
        history.push('/review-entry-delegator-flows');
      } else if (e?.key === 'reports') {
        history.push('/reports');
      } else if (e?.key === 'dashboard') {
        history.push('/dashboard');
      }
    } else if (user_role === 'cec-user') {
      if (e?.key === 'welcome') {
        history.push('/welcome');
      } else if (e?.key === 'view-past-gift') {
        history.push('/view-past-gift');
      } else if (e?.key === 'gift_yet_to_be_given') {
        history.push('/view-past-gift');
      } else if (e?.key === 'view-gift-received') {
        history.push('/view-gift-received');
      } else if (e?.key === 'view-gift-requests') {
        history.push('/view-gift-requests');
      } else if (e?.key === 'view-gift-received-request') {
        history.push('/view-gift-received-request');
      } else if (e?.key === 'view-gift-returns') {
        history.push('/view-gift-returns');
      }
      if (e?.key === 'admin') {
        history.push('/admin');
      } else if (e?.key === 'reports') {
        history.push('/reports');
      }
    } else if (user_role === 'ro') {
      if (e?.key === 'welcome') {
        history.push('/welcome');
      } else if (e?.key === 'view-past-gift') {
        history.push('/view-past-gift');
      } else if (e?.key === 'gift_yet_to_be_given') {
        history.push('/view-past-gift');
      } else if (e?.key === 'view-gift-received') {
        history.push('/view-gift-received');
      } else if (e?.key === 'view-gift-requests') {
        history.push('/view-gift-requests');
      } else if (e?.key === 'view-gift-received-request') {
        history.push('/view-gift-received-request');
      } else if (e?.key === 'view-gift-returns') {
        history.push('/view-gift-returns');
      }
    } else {
      if (e?.key === 'welcome') {
        history.push('/welcome');
      } else if (e?.key === 'view-past-gift') {
        history.push('/view-past-gift');
      } else if (e?.key === 'gift_yet_to_be_given') {
        history.push('/view-past-gift');
      } else if (e?.key === 'view-gift-received') {
        history.push('/view-gift-received');
      } else if (e?.key === 'view-gift-requests') {
        history.push('/view-gift-requests');
      } else if (e?.key === 'view-gift-received-request') {
        history.push('/view-gift-received-request');
      }
    }
    setCurrent(e?.key);
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="left"
      width={280}
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: '42px 16px 0' }}
      footerStyle={{ padding: '0 16px 32px', border: 'none' }}
      className={styles.container}
      footer={
        <div className={styles.footer_container}>
          <Link
            to={'/welcome'}
            className={`${styles.menu_item}${
              'welcome' === selectedMenuKey ? ` ${styles.selected}` : ''
            }`}
          >
            <div className={styles.menu_item_icon}>
              <Image alt="tata-projects-logo" src={tataPro} preview={false} />
            </div>
            {/* <CustomText
              font="medium"
              size={16}
              color={'welcome' === selectedMenuKey ? 'secondary' : 1}
              className={styles.menu_item_label}
            >
              Meet the NEEV Team
            </CustomText> */}
          </Link>
          <Divider style={{ margin: '20px 0' }} />
          <div className={styles.user_container}>
            <Space size={12}>
              <Avatar className={`cs-medium ${styles.avatar}`}>
                {(userDetails.user?.name || 'U').slice(0, 1)}
              </Avatar>
              <Space direction="vertical" size={0}>
                <Text font="bold" size={15}>
                  {userDetails.user?.name}
                </Text>
                {/* <CustomText color={3}>
                  {user_details?.user_details?.job_title ||
                  user_details?.user_details?.email
                    ? '@' +
                      user_details?.user_details?.email.slice(
                        0,
                        user_details?.user_details?.email.indexOf('@')
                      )
                    : ''}
                </CustomText> */}
              </Space>
            </Space>
            <div
              className={styles.logout_btn_container}
              onClick={signOutClickHandler}
            >
              <Image alt="logout" src={logoutIcon} preview={false} />
            </div>
          </div>
        </div>
      }
    >
      <Space direction="vertical" size={30} className="w-100">
        <Space
          size={10}
          className="d-flex justify-content-between align-items-end"
        >
          <Image
            alt="tata-pojects-logo"
            src={tataProjectsLogo}
            preview={false}
          />
          {/* <Image alt="neev-logo" src={neevLogo} preview={false} /> */}
        </Space>
        <div className={styles.menu_container}>
          <Space direction="vertical" size={20} className="w-100">
            {user_role === 'admin' || user_role === 'sys-admin' ? (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={itemsAdmin}
                className={`${styles.menuDropdown}`}
              />
            ) : user_role === 'cec-user' ? (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={itemsCEC}
                className={`${styles.menuDropdown}`}
              />
            ) : user_role === 'ro' ? (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={itemsRo}
                className={`${styles.menuDropdown}`}
              />
            ) : (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
                className={`${styles.menuDropdown}`}
              />
            )}
          </Space>
        </div>
      </Space>
    </Drawer>
  );
};

export default LeftDrawer;

import React, { useEffect } from 'react';
import { Button, Row, Spin, Col, Dropdown } from 'antd';
import lady from '../../image/lady.svg';
import gift from '../../image/gift.svg';
import arrow from '../../image/arrow.svg';
import styles from './index.module.less';
import { history } from 'app/history';
import CardStatus from './cardStatus';
import { getDashboardCounts } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { sizeChecker } from 'utilities/helpers';
// import { GiftOutlined } from '@ant-design/icons';
// import gift_requests from '../../../../assets/images/gift_requests.png';
// import gift_received from '../../../../assets/images/gift_received.png';
// import gift_returns from '../../../../assets/images/gift_returns.png';
// import settings from '../../../../assets/images/settings.png';

function WelcomePage({ user_role }) {
  const isMobile = sizeChecker('xs');

  const dispatch = useDispatch();

  console.log('user_role', user_role);

  const {
    acceptedCount,
    pendingCount,
    rejectedCount,
    returnedCount,
    waitingForOthersCount,
    getDashboardCountsLoading
  } = useSelector((state) => state.dashboard);

  //console.log('dashboardCounts', dashboardCounts);
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let payload = {};
    dispatch(getDashboardCounts(payload));
  }, []);

  const items = [
    {
      key: 'Gifts_I_want_to_give',
      //icon: <GiftOutlined />,
      label: (
        <span onClick={() => history.push('/view-past-gift')}>
          Gifts given or to be given
        </span>
      )

      // children: [
      //   {
      //     type: 'group',

      //     children: [
      //       {
      //         key: 'view-gift-requests',
      //         path: '/view-gift-requests',
      //         icon: <GiftOutlined />,
      //         //  (
      //         //   <Image
      //         //     className={`${styles.gift_icon_size}`}
      //         //     alt="gift_requests-icon"
      //         //     src={gift_requests}
      //         //     preview={false}
      //         //   />
      //         // ),
      //         label: (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href="/view-gift-requests"
      //           >
      //             Review gift given requests
      //           </a>
      //         )
      //       },
      //       {
      //         key: 'view-gift-received-request',
      //         path: '/view-gift-received-request',
      //         icon: <GiftOutlined />,
      //         // (
      //         //   <Image
      //         //     className={`${styles.gift_icon_size}`}
      //         //     alt="gift_requests-icon"
      //         //     src={gift_requests}
      //         //     preview={false}
      //         //   />
      //         // ),
      //         label: (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href="/view-gift-received-request"
      //           >
      //             Review gift received requests
      //           </a>
      //         )
      //       },
      //       {
      //         key: 'view-gift-returns',
      //         path: '/view-gift-returns',
      //         icon: <GiftOutlined />,
      //         // (
      //         //   <Image
      //         //     className={`${styles.gift_icon_size}`}
      //         //     alt="gift_returns-icon"
      //         //     src={gift_returns}
      //         //     preview={false}
      //         //   />
      //         // ),
      //         label: (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href="/view-gift-returns"
      //           >
      //             Review Gift Returns
      //           </a>
      //         )
      //       }
      //     ]
      //   }
      // ]
    },
    {
      key: 'view-gift-received',
      label: (
        <span onClick={() => history.push('/view-gift-received')}>
          Gifts received
        </span>
      )

      // children: [
      //   {
      //     type: 'group',
      //     // label: 'My Requests',
      //     children: [
      //       {
      //         key: 'view-past-gift',
      //         path: '/view-past-gift',
      //         icon: <GiftOutlined />,
      //         label: (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href="/view-past-gift"
      //           >
      //             Gift given/to be given
      //           </a>
      //         )
      //       },
      //       {
      //         key: 'view-gift-received',
      //         path: '/view-gift-received',
      //         icon: <GiftOutlined />,
      //         //  (
      //         //   <Image
      //         //     className={`${styles.gift_received_size}`}
      //         //     alt="gift_received-icon"
      //         //     src={gift_received}
      //         //     preview={false}
      //         //   />
      //         // ),
      //         label: (
      //           <a
      //             target="_blank"
      //             rel="noopener noreferrer"
      //             href="/view-gift-received"
      //           >
      //             Gifts received
      //           </a>
      //         )
      //       }
      //     ]
      //   }
      // ]
    }

    /* {
      key: 'Gifts I already gave',
      label: (
        <span onClick={() => history.push('/view-past-gift')}>
          Gifts I already gave
        </span>
      )
    } */
  ];

  return (
    <Spin spinning={getDashboardCountsLoading}>
      <div className={`${styles.lady_background_welcome}`}>
        <Row
          gutter={16}
          style={{
            flexDirection: isMobile ? 'column' : 'row'
          }}
        >
          <Col span={isMobile ? 24 : 6}>
            <img className={`${styles.lady_img}`} src={lady} />
          </Col>
          <Col span={isMobile ? 24 : 17}>
            <div className={`${styles.cardStatus_container}`}>
              <div className={`${styles.user_name}`}>
                Welcome, {user_role?.name}!
              </div>
              <div className={`${styles.user_name_subText}`}>
                <CardStatus
                  pending={pendingCount}
                  accepted={acceptedCount}
                  rejected={rejectedCount}
                  returned={returnedCount}
                  action={waitingForOthersCount}
                />
              </div>
              <Row
                className={
                  user_role?.roles[0] === 'cec-user'
                    ? `${styles.declare_gift_button_containerCEC}`
                    : `${styles.declare_gift_button_container}`
                }
              >
                <Button
                  style={{
                    marginBottom:
                      user_role?.roles[0] === 'cec-user' && isMobile
                        ? '10px'
                        : '10px'
                  }}
                  className={`${styles.user_name_button}`}
                  onClick={() => history.push('/declare-a-gift')}
                >
                  Declare a Gift
                </Button>
                {isMobile ? (
                  <Dropdown
                    menu={{ items }}
                    placement="bottom"
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Button
                        className={`${styles.user_name_button1}`}
                        style={{
                          marginBottom:
                            user_role?.roles[0] === 'cec-user' && isMobile
                              ? '10px'
                              : '10px'
                        }}
                      >
                        View status of all past requests
                      </Button>
                    </a>
                  </Dropdown>
                ) : (
                  <Dropdown menu={{ items }} placement="bottom">
                    <Button
                      className={`${styles.user_name_button1}`}
                      style={{
                        marginBottom:
                          user_role?.roles[0] === 'cec-user' && isMobile
                            ? '10px'
                            : ''
                      }}
                    >
                      View status of all past requests
                    </Button>
                  </Dropdown>
                )}

                {user_role?.roles[0] === 'cec-user' && (
                  <Button
                    style={{
                      marginBottom:
                        user_role?.roles[0] === 'cec-user' && isMobile
                          ? '10px'
                          : ''
                    }}
                    className={`${styles.user_name_button1}`}
                    onClick={() => history.push('/dashboard')}
                  >
                    View Dashboard
                  </Button>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className={`${styles.policies_background}`}>
        <Row
          style={{
            flexDirection: isMobile ? 'column' : 'row'
          }}
        >
          <Col span={isMobile ? 24 : 7}>
            <div className={`${styles.policies_title}`}>
              <a
                className={`${styles.a_color}`}
                // href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                href="/Gifts_Hospitality_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img className={`${styles.gift_img}`} src={gift} />
              </a>
            </div>
            <div className={`${styles.policies_title}`}>Our Policies</div>
            <div className={`${styles.policies_text}`}>
              <a
                className={`${styles.a_color}`}
                //href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                href="/Gifts_Hospitality_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Click here to view the policies
              </a>
            </div>
          </Col>
          <Col span={isMobile ? 24 : 15}>
            <div
              style={{ marginLeft: isMobile ? '10px' : '50px' }}
              className={`${styles.Receive_container}`}
            >
              <div className={`${styles.Receive_title}`}>Receive a gift </div>
              <div className={`${styles.Receive_text}`}>
                The Company recognizes that it is customary for some of its
                business associates to occasionally give modest gifts to those
                in the Company with whom they do business or have transactional
                dealings. It is important, however, that these gifts do not
                affect an employee’s/Designated Person’s business judgment, or
                give the appearance that judgment may be affected. Accordingly,
                the Company’s employees must be very careful when it comes to
                accepting such gifts. Refer the Gifts & Hospitality policy to
                have an understanding which gifts can be accepted.
              </div>
              <div className={`${styles.learn_arrow}`}>
                <a
                  className={`${styles.learn_more}`}
                  //href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                  href="/Gifts_Hospitality_Policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                  <img src={arrow} />
                </a>{' '}
              </div>
              <div style={{ marginTop: '50px' }}>
                <div className={`${styles.gift_title}`}>Give a gift </div>
                <div className={`${styles.gift_text}`}>
                  The act of offering gifts assists in establishing or enhancing
                  all types of relationships. This is also true for the
                  corporate world and government agencies. It is also a way to
                  recognize talent and achievements. The Company should have
                  bona fide reasons for offering gifts, such as thanking
                  long-standing customers, recognizing a dedicated employee, and
                  so on. The primary intent should be to affirm the relationship
                  in the most professional manner. Refer the Gifts & Hospitality
                  Policy for more details.
                </div>
                <div className={`${styles.learn_arrow}`}>
                  <a
                    className={`${styles.learn_more}`}
                    // href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                    href="/Gifts_Hospitality_Policy.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                    <img src={arrow} />
                  </a>{' '}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}
export default WelcomePage;

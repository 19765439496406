import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  receivedGift_getbyId,
  received_gift_accept,
  received_gift_reject
} from './api';

const initialState = {
  getReceivedGiftByIdLoading: false,
  receivedGiftAcceptLoading: false,
  receivedGiftRejectLoading: false,
  receivedGiftDetailsById: []
};

const actions = {
  GET_RECEIVED_GIFT_BY_ID: 'getReceivedGiftById/GET_RECEIVED_GIFT_BY_ID',
  RECEIVED_GIFT_ACCEPT: 'receivedGiftAccept/RECEIVED_GIFT_ACCEPT',
  RECEIVED_GIFT_REJECT: 'receivedGiftReject/RECEIVED_GIFT_REJECT'
};
// /* uniqueId=8d5c7e42-e2f8-44bf-b254-22d7b61147e0&userId=8dc1d45f-bba2-42fb-9c48-91294bdfb763&hashRoot=i8u5OTS8cU0kAeTUWXeGxDt5XQo3TGxp&giftType=gr */
export const getReceivedGiftById = createAsyncThunk(
  actions.GET_RECEIVED_GIFT_BY_ID,
  async (payload) => {
    const response = await receivedGift_getbyId(
      `${payload?.id}?uniqueId=${payload?.uniqueId}&userId=${payload?.userId}&hashRoot=${payload?.hashRoot}&giftType=${payload?.giftType}`
    ); //payload
    return response;
  }
);

export const receivedGiftAccept = createAsyncThunk(
  actions.RECEIVED_GIFT_ACCEPT,
  async (payload) => {
    const response = await received_gift_accept(
      `${payload?.grId}?uniqueId=${payload?.uniqueId}&userId=${payload?.userId}&hashRoot=${payload?.hashRoot}&giftType=${payload?.giftType}`,
      payload
    );
    return response;
  }
);

export const receivedGiftReject = createAsyncThunk(
  actions.RECEIVED_GIFT_REJECT,
  async (payload) => {
    const response = await received_gift_reject(
      `${payload?.grId}?uniqueId=${payload?.uniqueId}&userId=${payload?.userId}&hashRoot=${payload?.hashRoot}&giftType=${payload?.giftType}`,
      payload
    );
    return response;
  }
);

export const receivedRequestViewSlice = createSlice({
  name: 'receivedRequestView',
  initialState,
  reducers: {
    vehicleFormData: (state, action) => {
      state.vehicleData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReceivedGiftById.pending, (state) => {
        state.getReceivedGiftByIdLoading = true;
      })
      .addCase(getReceivedGiftById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getReceivedGiftByIdLoading = false;
        state.receivedGiftDetailsById = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getReceivedGiftById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getReceivedGiftByIdLoading = false;
        message.error(msg);
      });
    builder
      .addCase(receivedGiftAccept.pending, (state) => {
        state.receivedGiftAcceptLoading = true;
      })
      .addCase(receivedGiftAccept.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.receivedGiftAcceptLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(receivedGiftAccept.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.receivedGiftAcceptLoading = false;

        message.error(msg);
      });
    builder
      .addCase(receivedGiftReject.pending, (state) => {
        state.receivedGiftRejectLoading = true;
      })
      .addCase(receivedGiftReject.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.receivedGiftRejectLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(receivedGiftReject.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.receivedGiftRejectLoading = false;

        message.error(msg);
      });
  }
});
export const { vehicleFormData } = receivedRequestViewSlice.actions;
export default receivedRequestViewSlice.reducer;

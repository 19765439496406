import apiClient from 'utilities/apiClient';

export const declare_a_gift = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.declare_a_gift}`,
    payload,
    true,
    'file'
  );
};
export const gift_master = (payload) => {
  return apiClient.get(`${apiClient.Urls.gift_master}`, payload, true);
};
export const deligators_id = (payload) => {
  return apiClient.get(`${apiClient.Urls.deligators_id}`, payload, true);
};
export const user_details = (payload) => {
  return apiClient.get(`${apiClient.Urls.user_details}`, payload, true);
};
export const bulk_upload = (payload) => {
  return apiClient.post(`${apiClient.Urls.bulk_upload}`, payload, true, 'file');
};

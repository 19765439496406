import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { message } from 'antd';
import { dashboard_counts, dashboard_api } from './api';

const initialState = {
  getDashboardCountsLoading: false,
  getWaitingForOthersLoading: false,
  getActionRequiredLoading: false,
  getApprovedLoading: false,
  getRejectedLoading: false,
  acceptedCount: '',
  pendingCount: '',
  rejectedCount: '',
  returnedCount: '',
  waitingForOthersCount: '',
  waitingForOthersList: [],
  actionRequiredList: [],
  approvedList: [],
  rejectedList: [],
  giftType: ''
};

const actions = {
  GET_DASHBOARD_COUNTS: 'getDashboardCounts/GET_DASHBOARD_COUNTS',
  GET_WAITING_FOR_OTHERS: 'getWaitingForOthers/GET_WAITING_FOR_OTHERS',
  GET_ACTION_REQUIRED: 'getActionRequired/GET_ACTION_REQUIRED',
  GET_APPROVED: 'getApproved/GET_APPROVED',
  GET_REJECT: 'getRejected/GET_REJECT'
  //GET_REJECT:'getRejected/GET_REJECT',
};
export const getDashboardCounts = createAsyncThunk(
  actions.GET_DASHBOARD_COUNTS,
  async (payload) => {
    const response = await dashboard_counts(payload);
    console.log('response', response);
    return response;
  }
);
export const getWaitingForOthers = createAsyncThunk(
  actions.GET_WAITING_FOR_OTHERS,
  async (payload) => {
    const response = await dashboard_api(`qa-waiting-for-others`, payload);
    return response;
  }
);
export const getActionRequired = createAsyncThunk(
  actions.GET_ACTION_REQUIRED,
  async (payload) => {
    const response = await dashboard_api(`qa-action-required`, payload);
    return response;
  }
);
export const getApproved = createAsyncThunk(
  actions.GET_APPROVED,
  async (payload) => {
    const response = await dashboard_api(`qa-approved`, payload);
    return response;
  }
);
export const getRejected = createAsyncThunk(
  actions.GET_REJECT,
  async (payload) => {
    const response = await dashboard_api(`qa-rejected`, payload);
    return response;
  }
);
// export const getReturn = createAsyncThunk(
//   actions.GET_RETURN,
//   async (payload) => {
//     const response = await dashboard_api(`qa-rejected`, payload);
//     return response;
//   }
// );
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    giftTypes: (state, action) => {
      state.giftType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.getDashboardCountsLoading = true;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        console.log('action.payload', action.payload);
        const {
          acceptedCount,
          pendingCount,
          rejectedCount,
          returnedCount,
          waitingForOthersCount,
          pagination
        } = action.payload;

        state.getDashboardCountsLoading = false;
        state.acceptedCount = acceptedCount ? acceptedCount : '';
        state.pendingCount = pendingCount ? pendingCount : '';
        state.rejectedCount = rejectedCount ? rejectedCount : '';
        state.returnedCount = returnedCount ? returnedCount : '';
        state.waitingForOthersCount = waitingForOthersCount
          ? waitingForOthersCount
          : '';
        state.pagination = pagination || null;
      })
      .addCase(getDashboardCounts.rejected, (state) => {
        state.getDashboardCountsLoading = false;
      });
    builder
      .addCase(getWaitingForOthers.pending, (state) => {
        state.getWaitingForOthersLoading = true;
      })
      .addCase(getWaitingForOthers.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getWaitingForOthersLoading = false;
        state.waitingForOthersList = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getWaitingForOthers.rejected, (state) => {
        state.getWaitingForOthersListLoading = false;
      });
    ///
    builder
      .addCase(getActionRequired.pending, (state) => {
        state.getActionRequiredLoading = true;
      })
      .addCase(getActionRequired.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getActionRequiredLoading = false;
        state.actionRequiredList = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getActionRequired.rejected, (state) => {
        state.getActionRequiredLoading = false;
      });
    ///
    builder
      .addCase(getApproved.pending, (state) => {
        state.getApprovedLoading = true;
      })
      .addCase(getApproved.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getApprovedLoading = false;
        state.approvedList = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getApproved.rejected, (state) => {
        state.getApprovedLoading = false;
      });
    ////
    builder
      .addCase(getRejected.pending, (state) => {
        state.getRejectedLoading = true;
      })
      .addCase(getRejected.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getRejectedLoading = false;
        state.rejectedList = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getRejected.rejected, (state) => {
        state.getRejectedLoading = false;
      });
  }
});
export const { giftTypes } = dashboardSlice.actions;
export default dashboardSlice.reducer;

import React, { useState, useEffect, useMemo } from 'react';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Select, Form, Space, Row, Spin } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  get_all_users,
  get_all_sbg,
  map_entry_delegator
} from '../../../redux/slice';
import { debounce } from 'lodash';
//import { sizeChecker } from 'utilities/helpers';

//const { TextArea } = Input;
const { Option } = Select;

function CreateNewDelegatorFlow() {
  //const isMobile = sizeChecker('xs');

  TabTitle('Gift Register - Create New Delegator Flow');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  const [allUsersSearch, setAllUsersSearch] = useState('');
  const [allSbgSearch, setAllSbgSearch] = useState('');

  const {
    get_all_usersLoading,
    all_users_List,
    map_entry_delegatorLoading,
    all_sbg_List,
    get_all_sbgLoading
  } = useSelector((state) => state.admin);
  console.log('all_users_List', all_users_List);

  useEffect(() => {
    const payload = {};
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]);

  useEffect(() => {
    const payload = {};
    if (allSbgSearch) payload['search'] = allSbgSearch;
    dispatch(get_all_sbg(payload));
  }, [allSbgSearch]);

  const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherSbgId = useMemo(() => {
    const loadOptions = (allSbgSearch) => {
      setAllSbgSearch(allSbgSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = (values) => {
    console.log(values);
    const payload = {
      delegator_id: formValues?.delegatorId,
      delegate_id: formValues?.delegateId
    };
    dispatch(map_entry_delegator(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/entry-delegator-flows');
      }
    });
  };

  return (
    <CustomLayout>
      <Spin spinning={get_all_usersLoading}>
        <div style={{ marginTop: '50px' }}>
          <Row className={`${styles.row_container_setting}`}>
            <div className={`${styles.gift_container}`}>
              <div className={`${styles.myGift_title_setting}`}>
                Configure new delegate
              </div>
            </div>
          </Row>{' '}
          <div className={`${styles.container}`}>
            <div className={`${styles.cardContainer}`}>
              <Card className={`${styles.cardStyles_Container}`}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className={`${styles.formstyles}`}
                >
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the delegator
                          </div>
                        }
                        name="delegatorId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select delegator"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('delegatorId', value)
                          }
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                        >
                          {all_users_List?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {`${item?.givenName} (${item.email})`}
                              </Option>
                            );
                          })}
                        </Select>
                        {/* <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select delegator"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('delegatorId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select> */}
                      </FormItem>
                    </div>
                  </Card>
                  <Spin spinning={get_all_sbgLoading}>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Please select the delegate
                            </div>
                          }
                          name="delegateId"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            onSearch={debounceFetcherSbgId}
                            placeholder="Select delegate"
                            onChange={(value) =>
                              handleFormDataOnChange('delegateId', value)
                            }
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                          >
                            {all_sbg_List?.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item?.givenName} (${item.email})`}
                                </Option>
                              );
                            })}
                          </Select>
                          {/* <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select delegate"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('delegateId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select> */}
                        </FormItem>
                      </div>
                    </Card>
                  </Spin>
                  <div style={{ marginTop: '20px' }}>
                    <FormItem>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: ' center'
                        }}
                      >
                        <Button
                          loading={map_entry_delegatorLoading}
                          className={`${styles.ExceptionalApproval_button}`}
                          htmlType="submit"
                          size="small"
                        >
                          Save
                        </Button>

                        <Button
                          className={`${styles.cancel_rejectModal_button}`}
                          size="small"
                          onClick={() => history.push('/entry-delegator-flows')}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </FormItem>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
}
export default CreateNewDelegatorFlow;

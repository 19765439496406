import React, { useState, useEffect, useMemo } from 'react'; //, useMemo
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Space, Row, Spin, Input, Select } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  //create_bu_master,
  get_all_users,
  get_RO_Master,
  create_ROUsers_master
} from '../../../../redux/slice';
import { debounce } from 'lodash';
import FormData from 'form-data';

//const { TextArea } = Input;
const { Option } = Select;

function CreateRoUsersFlows() {
  TabTitle('Gift Register - Create New RO Flow');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  const [allUsersSearch, setAllUsersSearch] = useState('');
  const [allLocationSearch, setAllLocationSearch] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');

  const {
    get_all_usersLoading,
    all_users_List,
    create_bu_masterLoading,
    //create_ROUsers_masterLoading,
    ro_MasterList
  } = useSelector((state) => state.admin);
  console.log('all_users_List', all_users_List);

  useEffect(() => {
    const payload = {};
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]);

  const debounceFetcherUserId = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  useEffect(() => {
    const payload = {};
    if (allLocationSearch) payload['search'] = allLocationSearch;
    dispatch(get_RO_Master(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log('response?.payload?.data', response?.payload?.data);
      }
    });
  }, [allLocationSearch]);

  const debounceFetcherallLocationSearch = useMemo(() => {
    const loadOptions = (allLocationSearch) => {
      setAllLocationSearch(allLocationSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  useEffect(() => {
    let locationValues = ro_MasterList?.filter(
      (item) => item?.id === formValues?.locationName
    );
    console.log('locationValues', locationValues);
    setLocationName(locationValues[0]?.location);
    setLocationAddress(locationValues[0]?.locationAddress);
  }, [formValues?.locationName]);

  console.log('LocationName', locationName);
  console.log('LocationAddress', locationAddress);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    let formdata = new FormData();
    formdata.append('id', formValues?.buName);
    formdata.append('locationName', locationName);

    formdata.append('locationAddress', locationAddress);
    /*  const payload = {
      id: formValues?.buName,
      locationName: locationName,
      locationAddress: locationAddress
    }; */
    dispatch(create_ROUsers_master(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/roUsers-flow');
      }
    });
  };

  return (
    <CustomLayout>
      <Spin spinning={get_all_usersLoading}>
        <div style={{ marginTop: '50px' }}>
          <Row className={`${styles.row_container_setting}`}>
            <div className={`${styles.gift_container}`}>
              <div className={`${styles.myGift_title_setting}`}>Add new RO</div>
            </div>
          </Row>{' '}
          <div className={`${styles.container}`}>
            <div className={`${styles.cardContainer}`}>
              <Card className={`${styles.cardStyles_Container}`}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className={`${styles.formstyles}`}
                >
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Select the RO User Name
                          </div>
                        }
                        name="buName"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select the RO User Name"
                          onSearch={debounceFetcherUserId}
                          onChange={(value) =>
                            handleFormDataOnChange('buName', value)
                          }
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                        >
                          {all_users_List?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {`${item?.givenName} (${item.email})`}
                              </Option>
                            );
                          })}
                        </Select>
                        {/* <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select the RO User Name"
                          onSearch={debounceFetcherUserId}
                          onChange={(value) =>
                            handleFormDataOnChange('buName', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select> */}
                      </FormItem>
                    </div>
                  </Card>

                  {/* <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the BU Head from the list below
                          </div>
                        }
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select BU Head"
                          onSearch={debounceFetcherUserId}
                          onChange={(value) =>
                            handleFormDataOnChange('userId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} {''} {item?.surname}(
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Enter the SBG full name
                          </div>
                        }
                        name="sbgName"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Input
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the SBG full name"
                          onChange={(e) =>
                            handleFormDataOnChange('sbgName', e.target.value)
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the SBG Head from the list below
                          </div>
                        }
                        name="sbgId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select SBG Head"
                          onSearch={debounceFetcherUserId}
                          onChange={(value) =>
                            handleFormDataOnChange('sbgId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} {''} {item?.surname}(
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card> */}
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Select the Location
                          </div>
                        }
                        name="locationName"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Enter the Location"
                          onSearch={debounceFetcherallLocationSearch}
                          onChange={(e) =>
                            handleFormDataOnChange('locationName', e)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {ro_MasterList?.map((item, index) => (
                            <Option
                              title={item?.location}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                {item?.location}
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Enter the Location Address
                          </div>
                        }
                        //name="locationAddress"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Input
                          disabled
                          value={locationAddress}
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the Location Address"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'locationAddress',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  <div style={{ marginTop: '20px' }}>
                    <FormItem>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: ' center'
                        }}
                      >
                        <Button
                          loading={create_bu_masterLoading}
                          className={`${styles.ExceptionalApproval_button}`}
                          htmlType="submit"
                          size="small"
                        >
                          Save
                        </Button>

                        <Button
                          className={`${styles.cancel_rejectModal_button}`}
                          size="small"
                          onClick={() => history.push('/roUsers-flow')}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </FormItem>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
}
export default CreateRoUsersFlows;

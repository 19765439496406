import styles from './index.module.less';
import { Space, Image, Row, Col, Tooltip } from 'antd'; //, Card
import { FormOutlined } from '@ant-design/icons'; // ClockCircleOutlined,
// SafetyCertificateOutlined,
// CloseSquareOutlined,
// RollbackOutlined

import { numFormating } from 'utilities/helpers';
import pendingIcon from '../../../image/pendingIcon.svg';
import approvedIcon from '../../../image/approvedIcon.svg';
import rejectedIcon from '../../../image/rejectedIcon.svg';
import returnedIcon from '../../../image/returnedIcon.svg';
import { sizeChecker } from 'utilities/helpers';
// import allocated_icon from '../images/allocated_icon.svg';

export default function CardStatus({
  pending,
  accepted,
  rejected,
  returned,
  action
}) {
  const isMobile = sizeChecker('xs');
  /* <a href={`view-gift-details/${record.id}`}>
                {ticket === null ? '' : ticket}
              </a> 
              
              <a href={itm.link} target="_blank" rel="noreferrer">
                        <ArrowRightOutlined />
                      </a>*/
  const cardContent = [
    {
      id: 1,
      title: (
        <a href={`/pending-approvals`} className={`${styles.a_color}`}>
          <Tooltip
            title={
              'Gift Requests that I have raised that are pending for approval.'
            }
            color={'white'}
            overlayInnerStyle={{
              color: '#32324D',
              fontSize: '12px'
            }}
            arrowPointAtCenter="false"
          >
            PENDING APPROVALS {/* WAITING FOR OTHERS */}
          </Tooltip>
        </a>
      ), //'Waiting for others', //
      value: action,
      icon: (
        <a href="">
          <Image
            className={`${styles.gift_pending_icon_size}`}
            alt="pending-icon"
            src={pendingIcon}
            preview={false}
          />
        </a>
      ) //<ClockCircleOutlined style={{ color: '#455A64' }} />
    },
    {
      id: 2,
      title: (
        <a href="/action-required" className={`${styles.a_color}`}>
          <Tooltip
            title={'Gift Requests that are waiting for my approval'}
            color={'white'}
            overlayInnerStyle={{
              color: '#32324D',
              fontSize: '12px'
            }}
            arrowPointAtCenter="false"
          >
            ACTION REQUIRED
          </Tooltip>
        </a>
      ), //'Action required', //
      value: pending,
      icon: (
        <a href="/action-required">
          <FormOutlined className={`${styles.gift_antd_icon_size}`} />
        </a>
      )
    },
    {
      id: 3,
      title: (
        <a href="/approved" className={`${styles.a_color}`}>
          <Tooltip
            title={'My gift requests that are approved.'}
            color={'white'}
            overlayInnerStyle={{
              color: '#32324D',
              fontSize: '12px'
            }}
            arrowPointAtCenter="false"
          >
            {' '}
            APPROVED
          </Tooltip>
        </a>
      ),
      value: accepted,
      icon: (
        <a href="/approved">
          <Image
            className={`${styles.gift_icon_size}`}
            alt="approvedIcon"
            src={approvedIcon}
            preview={false}
          />
        </a>
      ) // <SafetyCertificateOutlined style={{ color: '#18AB27' }} /> // initiated_icon //<QuestionCircleOutlined />
    },

    {
      id: 4,
      title: (
        <a href="/rejected" className={`${styles.a_color}`}>
          <Tooltip
            title={'My gift requests that are rejected.'}
            color={'white'}
            overlayInnerStyle={{
              color: '#32324D',
              fontSize: '12px'
            }}
            arrowPointAtCenter="false"
          >
            REJECTED
          </Tooltip>
        </a>
      ),
      value: rejected,
      icon: (
        <a href="/rejected">
          <Image
            className={`${styles.gift_icon_size}`}
            alt="rejectedIcon"
            src={rejectedIcon}
            preview={false}
          />
        </a>
      ) // <CloseSquareOutlined style={{ color: '#B71212' }} /> // proposed_icon //<QuestionCircleOutlined />
    },

    {
      id: 5,
      title: (
        <a href="/returned" className={`${styles.a_color}`}>
          <Tooltip
            title={'Gifts returned to RO/Gift giver'}
            color={'white'}
            overlayInnerStyle={{
              color: '#32324D',
              fontSize: '12px'
            }}
            arrowPointAtCenter="false"
          >
            RETURNED
            {/* GIFTS RETURNED TO RO/GIFT GIVER style={{ fontSize: '12px' }}*/}
          </Tooltip>
        </a>
      ),
      value: returned,
      icon: (
        <a href="/returned">
          <Image
            className={`${styles.gift_icon_size}`}
            alt="returnedIcon"
            src={returnedIcon}
            preview={false}
          />
        </a>
      ) // <RollbackOutlined style={{ color: '#2264AE' }} /> //ratified_icon // <QuestionCircleOutlined />
    }
  ];
  return (
    <div>
      <div className={`${styles.card_container} mb-3 `}>
        <Space
          direction={isMobile ? 'vertical' : 'horizontal'}
          size="middle"
          //gap={8}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {cardContent?.map((item, index) => (
            <div key={index} className={`${styles.card_content_style}`}>
              <Row style={{ height: '45px' }}>
                <Col span={7}>
                  <div className={`${styles.card_icon_div}`}>
                    <span className={`${styles.card_icon_style}`}>
                      {item?.icon}
                    </span>
                  </div>
                </Col>
                <Col span={17}>
                  <span className={`${styles.card_title_style}`}>
                    {item?.title}
                  </span>
                </Col>
              </Row>
              <Row className={`${styles.card_amount_style}`}>
                {numFormating(item?.value)}
              </Row>
            </div>
          ))}
        </Space>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import SeniorManagementFlowPage from './SeniorManagementFlowPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_Senior_Management_Flow,
  remove_bu_master
} from '../../../redux/slice';
import FormData from 'form-data';

function SeniorManagementFlow() {
  TabTitle('Gift Register - Senior Management Flows');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    senior_ManagementList,
    pagination,
    get_Senior_Management_FlowLoading,
    remove_entry_delegatorLoading
  } = useSelector((state) => state.admin);

  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  //console.log('searchRequest', searchRequest);
  //console.log(triggerFilter);
  console.log('senior_ManagementList', senior_ManagementList);

  useEffect(() => {
    handleGetAllEntryDelegatorsList();
  }, [page, pageSize]);
  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllEntryDelegatorsList();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]); //page,

  const handleGetAllEntryDelegatorsList = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };
    dispatch(get_Senior_Management_Flow(payload));
  };

  /* const handleChange = (e) => {
    const value = { search: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  };*/
  const handleDeleteRequest = (record) => {
    let formdata = new FormData();
    //console.log('record', record?.record);
    formdata.append('isActive', false);
    formdata.append('id', record?.record?.key);

    dispatch(remove_bu_master(formdata)).then(function (response) {
      if (response) {
        handleGetAllEntryDelegatorsList();
      }
    });
  };
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <SeniorManagementFlowPage
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          setTriggerFilter={setTriggerFilter}
          senior_ManagementList={senior_ManagementList}
          pagination={pagination}
          get_Senior_Management_FlowLoading={get_Senior_Management_FlowLoading}
          setSearchRequest={setSearchRequest}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          handleDeleteRequest={handleDeleteRequest}
          remove_entry_delegatorLoading={remove_entry_delegatorLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default SeniorManagementFlow;

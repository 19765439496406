import React from 'react';
import DashboardPage from './components/dashboardPage';
import CustomLayout from '@/common/Layout';
//import LocalStorage from 'utilities/localStorage';
import { getLoggedInUser } from '../../utilities/helpers';

//import { useDispatch, useSelector } from 'react-redux';

const Dashboard = () => {
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  // console.log("user_role",user_role?.name)

  /* const { allRequests, pagination, getAllApproverLoading } = useSelector(
    (state) => state.dashboard
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showDateranga, setShowDateranga] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter]); //page,
  const handleGetRequests = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter
    };
    dispatch(getAllRequests(payload));
  };

  const handleDeleteRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteRequest(id)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };
  const handleDeleteDraft = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteDraft(id)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };

  const handleChange = (e) => {
    const value = { ticket: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  }; */

  return (
    <>
      <CustomLayout>
        <div style={{ marginTop: '50px' }}>
          <DashboardPage user_role={user_role} />
        </div>
      </CustomLayout>
    </>
  );
};

export default Dashboard;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  returnGift_list,
  delete_requests,
  roUsers_list,
  returnGift_form
} from './api';

const initialState = {
  returnGiftsListLoading: false,
  getAllApproverLoading: false,
  getROUsersListLoading: false,
  returnGift_formLoading: false,
  returnGiftsList: [],
  CountsReturnGiftsList: {},
  roUsersList: [],
  pagination: []
};

const actions = {
  GET_RETURN_GIFT_LIST: 'getReturnGiftsList/GET_RETURN_GIFT_LIST',
  DELETE_REQUEST: 'deleteRequest/DELETE_REQUEST',
  //SEARCH_TICKET: 'searchvalues/SEARCH_TICKET',
  GET_RO_USERS_LIST: 'getROUsersList/GET_RO_USERS_LIST',
  RETURN_GIFT_RO_FORM: 'returnGift_RO_form/RETURN_GIFT_RO_FORM',
  RETURN_GIFT_GIVER_FORM: 'returnGift_Giver_form/RETURN_GIFT_GIVER_FORM',
  RETURN_GIFT_CEC_FORM: 'returnGift_RO_form/RETURN_GIFT_CEC_FORM'
};

export const getReturnGiftsList = createAsyncThunk(
  actions.GET_RETURN_GIFT_LIST,
  async (payload) => {
    const response = await returnGift_list(payload);
    return response;
  }
);
export const getROUsersList = createAsyncThunk(
  actions.GET_RO_USERS_LIST,
  async (payload) => {
    const response = await roUsers_list(payload);
    return response;
  }
);
export const returnGift_RO_form = createAsyncThunk(
  actions.RETURN_GIFT_RO_FORM,
  async (payload) => {
    const response = await returnGift_form(
      `return-to-ro/${payload?.get('grId')}`,
      payload
    );
    return response;
  }
);
export const returnGift_Giver_form = createAsyncThunk(
  actions.RETURN_GIFT_GIVER_FORM,
  async (payload) => {
    const response = await returnGift_form(
      `return-to-giver/${payload?.get('grId')}`,
      payload
    );
    return response;
  }
);
export const returnGift_CEC_form = createAsyncThunk(
  actions.RETURN_GIFT_CEC_FORM,
  async (payload) => {
    const response = await returnGift_form(
      `return-to-cec/${payload?.get('grId')}`,
      payload
    );
    return response;
  }
);
export const deleteRequest = createAsyncThunk(
  actions.DELETE_REQUEST,
  async (payload) => {
    const response = await delete_requests(
      `delete/gatepass?gpid=${payload.gpid}`,
      payload
    );
    return response;
  }
);

/* export const searchvalues = createAsyncThunk(
  actions.SEARCH_TICKET,
  async (payload) => {
    const response = await requests(
      `gatepass?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
); */

export const returnGiftsListSlice = createSlice({
  name: 'returnGifts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReturnGiftsList.pending, (state) => {
        state.returnGiftsListLoading = true;
      })
      .addCase(getReturnGiftsList.fulfilled, (state, action) => {
        const { counts, data, pagination } = action.payload;
        state.returnGiftsListLoading = false;
        state.returnGiftsList = data ? data : [];
        state.CountsReturnGiftsList = counts ? counts : {};
        state.pagination = pagination || null;
      })
      .addCase(getReturnGiftsList.rejected, (state) => {
        state.returnGiftsListLoading = false;
      });

    builder
      .addCase(getROUsersList.pending, (state) => {
        state.getROUsersListLoading = true;
      })
      .addCase(getROUsersList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getROUsersListLoading = false;
        state.roUsersList = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getROUsersList.rejected, (state) => {
        state.getROUsersListLoading = false;
      });
    builder
      .addCase(returnGift_RO_form.pending, (state) => {
        state.returnGift_formLoading = true;
      })
      .addCase(returnGift_RO_form.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.returnGift_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(returnGift_RO_form.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.returnGift_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(returnGift_Giver_form.pending, (state) => {
        state.returnGift_formLoading = true;
      })
      .addCase(returnGift_Giver_form.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.returnGift_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(returnGift_Giver_form.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.returnGift_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(deleteRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success('record deleted successfully');
        }
      })
      .addCase(deleteRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(returnGift_CEC_form.pending, (state) => {
        state.returnGift_formLoading = true;
      })
      .addCase(returnGift_CEC_form.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.returnGift_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(returnGift_CEC_form.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.returnGift_formLoading = false;

        message.error(msg);
      });
    /* builder
      .addCase(searchvalues.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvalues.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(searchvalues.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      }); */
  }
});
export default returnGiftsListSlice.reducer;

import DeclareGiftPage from './components/declareGiftPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';

function DeclareGift() {
  TabTitle('Gift Register - Declare a Gift');
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <DeclareGiftPage />
      </div>
    </CustomLayout>
  );
}
export default DeclareGift;

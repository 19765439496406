ColumnChart;
import React from 'react';
import { Chart } from 'react-google-charts';
function ColumnChart({ stakeHolderCount }) {
  const options = {
    title: 'Gifts by Recipient Type',
    colors: ['#3366cc', '#109618'],
    is3D: true,
    fontSize: 16
  };

  let newArray = ['', ''];

  const arrOfArr4 = Object.keys(stakeHolderCount).map((key) => [
    //key,
    stakeHolderCount[key]['name'],
    stakeHolderCount[key]['totalCount']
  ]);
  let newArrOfArr = [newArray, ...arrOfArr4];
  // console.log('arrOfArr4]@@@@@', arrOfArr4);
  // console.log('444444444', newArrOfArr);
  // Output: [['a', 1], ['b', 2], ['c', 3]]
  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={newArrOfArr}
      options={options}
    />
  );
}
export default ColumnChart;

import React, { useState, useEffect, useMemo } from 'react';
import CustomLayout from '@/common/Layout';
import { TabTitle } from 'utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Select, Form, Space, Row, Spin } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  get_all_users,
  set_role_mapping,
  get_all_roles
} from '../../../../redux/slice';
import { debounce } from 'lodash';
import FormData from 'form-data';

//const { TextArea } = Input;
const { Option } = Select;

function Create_CXO_Users() {
  TabTitle('Gift Register - Assign Role To the User');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  //const [allUsersSearch, setAllUsersSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');

  const {
    get_all_usersLoading,
    all_users_List,
    set_role_mappingLoading,
    all_roles_list
  } = useSelector((state) => state.admin);
  console.log('all_users_List', all_users_List);
  console.log('all_roles_list', all_roles_list);

  useEffect(() => {
    const payload = {};
    if (userSearch) payload['search'] = userSearch;
    dispatch(get_all_users(payload));
  }, [userSearch]);

  useEffect(() => {
    const payload = {
      role: 'cxo'
    };
    //if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_roles(payload));
  }, []); //allUsersSearch

  /* const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []); */

  const debounceFetcherUserId = useMemo(() => {
    const loadOptions = (userSearch) => {
      setUserSearch(userSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = (values) => {
    let formdata = new FormData();
    formdata.append(
      'userId',
      values?.userId === undefined ? '' : values?.userId
    );
    formdata.append('roleId', all_roles_list[0]?.id);
    // formdata.append('isActive', true);
    dispatch(set_role_mapping(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/cxo-users-flow');
      }
    });
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <Spin spinning={get_all_usersLoading}>
          <div style={{ marginTop: '50px' }}>
            <Row className={`${styles.row_container_setting}`}>
              <div className={`${styles.gift_container}`}>
                <div className={`${styles.myGift_title_setting}`}>
                  Assign role to the user
                </div>
              </div>
            </Row>{' '}
            <div className={`${styles.container}`}>
              <div className={`${styles.cardContainer}`}>
                <Card className={`${styles.cardStyles_Container}`}>
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    className={`${styles.formstyles}`}
                  >
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Please select the CXO User
                            </div>
                          }
                          name="userId"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select user"
                            onSearch={debounceFetcherUserId}
                            onChange={(value) =>
                              handleFormDataOnChange('userId', value)
                            }
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                          >
                            {all_users_List?.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item?.givenName} (${item.email})`}
                                </Option>
                              );
                            })}
                          </Select>
                          {/* <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select user"
                            //onSearch={inputVenderName}
                            onSearch={debounceFetcherUserId}
                            onChange={(value) =>
                              handleFormDataOnChange('userId', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {all_users_List?.map((item, index) => (
                              <Option
                                title={item?.givenName}
                                key={index}
                                value={item?.id}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  <span style={{ marginLeft: '10px' }}>
                                    {item?.givenName} {''} {item?.surname}(
                                    <span
                                      style={{ textTransform: 'lowercase' }}
                                    >
                                      {item?.email}
                                    </span>
                                    )
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select> */}
                        </FormItem>
                      </div>
                    </Card>
                    {/*  <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Please select the role
                            </div>
                          }
                          name="roleId"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select role"
                            onSearch={debounceFetcherAllUsers}
                            onChange={(value) =>
                              handleFormDataOnChange('roleId', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {all_roles_list?.map((item, index) => (
                              <Option
                                title={item?.role}
                                key={index}
                                value={item?.id}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  <span style={{ marginLeft: '10px' }}>
                                    {item?.role}
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </div>
                    </Card> */}
                    <div style={{ marginTop: '20px' }}>
                      <FormItem>
                        <Space
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: ' center'
                          }}
                        >
                          <Button
                            loading={set_role_mappingLoading}
                            className={`${styles.ExceptionalApproval_button}`}
                            htmlType="submit"
                            size="small"
                          >
                            Save
                          </Button>

                          <Button
                            className={`${styles.cancel_rejectModal_button}`}
                            size="small"
                            onClick={() => history.push('/cxo-users-flow')}
                          >
                            Cancel
                          </Button>
                        </Space>
                      </FormItem>
                    </div>
                  </Form>
                </Card>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </CustomLayout>
  );
}
export default Create_CXO_Users;

/* import React, { useState, useEffect, useMemo } from 'react';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Space, Row, Spin, Select } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  get_all_users,
  create_designated_authorities,
  get_Designated_Authorities
} from '../../../../redux/slice';
import { debounce } from 'lodash';
import FormData from 'form-data';

//const { TextArea } = Input;
const { Option } = Select;

function Create_CXO_Users() {
  TabTitle('Gift Register - Create New Delegator Flow');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  const [allUsersSearch, setAllUsersSearch] = useState('');
  const [allRolesSearch, setAllRolesSearch] = useState('');

  const {
    get_all_usersLoading,
    all_users_List,
    create_designated_authoritiesLoading,
    designated_Authorities_List
  } = useSelector((state) => state.admin);
  console.log('all_users_List', all_users_List);

  useEffect(() => {
    const payload = {};
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]);

  useEffect(() => {
    const payload = {};
    if (allRolesSearch) payload['search'] = allRolesSearch;
    dispatch(get_Designated_Authorities(payload));
  }, [allRolesSearch]);

  const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []);
  const debounceFetcherAllRole = useMemo(() => {
    const loadOptions = (allRolesSearch) => {
      setAllRolesSearch(allRolesSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    let formdata = new FormData();
    formdata.append('id', formValues?.id === undefined ? '' : formValues?.id);

    formdata.append(
      'userId',
      formValues?.userId === undefined ? '' : formValues?.userId
    );
    // formdata.append('isActive', true);

    dispatch(create_designated_authorities(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/create_cxo_users');
      }
    });
  };

  return (
    <CustomLayout>
      <Spin spinning={get_all_usersLoading}>
        <div style={{ marginTop: '50px' }}>
          <Row className={`${styles.row_container_setting}`}>
            <div className={`${styles.gift_container}`}>
              <div className={`${styles.myGift_title_setting}`}>
                Manage designated authorities
              </div>
            </div>
          </Row>{' '}
          <div className={`${styles.container}`}>
            <div className={`${styles.cardContainer}`}>
              <Card className={`${styles.cardStyles_Container}`}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className={`${styles.formstyles}`}
                >
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the designated authorities
                          </div>
                        }
                        name="id"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select role"
                          onSearch={debounceFetcherAllRole}
                          onChange={(value) =>
                            handleFormDataOnChange('id', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {designated_Authorities_List?.map((item, index) => (
                            <Option
                              //title={item?.role}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.role}
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>

                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the user
                          </div>
                        }
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select user"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('userId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  <div style={{ marginTop: '20px' }}>
                    <FormItem>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: ' center'
                        }}
                      >
                        <Button
                          loading={create_designated_authoritiesLoading}
                          className={`${styles.ExceptionalApproval_button}`}
                          htmlType="submit"
                          size="small"
                        >
                          Save
                        </Button>

                        <Button
                          className={`${styles.cancel_rejectModal_button}`}
                          size="small"
                          onClick={() => history.push('/create_cxo_users')}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </FormItem>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
}
export default Create_CXO_Users;
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { admin, admin_post, admin_patch } from './api';

const initialState = {
  get_all_usersLoading: false,
  get_all_sbgLoading: false,
  getAll_Entry_delegatorsListLoading: false,
  remove_entry_delegatorLoading: false,
  getAll_approval_delegatorsListLoading: false,
  remove_approval_delegatorLoading: false,
  map_entry_delegatorLoading: false,
  map_approval_delegatorLoading: false,
  get_BU_MasterLoading: false,
  get_RO_MasterLoading: false,
  get_ROUsers_MasterLoading: false,
  get_Designated_AuthoritiesLoading: false,
  get_Gift_MasterLoading: false,
  create_bu_masterLoading: false,
  create_ro_masterLoading: false,
  create_gift_masterLoading: false,
  create_designated_authoritiesLoading: false,
  remove_bu_masterLoading: false,
  remove_gift_masterLoading: false,
  remove_designated_authoritiesLoading: false,
  get_value_limits_listLoading: false,
  set_value_limitLoading: false,
  get_all_rolesLoading: false,
  set_role_mappingLoading: false,
  set_BU_mappingLoading: false,
  getAdminDashboardCountLoading: false,
  getAdminDashboardCountLoadingGifts: false,
  getAdminDashboardCountLoadingStakeHolders: false,
  getAdminDashboardCountLoadingBUs: false,
  getAdminDashboardCountLoadingSectors: false,
  create_ROUsers_masterLoading: false,
  update_ROUsers_masterLoading: false,
  get_Senior_Management_FlowLoading: false,
  create_Senior_Management_FlowLoading: false,
  entry_delegatorsList: [],
  approval_delegatorsList: [],
  all_users_List: [],
  all_sbg_List: [],
  bu_MasterList: [],
  ro_MasterList: [],
  roUsers_MasterList: [],
  designated_Authorities_List: [],
  gift_MasterList: [],
  valueLimits_list: [],
  all_roles_list: [],
  acceptedCount: '',
  giftsGiven: '',
  giftsReceived: '',
  numberOfRequests: '',
  pendingCount: '',
  prohibitedCount: '',
  rejectedCount: '',
  returnedCount: '',
  // top5BU: [],
  // gag_vs_gr: [],
  senior_ManagementList: [],
  // allBUs: [],
  // govtAndNonGovt: {},
  // stakeHolderCount: {},
  allGifts: [],
  top5Gifts: [],
  BUs: [],
  stakeHolders: [],
  sectors: []
};

const actions = {
  // DECLARE_GIFT_FORM: 'remove_entry_delegator/DECLARE_GIFT_FORM',
  GET_ALL_USERS: 'get_all_users/GET_ALL_USERS',
  GET_ALL_SBG: 'get_all_sbg/GET_ALL_SBG',
  GET_ALL_ENTRY_DELEGATORS_LIST:
    'getAll_Entry_delegatorsList/GET_ALL_ENTRY_DELEGATORS_LIST,',
  REMOVE_ENTRY_DELEGATOR: 'remove_entry_delegator/REMOVE_ENTRY_DELEGATOR',
  GET_ALL_APPROVAL_DELEGATORS_LIST:
    'getAll_approval_delegatorsList/GET_ALL_APPROVAL_DELEGATORS_LIST',
  REMOVE_APPROVAL_DELEGATOR:
    'remove_approval_delegator/REMOVE_APPROVAL_DELEGATOR',
  MAP_ENTRY_DELEGATOR: 'map_entry_delegator/MAP_ENTRY_DELEGATOR',
  MAP_APPROVAL_DELEGATOR: 'map_approval_delegator/MAP_APPROVAL_DELEGATOR',
  GET_BU_MASTER: 'get_BU_Master/GET_BU_MASTER',
  GET_DESIGNATED_AUTHORITIES:
    'get_Designated_Authorities/GET_DESIGNATED_AUTHORITIES',
  GET_GIFT_MASTER: 'get_Gift_Master/GET_GIFT_MASTER',
  CREATE_DESIGNATED_AUTHORITIES:
    'create_designated_authorities/CREATE_DESIGNATED_AUTHORITIES',
  CREATE_BU_MASTER: 'create_bu_master/CREATE_BU_MASTER',
  CREATE_GIFT_MASTER: 'create_gift_master/CREATE_GIFT_MASTER',
  REMOVE_BU_MASTER: 'remove_bu_master/REMOVE_BU_MASTER',
  REMOVE_GIFT_MASTER: 'remove_gift_master/REMOVE_GIFT_MASTER',
  REMOVE_DESIGNATED_AUTHORITIES:
    'remove_designated_authorities/REMOVE_DESIGNATED_AUTHORITIES',
  GET_VALUE_LIMITS_LIST: 'get_value_limits_list/GET_VALUE_LIMITS_LIST',
  SET_VALUE_LIMIT: 'set_value_limit/SET_VALUE_LIMIT',
  GET_ALL_ROLES: 'get_all_roles/GET_ALL_ROLES',
  SET_ROLE_MAPPING: 'set_role_mapping/SET_ROLE_MAPPING',
  SET_BU_MAPPING: 'set_BU_mapping/SET_BU_MAPPING',
  GET_ADMIN_DASHBOARD_COUNT: 'getAdminDashboardCount/GET_ADMIN_DASHBOARD_COUNT',
  GET_RO_MASTER: 'get_RO_Master/GET_RO_MASTER',
  CREATE_RO_MASTER: 'create_ro_master/CREATE_RO_MASTER',
  GET_RO_USERS_MASTER: 'get_ROUsers_Master/GET_RO_USERS_MASTER',
  CREATE_RO_USERS_MASTER: 'create_ROUsers_master/CREATE_RO_USERS_MASTER',
  GET_SENIOR_MANAGEMENT_FLOW:
    'get_Senior_Management_Flow/GET_SENIOR_MANAGEMENT_FLOW',
  CREATE_SENIOR_MANAGEMENT_FLOW:
    'create_Senior_Management_Flow/CREATE_SENIOR_MANAGEMENT_FLOW',
  UPDATE_RO_USERS_MASTER: 'update_ROUsers_master/UPDATE_RO_USERS_MASTER',
  GET_ADMIN_DASHBOARD_COUNT_BUs:
    'getAdminDashboardCountBUs/GET_ADMIN_DASHBOARD_COUNT_BUs',
  GET_ADMIN_DASHBOARD_COUNT_GIFTS:
    'getAdminDashboardCountGifts/GET_ADMIN_DASHBOARD_COUNT_GIFTS',
  GET_ADMIN_DASHBOARD_COUNT_SECTORS:
    'getAdminDashboardCountSectors/GET_ADMIN_DASHBOARD_COUNT_SECTORS',
  GET_ADMIN_DASHBOARD_COUNT_STAKEHOLDERS:
    'getAdminDashboardCountStakeHolders/GET_ADMIN_DASHBOARD_COUNT_STAKEHOLDERS'
};
//https://giftregister-api.tataprojects.com/api-v1/admin-dashboard-counts?section=counts
export const getAdminDashboardCountBUs = createAsyncThunk(
  actions.GET_ADMIN_DASHBOARD_COUNT_BUs,
  async (payload) => {
    const response = await admin(`admin-dashboard-counts`, payload);
    return response;
  }
);
export const getAdminDashboardCountSectors = createAsyncThunk(
  actions.GET_ADMIN_DASHBOARD_COUNT_SECTORS,
  async (payload) => {
    const response = await admin(`admin-dashboard-counts`, payload);
    return response;
  }
);
export const getAdminDashboardCountStakeHolders = createAsyncThunk(
  actions.GET_ADMIN_DASHBOARD_COUNT_STAKEHOLDERS,
  async (payload) => {
    const response = await admin(`admin-dashboard-counts`, payload);
    return response;
  }
);
export const getAdminDashboardCount = createAsyncThunk(
  actions.GET_ADMIN_DASHBOARD_COUNT,
  async (payload) => {
    const response = await admin(`admin-dashboard-counts`, payload);
    return response;
  }
);
export const getAdminDashboardCountGifts = createAsyncThunk(
  actions.GET_ADMIN_DASHBOARD_COUNT_GIFTS,
  async (payload) => {
    const response = await admin(`admin-dashboard-counts`, payload);
    return response;
  }
);

export const set_BU_mapping = createAsyncThunk(
  actions.SET_BU_MAPPING,
  async (payload) => {
    const response = await admin_post(
      `bu-mapping?userId=${payload?.get('userId')}&buId=${payload?.get(
        'buId'
      )}`,
      payload
    );
    return response;
  }
);

export const set_role_mapping = createAsyncThunk(
  actions.SET_ROLE_MAPPING,
  async (payload) => {
    const response = await admin_post(
      `role-mapping?userId=${payload?.get('userId')}&roleId=${payload?.get(
        'roleId'
      )}`,
      payload
    );
    return response;
  }
);
export const set_value_limit = createAsyncThunk(
  actions.SET_VALUE_LIMIT,
  async (payload) => {
    const response = await admin_post(
      `set-value-limit?limitCategoryId=${payload?.limitCategoryId}&value=${payload?.value}`,
      payload
    );
    return response;
  }
);
export const get_value_limits_list = createAsyncThunk(
  actions.GET_VALUE_LIMITS_LIST,
  async (payload) => {
    const response = await admin(`get-value-limits`, payload);
    return response;
  }
);

export const getAll_Entry_delegatorsList = createAsyncThunk(
  actions.GET_ALL_ENTRY_DELEGATORS_LIST,
  async (payload) => {
    const response = await admin(`all-gift-entry-delegators`, payload);
    return response;
  }
);
export const getAll_approval_delegatorsList = createAsyncThunk(
  actions.GET_ALL_APPROVAL_DELEGATORS_LIST,
  async (payload) => {
    const response = await admin(`all-gift-approval-delegators`, payload);
    return response;
  }
);
export const get_BU_Master = createAsyncThunk(
  actions.GET_BU_MASTER,
  async (payload) => {
    const response = await admin(`bu-master`, payload);
    return response;
  }
);
export const get_RO_Master = createAsyncThunk(
  actions.GET_RO_MASTER,
  async (payload) => {
    const response = await admin(`ro-master`, payload);
    return response;
  }
);
export const get_ROUsers_Master = createAsyncThunk(
  actions.GET_RO_USERS_MASTER,
  async (payload) => {
    const response = await admin(`ro-users`, payload);
    return response;
  }
);
export const get_Designated_Authorities = createAsyncThunk(
  actions.GET_DESIGNATED_AUTHORITIES,
  async (payload) => {
    const response = await admin(`designated-authorities`, payload);
    return response;
  }
);
export const get_Gift_Master = createAsyncThunk(
  actions.GET_GIFT_MASTER,
  async (payload) => {
    const response = await admin(`gift-master`, payload);
    return response;
  }
);

export const remove_entry_delegator = createAsyncThunk(
  actions.REMOVE_ENTRY_DELEGATOR,
  async (payload) => {
    const response = await admin_post(
      `remove-gift-entry-delegator?delegator_id=${payload?.delegator_id}&delegate_id=${payload?.delegate_id}`,
      payload
    );
    return response;
  }
);
export const remove_approval_delegator = createAsyncThunk(
  actions.REMOVE_APPROVAL_DELEGATOR,
  async (payload) => {
    const response = await admin_post(
      `remove-gift-approval-delegator?delegator_id=${payload?.delegator_id}&delegate_id=${payload?.delegate_id}`,
      payload
    );
    return response;
  }
);

export const get_all_users = createAsyncThunk(
  actions.GET_ALL_USERS,
  async (payload) => {
    const response = await admin(`get-all-users`, payload);
    return response;
  }
);
export const get_all_sbg = createAsyncThunk(
  actions.GET_ALL_SBG,
  async (payload) => {
    const response = await admin(`get-all-users`, payload);
    return response;
  }
);
export const get_all_roles = createAsyncThunk(
  actions.GET_ALL_ROLES,
  async (payload) => {
    const response = await admin(`all-roles`, payload);
    return response;
  }
);
export const map_entry_delegator = createAsyncThunk(
  actions.MAP_ENTRY_DELEGATOR,
  async (payload) => {
    const response = await admin_post(
      `map-gift-entry-delegator?delegator_id=${payload?.delegator_id}&delegate_id=${payload?.delegate_id}`,
      payload
    );
    return response;
  }
);
export const map_approval_delegator = createAsyncThunk(
  actions.MAP_APPROVAL_DELEGATOR,
  async (payload) => {
    const response = await admin_post(
      `map-gift-approval-delegator?delegator_id=${payload?.delegator_id}&delegate_id=${payload?.delegate_id}`,
      payload
    );
    return response;
  }
);
export const create_bu_master = createAsyncThunk(
  actions.CREATE_BU_MASTER,
  async (payload) => {
    console.log("payload?.get('id')", payload?.get('id'));
    const response = await admin_post(
      `bu-master?id=${payload?.get('id') === null ? '' : payload?.get('id')}`,
      payload
    );
    return response;
  }
);
export const create_ro_master = createAsyncThunk(
  actions.CREATE_RO_MASTER,
  async (payload) => {
    console.log("payload?.get('id')", payload?.get('id'));
    const response = await admin_post(
      `ro-master?id=${payload?.get('id') === null ? '' : payload?.get('id')}`,
      payload
    );
    return response;
  }
);
export const create_ROUsers_master = createAsyncThunk(
  actions.CREATE_RO_USERS_MASTER,
  async (payload) => {
    const response = await admin_post(`ro-users/create`, payload);
    return response;
  }
);
export const update_ROUsers_master = createAsyncThunk(
  actions.UPDATE_RO_USERS_MASTER,
  async (payload) => {
    const response = await admin_patch(`ro-users/update`, payload);
    return response;
  }
);
export const remove_bu_master = createAsyncThunk(
  actions.REMOVE_BU_MASTER,
  async (payload) => {
    const response = await admin_post(
      `bu-master?id=${payload?.get('id')}`,
      payload
    );
    return response;
  }
);
export const create_gift_master = createAsyncThunk(
  actions.CREATE_GIFT_MASTER,
  async (payload) => {
    const response = await admin_post(`gift-master`, payload);
    return response;
  }
);
export const remove_gift_master = createAsyncThunk(
  actions.REMOVE_GIFT_MASTER,
  async (payload) => {
    const response = await admin_post(
      `gift-master?id=${payload?.get('id')}`,
      payload
    );
    return response;
  }
);
export const create_designated_authorities = createAsyncThunk(
  actions.CREATE_DESIGNATED_AUTHORITIES,
  async (payload) => {
    const response = await admin_post(
      `designated-authorities?id=${payload?.get('id')}`,
      payload
    );
    return response;
  }
);
export const remove_designated_authorities = createAsyncThunk(
  actions.REMOVE_DESIGNATED_AUTHORITIES,
  async (payload) => {
    const response = await admin_post(
      `designated-authorities?id=${payload?.get('id')}`,
      payload
    );
    return response;
  }
);
export const get_Senior_Management_Flow = createAsyncThunk(
  actions.GET_SENIOR_MANAGEMENT_FLOW,
  async (payload) => {
    const response = await admin(`senior-management`, payload);
    return response;
  }
);
export const create_Senior_Management_Flow = createAsyncThunk(
  actions.CREATE_SENIOR_MANAGEMENT_FLOW,
  async (payload) => {
    const response = await admin_patch(
      `senior-management?oldUserId=${payload?.get(
        'oldUserId'
      )}&newUserId=${payload?.get('newUserId')}`,
      payload
    );
    return response;
  }
);
export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAll_Entry_delegatorsList.pending, (state) => {
        state.getAll_Entry_delegatorsListLoading = true;
      })
      .addCase(getAll_Entry_delegatorsList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAll_Entry_delegatorsListLoading = false;
        state.entry_delegatorsList = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(getAll_Entry_delegatorsList.rejected, (state) => {
        state.getAll_Entry_delegatorsListLoading = false;
      });
    builder
      .addCase(remove_entry_delegator.pending, (state) => {
        state.remove_entry_delegatorLoading = true;
      })
      .addCase(remove_entry_delegator.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.remove_entry_delegatorLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(remove_entry_delegator.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.remove_entry_delegatorLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAll_approval_delegatorsList.pending, (state) => {
        state.getAll_approval_delegatorsListLoading = true;
      })
      .addCase(getAll_approval_delegatorsList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAll_approval_delegatorsListLoading = false;
        state.approval_delegatorsList = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(getAll_approval_delegatorsList.rejected, (state) => {
        state.getAll_approval_delegatorsListLoading = false;
      });
    builder
      .addCase(remove_approval_delegator.pending, (state) => {
        state.remove_approval_delegatorLoading = true;
      })
      .addCase(remove_approval_delegator.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.remove_approval_delegatorLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(remove_approval_delegator.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.remove_approval_delegatorLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_all_users.pending, (state) => {
        state.get_all_usersLoading = true;
      })
      .addCase(get_all_users.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.get_all_usersLoading = false;
        state.all_users_List = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_all_users.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_all_usersLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_all_sbg.pending, (state) => {
        state.get_all_sbgLoading = true;
      })
      .addCase(get_all_sbg.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.get_all_sbgLoading = false;
        state.all_sbg_List = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_all_sbg.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_all_sbgLoading = false;

        message.error(msg);
      });
    builder
      .addCase(map_entry_delegator.pending, (state) => {
        state.map_entry_delegatorLoading = true;
      })
      .addCase(map_entry_delegator.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.map_entry_delegatorLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(map_entry_delegator.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.map_entry_delegatorLoading = false;

        message.error(msg);
      });
    builder
      .addCase(map_approval_delegator.pending, (state) => {
        state.map_approval_delegatorLoading = true;
      })
      .addCase(map_approval_delegator.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.map_approval_delegatorLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(map_approval_delegator.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.map_approval_delegatorLoading = false;

        message.error(msg);
      });

    builder
      .addCase(get_BU_Master.pending, (state) => {
        state.get_BU_MasterLoading = true;
      })
      .addCase(get_BU_Master.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_BU_MasterLoading = false;
        state.bu_MasterList = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(get_BU_Master.rejected, (state) => {
        state.get_BU_MasterLoading = false;
      });
    builder
      .addCase(get_RO_Master.pending, (state) => {
        state.get_RO_MasterLoading = true;
      })
      .addCase(get_RO_Master.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_RO_MasterLoading = false;
        state.ro_MasterList = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(get_RO_Master.rejected, (state) => {
        state.get_RO_MasterLoading = false;
      });
    builder
      .addCase(get_ROUsers_Master.pending, (state) => {
        state.get_ROUsers_MasterLoading = true;
      })
      .addCase(get_ROUsers_Master.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_ROUsers_MasterLoading = false;
        state.roUsers_MasterList = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(get_ROUsers_Master.rejected, (state) => {
        state.get_ROUsers_MasterLoading = false;
      });
    builder
      .addCase(get_Designated_Authorities.pending, (state) => {
        state.get_Designated_AuthoritiesLoading = true;
      })
      .addCase(get_Designated_Authorities.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_Designated_AuthoritiesLoading = false;
        state.designated_Authorities_List = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(get_Designated_Authorities.rejected, (state) => {
        state.get_Designated_AuthoritiesLoading = false;
      });

    builder
      .addCase(get_Gift_Master.pending, (state) => {
        state.get_Gift_MasterLoading = true;
      })
      .addCase(get_Gift_Master.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_Gift_MasterLoading = false;
        state.gift_MasterList = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(get_Gift_Master.rejected, (state) => {
        state.get_Gift_MasterLoading = false;
      });
    builder
      .addCase(create_bu_master.pending, (state) => {
        state.create_bu_masterLoading = true;
      })
      .addCase(create_bu_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.create_bu_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(create_bu_master.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.create_bu_masterLoading = false;

        message.error(msg);
      });

    builder
      .addCase(create_ro_master.pending, (state) => {
        state.create_ro_masterLoading = true;
      })
      .addCase(create_ro_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.create_ro_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(create_ro_master.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.create_ro_masterLoading = false;

        message.error(msg);
      });
    builder
      .addCase(create_ROUsers_master.pending, (state) => {
        state.create_ROUsers_masterLoading = true;
      })
      .addCase(create_ROUsers_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.create_ROUsers_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(create_ROUsers_master.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.create_ROUsers_masterLoading = false;

        message.error(msg);
      });
    builder
      .addCase(update_ROUsers_master.pending, (state) => {
        state.update_ROUsers_masterLoading = true;
      })
      .addCase(update_ROUsers_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.update_ROUsers_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(update_ROUsers_master.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.update_ROUsers_masterLoading = false;

        message.error(msg);
      });
    builder
      .addCase(create_gift_master.pending, (state) => {
        state.create_gift_masterLoading = true;
      })
      .addCase(create_gift_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.create_gift_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(create_gift_master.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.create_gift_masterLoading = false;
        message.error(msg);
      });

    builder
      .addCase(create_designated_authorities.pending, (state) => {
        state.create_designated_authoritiesLoading = true;
      })
      .addCase(create_designated_authorities.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.create_designated_authoritiesLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(create_designated_authorities.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.create_designated_authoritiesLoading = false;

        message.error(msg);
      });
    builder
      .addCase(remove_bu_master.pending, (state) => {
        state.remove_bu_masterLoading = true;
      })
      .addCase(remove_bu_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.remove_bu_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(remove_bu_master.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.remove_bu_masterLoading = false;

        message.error(msg);
      });
    builder
      .addCase(remove_gift_master.pending, (state) => {
        state.remove_gift_masterLoading = true;
      })
      .addCase(remove_gift_master.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.remove_gift_masterLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(remove_gift_master.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.remove_gift_masterLoading = false;
        message.error(msg);
      });
    builder
      .addCase(remove_designated_authorities.pending, (state) => {
        state.remove_designated_authoritiesLoading = true;
      })
      .addCase(remove_designated_authorities.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.remove_designated_authoritiesLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(remove_designated_authorities.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.remove_designated_authoritiesLoading = false;
        message.error(msg);
      });
    builder
      .addCase(get_value_limits_list.pending, (state) => {
        state.get_value_limits_listLoading = true;
      })
      .addCase(get_value_limits_list.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_value_limits_listLoading = false;
        state.valueLimits_list = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(get_value_limits_list.rejected, (state) => {
        state.get_value_limits_listLoading = false;
      });
    builder
      .addCase(set_value_limit.pending, (state) => {
        state.set_value_limitLoading = true;
      })
      .addCase(set_value_limit.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.set_value_limitLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(set_value_limit.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.set_value_limitLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_all_roles.pending, (state) => {
        state.get_all_rolesLoading = true;
      })
      .addCase(get_all_roles.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.get_all_rolesLoading = false;
        state.all_roles_list = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_all_roles.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_all_rolesLoading = false;

        message.error(msg);
      });
    builder
      .addCase(set_role_mapping.pending, (state) => {
        state.set_role_mappingLoading = true;
      })
      .addCase(set_role_mapping.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.set_role_mappingLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(set_role_mapping.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.set_role_mappingLoading = false;

        message.error(msg);
      });
    builder
      .addCase(set_BU_mapping.pending, (state) => {
        state.set_BU_mappingLoading = true;
      })
      .addCase(set_BU_mapping.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.set_BU_mappingLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(set_BU_mapping.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.set_BU_mappingLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAdminDashboardCountBUs.pending, (state) => {
        state.getAdminDashboardCountLoadingBUs = true;
      })
      .addCase(getAdminDashboardCountBUs.fulfilled, (state, action) => {
        const { success, msg, BUs } = action.payload;
        state.getAdminDashboardCountLoadingBUs = false;
        if (success) {
          state.BUs = BUs;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminDashboardCountBUs.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAdminDashboardCountLoadingBUs = false;
        message.error(msg);
      });
    builder
      .addCase(getAdminDashboardCountGifts.pending, (state) => {
        state.getAdminDashboardCountLoadingGifts = true;
      })
      .addCase(getAdminDashboardCountGifts.fulfilled, (state, action) => {
        const { success, msg, allGifts, top5Gifts } = action.payload;
        state.getAdminDashboardCountLoadingGifts = false;
        if (success) {
          state.allGifts = allGifts;
          state.top5Gifts = top5Gifts;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminDashboardCountGifts.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAdminDashboardCountLoadingGifts = false;
        message.error(msg);
      });
    builder
      .addCase(getAdminDashboardCountStakeHolders.pending, (state) => {
        state.getAdminDashboardCountLoadingStakeHolders = true;
      })
      .addCase(
        getAdminDashboardCountStakeHolders.fulfilled,
        (state, action) => {
          const {
            success,
            msg,
            // allGifts,
            // top5Gifts,
            // sectors,
            // BUs,
            stakeHolders
          } = action.payload;
          state.getAdminDashboardCountLoadingStakeHolders = false;
          if (success) {
            // state.allGifts = allGifts;
            // state.top5Gifts = top5Gifts;
            // state.sectors = sectors;
            // state.BUs = BUs;
            state.stakeHolders = stakeHolders;
          } else {
            message.error(msg);
          }
        }
      )
      .addCase(getAdminDashboardCountStakeHolders.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAdminDashboardCountLoadingStakeHolders = false;
        message.error(msg);
      });
    builder
      .addCase(getAdminDashboardCountSectors.pending, (state) => {
        state.getAdminDashboardCountLoadingSectors = true;
      })
      .addCase(getAdminDashboardCountSectors.fulfilled, (state, action) => {
        const {
          success,
          msg,
          // allGifts,
          // top5Gifts,
          sectors
          // BUs,
          // stakeHolders
        } = action.payload;
        state.getAdminDashboardCountLoadingSectors = false;
        if (success) {
          // state.allGifts = allGifts;
          // state.top5Gifts = top5Gifts;
          state.sectors = sectors;
          // state.BUs = BUs;
          // state.stakeHolders = stakeHolders;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminDashboardCountSectors.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAdminDashboardCountLoadingSectors = false;
        message.error(msg);
      });
    builder
      .addCase(getAdminDashboardCount.pending, (state) => {
        state.getAdminDashboardCountLoading = true;
      })
      .addCase(getAdminDashboardCount.fulfilled, (state, action) => {
        const {
          success,
          acceptedCount,
          giftsGiven,
          giftsReceived,
          numberOfRequests,
          pendingCount,
          prohibitedCount,
          rejectedCount,
          returnedCount,
          msg
        } = action.payload;
        state.getAdminDashboardCountLoading = false;
        if (success) {
          state.acceptedCount = acceptedCount;
          state.giftsGiven = giftsGiven;
          state.giftsReceived = giftsReceived;
          state.numberOfRequests = numberOfRequests;
          state.pendingCount = pendingCount;
          state.prohibitedCount = prohibitedCount;
          state.rejectedCount = rejectedCount;
          state.returnedCount = returnedCount;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAdminDashboardCount.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAdminDashboardCountLoading = false;
        message.error(msg);
      });
    builder
      .addCase(get_Senior_Management_Flow.pending, (state) => {
        state.get_Senior_Management_FlowLoading = true;
      })
      .addCase(get_Senior_Management_Flow.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.get_Senior_Management_FlowLoading = false;
        state.senior_ManagementList = data ? data : [];

        state.pagination = pagination || null;
      })
      .addCase(get_Senior_Management_Flow.rejected, (state) => {
        state.get_Senior_Management_FlowLoading = false;
      });
    builder
      .addCase(create_Senior_Management_Flow.pending, (state) => {
        state.create_Senior_Management_FlowLoading = true;
      })
      .addCase(create_Senior_Management_Flow.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg: msg } = action.payload;
        state.create_Senior_Management_FlowLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(create_Senior_Management_Flow.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.create_Senior_Management_FlowLoading = false;

        message.error(msg);
      });
  }
});
export default adminSlice.reducer;

import React, { useState, useEffect, useMemo } from 'react'; //, useMemo
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Space, Row, Spin, Input, Select } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  create_Senior_Management_Flow,
  get_all_users
} from '../../../../redux/slice';
import { debounce } from 'lodash';
import FormData from 'form-data';
//import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

//const { TextArea } = Input;
const { Option } = Select;

function EditSeniorManagementFlow() {
  TabTitle('Gift Register - Edit Senior Management Flow');

  //const params = useParams();

  const buRecord = LocalStorage.getItem('senior-management_record');
  console.log('buRecord%%%%%%%%%', buRecord);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  const [allUsersSearch, setAllUsersSearch] = useState('');

  const {
    get_all_usersLoading,
    all_users_List,
    create_Senior_Management_FlowLoading
  } = useSelector((state) => state.admin);
  console.log('all_users_List', all_users_List);

  useEffect(() => {
    form.setFieldsValue({
      oldUserName: buRecord?.userName
      // oldUserId: buRecord?.id
    });
  }, [buRecord]);
  console.log('buRecord?.roUserName', buRecord?.userName);
  useEffect(() => {
    const payload = {};
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]);

  const debounceFetcherUserId = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    let formdata = new FormData();
    // formdata.append('id', params?.id);
    formdata.append('oldUserId', buRecord?.id);

    formdata.append(
      'newUserId',
      formValues?.newUserId === undefined ? buRecord?.id : formValues?.newUserId
    );

    //formdata.append('isActive', true);

    /* const payload = {
      oldUserId: buRecord?.id,
      newUserId:
        formValues?.newUserId === undefined
          ? buRecord?.id
          : formValues?.newUserId
    }; */

    dispatch(create_Senior_Management_Flow(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/senior-management-flow');
      }
    });
  };

  return (
    <CustomLayout>
      <Spin spinning={get_all_usersLoading}>
        <div style={{ marginTop: '50px' }}>
          <Row className={`${styles.row_container_setting}`}>
            <div className={`${styles.gift_container}`}>
              <div className={`${styles.myGift_title_setting}`}>
                Edit Senior Management
              </div>
            </div>
          </Row>{' '}
          <div className={`${styles.container}`}>
            <div className={`${styles.cardContainer}`}>
              <Card className={`${styles.cardStyles_Container}`}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className={`${styles.formstyles}`}
                >
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Enter the existing senior management
                          </div>
                        }
                        //name="oldUserId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Input
                          disabled
                          value={buRecord?.userName}
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the senior management name"
                          onChange={(e) =>
                            handleFormDataOnChange('oldUserId', e.target.value)
                          }
                        />
                        {/* <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select delegator"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('delegatorId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select> */}
                      </FormItem>
                    </div>
                  </Card>

                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select new senior management from the list
                            below
                          </div>
                        }
                        name="newUserId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select senior management"
                          onSearch={debounceFetcherUserId}
                          onChange={(value) =>
                            handleFormDataOnChange('newUserId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} {''} {item?.surname}(
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  <div style={{ marginTop: '20px' }}>
                    <FormItem>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: ' center'
                        }}
                      >
                        <Button
                          loading={create_Senior_Management_FlowLoading}
                          className={`${styles.ExceptionalApproval_button}`}
                          htmlType="submit"
                          size="small"
                        >
                          Save
                        </Button>

                        <Button
                          className={`${styles.cancel_rejectModal_button}`}
                          size="small"
                          onClick={() =>
                            history.push('/senior-management-flow')
                          }
                        >
                          Cancel
                        </Button>
                      </Space>
                    </FormItem>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
}
export default EditSeniorManagementFlow;

import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
// import Layout from 'common/Layout/index';
// import VerticalBarChart from './verticalBarChart';
import StackedBarChart from './stackedBarChart';
// import Table_open_allocated from './table_open_allocated';
// import SpeedometerChart from './speedometerChart';
import Top5_gagChart from './top5_gagChart';
import Top5_grChart from './top5_grChart';
import { Radio, Spin, Space, Divider, Row, DatePicker, Card } from 'antd'; // Select,
//import { dateRangeDashboard } from 'utilities/helpers';
import CardStatus1 from './cardStatus1';
import CardStatus2 from './cardStatus2';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdminDashboardCount,
  getAdminDashboardCountBUs,
  getAdminDashboardCountGifts,
  getAdminDashboardCountSectors,
  getAdminDashboardCountStakeHolders
} from '../../../admin/redux/slice';
import moment from 'moment';
import Table_open_allocated from './table_open_allocated';
//import { Chart } from 'react-google-charts';
import PaiChart from './paiChart';
import ColumnChart from './columnChart';
import TreeChart from './treeCharts';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';

//const { Option } = Select;

const filters = [
  {
    name: 'Snapshot',
    value: 'counts'
  },
  {
    name: 'BU-wise Summary',
    value: 'BUs'
  },
  {
    name: 'Recipient Summary',
    value: 'stakeHolders'
  },
  {
    name: 'Gift Type Summary',
    value: 'gifts'
  }
];

function DashboardPage() {
  const isMobile = sizeChecker('xs');

  const dispatch = useDispatch();

  const {
    // getAdminDashboardCountLoading1,
    getAdminDashboardCountLoading,
    getAdminDashboardCountLoadingGifts,
    getAdminDashboardCountLoadingStakeHolders,
    getAdminDashboardCountLoadingBUs,
    getAdminDashboardCountLoadingSectors,
    acceptedCount,
    giftsGiven,
    giftsReceived,
    numberOfRequests,
    pendingCount,
    prohibitedCount,
    rejectedCount,
    returnedCount,
    allGifts,
    top5Gifts,
    sectors,
    BUs,
    stakeHolders
  } = useSelector((state) => state.admin);

  // console.log('acceptedCount', acceptedCount);
  // console.log('giftsGiven', giftsGiven);
  // console.log('giftsReceived', giftsReceived);
  // console.log('numberOfRequests', numberOfRequests);
  // console.log('pendingCount', pendingCount);
  // console.log('prohibitedCount', prohibitedCount);
  // console.log('rejectedCount', rejectedCount);
  // console.log('returnedCount', returnedCount);
  //console.log('top5Gifts', top5Gifts);
  //console.log('sectors', sectors);
  //console.log('allGifts', allGifts);
  //console.log('BUs', BUs);
  //console.log('stakeHolders', stakeHolders);

  // const [selectedDate, setSelectedDate] = useState(
  //   dateRangeDashboard[2]?.value
  // );
  //...........................................................................
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('dashboard_Radiofilter')) === null
      ? 'counts'
      : JSON?.parse(window.localStorage.getItem('dashboard_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      setFromDate(null);
      setToDate(null);
    }
  };
  console.log(
    'fromDate***********',
    moment(fromDate?._d)?.format('YYYY-MM-DD')
  );
  console.log('toDate************', moment(toDate?._d)?.format('YYYY-MM-DD'));
  console.log('radiofilter', radiofilter);
  console.log('triggerFilter', triggerFilter);
  //.................................................................................
  // const [dateRange, setDateRange] = useState([moment(), moment()]);

  // console.log('dateRange', dateRange);

  // console.log('fromDate', moment(dateRange[0]?._d)?.format('YYYY-MM-DD'));
  // console.log('toDate', moment(dateRange[1]?._d)?.format('YYYY-MM-DD'));

  // console.log('selectedDate', moment(selectedDate).format('YYYY MM DD'));
  useEffect(() => {
    handleGetRequests();
  }, []);

  useEffect(() => {
    if (triggerFilter || fromDate || toDate) {
      handleGetRequests();
    }
  }, [fromDate, toDate, radiofilter]);

  const handleGetRequests = () => {
    if (radiofilter === 'counts') {
      let payload = {
        fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
        toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
        section: radiofilter //'counts'
      };
      dispatch(getAdminDashboardCount(payload));
      LocalStorage.setItem('countsDates', JSON.stringify(payload));
    } else if (radiofilter === 'BUs') {
      let payload3 = {
        fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
        toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
        section: 'BUs'
      };
      dispatch(getAdminDashboardCountBUs(payload3));
    } else if (radiofilter === 'stakeHolders') {
      let payload4 = {
        fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
        toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
        section: 'stakeHolders'
      };
      dispatch(getAdminDashboardCountStakeHolders(payload4));
      let payload2 = {
        fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
        toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
        section: 'sectors'
      };
      dispatch(getAdminDashboardCountSectors(payload2));
    } else if (radiofilter === 'gifts') {
      let payload1 = {
        fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
        toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
        section: 'gifts'
      };
      dispatch(getAdminDashboardCountGifts(payload1));
    }
  };

  /*  useEffect(() => {
    let payload = {
      fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? moment()?.format('YYYY-MM-DD')
      //   : moment(dateRange[0]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),
      toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? moment()?.format('YYYY-MM-DD')
      //   : moment(dateRange[1]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),
      // fromDate: moment(selectedDate).format('YYYY MM DD'),
      // toDate: moment().format('YYYY MM DD'),
      section: 'counts'
    };
    dispatch(getAdminDashboardCount(payload));
  }, [fromDate, toDate, radiofilter]); //dateRange//selectedDate */

  /*  useEffect(() => {
    let payload1 = {
      fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? `01 ${moment().subtract(1, 'months').format('YYYY MM')}`
      //   : moment(dateRange[0]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),
      toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? moment()?.format('YYYY-MM-DD')
      //   : moment(dateRange[1]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),

      // fromDate: moment(selectedDate).format('YYYY MM DD'),
      // toDate: moment().format('YYYY MM DD'),
      section: 'gifts'
    };
    dispatch(getAdminDashboardCountGifts(payload1));
  }, [fromDate, toDate]); */

  /*  useEffect(() => {
    if (radiofilter === 'stakeHolders') {
      let payload2 = {
        fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
        // dateRange === null
        //   ? `01 ${moment().subtract(1, 'months').format('YYYY MM')}`
        //   : moment(dateRange[0]?._d)?.format('YYYY-MM-DD') ||
        //     moment()?.format('YYYY-MM-DD'),
        toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
        // dateRange === null
        //   ? moment()?.format('YYYY-MM-DD')
        //   : moment(dateRange[1]?._d)?.format('YYYY-MM-DD') ||
        //     moment()?.format('YYYY-MM-DD'),

        // fromDate: moment(selectedDate).format('YYYY MM DD'),
        // toDate: moment().format('YYYY MM DD'),
        section: 'sectors'
      };
      dispatch(getAdminDashboardCountSectors(payload2));
    }
  }, [fromDate, toDate, radiofilter]); */
  /*  useEffect(() => {
    let payload3 = {
      fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? `01 ${moment().subtract(1, 'months').format('YYYY MM')}`
      //   : moment(dateRange[0]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),
      toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? moment()?.format('YYYY-MM-DD')
      //   : moment(dateRange[1]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),

      // fromDate: moment(selectedDate).format('YYYY MM DD'),
      // toDate: moment().format('YYYY MM DD'),
      section: 'BUs'
    };
    dispatch(getAdminDashboardCountBUs(payload3));
  }, [fromDate, toDate]); */
  /*  useEffect(() => {
    let payload4 = {
      fromDate: moment(fromDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? `01 ${moment().subtract(1, 'months').format('YYYY MM')}`
      //   : moment(dateRange[0]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),
      toDate: moment(toDate?._d)?.format('YYYY-MM-DD'),
      // dateRange === null
      //   ? moment()?.format('YYYY-MM-DD')
      //   : moment(dateRange[1]?._d)?.format('YYYY-MM-DD') ||
      //     moment()?.format('YYYY-MM-DD'),

      // fromDate: moment(selectedDate).format('YYYY MM DD'),
      // toDate: moment().format('YYYY MM DD'),
      section: 'stakeHolders'
    };
    dispatch(getAdminDashboardCountStakeHolders(payload4));
  }, [fromDate, toDate]); */

  //console.log('ageing_category', ageing_category);
  //console.log('ageing_data', ageing_data);

  /*  const handleChangeDateRange = (value) => {
    setSelectedDate(value);
  }; */
  /*   const govtAndNonGovt1 = govtAndNonGovt?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.recipientName,
    ticket: item?.ticket,
  })); */

  return (
    <>
      <Spin spinning={getAdminDashboardCountLoading}>
        <div className={`${styles.lady_background_welcome}`}>
          <Space className={`${styles.title_container} mb-3`}>
            <div className={`${styles.text_Title} sfprotext-bold font-22`}>
              Gift Register Analytics
            </div>
            {/* <div>
              <h2>Date Range Picker</h2>
              <DatePicker.RangePicker
                value={[fromDate, toDate]}
                onChange={handleDateRangeChange}
              />
            </div> */}

            <div className={`${styles.dateRange_container}`}>
              <h4 style={{ marginBottom: '0px' }}>Select Date Range</h4>
              <DatePicker.RangePicker
                className={`${styles.dateRange_picker}`}
                value={[fromDate, toDate]}
                onChange={handleDateRangeChange}
                disabledDate={(current) => {
                  return current && current.valueOf() > Date.now();
                }}
              />
              {/* <DatePicker.RangePicker
                className={`${styles.dateRange_style}`}
                format={'DD-MMM-YYYY'}
                onChange={(x) => {
                  setDateRange(x);
                }}
                disabledDate={(current) => {
                  return current && current.valueOf() > Date.now();
                }}
                value={dateRange}
                // separator={'-'}
                // style={{ width: '100%' }}
              /> */}
              {/*  <Select
                defaultValue={dateRangeDashboard[2]}
                style={{ width: 220 }}
                onChange={handleChangeDateRange}
                // options={dateRangeDashboard}
              >
                {dateRangeDashboard.map((item, index) => {
                  const { label, value } = item;

                  return (
                    <Option key={index} value={value} label={label}>
                      <div style={{ fontSize: '12px' }}>{label}</div>
                    </Option>
                  );
                })}
              </Select> */}
            </div>
          </Space>
          <Row className={`${styles.row_display_isMobile}`}>
            <Radio.Group
              value={radiofilter}
              size="small"
              //className={`${styles.button_import_style}`}
              className={
                isMobile
                  ? `${styles.button_import_style_isMobile}`
                  : `${styles.button_import_style}`
              }
            >
              {filters.map((item, index) => {
                return (
                  <Radio.Button
                    value={item.value}
                    key={index}
                    className={
                      isMobile
                        ? `${styles.radio_button}`
                        : `${styles.RadioButton_import_style}`
                    }
                    onChange={() => {
                      window.localStorage.setItem(
                        'dashboard_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Row>
          <Divider style={{ marginTop: '0px' }} />

          {/* <Divider /> */}

          {radiofilter === 'counts' ? (
            <div className={`${styles.count_container}`}>
              <Row gutter={16}>
                <div>
                  <div className={`${styles.user_name_subText}`}>
                    <CardStatus1
                      acceptedCount={acceptedCount}
                      giftsGiven={giftsGiven}
                      giftsReceived={giftsReceived}
                      numberOfRequests={numberOfRequests}
                    />
                  </div>
                </div>
              </Row>
              <Row gutter={16}>
                <div>
                  <div className={`${styles.user_name_subText}`}>
                    <CardStatus2
                      pendingCount={pendingCount}
                      prohibitedCount={prohibitedCount}
                      rejectedCount={rejectedCount}
                      returnedCount={returnedCount}
                    />
                  </div>
                </div>
              </Row>
            </div>
          ) : (
            ''
          )}
          {radiofilter === 'BUs' ? (
            <Spin spinning={getAdminDashboardCountLoadingBUs}>
              {/* <div className={`${styles.chartView_container} mb-3`}>
                <Row className={`${styles.textAligment} `}>
                  <Col span={12} className={`${styles.horiz_container1} `}> */}
              <Card className={`${styles.cardStyles}`}>
                <div className={`${styles.table_container1}`}>
                  <Table_open_allocated count_BU_wise={BUs} />
                </div>
              </Card>
              {/*  </Col> */}
              {/* <Col
                span={8}
                className={`${styles.horiz_container1} `}
                style={{ marginLeft: '40px' }}
              > */}
              <Card className={`${styles.cardStyles}`}>
                <TreeChart allBUs={BUs} />
              </Card>
              {/*    </Col> */}
              {/*  </Row>
              </div> */}
            </Spin>
          ) : (
            ''
          )}
          {radiofilter === 'stakeHolders' ? (
            <Spin spinning={getAdminDashboardCountLoadingStakeHolders}>
              <Spin spinning={getAdminDashboardCountLoadingSectors}>
                {/* <div className={`${styles.chartView_container} mb-3`}>
                  <Row className={`${styles.textAligment} `}>
                    <Col span={12} className={`${styles.horiz_container1} `}> */}
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.table_container1}`}>
                    <ColumnChart stakeHolderCount={stakeHolders} />
                  </div>
                </Card>
                {/*  </Col>
                    <Col span={12} className={`${styles.horiz_container2} `}> */}
                <Card className={`${styles.cardStyles_paiChart}`}>
                  <PaiChart govtAndNonGovt={sectors} />
                </Card>
                {/* </Col>
                  </Row>
                </div> */}
              </Spin>
            </Spin>
          ) : (
            ''
          )}
          {radiofilter === 'gifts' ? (
            <Spin spinning={getAdminDashboardCountLoadingGifts}>
              {isMobile ? (
                ''
              ) : (
                <div className={`${styles.chartView_container} mb-3`}>
                  <StackedBarChart gag_vs_gr={allGifts} />
                </div>
              )}
              <Space className={`${styles.tableView_container} mb-3`}>
                <div className={`${styles.horiz_container} `}>
                  <Top5_gagChart
                    top_five_gag_chart={top5Gifts}
                    isMobile={isMobile}
                  />
                </div>
                <div className={`${styles.horiz_container} `}>
                  <Top5_grChart
                    top_five_gr_chart={top5Gifts}
                    isMobile={isMobile}
                  />
                </div>
              </Space>
            </Spin>
          ) : (
            ''
          )}
        </div>
      </Spin>
    </>
  );
}
export default DashboardPage;

import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Row, Input, Button } from 'antd';
import styles from './index.module.less';
// import moment from 'moment';
// import { getLoggedInUser} from 'utilities/helpers';
//import { message } from 'antd';
import FormItem from 'common/FormItem';
//import FormData from 'form-data';
import { useDispatch, useSelector } from 'react-redux';
import { set_value_limit } from '../../../../redux/slice';

function EditLimitValueModal({
  setOpenLimitValueModal,
  openLimitValueModal,
  limitValueData,
  handleGetAllEntryDelegatorsList
}) {
  //const [loading, setLoading] = useState(false);
  const [giftValue, setGiftValue] = useState();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { set_value_limitLoading } = useSelector((state) => state.admin);

  useEffect(() => {
    form.setFieldsValue({
      limitValue: limitValueData?.value
    });
  }, [limitValueData]);

  // const user_role1 = getLoggedInUser();
  // const user_role = user_role1?.user_details?.role;
  console.log('limitValueData', limitValueData);
  const onFinish = () => {
    //let formdata = new FormData();
    // formdata.append('limitCategoryId', limitValueData?.key);
    // formdata.append('value', giftValue);
    const payload = {
      limitCategoryId: limitValueData?.key,
      value: giftValue
    };
    dispatch(set_value_limit(payload)).then(function (response) {
      if (response) {
        setOpenLimitValueModal(false);
        handleGetAllEntryDelegatorsList();
      }
    });
  };
  const handleValueOfGift = (e) => {
    setGiftValue(e.target.value);
  };
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            Edit Limit Value :{' '}
          </span>
        }
        open={openLimitValueModal}
        // className={`${styles.check_circle_icon}`}
        width={'720px'}
        onCancel={() => setOpenLimitValueModal(false)}
        // onOk={() => setOpenLimitValueModal(false)}
        footer={false}
        // cancelButtonProps={{ style: { display: 'none' } }}
        // okText={'Submit'}
        // okButtonProps={{
        //   loading: set_value_limitLoading,
        //   //disabled: loading,
        //   onClick: onOk
        // }}
      >
        <Form
          name="date"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col className="gutter-row" span={24}>
              <FormItem
                name="limitValue"
                label={
                  <div className={`${styles.card_title}`}>
                    Enter Limit Value (in INR)
                    <div
                      style={{
                        fontSize: '12px',
                        color: '#a6a6ac',
                        fontFamily: 'SFProText Regular'
                      }}
                    ></div>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: '' //'This is a required field'
                  },
                  {
                    validator: async (_, value) => {
                      let num = 0;
                      if (num < value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Enter valid number');
                      }
                    }
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  step="0.000001"
                  className={`${styles.margin_Top}`}
                  allowClear
                  type="number"
                  min={1}
                  placeholder={limitValueData?.value} //"Enter limit value (in INR)"
                  onChange={handleValueOfGift}
                  onKeyPress={preventMinus}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Row>
              <Button
                className={`${styles.cancel_button}`}
                // htmlType="submit"
                size="middle"
                onClick={() => setOpenLimitValueModal(false)}
              >
                Cancel
              </Button>

              <Button
                loading={set_value_limitLoading}
                name="submit"
                className={`${styles.submit_button}`}
                htmlType="submit"
                size="middle"
                // onClick={submitHandler}
              >
                Submit
              </Button>
            </Row>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
}
export default EditLimitValueModal;

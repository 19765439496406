import React from 'react';
import styles from './index.module.less';
import { Radio, Card, Row, Col, Upload, Modal } from 'antd';
import FormItem from 'common/FormItem';

const { Dragger } = Upload;

function PerApproval_CEC_and_MD_Users({
  giftValue,
  formValues,
  handleFormDataOnChange,
  setTimeout,
  fileList,
  handlePreview,
  //handleChange,
  previewOpen,
  previewTitle,
  handleCancel,
  previewImage,
  //fileListLength,
  normFile,
  //uploadButton,
  fileList2,
  handleChange2,
  uploadButton2,
  fileListImage,
  handleChangeImage,
  uploadButtonImage,
  fileListImageLength,
  user
}) {
  return (
    <>
      {user?.user?.roles[0] === 'cec-user' ? (
        giftValue < 1000 &&
        formValues?.giftReceiverSector === 'non-government' ? (
          ''
        ) : (
          <>
            <Card className={`${styles.cardStyles}`}>
              <div className={`${styles.card_content}`}>
                <FormItem
                  name="hasMDApproval"
                  label={
                    <div className={`${styles.card_title}`}>
                      Do you have pre-approval from MD/MD Delegate?
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                >
                  <Radio.Group
                    onChange={(e) =>
                      handleFormDataOnChange('hasMDApproval', e.target.value)
                    }
                    //value={value}
                    className={`${styles.card_Radio}`}
                  >
                    <Row
                      className={`${styles.Radio_button_margin}`}
                      style={{ marginBottom: '10px' }}
                    >
                      <Col span={12}>
                        <Radio value={true}>Yes</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value={false}>No</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </FormItem>
              </div>
            </Card>

            {formValues?.hasMDApproval === true ? (
              <Card className={`${styles.cardStyles}`}>
                <div className={`${styles.card_content}`}>
                  <FormItem
                    name="files2"
                    label={
                      <div className={`${styles.card_title}`}>
                        Upload proof of pre-approval
                      </div>
                    }
                    rules={[
                      // {
                      //   required: true,
                      //   message: 'This is a required field'
                      // },
                      {
                        validator: async (_, value) => {
                          console.log(value);
                          if (fileList2.length > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('This is a required field');
                          }
                        }
                      }
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Dragger
                      className={`${styles.margin_Top}`}
                      beforeUpload={() => false}
                      customRequest={({ onChange }) =>
                        setTimeout(() => {
                          onChange('ok', null);
                        }, 0)
                      }
                      action="false"
                      listType="picture-card"
                      fileList={fileList2}
                      onPreview={handlePreview}
                      onChange={handleChange2}
                    >
                      {fileList2.length >= 8 ? null : uploadButton2}
                    </Dragger>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                      width="800px"
                    >
                      <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                      />
                    </Modal>
                  </FormItem>
                </div>
              </Card>
            ) : (
              ''
            )}
          </>
        )
      ) : (
        ''
      )}

      {user?.user?.roles[0] === 'md' ? (
        giftValue < 1000 &&
        formValues?.giftReceiverSector === 'non-government' ? (
          ''
        ) : (
          <>
            {' '}
            <Card className={`${styles.cardStyles}`}>
              <div className={`${styles.card_content}`}>
                <FormItem
                  name="hasCECApproval"
                  label={
                    <div className={`${styles.card_title}`}>
                      Do you have pre-approval from CEC?
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                >
                  <Radio.Group
                    onChange={(e) =>
                      handleFormDataOnChange('hasCECApproval', e.target.value)
                    }
                    //value={value}
                    className={`${styles.card_Radio}`}
                  >
                    <Row
                      className={`${styles.Radio_button_margin}`}
                      style={{ marginBottom: '10px' }}
                    >
                      <Col span={12}>
                        <Radio value={true}>Yes</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value={false}>No</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </FormItem>
              </div>
            </Card>
            {/* Upload documents of CEC  */}
            {formValues?.hasCECApproval === true ? (
              <>
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      name="gift_image"
                      label={
                        <div className={`${styles.card_title}`}>
                          <span className={`${styles.starStyles}`}>*</span>
                          Upload proof of pre-approval
                        </div>
                      }
                      rules={[
                        // {
                        //   required: true,
                        //   message: 'This is a required field'
                        // },
                        {
                          validator: async (_, value) => {
                            console.log(value);
                            if (fileListImageLength?.length > 0) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject('This is a required field');
                            }
                          }
                        }
                      ]}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Dragger
                        className={`${styles.margin_Top}`}
                        beforeUpload={() => false}
                        customRequest={({ onChange }) =>
                          setTimeout(() => {
                            onChange('ok', null);
                          }, 0)
                        }
                        action="false"
                        listType="picture-card"
                        fileList={fileListImage}
                        onPreview={handlePreview}
                        onChange={handleChangeImage}
                        type="file"
                        //accept="image/*"
                      >
                        {fileList.length >= 8 ? null : uploadButtonImage}
                      </Dragger>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                        //width="800px"
                      >
                        <img
                          alt="example"
                          style={{ width: '100%' }}
                          src={previewImage}
                        />
                      </Modal>
                    </FormItem>
                  </div>
                </Card>
              </>
            ) : (
              ''
            )}
          </>
        )
      ) : (
        ''
      )}
    </>
  );
}

export default PerApproval_CEC_and_MD_Users;

import React, { useState } from 'react';
import styles from './index.module.less';
import {
  Button,
  Col,
  Row,
  Form,
  Card,
  Typography,
  Spin,
  Tooltip,
  Divider,
  Steps,
  Alert,
  Tag
} from 'antd';
import FormItem from 'common/FormItem';
import { history } from 'app/history';
import Approval_Modal from './approval_Modal';
import Reject_Modal from './reject_Modal';
//import ReturnRO_Modal from '../../../view_gift_return/components/viewReturnGiftPage/returnRO_Modal';
import {
  getLoggedInUser,
  numIntStyleFormat,
  Recipient_Type,
  numFormating
} from '../../../../utilities/helpers';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';
// import FormData from 'form-data';
// import {
//   returnGift_RO_form,
//   returnGift_Giver_form
// } from '../../../view_gift_return/redux/slice';
// import { useDispatch } from 'react-redux';

const { Text } = Typography;

function ViewGiftReceivedDetailsPage({
  getGiftByIdLoading,
  getGiftDetailsById,
  Id,
  type,
  hashRoot,
  uniqueId,
  userId
  //status
  //exceptionRequestsLoading
}) {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();

  //const dispatch = useDispatch();

  //const [ellipsis] = useState(true);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  console.log('user_role1', user_role);

  const redirect_action = LocalStorage.getItem('redirect_action');
  console.log('redirect_action@@@@@@', redirect_action);

  console.log('getGiftDetailsById', getGiftDetailsById);

  const getGiftDetailsById_Status =
    getGiftDetailsById?.ReceivingFlowWorkings?.length > 0
      ? getGiftDetailsById?.ReceivingFlowWorkings[0]?.status
      : '';

  const [exceptionalApprovalModal, setExceptionalApprovalModal] =
    useState(false);
  //const [returnToROModal, setReturnToROModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const onFinish = (values) => {
    console.log('Values', values);
  };

  // const HandleReturnGift = (payload) => {
  //   let formdata = new FormData();

  //   formdata.append('grId', payload?.grId);
  //   payload?.whom_return === true
  //     ? ''
  //     : formdata.append(' roId', payload?.roId);

  //   for (let i in payload?.fileList) {
  //     formdata.append(
  //       'roDocument',
  //       payload?.fileList?.length === 0
  //         ? ''
  //         : payload?.fileList[i]?.originFileObj
  //     );
  //   }
  //   formdata.append(
  //     'commentsOfReturn',
  //     payload?.commentsOfReturn === undefined ? '' : payload?.commentsOfReturn
  //   );
  //   payload?.whom_return === true
  //     ? dispatch(returnGift_Giver_form(formdata)).then(function (response) {
  //         if (response?.payload?.success) {
  //           console.log(response);
  //           setReturnToROModal(false);
  //           history.push('/view-gift-received-request');
  //         }
  //       })
  //     : dispatch(returnGift_RO_form(formdata)).then(function (response) {
  //         if (response?.payload?.success) {
  //           console.log(response);
  //           setReturnToROModal(false);
  //           history.push('/view-gift-received-request');
  //         }
  //       });
  // };

  const items =
    getGiftDetailsById?.ReceivingFlow?.length > 0
      ? getGiftDetailsById?.ReceivingFlow?.map((item) => {
          return {
            title:
              item?.status === 'accepted' ? (
                <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
              ) : item?.status === 'pending' ? (
                <span style={{ color: '#2264ae' }}>Pending</span>
              ) : item?.status === 'rejected' ? (
                'Rejected'
              ) : item?.status === null ? (
                'Waiting'
              ) : (
                'Waiting'
              ),
            status:
              item?.status === 'accepted'
                ? 'finish'
                : item?.status === 'pending'
                ? 'process'
                : item?.status === 'rejected'
                ? 'error'
                : item?.status === null
                ? 'wait'
                : 'wait',

            description:
              item?.status === 'accepted' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Approved by:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      {' '}
                      Approved on:{' '}
                    </span>
                    {moment(item?.updatedAt).format('DD MMM YYYY')}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>Notes:</span>
                    <Tooltip
                      title={
                        item?.approverComment === null
                          ? 'NA'
                          : item?.approverComment
                      }
                      color={'white'}
                      overlayInnerStyle={{ color: '#32324D', fontSize: '11px' }}
                      arrowPointAtCenter="false"
                    >
                      {item?.approverComment === null ? (
                        ' NA'
                      ) : (
                        <Text
                          className={`${styles.ant_collapse_header}`}
                          // ellipsis={true}
                        >
                          {item?.approverComment}
                        </Text>
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : item?.status === 'rejected' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Rejected by:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      {' '}
                      Rejected on:{' '}
                    </span>
                    {moment(item?.updatedAt).format('DD MMM YYYY')}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}> Notes:</span>
                    <Tooltip
                      title={
                        item?.approverComment === null
                          ? 'NA'
                          : item?.approverComment
                      }
                      color={'white'}
                      overlayInnerStyle={{ color: '#32324D', fontSize: '11px' }}
                      arrowPointAtCenter="false"
                    >
                      {item?.approverComment === null ? (
                        ' NA'
                      ) : (
                        <Text
                          className={`${styles.ant_collapse_header}`}
                          // ellipsis={true}
                        >
                          {item?.approverComment}
                        </Text>
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : item?.status === 'pending' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      waiting for approval:{' '}
                    </span>
                    {item?.User?.givenName}
                  </div>
                </div>
              ) : item?.status === null ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Waiting for Approval:{' '}
                    </span>
                    {item?.User?.givenName}
                  </div>
                </div>
              ) : (
                ''
              )
            //subTitle: item?.updatedAt
          };
        })
      : '';
  return (
    <Spin spinning={getGiftByIdLoading}>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <Col span={18} className={`${styles.gift_container}`}>
            <div
              className={
                isMobile
                  ? `${styles.myGift_title_isMobile}`
                  : `${styles.myGift_title}`
              }
            >
              {getGiftDetailsById?.ticket}
            </div>
            <div
              className={
                isMobile
                  ? `${styles.myGift_text_isMobile}`
                  : `${styles.myGift_text}`
              }
            >
              View the details of the gift
            </div>
          </Col>
          {/* <Col span={14} className={`${styles.mySubGift_title}`}>
            Details of the Review Received Gift
          </Col> */}
        </Row>
        <Card className={`${styles.card_styles}`}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
          >
            <Typography className={`${styles.CardTitleText}`}>
              {`Requestor Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.givenName}{' '}
                    {getGiftDetailsById?.Receiver?.surname}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.designation === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.designation}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Location
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.location === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.location}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Email id
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <a>
                      {getGiftDetailsById?.Receiver?.email === null
                        ? 'NA'
                        : getGiftDetailsById?.Receiver?.email}
                    </a>
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Department/Function
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Line Manager
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.ReportingManager?.User
                      ?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.ReportingManager?.User
                          ?.givenName}{' '}
                    {
                      getGiftDetailsById?.Receiver?.ReportingManager?.User
                        ?.surname
                    }
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Employee id
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.empId === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.empId}{' '}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Business Unit (BU)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of reporting of the gift
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.dateOfReporting === null
                      ? 'NA'
                      : moment(getGiftDetailsById?.dateOfReporting).format(
                          'DD MMM YYYY'
                        )}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Typography className={`${styles.CardTitleText}`}>
              {`Gift giver Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />

            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Full Name of the Giver
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giverName}{' '}
                    {/*  {getGiftDetailsById?.Receiver?.surname} */}
                  </Typography>
                </FormItem>
              </Col>

              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Type of Giver
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giverType}
                  </Typography>
                </FormItem>
              </Col>
              {getGiftDetailsById?.wasThereOngoingTender === true && (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide details of the ongoing tender/discussion and the
                    role played by the giver of the gift
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.ongoingTenderDiscussion === null ||
                      getGiftDetailsById?.ongoingTenderDiscussion === ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.ongoingTenderDiscussion}
                    </Typography>
                  </div>
                </Col>
              )}
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Giver Organization Full Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.organizationName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Vendor Name/s (gift givers)"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Was it a Govt. Official
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {Recipient_Type(getGiftDetailsById?.giftGiverSector)}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation of Gift giver
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.designation : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontWeight: 600,
                    fontSize: '13px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Was there any ongoing tender/discussion with the company at
                  the time of this gift?
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.wasThereOngoingTender === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </div>
              </Col>
              {getGiftDetailsById?.wasThereOngoingTender === true && (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide a business justification for this gift during an
                    ongoing tender/discussion
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        null ||
                      getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender}
                    </Typography>
                  </div>
                </Col>
              )}
            </Row>

            <Typography className={`${styles.CardTitleText}`}>
              {`Gift Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />

            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  // label="Gift Type"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Gift Type
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.giftName : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of receipt of gift
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {moment(getGiftDetailsById?.dateOfGiftReceived).format(
                      'DD MMM YYYY'
                    )}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Whether the gift has been accepted or returned?
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.isTheGiftReturned === false
                      ? 'Accepted'
                      : 'Returned'}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Description of Gift
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giftDescription === ''
                      ? 'Not applicable'
                      : getGiftDetailsById?.giftDescription}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Approximate value of the gift (in INR)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {numIntStyleFormat(getGiftDetailsById?.valueOfGift)}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                {getGiftDetailsById?.isTheGiftReturned === false ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide the reason for acceptance of the gift
                  </div>
                ) : getGiftDetailsById?.isTheGiftReturned === true &&
                  getGiftDetailsById?.returnedToGiver === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide the reason for returned of the gift by Giver
                  </div>
                ) : getGiftDetailsById?.isTheGiftReturned === true &&
                  getGiftDetailsById?.returnedToRo === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide the reason for returned of the gift by RO
                  </div>
                ) : (
                  ''
                )}

                <Typography className={`${styles.valueText}`}>
                  {getGiftDetailsById?.isTheGiftReturned === false
                    ? getGiftDetailsById?.commentsOfAccept
                    : getGiftDetailsById?.commentsOfReturn}
                </Typography>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '10px' }}>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Was the gift embossed with the logo of the gift-giving
                  company?
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.isGiftEmbossed === true
                      ? 'Yes'
                      : getGiftDetailsById?.isGiftEmbossed === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontWeight: 600,
                    fontSize: '13px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  The context/business purpose of this gift
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.contextOrBusinessPurposeOfTheGift ===
                      null ||
                    getGiftDetailsById?.contextOrBusinessPurposeOfTheGift === ''
                      ? 'NA'
                      : getGiftDetailsById?.contextOrBusinessPurposeOfTheGift}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                {getGiftDetailsById?.isTheGiftReturned === true &&
                getGiftDetailsById?.returnedToGiver === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Date of return to the gift giver
                  </div>
                ) : getGiftDetailsById?.isTheGiftReturned === true &&
                  getGiftDetailsById?.returnedToRo === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Date of the gift handed over to the RO
                  </div>
                ) : (
                  ''
                )}
                <Typography className={`${styles.valueText}`}>
                  {getGiftDetailsById?.isTheGiftReturned === true
                    ? getGiftDetailsById?.dateOfReporting === null
                      ? 'NA'
                      : moment(getGiftDetailsById?.dateOfGiftReturned).format(
                          'DD MMM YYYY'
                        )
                    : ''}
                </Typography>
              </Col>
            </Row>

            {getGiftDetailsById?.ReceivingFlow?.length !== 0 && (
              <>
                <Divider />
                <Typography className={`${styles.CardTitleText}`}>
                  {`Review Details`}
                </Typography>
                <Divider className={`${styles.divider_margin}`} />
              </>
            )}

            {getGiftDetailsById?.ReceiverId === user_role?.id ? (
              getGiftDetailsById?.status === 'accepted' ? (
                <Tag className={`${styles.status_style}`} color={'green'}>
                  Approved
                </Tag>
              ) : getGiftDetailsById?.status === 'rejected' ? (
                <Tag className={`${styles.status_style}`} color={'red'}>
                  Rejected
                </Tag>
              ) : getGiftDetailsById?.status === 'pending' ? (
                <Tag className={`${styles.status_style}`} color={'blue'}>
                  Pending
                </Tag>
              ) : (
                ''
              )
            ) : getGiftDetailsById?.ReceivingFlow?.length > 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row'
                  }}
                >
                  <Steps
                    className={`${styles.steps_style}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      color: '#2264ae'
                    }}
                    size="small"
                    current={getGiftDetailsById?.ReceivingFlow?.length}
                    items={items}
                    //progressDot={customDot}
                    responsive={true}
                  />
                </div>{' '}
              </>
            ) : getGiftDetailsById_Status === 'accepted' ? (
              <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
            ) : (
              /* getGiftDetailsById_Status === 'rejected' ? (
              <span style={{ color: '#B71212' }}>
                Rejected
                {getGiftDetailsById?.ReceivingFlow?.length === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      justifyItems: 'flex-start',
                      flexDirection: 'column',
                      fontSize: '11px',
                      color: ' rgba(0, 0, 0, 0.45)'
                    }}
                  >
                    <div>
                      Notes:
                      <Tooltip
                        title={getGiftDetailsById?.reasonForReject}
                        color={'white'}
                        overlayInnerStyle={{
                          color: '#32324D',
                          fontSize: '11px'
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header1}`}
                          ellipsis={true}
                        >
                          {getGiftDetailsById?.reasonForReject}
                        </Text>
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </span>
            ) : */ ''
            )}
            <Divider />

            <Alert
              message={`Value of Gifts Received this Fiscal: ₹${numFormating(
                getGiftDetailsById?.thisFiscalYearReceivedGiftsValue
              )}`}
              type="info"
              showIcon
              style={{
                marginTop: '-20px',
                fontWeight: 600,
                width: isMobile ? '300px' : '400px'
              }}
            />

            {getGiftDetailsById_Status === 'pending' ? (
              <Row style={{ marginTop: '20px' }}>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button1}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => {
                      redirect_action === 'home_action_gr'
                        ? history.push('/view-gift-received-request')
                        : redirect_action === 'dashboard_action'
                        ? history.push('/action-required')
                        : '';
                    }}
                  >
                    Go back
                  </Button>
                </span>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => setExceptionalApprovalModal(true)}
                  >
                    Approve
                  </Button>
                </span>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button_reject}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => setRejectModal(true)}
                  >
                    Reject
                  </Button>
                </span>
              </Row>
            ) : (
              <div style={{ marginTop: '20px' }}>
                <Button
                  className={`${styles.Goback_button}`}
                  htmlType="submit"
                  size="small"
                  onClick={() => {
                    redirect_action === 'home_action_gr'
                      ? history.push('/view-gift-received-request')
                      : redirect_action === 'dashboard_action'
                      ? history.push('/action-required')
                      : '';
                  }}
                >
                  Go back
                </Button>
              </div>
            )}
          </Form>
        </Card>
        {exceptionalApprovalModal ? (
          <Approval_Modal
            exceptionalApprovalModal={exceptionalApprovalModal}
            setExceptionalApprovalModal={setExceptionalApprovalModal}
            getGiftDetailsById={getGiftDetailsById}
            Id={Id}
            type={type}
            hashRoot={hashRoot}
            uniqueId={uniqueId}
            userId={userId}
            redirect_action={redirect_action}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
        {rejectModal ? (
          <Reject_Modal
            rejectModal={rejectModal}
            setRejectModal={setRejectModal}
            getGiftDetailsById={getGiftDetailsById}
            Id={Id}
            type={type}
            hashRoot={hashRoot}
            uniqueId={uniqueId}
            userId={userId}
            redirect_action={redirect_action}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
        {/*  {returnToROModal ? (
          <ReturnRO_Modal
            returnToROModal={returnToROModal}
            setReturnToROModal={setReturnToROModal}
            getGiftDetailsById={getGiftDetailsById}
            HandleReturnGift={HandleReturnGift}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )} */}
      </div>
    </Spin>
  );
}
export default ViewGiftReceivedDetailsPage;
/* :  getGiftDetailsById?.status  ===
                'rejected' ? (
                <Row style={{ marginTop: '20px' }}>
                  <span style={{ marginRight: '10px' }}>
                    <Button
                      className={`${styles.Goback_button1}`}
                      htmlType="submit"
                      size="small"
                      onClick={() =>
                        history.push('/view-gift-received-request')
                      }
                    >
                      Go back
                    </Button>
                  </span>
                  {getGiftDetailsById?.returnedToGiver === false &&
                  getGiftDetailsById?.returnedToRo === false ? (
                    <span style={{ marginRight: '10px' }}>
                      <Button
                        className={`${styles.Goback_button}`}
                        htmlType="submit"
                        size="small"
                        onClick={() => setReturnToROModal(true)}
                      >
                        Return
                      </Button>
                    </span>
                  ) : (
                    ''
                  )}
                </Row>
              ) */

import React, { useState } from 'react';
import styles from './index.module.less';
import {
  Button,
  Col,
  Row,
  Form,
  Card,
  Typography,
  Spin,
  Tooltip,
  Divider,
  //Steps,
  Tag
} from 'antd';
import FormItem from 'common/FormItem';
import { history } from 'app/history';
import ExceptionalApproval_Modal from './exceptional_approval_Modal';
import RejectGift_Modal from './rejectGift_Modal';
import {
  getLoggedInUser,
  numIntStyleFormat,
  Recipient_Type
} from '../../../../utilities/helpers';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import View_cxo_preApproval from './View_cxo_preApproval';
import View_sbgHead_preApproval from './View_sbgHead_preApproval';
import View_CEC_and_MD_preApproval from './View_CEC_and_MD_preApproval';
import { sizeChecker } from 'utilities/helpers';
//import PdfViewerComponent from './PdfViewerComponent';

const { Text } = Typography;

function ViewGiftDetailsPage({
  getGiftByIdLoading,
  getGiftDetailsById
  //exceptionRequestsLoading
}) {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();

  // const [loading, setLoading] = useState(false);
  // const [fileName, setFileName] = useState(null);

  const redirect = LocalStorage.getItem('redirect');
  console.log('redirect@@@@@@', redirect);

  const dashboardCard_status = LocalStorage.getItem('dashboardCard_status');
  console.log('dashboardCard_status%%%%%%%', dashboardCard_status);
  const dashboardCard_ReceivedGifts_status = LocalStorage.getItem(
    'dashboardCard_ReceivedGifts_status'
  );
  console.log(
    'dashboardCard_ReceivedGifts_status%%%%%%%',
    dashboardCard_ReceivedGifts_status
  );

  console.log('By id', getGiftDetailsById);

  // const dispatch = useDispatch();

  const [ellipsis] = useState(true);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  console.log('user_role1', user_role?.roles[0]);

  const [exceptionalApprovalModal, setExceptionalApprovalModal] =
    useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  //const [returnToROModal, setReturnToROModal] = useState(false);

  const onFinish = (values) => {
    console.log('Values', values);
  };

  //const apiUrl = process.env.REACT_APP_API_URL;
  // const handleFetchDocument = (fileName) => {
  //   setLoading(true);

  //   const payload = {
  //     originalName: fileName
  //   };

  //   const accessToken = user_role1?.accessToken;

  //   fetch(`${apiUrl}/fetch-file?fileName=${payload.originalName}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/pdf',
  //       Authorization: `${accessToken}`
  //     }
  //   })
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       setLoading(false);
  //       const url = URL.createObjectURL(blob);

  //       // window.open(url, '_blank');
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'document.pdf');
  //       link.click();
  //       window.open(url, '_blank');
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching PDF:', error);
  //       setLoading(false);
  //     });
  // };
  // const handleFetchDocument = (fileName) => {
  //   // const url = `${apiUrl}/fetch-file?fileName=${fileName}`;

  //   // // Open the URL in a new tab
  //   // window.open(url, '_blank');

  // };

  const handleFetchDocument = (fileName) => {
    //   console.log('handleFetchDocument called with fileName:', fileName);
    //   setFileName(fileName);
    //   const pdfUrl = `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`;
    // const viewerUrl = `/pdf-viewer.html?pdfUrl=${encodeURIComponent(pdfUrl)}`;
    // return viewerUrl;
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };
  return (
    <Spin spinning={getGiftByIdLoading}>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <Col span={19} className={`${styles.gift_container}`}>
            <div
              className={
                isMobile
                  ? `${styles.myGift_title_isMobile}`
                  : `${styles.myGift_title}`
              }
            >
              {getGiftDetailsById?.ticket}
              <span style={{ textTransform: 'uppercase' }}>
                -{getGiftDetailsById?.type}
              </span>
            </div>
            <div
              className={
                isMobile
                  ? `${styles.myGift_text_isMobile}`
                  : `${styles.myGift_text}`
              }
            >
              View the details of the gift
            </div>
          </Col>
          {/*  <Col span={14} className={`${styles.mySubGift_title}`}>
            Details of the My Gift Requests
          </Col> */}
        </Row>

        <Card className={`${styles.card_styles}`}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
          >
            <Typography className={`${styles.CardTitleText}`}>
              {`Requestor Details`}
            </Typography>
            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.givenName}{' '}
                    {getGiftDetailsById?.Creator?.surname}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.designation === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.designation}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Location
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.location === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.location}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Email
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <a>
                      {getGiftDetailsById?.Creator?.email === null
                        ? 'NA'
                        : getGiftDetailsById?.Creator?.email}
                    </a>
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Department/Function
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Line Manager
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.ReportingManager?.User
                      ?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.ReportingManager?.User
                          ?.givenName}{' '}
                    {
                      getGiftDetailsById?.Creator?.ReportingManager?.User
                        ?.surname
                    }
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Employee ID
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.empId === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.empId}{' '}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Business Unit (BU)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of reporting of the gift
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.dateOfReporting === null
                      ? 'NA'
                      : moment(getGiftDetailsById?.dateOfReporting).format(
                          'DD MMM YYYY'
                        )}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Typography className={`${styles.CardTitleText}`}>
              {`Recipient Details`}
            </Typography>
            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Full Name of the Recipient
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.recipientName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Type of Recipient
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.recipientType}
                  </Typography>
                </FormItem>
              </Col>
              {getGiftDetailsById?.wasThereOngoingTender === true ? (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide details of the ongoing tender/discussion and the
                    role played by the recipient of the proposed gift
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.ongoingTenderDiscussion === null ||
                      getGiftDetailsById?.ongoingTenderDiscussion === ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.ongoingTenderDiscussion}
                    </Typography>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Recipient Organization Full Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.organizationName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    getGiftDetailsById?.type === 'gytb' ? (
                      <span
                        style={{
                          color: '#344054',
                          fontWeight: 600,
                          fontSize: '14px',
                          fontFamily: 'SFProText Regular'
                        }}
                      >
                        Is the recipient a Govt. Official?
                      </span>
                    ) : (
                      <span
                        style={{
                          color: '#344054',
                          fontWeight: 600,
                          fontSize: '14px',
                          fontFamily: 'SFProText Regular'
                        }}
                      >
                        Was it a Govt. Official
                      </span>
                    )
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {Recipient_Type(getGiftDetailsById?.giftReceiverSector)}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation of the Recipient
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.designation : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <>
                  {getGiftDetailsById?.type === 'gytb' ? (
                    <div className={`${styles.titleText}`}>
                      Is there any ongoing tender/discussion with the company at
                      time of this gift?
                    </div>
                  ) : (
                    <div className={`${styles.titleText}`}>
                      Was there any ongoing tender/discussion with the company
                      at time of this gift?
                    </div>
                  )}
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {getGiftDetailsById?.wasThereOngoingTender === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <>
                  {getGiftDetailsById?.type === 'gytb' ? (
                    <div className={`${styles.titleText}`}>
                      Is this a gift to a family member of a supplier, customer,
                      or other business partner?
                    </div>
                  ) : (
                    <div className={`${styles.titleText}`}>
                      Was this a gift to a family member of a supplier,
                      customer, or other business partner?
                    </div>
                  )}
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {getGiftDetailsById?.giftReceivedByFamily === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              {getGiftDetailsById?.wasThereOngoingTender === true ? (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide a business justification for why this gift was given
                    during an ongoing tender/discussion
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        null ||
                      getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender}
                    </Typography>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <Typography
              className={`${styles.CardTitleText}`}
              style={{ marginTop: '10px' }}
            >
              {`Gift Details`}
            </Typography>
            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  // label="Gift Type"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Gift Type
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.giftName : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Was the gift procured locally?
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.procuredHow === 'tpl' ? 'No' : 'Yes'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontWeight: 600,
                    fontSize: '13px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Context/Business Purpose of the Gift
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.contextOrBusinessPurposeOfTheGift ===
                    null
                      ? 'NA'
                      : getGiftDetailsById?.contextOrBusinessPurposeOfTheGift}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Description of Gift
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giftDescription === ''
                      ? 'Not applicable'
                      : getGiftDetailsById?.giftDescription}
                  </Typography>
                </div>
              </Col>
              {/*  <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Description of Gift
                </div>
                <div>
                  <Tooltip
                    title={
                      getGiftDetailsById?.giftDescription === ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.giftDescription
                    }
                    color={'white'}
                    overlayInnerStyle={{
                      color: 'black',
                      fontSize: '12px',
                      fontWeight: 600
                    }}
                    arrowPointAtCenter="false"
                  >
                    <Typography className={`${styles.valueText}`}>
                      <Text
                        className={`${styles.ant_collapse_header_label}`}
                        ellipsis={true}
                      >
                        {getGiftDetailsById?.giftDescription === ''
                          ? 'Not applicable'
                          : getGiftDetailsById?.giftDescription}
                      </Text>
                    </Typography>
                  </Tooltip>
                </div>
              </Col> */}
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Date of gift received"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of Gift Giving
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {moment(getGiftDetailsById?.dateOfGiftReceived).format(
                      'DD MMM YYYY'
                    )}
                  </Typography>
                </FormItem>
              </Col>

              {/* <Col span={isMobile ? 24 : 8}>
                <FormItem
                  // label="Image of the gift"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Gift Image
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <div
                      style={{
                        marginTop: '0px',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      {getGiftDetailsById?.GiftImages?.length === 0
                        ? 'Not applicable'
                        : getGiftDetailsById?.GiftImages?.map((item, index) => (
                            <Tooltip title={item?.originalName} key={index}>
                              <Text
                                style={
                                  ellipsis
                                    ? {
                                        width: 80
                                        //marginRight: '8px'
                                      }
                                    : undefined
                                }
                                ellipsis={
                                  ellipsis
                                    ? {
                                        tooltip: ''
                                      }
                                    : false
                                }
                              >
                                <a
                                  href={item?.fileUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item?.originalName}
                                </a>
                              </Text>
                            </Tooltip>
                          ))}
                    </div>
                  </Typography>
                </FormItem>
              </Col> */}
            </Row>
            <Row
              gutter={16}
              style={{ marginBottom: '20px', marginTop: '10px' }}
              className={`${styles.row_display_isMobile}`}
            >
              <Col span={isMobile ? 24 : 8}>
                <>
                  <div className={`${styles.titleText}`}>
                    Was the gift taken from the stock of promotional items?
                  </div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.isGiftEmbossed === true
                      ? 'Yes'
                      : getGiftDetailsById?.isGiftEmbossed === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Value of the Gift (in INR)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {numIntStyleFormat(getGiftDetailsById?.valueOfGift)}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            {getGiftDetailsById?.type === 'gag' &&
            user_role?.roles[0] === 'cxo' ? (
              <View_cxo_preApproval getGiftDetailsById={getGiftDetailsById} />
            ) : getGiftDetailsById?.type === 'gag' &&
              user_role?.roles[0] === 'sbg-head' ? (
              <View_sbgHead_preApproval
                getGiftDetailsById={getGiftDetailsById}
              />
            ) : (getGiftDetailsById?.type === 'gag' &&
                user_role?.roles[0] === 'cec-user') ||
              (getGiftDetailsById?.type === 'gag' &&
                user_role?.roles[0] === 'md') ? (
              <View_CEC_and_MD_preApproval
                getGiftDetailsById={getGiftDetailsById}
                user_role={user_role}
              />
            ) : getGiftDetailsById?.type === 'gag' &&
              getGiftDetailsById?.valueOfGift < 1000 &&
              getGiftDetailsById?.giftReceiverSector === 'non-government' ? (
              ''
            ) : getGiftDetailsById?.type === 'gag' ? (
              <Row gutter={16} className={`${styles.row_display_isMobile}`}>
                <Col span={isMobile ? 24 : 8}>
                  <FormItem
                    //label="Number of gift articles"
                    label={
                      <span
                        style={{
                          color: '#344054',
                          fontWeight: 600,
                          fontSize: '14px',
                          fontFamily: 'SFProText Regular'
                        }}
                      >
                        {user_role?.roles[0] === 'bu-head'
                          ? 'Pre-approval from your SBG/Functional Head'
                          : 'BU/Department Head Pre-approval'}
                      </span>
                    }
                    className={`${styles.select_styles}`}
                  >
                    <Typography className={`${styles.valueText}`}>
                      {' '}
                      {getGiftDetailsById?.approval1 === true
                        ? 'Yes'
                        : getGiftDetailsById?.approval1 === false
                        ? 'No'
                        : 'Not applicable'}
                    </Typography>
                  </FormItem>
                </Col>

                {getGiftDetailsById?.approval1 === true ? (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          {user_role?.roles[0] === 'bu-head'
                            ? 'Pre-approval documents by SBG/Functional Head.........'
                            : 'Pre-approval documents by BU Head.............'}
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      {/* <Spin spinning={loading}> */}
                      <Typography className={`${styles.valueText}`}>
                        <div
                          style={{
                            marginTop: '0px',
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          {getGiftDetailsById?.PreApprovals?.length === 0
                            ? 'Not applicable'
                            : getGiftDetailsById?.PreApprovals?.map(
                                (item, index) => (
                                  <Tooltip
                                    title={item?.originalName}
                                    key={index}
                                  >
                                    <Text
                                      style={
                                        ellipsis
                                          ? {
                                              width: 80
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        ellipsis
                                          ? {
                                              tooltip: ''
                                            }
                                          : false
                                      }
                                    >
                                      {/* <Button
                                          //href={item?.fileUrl}
                                          // href={`https://gr-api.lexyslabs.com/api-v1/fetch-file?fileName=${item?.fileName}`}
                                          type="link"
                                          // target="_blank"
                                          // rel="noreferrer"
                                          onClick={() => {
                                            handleFetchDocument(item?.fileName);
                                          }}
                                        >
                                          {item?.originalName}
                                        </Button> */}
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          const viewerUrl = handleFetchDocument(
                                            item?.fileName
                                          );
                                          if (viewerUrl) {
                                            window.open(viewerUrl, '_blank');
                                          }
                                        }}
                                      >
                                        {item?.originalName}
                                      </Button>
                                      {/*  {fileName && (
                                          <PdfViewerComponent
                                            fileName={fileName}
                                          />
                                        )} */}
                                    </Text>
                                  </Tooltip>
                                )
                              )}
                        </div>
                      </Typography>
                      {/*  </Spin> */}
                    </FormItem>
                  </Col>
                ) : (
                  ''
                )}
                {getGiftDetailsById?.approval1 === true &&
                getGiftDetailsById?.giftReceiverSector === 'non-government' &&
                getGiftDetailsById?.procuredHow === 'local' ? (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      // label="Value of gift (INR)"
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          CEC Delegate Pre-approval
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        {getGiftDetailsById?.hasCECApproval === true
                          ? 'Yes'
                          : getGiftDetailsById?.hasCECApproval === false
                          ? 'No'
                          : 'Not applicable'}
                      </Typography>
                    </FormItem>
                  </Col>
                ) : getGiftDetailsById?.giftReceiverSector ===
                    'non-government' &&
                  getGiftDetailsById?.procuredHow === 'tpl' ? (
                  ''
                ) : getGiftDetailsById?.approval1 === true ? (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      // label="Value of gift (INR)"
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          MD/MD Delegate Pre-approval
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        {getGiftDetailsById?.approval2 === true
                          ? 'Yes'
                          : getGiftDetailsById?.approval2 === false
                          ? 'No'
                          : 'Not applicable'}
                      </Typography>
                    </FormItem>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            ) : (
              ''
            )}
            {getGiftDetailsById?.type === 'gag' &&
            getGiftDetailsById?.hasCECApproval === true ? (
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      {'Pre-approval documents by CEC Delegate'}
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <div
                      style={{
                        marginTop: '0px',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      {getGiftDetailsById?.CECApprovalDocs?.length === 0
                        ? 'Not applicable'
                        : getGiftDetailsById?.CECApprovalDocs?.map(
                            (item, index) => (
                              <Tooltip title={item?.originalName} key={index}>
                                <Text
                                  style={
                                    ellipsis
                                      ? {
                                          width: 80
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    ellipsis
                                      ? {
                                          tooltip: ''
                                        }
                                      : false
                                  }
                                >
                                  {/* <a
                                    href={item?.fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.originalName}
                                  </a> */}
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      handleFetchDocument(item?.fileName);
                                    }}
                                  >
                                    {item?.originalName}
                                  </Button>
                                </Text>
                              </Tooltip>
                            )
                          )}
                    </div>
                  </Typography>
                </FormItem>
              </Col>
            ) : (
              ''
            )}
            {getGiftDetailsById?.type === 'gag' &&
            getGiftDetailsById?.approval2 === true ? (
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      {'Pre-approval documents by MD/MD Delegate'}
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <div
                      style={{
                        marginTop: '0px',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      {getGiftDetailsById?.ApprovalDocs?.length === 0
                        ? 'Not applicable'
                        : getGiftDetailsById?.ApprovalDocs?.map(
                            (item, index) => (
                              <Tooltip title={item?.originalName} key={index}>
                                <Text
                                  style={
                                    ellipsis
                                      ? {
                                          width: 80
                                        }
                                      : undefined
                                  }
                                  ellipsis={
                                    ellipsis
                                      ? {
                                          tooltip: ''
                                        }
                                      : false
                                  }
                                >
                                  {/* <a
                                    href={item?.fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item?.originalName}
                                  </a> */}
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      handleFetchDocument(item?.fileName);
                                    }}
                                  >
                                    {item?.originalName}
                                  </Button>
                                </Text>
                              </Tooltip>
                            )
                          )}
                    </div>
                  </Typography>
                </FormItem>
              </Col>
            ) : (
              ''
            )}
            <Typography
              className={`${styles.CardTitleText}`}
              style={{ marginTop: '10px' }}
            >
              {`Review Details`}
            </Typography>
            <Divider className={`${styles.divider_margin}`} />
            {getGiftDetailsById?.status === 'accepted' ? (
              <Tag className={`${styles.status_style}`} color={'green'}>
                Approved
              </Tag>
            ) : getGiftDetailsById?.status === 'rejected' ? (
              <>
                <Tag className={`${styles.status_style}`} color={'red'}>
                  Rejected
                </Tag>
                <div className={`${styles.reasonForReject_text}`}>
                  {getGiftDetailsById?.reasonForReject === null ||
                  getGiftDetailsById?.reasonForReject === ''
                    ? 'NA'
                    : getGiftDetailsById?.reasonForReject}
                </div>
              </>
            ) : getGiftDetailsById?.status === 'pending' ? (
              <Tag className={`${styles.status_style}`} color={'blue'}>
                Pending
              </Tag>
            ) : (
              ''
            )}
            {/*  {getGiftDetailsById?.CreatorId === user_role?.id ? (
              getGiftDetailsById?.status === 'accepted' ? (
                <Tag className={`${styles.status_style}`} color={'green'}>
                  Approved
                </Tag>
              ) : getGiftDetailsById?.status === 'rejected' ? (
                <>
                  <Tag className={`${styles.status_style}`} color={'red'}>
                    Rejected
                  </Tag>
                  <div className={`${styles.reasonForReject_text}`}>
                    {getGiftDetailsById?.reasonForReject}
                  </div>
                </>
              ) : getGiftDetailsById?.status === 'pending' ? (
                <Tag className={`${styles.status_style}`} color={'blue'}>
                  Pending
                </Tag>
              ) : (
                ''
              )
            ) : getGiftDetailsById?.GivingFlow?.length > 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row'
                  }}
                >
                  <Steps
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      color: 'green'
                    }}
                    size="small"
                    current={getGiftDetailsById?.GivingFlow?.length}
                    items={items}
                    //progressDot={customDot}
                    responsive={true}
                  />
                </div>{' '}
              </>
            ) : getGiftDetailsById?.status === 'accepted' ? (
              <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
            ) : getGiftDetailsById?.status === 'rejected' ? (
              <span style={{ color: '#B71212' }}>
                Rejected
                {getGiftDetailsById?.GivingFlow?.length === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      justifyItems: 'flex-start',
                      flexDirection: 'column',
                      fontSize: '11px',
                      color: ' rgba(0, 0, 0, 0.45)'
                    }}
                  >
                    <div>
                      <Tooltip
                        title={getGiftDetailsById?.reasonForReject}
                        color={'white'}
                        overlayInnerStyle={{
                          color: '#32324D',
                          fontSize: '11px'
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header1}`}
                          // ellipsis={true}
                        >
                          {getGiftDetailsById?.reasonForReject}
                        </Text>
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </span>
            ) : (
              ''
            )} */}
            <Divider />
            <div style={{ marginTop: '20px' }}>
              <Button
                className={`${styles.Goback_button}`}
                htmlType="submit"
                size="small"
                onClick={() => {
                  redirect === 'home_gag'
                    ? history.push('/view-past-gift')
                    : redirect === 'dashboard_approved'
                    ? history.push('/approved')
                    : redirect === 'dashboard_rejected'
                    ? history.push('/rejected')
                    : redirect === 'dashboard_return'
                    ? history.push('/returned')
                    : redirect === 'dashboard_pending'
                    ? history.push('/pending-approvals')
                    : (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_status === 'REJECTED') ||
                      (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_status === 'ACCEPTED') ||
                      (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_status === 'PROHIBITED') ||
                      (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_status === 'PENDING') ||
                      (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_status === '')
                    ? history.push('/adminDashboard-giftGiven-link')
                    : (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_ReceivedGifts_status === 'RETURNED') ||
                      (user_role?.roles[0] === 'sys-admin' &&
                        dashboardCard_ReceivedGifts_status === '')
                    ? history.push('/adminDashboard-giftReceived-link')
                    : '';
                }}
              >
                Go back
              </Button>
            </div>
          </Form>
        </Card>
        {exceptionalApprovalModal ? (
          <ExceptionalApproval_Modal
            exceptionalApprovalModal={exceptionalApprovalModal}
            setExceptionalApprovalModal={setExceptionalApprovalModal}
            getGiftDetailsById={getGiftDetailsById}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
        {rejectModal ? (
          <RejectGift_Modal
            rejectModal={rejectModal}
            setRejectModal={setRejectModal}
            getGiftDetailsById={getGiftDetailsById}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
        {/*  {returnToROModal ? (
          <ReturnRO_Modal
            returnToROModal={returnToROModal}
            setReturnToROModal={setReturnToROModal}
            getGiftDetailsById={getGiftDetailsById}
            HandleReturnGift={HandleReturnGift}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )} */}
      </div>
    </Spin>
  );
}
export default ViewGiftDetailsPage;

import React from 'react';
import { Modal, Button } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Input, Space } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { rejectRequests } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';

const { TextArea } = Input;

const RejectGift_Modal = ({
  rejectModal,
  setRejectModal
  //getGiftDetailsById,
  // rejectGiftLoading
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { rejectGiftLoading } = useSelector((state) => state.view);

  const onFinish = (values) => {
    const payload = {
      grId: params.id,
      reasonForExcemption: values?.reason_for_excemption
    };

    {
      dispatch(rejectRequests(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);
          history.push('view-past-gift');
          setRejectModal(false);
        }
      });
    }
  };

  return (
    <>
      <Modal
        title={<span>Please confirm</span>}
        visible={rejectModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setRejectModal(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            label=" Are you sure you want to reject this Gift? This action cannot be
            undone."
            name="reason_for_excemption"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
            className={`${styles.select_styles}`}
          >
            <TextArea allowClear placeholder="Enter reason" />
          </FormItem>

          <FormItem>
            <Space
            /*  style={{
                display: 'flex',
                justifyContent: 'center',
                justifyItems: ' center'
              }} */
            >
              <Button
                loading={rejectGiftLoading}
                className={`${styles.Goback_button_reject}`}
                htmlType="submit"
                size="small"
              >
                Reject
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setRejectModal(false)}
              >
                No
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default RejectGift_Modal;

import styles from './index.module.less';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
//import { sizeChecker } from 'utilities/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'hide'
    },
    title: {
      display: true,
      text: 'Top 5 types of frequently given gifts' //'Top 5 BU by Gifts given'
    }
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 10,
          // fontStyle: 'bold',
          // fontWaight:'SFProText Regular',
          // style: 'italic',
          weight: '600'
        }
      }
    }
  }
};
/* export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    //  datalabels: {
    //   display: true,
    // },
    legend: {
      display: false
    },
    legend: {
      position: 'hide'
    },
    title: {
      display: true,
      text: 'Top 5 Project by invoices'
    }
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 12
        }
      }
      // title: {
      //   display: false,
      //  // text: 'titleX',
      //   font: {
      //     size: 25
      //   }
      // }
    },
    y: {
      ticks: {
        font: {
          size: 10
        }
      }
      // title: {
      //   display: false,
      //   //text: 'titleY',
      //   font: {
      //     size: 20
      //   }
      // }
    }
  }
}; */

export default function Top5_gagChart({ top_five_gag_chart }) {
  //const isMobile = sizeChecker('xs');
  const arrayForSort = [...top_five_gag_chart];
  // const labels = arrayForSort?.map((item) => {
  //   return <div>{item?.tds_project_name}</div>;
  // });

  const data = {
    labels: arrayForSort?.map((item) => {
      return item?.name;
    }),

    datasets: [
      {
        label: '',
        data: arrayForSort?.map((item) => item?.gagCount),
        backgroundColor: '#2264ae', //'#7673FF',
        borderColor: '#2264ae',
        barThickness: 25
      }
    ],
    options: {
      legend: {
        display: false //This will do the task
      }
    }
  };
  return (
    <div>
      <Bar
        className={`${styles.bar_container}`}
        options={options}
        data={data}
        // width={!isMobile ? 500 : 300}
        height={370}
      />
    </div>
  );
}

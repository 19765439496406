import React, { useState, useCallback } from 'react';
import styles from './index.module.less';
import {
  Radio,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  Tag,
  Popconfirm,
  Space
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { history } from 'app/history';
import LocalStorage from 'utilities/localStorage';
import AllExportReports from '../reports/AllExportReports';
//import moment from 'moment';
//import { Value_of_gift } from '../../../../utilities/helpers';

const filters = [
  /*  {
    name: 'Gift Received',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  } */
];

function BuMasterFlowsPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  bu_MasterList,
  pagination,
  get_BU_MasterLoading,
  setSearchRequest,
  showDisablePopup,
  setShowDisablePopup,
  handleDeleteRequest
}) {
  const bu_MasterList1 = bu_MasterList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    buName: item?.buName,
    buCode: item?.buCode,
    status: item?.isActive,
    buHeadId: item?.BuHead?.id,
    buHead: `${
      item?.BuHead?.givenName === undefined ? 'NA' : item?.BuHead?.givenName
    }${' '}${'('}${
      item?.BuHead?.email === undefined ? 'NA' : item?.BuHead?.email
    }${')'}`,
    sbgHead: `${
      item?.SbgHead?.givenName === undefined ? 'NA' : item?.SbgHead?.givenName
    }${' '}${'('}${
      item?.SbgHead?.email === undefined ? 'NA' : item?.SbgHead?.email
    }${')'}`,
    SbgHeadId: item?.SbgHeadId,
    sbgName: item?.sbgName
  }));
  console.log('bu_MasterList1', bu_MasterList1);
  const handleBU_record = (record) => {
    //console.log('@@@@@@@@@@', record);
    history.push(`edit-bu-master-flow/${record.id}`);
    LocalStorage.setItem('bu_record', JSON.stringify(record));
  };
  const [isAllExortModalOpen, setAllExortModalOpen] = useState(false);

  const columns = [
    {
      title: 'BU/Function Name',
      dataIndex: 'buName',
      key: 'buName',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.buName?.localeCompare(b?.buName),
      render: (buName) => {
        return (
          <>
            <Tooltip
              title={buName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <a href={`view-gift-received-details/${record.id}`}> */}
              <span className={styles.text_transform}>
                {buName === null ? '' : buName}
              </span>
              {/*  </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'BU/Function Head',
      dataIndex: 'buHead',
      key: 'buHead',
      // width: 400,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.buHead?.localeCompare(b?.buHead),
      render: (buHead) => {
        return (
          <>
            <Tooltip
              title={buHead}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {buHead === null ? '' : buHead}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'SBG Head',
      dataIndex: 'sbgHead',
      key: 'sbgHead',
      // width: 400,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.sbgHead?.localeCompare(b?.sbgHead),
      render: (sbgHead) => {
        return (
          <>
            <Tooltip
              title={sbgHead}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {sbgHead === null ? '' : sbgHead}
            </Tooltip>
          </>
        );
      }
    },
    /*  {
      title: 'Delegated On',
      dataIndex: 'delegated_date',
      key: 'delegated_date',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`delegated_date`]).unix() - moment(b[`delegated_date`]).unix(),
      render: (delegated_date) => (
        <>
          <Tooltip
            title={
              delegated_date === null
                ? ''
                : moment(delegated_date).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {delegated_date === null
              ? ''
              : moment(delegated_date).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    }, */
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      ellipsis: true,
      //sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === true
              ? 'green'
              : record?.status === false
              ? 'red'
              : 'transparent'
          }
        >
          {record?.status === true ? 'ACTIVE' : 'DEACTIVATED'}
        </Tag>
      )
    },
    {
      title: 'Action',
      key: '',
      width: 180,
      render: (record) => (
        <>
          <Space size="middle">
            <>
              <Popconfirm
                placement="top"
                title="Are you sure you want to delete this BU?"
                open={visibility(record)}
                onConfirm={() => {
                  handleDeleteRequest({
                    record: record
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Delete"
                cancelText="No"
                okButtonProps={{ type: 'danger' }}
              >
                <a
                  className={`${styles.allocation_btn}`}
                  style={{
                    fontSize: '12px'
                  }}
                  type="link"
                  disabled={record?.status === false}
                >
                  <DeleteOutlined className={`${styles.delete_bu}`} />
                </a>
              </Popconfirm>
            </>
          </Space>
          <EditOutlined
            className={`${styles.edit_bu}`}
            onClick={() => handleBU_record(record)}
            /*  onClick={() => history.push(`edit-bu-master-flow/${record.id}`)} */
          />
        </>
      )
    }
  ];

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no BU to display
        </div>
      </span>
    )
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title}`}>Configure BU Master</div>
            <div className={`${styles.myGift_text}`}>
              View and manage BU Master
            </div>
          </div>
          <div className={`${styles.declareGift_div}`}>
            <Row>
              <Button
                className={`${styles.export_button}`}
                onClick={() => setAllExortModalOpen(true)}
              >
                Export
              </Button>
              <Button
                className={`${styles.declareGift_button}`}
                icon={<PlusOutlined />}
                onClick={() => history.push('/create-bu-master-flow')}
              >
                Add New BU
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                className={`${styles.declareGift_button}`}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push('/admin')}
              >
                Back
              </Button>
            </Row>
          </div>
        </Row>
        <div className={`${styles.tabs_container}`}>
          <Row>
            {/* className={`${styles.row_container}`} */}
            <Col span={16}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      //className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={8}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by BU name'
                }}
              />
            </Col>
          </Row>
        </div>
        <Card className={`${styles.cardStyles}`}>
          <div className={`${styles.table_container}`}>
            <CustomTable
              showSorterTooltip={false}
              columns={columns}
              dataSource={bu_MasterList1}
              locale={locale}
              loading={get_BU_MasterLoading}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
        </Card>
        {isAllExortModalOpen ? (
          <AllExportReports
            isAllExortModalOpen={isAllExortModalOpen}
            setAllExortModalOpen={setAllExortModalOpen}
            apiName={'bu_and_sbgs_master'}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
export default BuMasterFlowsPage;

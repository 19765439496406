import styles from './index.module.less';
import {
  Radio,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  //Badge,
  Tag
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import {
  // Value_of_gift,
  numIntStyleFormat
  //numFormating
} from '../../../../utilities/helpers';
import LocalStorage from 'utilities/localStorage';
import Switcher from '../../../../common/Switcher';
import { useDispatch } from 'react-redux';
import { giftTypes } from '../../../welcome/redux/slice';
import { sizeChecker } from 'utilities/helpers';

const filters = [
  {
    name: 'My Gifts',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  }
];

function ViewPastGiftPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  pastGiftsList,
  pagination,
  pastGiftsListLoading,
  CountsPastGiftsList,
  setSearchRequest,
  onChange
}) {
  const isMobile = sizeChecker('xs');

  const dispatch = useDispatch();

  const pastGiftsList1 = pastGiftsList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.recipientName,
    ticket: item?.ticket,
    GiftType: item?.giftName, //    GiftMaster?.title,
    valueOfGift: item?.valueOfGift,
    dateOfGiftReceived: item?.dateOfGiftReceived,
    status: item?.status,
    message: item?.giftDescription,
    organizationName: item?.organizationName,
    designation: item?.designation,
    giftReceiverSector: item?.giftReceiverSector,
    type: item?.type,
    recipientType: item?.recipientType,
    contextOrBusinessPurposeOfTheGift: item?.contextOrBusinessPurposeOfTheGift,
    bulkUploadGroupNumber: item?.bulkUploadGroupNumber
  }));
  const handleReDirect = (record) => {
    console.log('record', record);
    //setRecordData(record);
    history.push(`view-gift-details/${record.id}`);

    dispatch(giftTypes(record.type));
    LocalStorage.setItem('giftType', JSON.stringify(record?.type));
    LocalStorage.setItem('redirect', JSON.stringify('home_gag'));
  };
  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <Tooltip
                title={'bulk upload'}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {' '}
                {record?.bulkUploadGroupNumber === null ||
                record?.bulkUploadGroupNumber === '' ? (
                  ''
                ) : (
                  <CopyOutlined style={{ color: '#108ee9' }} />
                )}{' '}
              </Tooltip>
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {ticket === null ? '' : ticket}
              </a>
              {/*  <a href={`view-gift-details/${record.id}?${record.type}`}>
                {ticket === null ? '' : ticket}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Recipient',
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 90,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.recipientName?.localeCompare(b?.recipientName),
      render: (recipientName, record) => {
        return (
          <>
            <Tooltip
              title={recipientName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {recipientName === null ? '' : recipientName}
              </a>
              {/*  <a href={`view-gift-details/${record.id}?${record.type}`}>
                {recipientName === null ? '' : recipientName}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient Organization'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Organization
        </Tooltip>
      ),
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a?.organizationName?.localeCompare(b?.organizationName),
      ellipsis: true,
      render: (organizationName) => {
        return (
          <>
            <Tooltip
              title={organizationName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {organizationName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient Designation'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Designation
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'designation',
      key: 'designation',
      sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
      ellipsis: true,
      render: (designation) => {
        return (
          <>
            <Tooltip
              title={designation}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {designation}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient Stakeholder Type'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Stakeholder Type
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'recipientType',
      key: 'recipientType',
      sorter: (a, b) => a?.recipientType?.localeCompare(b?.recipientType),
      ellipsis: true,
      width: 90,
      render: (recipientType) => {
        return (
          <>
            <Tooltip
              title={recipientType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {recipientType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Government Official?'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Government Official?
        </Tooltip>
      ),
      dataIndex: 'giftReceiverSector',
      key: 'giftReceiverSector',
      sorter: (a, b) =>
        a?.giftReceiverSector?.localeCompare(b?.giftReceiverSector),
      ellipsis: true,
      width: 70,
      render: (giftReceiverSector) => {
        return (
          <>
            <Tooltip
              title={giftReceiverSector}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giftReceiverSector === 'government' ? 'Yes' : 'No'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Context/Business Purpose of the Gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Context/Business Purpose of the Gift
        </Tooltip>
      ),
      dataIndex: 'contextOrBusinessPurposeOfTheGift',
      key: 'contextOrBusinessPurposeOfTheGift',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) =>
        a?.contextOrBusinessPurposeOfTheGift?.localeCompare(
          b?.contextOrBusinessPurposeOfTheGift
        ),
      render: (contextOrBusinessPurposeOfTheGift) => {
        return (
          <>
            <Tooltip
              title={
                contextOrBusinessPurposeOfTheGift === ''
                  ? 'NA'
                  : contextOrBusinessPurposeOfTheGift
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {contextOrBusinessPurposeOfTheGift === ''
                ? 'NA'
                : contextOrBusinessPurposeOfTheGift}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'GiftType',
      key: 'GiftType',
      sorter: (a, b) => a?.GiftType?.localeCompare(b?.GiftType),
      ellipsis: true,
      render: (GiftType) => {
        return (
          <>
            <Tooltip
              title={GiftType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {GiftType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value',
      dataIndex: 'valueOfGift',
      key: 'valueOfGift',
      ellipsis: {
        showTitle: false
      },
      width: 80,
      sorter: (a, b) => a?.valueOfGift - b?.valueOfGift, //(a, b) => a?.valueOfGift?.localeCompare(b?.valueOfGift),
      render: (valueOfGift) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(valueOfGift)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {numIntStyleFormat(valueOfGift)}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Date of gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Date of gift
        </Tooltip>
      ),
      dataIndex: 'dateOfGiftReceived',
      key: 'dateOfGiftReceived',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`dateOfGiftReceived`]).unix() -
        moment(b[`dateOfGiftReceived`]).unix(),
      render: (dateOfGiftReceived) => (
        <>
          <Tooltip
            title={
              dateOfGiftReceived === null
                ? ''
                : moment(dateOfGiftReceived).format('DD MMM YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {dateOfGiftReceived === null
              ? ''
              : moment(dateOfGiftReceived).format('DD MMM YY')}
          </Tooltip>
        </>
      )
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === null
              ? 'transparent'
              : record?.status === 'rejected'
              ? 'red'
              : record?.status === 'pending'
              ? 'blue'
              : record?.status === 'accepted'
              ? 'green'
              : record?.status === 'returned'
              ? 'magenta'
              : 'cyan'
          }
        >
          {status === null ? '' : status}
        </Tag>
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <div className={`${styles.container}`}>
      <Row className={`${styles.row_container}`}>
        <div className={`${styles.gift_container}`}>
          <div
            className={
              isMobile
                ? `${styles.myGift_title_isMobile}`
                : `${styles.myGift_title}`
            }
          >
            Gifts given or to be given
          </div>
          <div
            className={
              isMobile
                ? `${styles.myGift_text_isMobile}`
                : `${styles.myGift_text}`
            }
          >
            View the status of requests made or make a new request
          </div>
        </div>
        <div className={`${styles.declareGift_div}`}>
          <Switcher
            left={'My requests'}
            right={'Delegated requests'}
            onChange={onChange}
          />
        </div>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end'
        }}
        className={`${styles.declareGift_button_isMobile}`}
      >
        <div>
          <Button
            className={`${styles.declareGift_button}`}
            icon={<PlusOutlined />}
            onClick={() => history.push('declare-a-gift')}
          >
            Declare a Gift
          </Button>
        </div>
      </Row>
      <br />
      <div
        className={
          isMobile
            ? `${styles.tabs_container_isMobile}`
            : `${styles.tabs_container}`
        }
      >
        <Row className={`${styles.row_display_isMobile}`}>
          <Col span={16} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={
                isMobile
                  ? `${styles.button_import_style_isMobile}`
                  : `${styles.button_import_style}`
              }
            >
              {filters.map((item, index) => {
                return (
                  <Radio.Button
                    className={`${styles.radio_button}`}
                    value={item.value}
                    key={index}
                    onChange={() => {
                      window.localStorage.setItem(
                        'set_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name === 'My Gifts' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.all})
                        {/*  <div>
                          {' '}
                          <Badge
                            count={CountsPastGiftsList?.all}
                            style={{
                              backgroundColor: '#2264ae'
                            }}
                          />
                        </div> */}
                      </span>
                    ) : item.name === 'Pending' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.waitingForApproval})
                      </span>
                    ) : item.name === 'Accepted' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.accepted})
                      </span>
                    ) : item.name === 'Rejected' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.rejected})
                      </span>
                    ) : (
                      item.name
                    )}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Col>
          <Col span={8} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <SearchBar
              onChange={(e) => {
                setSearchRequest(e.target.value);
                setTriggerFilter(true);
              }}
              className={
                isMobile
                  ? `${styles.searchbar_isMobile}`
                  : `${styles.searchbar}`
              }
              inputProps={{
                placeholder: 'Search by recipient or request number#'
              }}
            />
          </Col>
        </Row>
      </div>
      <Card className={`${styles.cardStyles}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            showSorterTooltip={false}
            columns={columns}
            dataSource={pastGiftsList1}
            locale={locale}
            loading={pastGiftsListLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
      </Card>
    </div>
  );
}
export default ViewPastGiftPage;

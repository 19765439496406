import React from 'react';
import { Modal, Button } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Input, Space } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { rejectRequests } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';
import LocalStorage from 'utilities/localStorage';
import { getLoggedInUser } from 'utilities/helpers';

const { TextArea } = Input;

const RejectGift_Modal = ({
  rejectModal,
  setRejectModal,
  getGiftDetailsById,
  Id,
  type,
  hashRoot,
  uniqueId,
  userId,
  redirect_action
  // rejectGiftLoading
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { rejectGiftLoading } = useSelector((state) => state.view);

  const user_role1 = getLoggedInUser();
  const userDetails = LocalStorage.getItem('GiftReg_user');

  const onFinish = (values) => {
    if (userDetails?.user === undefined || user_role1?.user === null) {
      //one-click-reject by email
      const payload = {
        grId: Id,
        giftType: type,
        hashRoot: hashRoot,
        uniqueId: uniqueId,
        userId: userId,
        approverComments: values?.reason_for_excemption
      };

      dispatch(rejectRequests(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);

          setRejectModal(false);
          history.push('/one-click-reject');
        }
      });
    } else {
      const payload = {
        grId: params.id,
        approverComments: values?.reason_for_excemption,
        giftType: getGiftDetailsById?.type,
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };

      {
        dispatch(rejectRequests(payload)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            // history.push('/view-gift-requests');
            setRejectModal(false);
            redirect_action === 'home_action_gag'
              ? history.push('/view-gift-requests')
              : redirect_action === 'dashboard_action'
              ? history.push('/action-required')
              : history.push('/view-gift-requests');
          }
        });
      }
    }
  };

  return (
    <>
      <Modal
        title={<span>Please confirm</span>}
        visible={rejectModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setRejectModal(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <div className={`${styles.return_title_RO}`}>
            Are you sure you want to reject the gift request and ask the user to
            return the gift?
          </div>
          <div className={`${styles.return_subtitle_RO}`}>
            (This action cannot be undone)
          </div>
          <FormItem
            style={{
              marginTop: '-15px'
            }}
            label=""
            name="reason_for_excemption"
            rules={[
              {
                required: true,
                message: 'This is a required field'
              }
            ]}
            className={`${styles.textarea_styles}`}
          >
            <TextArea allowClear placeholder="Enter reason" />
          </FormItem>

          <FormItem className={`${styles.formItem_reject}`}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                justifyItems: ' center'
              }}
            >
              <Button
                loading={rejectGiftLoading}
                className={`${styles.Goback_button_reject}`}
                htmlType="submit"
                size="small"
              >
                Reject
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setRejectModal(false)}
              >
                Cancel
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default RejectGift_Modal;

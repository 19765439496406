import React from 'react';
import { Modal, Button } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space, Input } from 'antd'; // Input,
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { exceptionRequests } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';

const { TextArea } = Input;

const ExceptionalApproval_Modal = ({
  exceptionalApprovalModal,
  setExceptionalApprovalModal
  //getGiftDetailsById,
  // exceptionRequestsLoading
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { exceptionRequestsLoading } = useSelector((state) => state.view);

  const onFinish = (values) => {
    const payload = {
      grId: params.id,
      reasonForExcemption: values?.reason_for_excemption
    };

    {
      dispatch(exceptionRequests(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);

          setExceptionalApprovalModal(false);
          history.push('/view-past-gift');
        }
      });
    }
  };

  return (
    <>
      <Modal
        title={<span>Please confirm{/* Exception approval */}</span>}
        visible={exceptionalApprovalModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setExceptionalApprovalModal(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            label=" Are you sure you want to accept this Gift? This action cannot be
            undone." //"Enter reasons for making an exception approval"
            name="reason_for_excemption"
            rules={[
              {
                required: false,
                message: 'Required field'
              }
            ]}
            className={`${styles.select_styles}`}
          >
            <TextArea allowClear placeholder="Enter reason" />
          </FormItem>

          <FormItem>
            <Space
            /*  style={{
                display: 'flex',
                justifyContent: 'center',
                justifyItems: ' center'
              }} */
            >
              <Button
                loading={exceptionRequestsLoading}
                className={`${styles.ExceptionalApproval_button}`}
                htmlType="submit"
                size="small"
              >
                Accept
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setExceptionalApprovalModal(false)}
              >
                No
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default ExceptionalApproval_Modal;

import DeclareGiftFormPage from './components/declareGiftFormPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';

function DeclareGiftForm() {
  TabTitle('Gift Register - Declare Gift Form');
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <DeclareGiftFormPage />
      </div>
    </CustomLayout>
  );
}
export default DeclareGiftForm;

import React, { useEffect } from 'react';
import ViewGiftDetailsPage from './components/ViewGiftDetailsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';
import LocalStorage from 'utilities/localStorage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGiftById } from './redux/slice';

function ViewGiftDetails() {
  TabTitle('Gift Register - View Gift Details');
  const dispatch = useDispatch();
  const { getGiftByIdLoading, getGiftDetailsById } = useSelector(
    (state) => state.view
  );

  const giftType = LocalStorage.getItem('giftType');
  console.log('GiftTyp%%%%%%%%%', giftType);

  const giftType1 = useSelector((state) => state.dashboard.giftType);
  console.log('GiftType#########', giftType1);

  const params = useParams();
  const url = window.location.search;
  const urlType = url.replace('?', '');
  console.log('urType', urlType);

  useEffect(() => {
    handleGetGiftById();
  }, []);

  const handleGetGiftById = () => {
    const payload = { id: params.id, giftType: giftType };
    dispatch(getGiftById(payload));
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftDetailsPage
          getGiftDetailsById={getGiftDetailsById}
          getGiftByIdLoading={getGiftByIdLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewGiftDetails;

import apiClient from 'utilities/apiClient';

export const returnGift_list = (payload) => {
  return apiClient.get(`${apiClient.Urls.returnGift_list}`, payload, true);
};

export const roUsers_list = (payload) => {
  return apiClient.get(`${apiClient.Urls.roUsers_list}`, payload, true);
};
export const returnGift_form = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.returnGift_form}/${url}`,
    payload,
    true,
    'file'
  );
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.requester}/${url}`, payload, true);
};

import React, { useState, useEffect, useMemo } from 'react';
import CustomLayoutBU from '@/common/LayoutBU';
import { TabTitle, getLoggedInUser } from 'utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Select, Form, Space, Row, Spin } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  //   get_all_users,
  //   map_approval_delegator,
  get_BU_Master,
  set_BU_mapping
} from '../admin/redux/slice';
import { debounce } from 'lodash';
import FormData from 'form-data';

//const { TextArea } = Input;
const { Option } = Select;

function AssignBU() {
  TabTitle('Gift Register - Assign BU');

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const userDetails = getLoggedInUser();
  //console.log('123344444', userDetails?.user?.id);

  const [formValues, setFormValues] = useState({});
  //   const [allUsersSearch, setAllUsersSearch] = useState('');
  const [BUSearch, setBUSearch] = useState('');

  const {
    // get_all_usersLoading,
    // all_users_List,
    map_approval_delegatorLoading,
    bu_MasterList,
    get_BU_MasterLoading
  } = useSelector((state) => state.admin);
  //   console.log('all_users_List', all_users_List);
  //   console.log('bu_MasterList', bu_MasterList);

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 1000
    };
    if (BUSearch) payload['search'] = BUSearch;
    dispatch(get_BU_Master(payload));
  }, [BUSearch]);

  //   useEffect(() => {
  //     const payload = {};
  //     if (allUsersSearch) payload['search'] = allUsersSearch;
  //     dispatch(get_all_users(payload));
  //   }, [allUsersSearch]);

  const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (BUSearch) => {
      setBUSearch(BUSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    let formdata = new FormData();

    formdata.append('userId', userDetails?.user?.id);
    formdata.append('buId', formValues?.buId);

    dispatch(set_BU_mapping(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/welcome');
      }
    });
  };

  return (
    <CustomLayoutBU>
      <Spin spinning={get_BU_MasterLoading}>
        <div style={{ marginTop: '50px' }}>
          <Row className={`${styles.row_container_setting}`}>
            <div className={`${styles.gift_container}`}>
              <div className={`${styles.myGift_title_setting}`}>
                Configure BU
              </div>
            </div>
          </Row>{' '}
          <div className={`${styles.container}`}>
            <div className={`${styles.cardContainer}`}>
              <Card className={`${styles.cardStyles_Container}`}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className={`${styles.formstyles}`}
                >
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the BU
                          </div>
                        }
                        name="buId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select BU"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('buId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {bu_MasterList?.map((item, index) => (
                            <Option
                              title={item?.buName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.buName}
                                  {/*  (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  ) */}
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  {/*  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the delegate
                          </div>
                        }
                        name="delegateId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select delegate"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('delegateId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card> */}
                  <div style={{ marginTop: '20px' }}>
                    <FormItem>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: ' center'
                        }}
                      >
                        <Button
                          loading={map_approval_delegatorLoading}
                          className={`${styles.ExceptionalApproval_button}`}
                          htmlType="submit"
                          size="small"
                        >
                          Save
                        </Button>

                        {/* <Button
                          className={`${styles.cancel_rejectModal_button}`}
                          size="small"
                          onClick={() =>
                            history.push('/review-entry-delegator-flows')
                          }
                        >
                          Cancel
                        </Button> */}
                      </Space>
                    </FormItem>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Spin>
    </CustomLayoutBU>
  );
}
export default AssignBU;

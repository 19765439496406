import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { roGift_getbyId, ro_gift_accept, received_gift_reject } from './api';

const initialState = {
  getRoGiftByIdLoading: false,
  roGiftAcceptLoading: false,
  receivedGiftRejectROLoading: false,
  roGiftDetailsById: []
};

const actions = {
  GET_RO_GIFT_BY_ID: 'getROGiftById/GET_RO_GIFT_BY_ID',
  RO_GIFT_ACCEPT: 'roGiftAccept/RO_GIFT_ACCEPT',
  RECEIVED_GIFT_REJECT: 'receivedGiftRejectRO/RECEIVED_GIFT_REJECT'
};

export const getRoGiftById = createAsyncThunk(
  actions.GET_RO_GIFT_BY_ID,
  async (payload) => {
    const response = await roGift_getbyId(`${payload?.id}?${payload?.urlType}`); //payload
    return response;
  }
);

export const roGiftAccept = createAsyncThunk(
  actions.RO_GIFT_ACCEPT,
  async (payload) => {
    const response = await ro_gift_accept(`${payload?.grId}`, payload);
    return response;
  }
);

export const receivedGiftRejectRO = createAsyncThunk(
  actions.RECEIVED_GIFT_REJECT,
  async (payload) => {
    const response = await received_gift_reject(`${payload?.grId}`, payload);
    return response;
  }
);

export const roViewSlice = createSlice({
  name: 'roView',
  initialState,
  reducers: {
    vehicleFormData: (state, action) => {
      state.vehicleData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoGiftById.pending, (state) => {
        state.getRoGiftByIdLoading = true;
      })
      .addCase(getRoGiftById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getRoGiftByIdLoading = false;
        state.roGiftDetailsById = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getRoGiftById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getRoGiftByIdLoading = false;
        message.error(msg);
      });
    builder
      .addCase(roGiftAccept.pending, (state) => {
        state.roGiftAcceptLoading = true;
      })
      .addCase(roGiftAccept.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.roGiftAcceptLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(roGiftAccept.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.roGiftAcceptLoading = false;

        message.error(msg);
      });
    builder
      .addCase(receivedGiftRejectRO.pending, (state) => {
        state.receivedGiftRejectROLoading = true;
      })
      .addCase(receivedGiftRejectRO.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.receivedGiftRejectROLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(receivedGiftRejectRO.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.receivedGiftRejectROLoading = false;

        message.error(msg);
      });
  }
});
export const { vehicleFormData } = roViewSlice.actions;
export default roViewSlice.reducer;

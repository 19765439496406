import LocalStorage from './localStorage';
import moment from 'moment';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('GiftReg_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const Value_of_gift = (value) => {
  switch (value) {
    case 'over_5000':
      return 'Over 5000';
    case 'between_1000_and_5000':
      return 'Between 1000 and 5000';
    case 'under_1000':
      return 'Under 1000';
    default:
      return '';
  }
};

export const Recipient_Type = (value) => {
  switch (value) {
    case 'government':
      return 'Yes'; //'Government Official';
    case 'non-government':
      return 'No'; //'Non-Government Official';
    default:
      return '';
  }
};

const Financial_Year_Date = (current_monthYear) => {
  switch (current_monthYear) {
    case '01':
      return moment().subtract(11, 'months').format('MMM YYYY');
    case '02':
      return moment().subtract(10, 'months').format('MMM YYYY');
    case '03':
      return moment().subtract(9, 'months').format('MMM YYYY');
    case '04':
      return moment().subtract(8, 'months').format('MMM YYYY');
    case '05':
      return moment().subtract(7, 'months').format('MMM YYYY');
    case '06':
      return moment().subtract(6, 'months').format('MMM YYYY');
    case '07':
      return moment().subtract(5, 'months').format('MMM YYYY');
    case '08':
      return moment().subtract(4, 'months').format('MMM YYYY');
    case '09':
      return moment().subtract(2, 'months').format('MMM YYYY');
    case '10':
      return moment().subtract(1, 'months').format('MMM YYYY');
    case '11':
      return moment().subtract(12, 'months').format('MMM YYYY');
    default:
      return moment().subtract(0, 'months').format('MMM YYYY');
  }
};
export const dateRangeDashboard = [
  {
    label: 'Financial Year To Date',
    value: `01 ${Financial_Year_Date(moment().format('MM'))}`
  },
  {
    label: 'Quarter To Date',
    value: `01 ${moment().subtract(3, 'months').format('MMM YYYY')}`
  },
  {
    label: 'Month To Date',
    value: `01 ${moment().subtract(1, 'months').format('MMM YYYY')}`
  }
  /* { label: 'Month To Date', value: `01 ${moment().format('MMM YYYY')}` } */
];

export function numFormating(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  } else if (val >= 1000) {
    val = Math.abs(val)?.toLocaleString(undefined, {
      maximumFractionDigits: 2
    });
  }
  return val;
}
// console.log('Math.abs(value)', Math.abs(34.5555 + 34.444444));
// console.log('parseFloat(value1)', Math.abs(parseFloat('34.5555')));

export function numIntStyleFormat(value1) {
  let value = parseFloat(value1);
  value =
    value === undefined
      ? ''
      : value >= 0
      ? value === undefined
        ? ''
        : ` ₹${value?.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
      : value === undefined
      ? ''
      : `-₹${Math.abs(value)?.toLocaleString(undefined, {
          maximumFractionDigits: 2
        })}`;
  return value;
}
export const giftType = [
  { label: 'Prohibited', value: 'prohibited' },
  { label: 'Non-Prohibited', value: 'non-prohibited' }
];
export const recipientType = [
  { label: 'Supplier', value: 'supplier' },
  { label: 'Customer', value: 'customer' },
  { label: 'Other', value: 'other' }
];

export const pattern =
  /\b(cash|gold|money|amount|currency|legal|tender|hard|notes|bank|coins|change|silver|diamond|platinum|palladium|voucher|bank|draft|bill|bond|cheque|coupon|debenture|credit|ruby|gift|discount|coupons|gems|jewellery|stones|promise|vouchers)\b/;

export const statusesData = [
  { label: 'All', is_active: '' },
  { label: 'Active', is_active: true },
  { label: 'Disabled', is_active: false }
];

export const internalPriorities = [
  { label: 'All', internal_priority: '' },
  { label: 'Very High', internal_priority: 'Very High' },
  { label: 'High', internal_priority: 'High' },
  { label: 'Medium', internal_priority: 'Medium' },
  { label: 'Low', internal_priority: 'Low' },
  { label: 'Very Low', internal_priority: 'Very Low' }
];

export function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

export const sizeChecker = (size) => {
  const screens = useBreakpoint();

  return screens[size];
};

import React, { useState, useEffect } from 'react';
import styles from './index.module.less';
import { Button, Row, Card, Empty, Tooltip, Tag } from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import { getLoggedInUser, numIntStyleFormat } from 'utilities/helpers';
import LocalStorage from 'utilities/localStorage';
import { getReceivedGiftsList } from '../../../../view_gift_received/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
//import FormItem from 'common/FormItem';

function GiftReceivedLink() {
  const [searchRequest, setSearchRequest] = useState('');

  // const [form] = Form.useForm();

  const dispatch = useDispatch();

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  //console.log('user_role1', user_role);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { receivedGiftsList, pagination, receivedGiftsLoading } = useSelector(
    (state) => state.receivedGifts
  );

  const countsDates = LocalStorage.getItem('countsDates');
  const dashboardCard_ReceivedGifts_status = LocalStorage.getItem(
    'dashboardCard_ReceivedGifts_status'
  );
  console.log(
    'dashboardCard_ReceivedGifts_status%%%%%%%',
    dashboardCard_ReceivedGifts_status
  );
  useEffect(() => {
    /* if (page === 1) { */
    handleGetPastGiftsList();
    /*  } else {
      setPage(1);
    } */
  }, [searchRequest, page, pageSize]); //page,

  const handleGetPastGiftsList = () => {
    let payload = {
      page,
      per_page: pageSize,
      status:
        dashboardCard_ReceivedGifts_status === null
          ? ''
          : dashboardCard_ReceivedGifts_status,
      search: searchRequest,
      delegation_type: 'self',
      fromDate: countsDates?.fromDate,
      toDate: countsDates?.toDate
    };
    dispatch(getReceivedGiftsList(payload));
  };

  const pastGiftsList1 = receivedGiftsList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.giverName,
    ticket: item?.ticket,
    GiftType: item?.giftName, //    GiftMaster?.title,
    valueOfGift: item?.valueOfGift,
    dateOfGiftReceived: item?.dateOfGiftReceived,
    status: item?.status,
    message: item?.giftDescription,
    organizationName: item?.organizationName,
    designation: item?.designation,
    giftReceiverSector: item?.giftGiverSector,
    isTheGiftReturned: item?.isTheGiftReturned,
    isTheGiftSelfReturned: item?.isTheGiftSelfReturned,
    type: item?.type,
    ReceiverId: item?.ReceiverId,
    returnedToRo: item?.returnedToRo,
    returnedToGiver: item?.returnedToGiver,
    returnedToCEC: item?.returnedToCEC,
    giverType: item?.giverType,
    contextOrBusinessPurposeOfTheGift: item?.contextOrBusinessPurposeOfTheGift
  }));
  const handleReDirect = (record) => {
    console.log('record', record);
    //setRecordData(record);
    history.push(`view-gift-received-details/${record.id}`);

    //dispatch(giftTypes(record.type));
    LocalStorage.setItem('giftTypeGR', JSON.stringify(record?.type));
    LocalStorage.setItem('redirect', JSON.stringify('home_gr'));
  };
  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {ticket === null ? '' : ticket}
              </a>
              {/* <a
                href={`view-gift-received-details/${record.id}?${record.type}`}
              >
                {ticket === null ? '' : ticket}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Giver',
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 70,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.recipientName?.localeCompare(b?.recipientName),
      render: (recipientName, record) => {
        return (
          <>
            <Tooltip
              title={recipientName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {recipientName === null ? '' : recipientName}
              </a>
              {/* <a
                href={`view-gift-received-details/${record.id}?${record.type}`}
              >
                {recipientName === null ? '' : recipientName}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Organization'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Organization
        </Tooltip>
      ),
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a?.organizationName?.localeCompare(b?.organizationName),
      ellipsis: true,
      render: (organizationName) => {
        return (
          <>
            <Tooltip
              title={organizationName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {organizationName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Designation'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Designation
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'designation',
      key: 'designation',
      sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
      ellipsis: true,
      render: (designation) => {
        return (
          <>
            <Tooltip
              title={designation}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {designation}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Stakeholder Type'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Stakeholder Type
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'giverType',
      key: 'giverType',
      sorter: (a, b) => a?.giverType?.localeCompare(b?.giverType),
      ellipsis: true,
      width: '80px',
      render: (giverType) => {
        return (
          <>
            <Tooltip
              title={giverType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giverType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Government Official?'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Government Official?
        </Tooltip>
      ),
      dataIndex: 'giftReceiverSector',
      key: 'giftReceiverSector',
      sorter: (a, b) =>
        a?.giftReceiverSector?.localeCompare(b?.giftReceiverSector),
      ellipsis: true,
      width: '70px',
      render: (giftReceiverSector) => {
        return (
          <>
            <Tooltip
              title={giftReceiverSector}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giftReceiverSector === 'government' ? 'Yes' : 'No'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Context/Business Purpose of the Gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Context/Business Purpose of the Gift
        </Tooltip>
      ),
      dataIndex: 'contextOrBusinessPurposeOfTheGift',
      key: 'contextOrBusinessPurposeOfTheGift',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) =>
        a?.contextOrBusinessPurposeOfTheGift?.localeCompare(
          b?.contextOrBusinessPurposeOfTheGift
        ),
      render: (contextOrBusinessPurposeOfTheGift) => {
        return (
          <>
            <Tooltip
              title={
                contextOrBusinessPurposeOfTheGift === ''
                  ? 'NA'
                  : contextOrBusinessPurposeOfTheGift
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {contextOrBusinessPurposeOfTheGift === ''
                ? 'NA'
                : contextOrBusinessPurposeOfTheGift}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'GiftType',
      key: 'GiftType',
      sorter: (a, b) => a?.GiftType?.localeCompare(b?.GiftType),
      ellipsis: true,
      render: (GiftType) => {
        return (
          <>
            <Tooltip
              title={GiftType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {GiftType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value',
      dataIndex: 'valueOfGift',
      key: 'valueOfGift',
      ellipsis: {
        showTitle: false
      },
      width: 80,
      sorter: (a, b) => a?.valueOfGift - b?.valueOfGift,
      render: (valueOfGift) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(valueOfGift)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {numIntStyleFormat(valueOfGift)}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Date of gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Date of gift
        </Tooltip>
      ),
      dataIndex: 'dateOfGiftReceived',
      key: 'dateOfGiftReceived',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`dateOfGiftReceived`]).unix() -
        moment(b[`dateOfGiftReceived`]).unix(),
      render: (dateOfGiftReceived) => (
        <>
          <Tooltip
            title={
              dateOfGiftReceived === null
                ? ''
                : moment(dateOfGiftReceived).format('DD MMM YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {dateOfGiftReceived === null
              ? ''
              : moment(dateOfGiftReceived).format('DD MMM YY')}
          </Tooltip>
        </>
      )
    },
    /*  {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.message?.localeCompare(b?.message),
      render: (message) => {
        return (
          <>
            <Tooltip
              title={message === '' ? 'NA' : message}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {message === '' ? 'NA' : message}
            </Tooltip>
          </>
        );
      }
    }, */

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => {
        // console.log('RECORDDDDDDDD', record);
        return (
          <Tag
            className={`${styles.status_style}`}
            color={
              record?.status === null
                ? 'transparent'
                : (record?.status === 'rejected' &&
                    record?.isTheGiftReturned === true) ||
                  (record?.status === 'rejected' &&
                    record?.isTheGiftSelfReturned === true)
                ? 'magenta'
                : record?.status === 'pending'
                ? 'blue'
                : record?.status === 'accepted'
                ? 'green'
                : record?.status === 'returned'
                ? 'magenta'
                : 'red'
            }
          >
            {(record?.status === 'rejected' &&
              record?.isTheGiftReturned === true) ||
            (record?.status === 'rejected' &&
              record?.isTheGiftSelfReturned === true) ? (
              'RETURNED'
            ) : record?.status === 'rejected' ? (
              record?.ReceiverId === user_role?.id &&
              record?.returnedToRo === false &&
              record?.returnedToGiver === false &&
              record?.returnedToCEC === false ? (
                <>
                  <a style={{ color: 'red' }}>TO BE RETURNED</a>
                  {/* <a
                href={`view-gift-received-details/${record.id}?${record.type}`}
                style={{ color: 'red' }}
              >
                TO BE RETURNED
              </a> */}
                </>
              ) : (
                'TO BE RETURNED'
              )
            ) : (
              status
            )}
          </Tag>
        );
      }
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <div className={`${styles.container}`}>
      <Row className={`${styles.row_container}`}>
        <div className={`${styles.gift_container}`}>
          <div className={`${styles.myGift_title}`}>
            {dashboardCard_ReceivedGifts_status === 'PENDING'
              ? 'Pending Gifts'
              : dashboardCard_ReceivedGifts_status === 'RETURNED'
              ? 'Returned Gifts'
              : 'Gifts Received'}
          </div>
          <div className={`${styles.myGift_text}`}>
            {' '}
            View the status of requests made.
          </div>
        </div>
        <Button
          className={`${styles.declareGift_button}`}
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push('/dashboard')}
        >
          Back
        </Button>
      </Row>

      <div className={`${styles.tabs_container}`}>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SearchBar
            onChange={(e) => {
              setSearchRequest(e.target.value);
            }}
            className={` ${styles.searchbar}`}
            inputProps={{
              placeholder: 'Search by giver or request number#'
            }}
          />
        </Row>
      </div>
      <Card className={`${styles.cardStyles}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            columns={columns}
            dataSource={pastGiftsList1}
            locale={locale}
            loading={receivedGiftsLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
          />
        </div>
      </Card>
    </div>
  );
}
export default GiftReceivedLink;

import { Button, Row, Col } from 'antd';
//import lady from '../../image/lady.svg';
import gift from '../../image/gift.svg';
import arrow from '../../image/arrow.svg';
import giftCard from '../../image/giftCard.svg';
import pricingCard from '../../image/pricingCard.svg';
import gift_box from '../../image/gift_box.svg';
import styles from './index.module.less';
import { history } from 'app/history';
import { sizeChecker } from 'utilities/helpers';

function DeclareGiftPage() {
  const isMobile = sizeChecker('xs');

  return (
    <div className={`${styles.container}`}>
      <Row className={`${styles.cards_background}`}>
        <div className={`${styles.giftCard_div}`}>
          <img className={`${styles.pricingCard_img}`} src={pricingCard} />
          <Button
            className={`${styles.giftCard_img_button}`}
            onClick={() => history.push('/declare-gift-received')}
          >
            I want to declare a gift I received
          </Button>
        </div>
        <div className={`${styles.giftCard_div}`}>
          <img className={`${styles.giftCard_img}`} src={giftCard} />
          <Button
            className={`${styles.giftCard_img_button}`}
            onClick={() => history.push('/request-gift-ytb')}
          >
            I want to give a gift
          </Button>
        </div>

        <div className={`${styles.giftCard_div}`}>
          <img className={`${styles.gift_box_img}`} src={gift_box} />

          <Button
            className={`${styles.giftCard_img_button}`}
            onClick={() => history.push('/declare-gift-form')}
          >
            I want to declare a gift I gave
          </Button>
        </div>
      </Row>

      <div className={`${styles.policies_background}`}>
        <Row
          style={{
            flexDirection: isMobile ? 'column' : ''
          }}
        >
          <Col span={isMobile ? 24 : 7}>
            <div className={`${styles.policies_title}`}>
              <a
                className={`${styles.a_color}`}
                //href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                href="/Gifts_Hospitality_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img className={`${styles.gift_img}`} src={gift} />
              </a>
            </div>
            <div className={`${styles.policies_title}`}>Our Policies</div>
            <div className={`${styles.policies_text}`}>
              <a
                className={`${styles.a_color}`}
                //href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                href="/Gifts_Hospitality_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Click here to view the policies
              </a>
            </div>
          </Col>
          <Col span={isMobile ? 24 : 15}>
            <div
              style={{
                marginLeft: isMobile ? '10px' : '50px',
                marginTop: isMobile ? '30px' : ''
              }}
            >
              <div
                className={
                  isMobile
                    ? `${styles.Receive_title_isMobile}`
                    : `${styles.Receive_title}`
                }
              >
                Receive a gift{' '}
              </div>
              <div
                className={
                  isMobile
                    ? `${styles.Receive_text_isMobile}`
                    : `${styles.Receive_text}`
                }
              >
                The Company recognizes that it is customary for some of its
                business associates to occasionally give modest gifts to those
                in the Company with whom they do business or have transactional
                dealings. It is important, however, that these gifts do not
                affect an employee’s/Designated Person’s business judgment, or
                give the appearance that judgment may be affected. Accordingly,
                the Company’s employees must be very careful when it comes to
                accepting such gifts. Refer the Gifts & Hospitality policy to
                have an understanding which gifts can be accepted.
              </div>
              <div className={`${styles.learn_arrow}`}>
                <a
                  className={`${styles.learn_more}`}
                  // href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                  href="/Gifts_Hospitality_Policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                  <img src={arrow} />
                </a>{' '}
              </div>
              <div style={{ marginTop: '50px' }}>
                <div
                  className={
                    isMobile
                      ? `${styles.Receive_title_isMobile}`
                      : `${styles.gift_title}`
                  }
                >
                  Give a gift{' '}
                </div>
                <div
                  className={
                    isMobile
                      ? `${styles.Receive_text_isMobile}`
                      : `${styles.gift_text}`
                  }
                >
                  The act of offering gifts assists in establishing or enhancing
                  all types of relationships. This is also true for the
                  corporate world and government agencies. It is also a way to
                  recognize talent and achievements. The Company should have
                  bona fide reasons for offering gifts, such as thanking
                  long-standing customers, recognizing a dedicated employee, and
                  so on. The primary intent should be to affirm the relationship
                  in the most professional manner. Refer the Gifts & Hospitality
                  Policy for more details.
                </div>
                <div className={`${styles.learn_arrow}`}>
                  <a
                    className={`${styles.learn_more}`}
                    // href="https://hub.tataprojects.com/uploads/tataprojects/policydata/133_1665652434_corporate-gift-policy.pdf"
                    href="/Gifts_Hospitality_Policy.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                    <img src={arrow} />
                  </a>{' '}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default DeclareGiftPage;

import React from 'react';
import AdminPage from './components/adminPage';
import CustomLayout from '@/common/Layout';
import { getLoggedInUser, TabTitle } from '../../utilities/helpers';

const Admin = () => {
  TabTitle('Gift Register - Admin');
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  console.log(user_role);

  return (
    <>
      <CustomLayout>
        <div style={{ marginTop: '50px' }}>
          <AdminPage user_role={user_role} />
        </div>
      </CustomLayout>
    </>
  );
};

export default Admin;

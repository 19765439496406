import apiClient from 'utilities/apiClient';

export const roGift_getbyId = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.roGift_getbyId}/${url}`,
    payload,
    true
  );
};

export const ro_gift_accept = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.ro_gift_accept}/${url}`,
    payload,
    true
  );
};

export const received_gift_reject = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.received_gift_reject}/${url}`,
    payload,
    true
  );
};

import React, { useEffect, useState } from 'react';
import ViewGiftReceivedDetailsPage from './components/viewGiftReceivedDetailsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle, getLoggedInUser } from '../../utilities/helpers';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReceivedGiftById } from './redux/slice';
import LocalStorage from 'utilities/localStorage';
import CustomLayoutPublic from '@/common/LayoutPublic';

function ViewGiftReceivedRequestDetails() {
  TabTitle('Gift Register - View Gift Received Details');
  const dispatch = useDispatch();
  const { getReceivedGiftByIdLoading, receivedGiftDetailsById } = useSelector(
    (state) => state.receivedRequestView
  );

  const user_role1 = getLoggedInUser();
  //const user_role = user_role1?.user?.roles[0];
  console.log('user_role1QQQQQQQQQQQ', user_role1?.user);

  const userDetails = LocalStorage.getItem('GiftReg_user');
  console.log('userDetails%%%%%', userDetails?.user);
  const giftType = LocalStorage.getItem('giftTypeReceived-request');
  console.log('GiftTyp%%%%%%%%%', giftType);

  const [status, setStatus] = useState();
  const params = useParams();
  const url = window.location.search;
  const urlType = url.replace('?', '');
  console.log('urType', urlType);

  const urlParams = new URLSearchParams(window.location.search);

  // Read a specific query parameter
  const type = urlParams.get('giftType');
  const hashRoot = urlParams.get('hashRoot');
  const uniqueId = urlParams.get('uniqueId');
  const userId = urlParams.get('userId');

  console.log('paramsID', params?.id);
  console.log(type);
  console.log(hashRoot);
  console.log(uniqueId);
  console.log(userId);
  /* uniqueId=8d5c7e42-e2f8-44bf-b254-22d7b61147e0&userId=8dc1d45f-bba2-42fb-9c48-91294bdfb763&hashRoot=i8u5OTS8cU0kAeTUWXeGxDt5XQo3TGxp&giftType=gr */

  useEffect(() => {
    handleGetGiftById();
  }, []);
  const handleGetGiftById = () => {
    if (userDetails?.user === undefined || user_role1?.user === null) {
      const payload = {
        id: params.id,
        giftType: type,
        hashRoot: hashRoot,
        uniqueId: uniqueId,
        userId: userId
      };

      dispatch(getReceivedGiftById(payload));
    } else {
      const payload = {
        id: params.id,
        giftType: giftType,
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };
      dispatch(getReceivedGiftById(payload));
    }
  };
  useEffect(() => {
    if (receivedGiftDetailsById?.ReceivingFlowWorkings?.length > 0) {
      let sta = receivedGiftDetailsById?.ReceivingFlowWorkings[0]?.status;
      setStatus(sta);
    }
  }, [receivedGiftDetailsById]);
  return userDetails?.user === undefined || user_role1?.user === null ? (
    <CustomLayoutPublic>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftReceivedDetailsPage
          getGiftDetailsById={receivedGiftDetailsById}
          getGiftByIdLoading={getReceivedGiftByIdLoading}
          status={status}
          Id={params?.id}
          type={type}
          hashRoot={hashRoot}
          uniqueId={uniqueId}
          userId={userId}
        />
      </div>
    </CustomLayoutPublic>
  ) : (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftReceivedDetailsPage
          getGiftDetailsById={receivedGiftDetailsById}
          getGiftByIdLoading={getReceivedGiftByIdLoading}
          status={status}
          Id={params?.id}
          type={type}
          hashRoot={hashRoot}
          uniqueId={uniqueId}
          userId={userId}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewGiftReceivedRequestDetails;

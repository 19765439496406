import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import {
  Input,
  Form,
  Radio,
  Button,
  Card,
  Row,
  Col,
  Upload,
  Modal,
  Select,
  // message,
  DatePicker,
  Divider,
  Steps,
  Spin,
  //Checkbox,
  Tag
  //Popover
  // Tooltip
} from 'antd';
import FormItem from 'common/FormItem';
import {
  UploadOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import FormData from 'form-data';
import {
  declare_gift_form,
  get_gift_master,
  get_deligatorsId,
  get_userDetails
} from '../../redux/slice';
import { history } from '@/app/history';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  recipientType,
  getLoggedInUser,
  pattern
} from '../../../../utilities/helpers';
import GovernmentOfficial_Modal from './governmentOfficial_Modal';
import { get_BU_Master, set_BU_mapping } from '../../../admin/redux/slice';
import PerApproval_forCxoUser from './PerApproval_forCxoUser';
import PerApproval_sbgHead from './PerApproval_sbgHead';
import PerApproval_CEC_and_MD_Users from './PerApproval_CEC_and_MD_Users';
import { sizeChecker } from 'utilities/helpers';

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;
const { Step } = Steps;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function DeclareGiftFormPage() {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    declare_gift_formLoading,
    giftMaster,
    deligatorsIdList,
    userDetails,
    get_userDetailsLoading
  } = useSelector((state) => state.declareGiftForm);
  const { bu_MasterList, set_BU_mappingLoading, get_BU_MasterLoading } =
    useSelector((state) => state.admin);

  //const [value, setValue] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [doc, setDoc] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileListImage, setFileListImage] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [otherGift, setOtherGift] = useState('');
  const [giftSearch, setGiftSearch] = useState('');
  const [giftName, setGiftName] = useState('');
  const [recipientTypeOther, setRecipientTypeOther] = useState('');
  const [otherRecipient, setOtherRecipient] = useState('');
  const [deligatorsIdSearch, setDeligatorsIdSearch] = useState('');
  //const [draftLoading, setDraftLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [current, setCurrent] = useState(0);
  const [giftMasterOthers, setGiftMasterOthers] = useState([]);
  const [giftValue, setGiftValue] = useState();
  const [giftType, setGiftType] = useState('');
  const [fileListLength, setFileListLength] = useState();
  const [governmentOfficialModal, setGovernmentOfficialModal] = useState(false);
  const [BUSearch, setBUSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [toggleUpload, setToggleUpload] = useState(false);
  const [recall, setReCall] = useState(false);
  const [fileListImageLength, setFileListImageLength] = useState([]);

  const user = getLoggedInUser();

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 1000
    };
    if (BUSearch) payload['search'] = BUSearch;
    dispatch(get_BU_Master(payload));
  }, [BUSearch]);

  const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (BUSearch) => {
      setBUSearch(BUSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  console.log('user?.user?.id', user?.user?.roles[0]);

  useEffect(() => {
    const giftMaster1 = [...giftMaster, { title: 'Others', id: 'others' }];
    setGiftMasterOthers(giftMaster1);
  }, [giftMaster]);

  useEffect(() => {
    const payload = {};
    if (giftSearch) payload['name'] = giftSearch;
    dispatch(get_gift_master(payload));
  }, [giftSearch]);

  useEffect(() => {
    const payload = {
      userId:
        formValues?.whom_create === true
          ? user?.user?.id
          : formValues?.delegatorId === undefined
          ? user?.user?.id
          : formValues?.delegatorId
    };
    dispatch(get_userDetails(payload));
    if (formValues?.whom_create === true) {
      formValues['delegatorId'] = undefined;
    }
  }, [formValues?.delegatorId, formValues?.whom_create, recall]);

  useEffect(() => {
    const payload = {
      delegate_id: ''
    };
    if (deligatorsIdSearch) payload['search'] = deligatorsIdSearch;
    dispatch(get_deligatorsId(payload));
  }, [deligatorsIdSearch]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (giftSearch) => {
      setGiftSearch(giftSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherDeligatorsId = useMemo(() => {
    const loadOptions = (deligatorsIdSearch) => {
      setDeligatorsIdSearch(deligatorsIdSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleOtherDescription = (e) => {
    setOtherGift(e.target.value);
  };

  const handleOtherRecipientType = (e) => {
    setOtherRecipient(e.target.value);
  };

  //console.log('deligatorsId', deligatorsIdList);
  console.log('formValues', formValues);
  useEffect(() => {
    let name = giftMasterOthers?.filter(
      (item) => item?.id === formValues?.giftId
    );
    setGiftName(name[0]?.title);
    setGiftType(name[0]?.type);
  }, [formValues?.giftId]);

  //console.log('GiftName:', giftName);
  // console.log('GiftType:', giftType);

  useEffect(() => {
    if (formValues?.recipientType === 'other') {
      setRecipientTypeOther(formValues?.recipientType);
    } else {
      setRecipientTypeOther('');
    }
  }, [formValues?.recipientType]);

  const handlerBU = () => {
    let formdata = new FormData();

    formdata.append('userId', user?.user?.id);
    formdata.append('buId', formValues?.buId);

    dispatch(set_BU_mapping(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        // toggleChecked();
        setChecked(true);
        setToggleUpload(false);
        setReCall(!recall);
      }
    });
  };

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);
    if (slug === 'buId') {
      setToggleUpload(true);
      setChecked(false);
      // setUserDetails1(userDetails);
    }
    if (slug === 'whom_create' && value === true) {
      form.setFieldsValue({
        delegatorId: undefined
      });
    }
    if (slug === 'isGiftEmbossed') {
      form.setFieldsValue({
        procuredHow: undefined
      });
    }
    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  // //const [attachmentList, setAttachmentList] = useState('');
  // const isListOfAttachmentsEmpty =
  //  // fileList?.replace(/<(.|\n)*?>/g, '')?.trim()?.length === 0;
  // fileList?.length === 0;
  // if (isListOfAttachmentsEmpty === true) {
  //   // setAttachmentList('');
  // //  setFileList('');
  //   form.setFieldsValue({ fileList: undefined });
  // }
  // useEffect(() => {
  //   form.setFieldsValue({ fileList: formValues?.files });
  // }, [fileList]);

  const onFinish = (values) => {
    console.log('valuesITEMS', values);
    //const formValuesHasPreApproval = true;
    //setDraftLoading(true);
    let formdata = new FormData();

    formdata.append('type', 'gag');

    if (user?.user?.roles[0] === 'cec-user') {
      ('');
    } else {
      for (let i in fileListImage) {
        formdata.append(
          'cecApprovalDocs',
          fileListImage?.length === 0 ? '' : fileListImage[i]?.originFileObj
        );
      }
    }
    for (let i in fileList) {
      formValues?.hasPreApproval === true
        ? formdata.append(
            'preApprovalProofs',
            fileList?.length === 0 ? '' : fileList[i]?.originFileObj
          )
        : '';
    }

    if (
      user?.user?.roles[0] === 'cxo' ||
      user?.user?.roles[0] === 'sbg-head' ||
      user?.user?.roles[0] === 'cec-user'
    ) {
      for (let i in fileList2) {
        formValues?.hasMDApproval === true
          ? formdata.append(
              'approvalDocs',
              fileList2?.length === 0 ? '' : fileList2[i]?.originFileObj
            )
          : '';
      }
    } else if (user?.user?.roles[0] === 'md') {
      ('');
    } else {
      for (let i in fileList2) {
        formValues?.hasPreApproval === true &&
        formValues?.hasMDApproval === true
          ? formdata.append(
              'approvalDocs',
              fileList2?.length === 0 ? '' : fileList2[i]?.originFileObj
            )
          : '';
      }
    }

    formdata.append(
      'delegatorId',
      formValues?.whom_create === true ? '' : formValues?.delegatorId
    );

    formdata.append(
      'giftReceiverSector',
      formValues?.giftReceiverSector === undefined
        ? ''
        : formValues?.giftReceiverSector
    );
    formdata.append(
      'recipientName',
      formValues?.recipientName === undefined ? '' : formValues?.recipientName
    );
    formdata.append(
      'organizationName',
      formValues?.organizationName === undefined
        ? ''
        : formValues?.organizationName
    );
    formdata.append(
      'designation',
      formValues?.designation === undefined ? '' : formValues?.designation
    );

    formdata.append(
      'recipientType',
      formValues?.recipientType === 'other'
        ? `Others - ${otherRecipient}`
        : formValues?.recipientType === undefined
        ? ''
        : formValues?.recipientType
    );

    /* formdata.append(
      'noOfGiftArticles',
      formValues?.noOfGiftArticles === undefined
        ? ''
        : formValues?.noOfGiftArticles
    ); */

    formdata.append(
      'dateOfGiftReceived',
      formValues?.dateOfGiftReceived === undefined
        ? ''
        : moment(formValues?.dateOfGiftReceived).format()
    );

    formdata.append(
      'giftReceivedByFamily',
      formValues?.giftReceivedByFamily === undefined
        ? ''
        : formValues?.giftReceivedByFamily
    );
    formdata.append(
      'giftId',
      giftName === 'Others' ? 'others' : formValues?.giftId
    );
    formdata.append(
      'giftName',
      giftName === 'Others' ? `Others – ${otherGift}` : giftName
    );
    formdata.append(
      'valueOfGift',
      giftValue === undefined ||
        formValues?.giftId === 'd0df4905-a386-4868-8ef5-213b014ab967'
        ? 0
        : giftValue
    );
    /* formdata.append(
      'valueOfGift',
      formValues?.valueOfGift === undefined ? '' : formValues?.valueOfGift
    ); */

    formdata.append(
      'isGiftEmbossed',
      formValues?.isGiftEmbossed === undefined ? '' : formValues?.isGiftEmbossed
    );
    formdata.append(
      'contextOrBusinessPurposeOfTheGift',
      formValues?.contextOrBusinessPurposeOfTheGift === undefined
        ? ''
        : formValues?.contextOrBusinessPurposeOfTheGift
    );

    formdata.append(
      'giftDescription',
      formValues?.giftDescription === undefined
        ? ''
        : formValues?.giftDescription
    );
    formdata.append(
      'procuredHow',
      formValues?.procuredHow === undefined ? '' : formValues?.procuredHow
    );

    if (
      user?.user?.roles[0] === 'cxo' ||
      user?.user?.roles[0] === 'sbg-head' ||
      user?.user?.roles[0] === 'cec-user' ||
      user?.user?.roles[0] === 'md'
    ) {
      // formdata.append('approval1', true);
    } else {
      giftValue < 1000 && formValues?.giftReceiverSector === 'non-government'
        ? ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'local'
        ? formdata.append(
            'approval1',
            formValues?.hasPreApproval === undefined
              ? false
              : formValues?.hasPreApproval
          )
        : formdata.append(
            'approval1',
            formValues?.hasPreApproval === undefined
              ? false
              : formValues?.hasPreApproval
          );
    }

    if (user?.user?.roles[0] === 'cxo') {
      giftValue < 1000 && formValues?.giftReceiverSector === 'non-government'
        ? ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'local'
        ? /* formValues?.hasPreApproval === true */
          formdata.append(
            'hasCECApproval',
            formValues?.hasCECApproval === undefined
              ? false
              : formValues?.hasCECApproval
          )
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'tpl'
        ? formdata.append(
            'hasCECApproval',
            formValues?.hasCECApproval === undefined
              ? false
              : formValues?.hasCECApproval
          )
        : formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          );

      giftValue < 1000
        ? ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'local' &&
          formValues?.hasCECApproval === true
        ? formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          )
        : '';
    } else if (user?.user?.roles[0] === 'sbg-head') {
      giftValue < 1000 && formValues?.giftReceiverSector === 'non-government'
        ? ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'local'
        ? /* formValues?.hasPreApproval === true */
          formdata.append(
            'hasCECApproval',
            formValues?.hasCECApproval === undefined
              ? false
              : formValues?.hasCECApproval
          )
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'tpl'
        ? formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          )
        : formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          );

      giftValue < 1000
        ? ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'local' &&
          formValues?.hasCECApproval === true
        ? formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          )
        : '';
    } else if (user?.user?.roles[0] === 'cec-user') {
      giftValue < 1000 && formValues?.giftReceiverSector === 'non-government'
        ? ''
        : formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          );
    } else if (user?.user?.roles[0] === 'md') {
      giftValue < 1000 && formValues?.giftReceiverSector === 'non-government'
        ? ''
        : formdata.append(
            'hasCECApproval',
            formValues?.hasCECApproval === undefined
              ? false
              : formValues?.hasCECApproval
          );
    } else {
      giftValue < 1000 && formValues?.giftReceiverSector === 'non-government'
        ? ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'local'
        ? formValues?.hasPreApproval === true
          ? formdata.append(
              'hasCECApproval',
              formValues?.hasCECApproval === undefined
                ? false
                : formValues?.hasCECApproval
            )
          : ''
        : formValues?.giftReceiverSector === 'non-government' &&
          formValues?.procuredHow === 'tpl'
        ? ''
        : formValues?.hasPreApproval === true
        ? formdata.append(
            'approval2',
            formValues?.hasMDApproval === undefined
              ? false
              : formValues?.hasMDApproval
          )
        : '';
    }
    //For Final Approval only
    if (user?.user?.roles[0] === 'cxo') {
      if (giftType === 'non-prohibited' || giftName === 'Others') {
        if (formValues?.giftReceiverSector === 'non-government') {
          if (giftValue >= 1000 && giftValue <= 5000) {
            if (formValues?.procuredHow === 'local') {
              if (formValues?.hasCECApproval === true)
                if (formValues?.hasMDApproval === true) {
                  formdata.append('finalApproval', true);
                } else {
                  formdata.append('finalApproval', false);
                }
              else {
                formdata.append('finalApproval', false);
              }
            } else if (formValues?.procuredHow === 'tpl') {
              if (formValues?.hasCECApproval === true) {
                formdata.append('finalApproval', true);
              } else if (formValues?.hasCECApproval === false) {
                formdata.append('finalApproval', false);
              }
            }
          } else if (
            giftValue < 1000 &&
            formValues?.giftReceiverSector === 'non-government'
          ) {
            formdata.append('finalApproval', true);
          } else if (giftValue > 5000) {
            formdata.append('finalApproval', false);
          } else {
            formdata.append('finalApproval', false);
          }
        } else if (formValues?.giftReceiverSector === 'government') {
          if ((giftValue >= 1000 && giftValue <= 5000) || giftValue < 1000) {
            if (formValues?.hasMDApproval === true) {
              formdata.append('finalApproval', true);
            } else {
              formdata.append('finalApproval', false);
            }
          } else {
            formdata.append('finalApproval', false);
          }
        } else {
          formdata.append('finalApproval', false);
        }
      } else {
        formdata.append('finalApproval', false);
      }
    } else if (user?.user?.roles[0] === 'sbg-head') {
      if (giftType === 'non-prohibited' || giftName === 'Others') {
        if (formValues?.giftReceiverSector === 'non-government') {
          if (giftValue >= 1000 && giftValue <= 5000) {
            if (formValues?.procuredHow === 'local') {
              if (formValues?.hasCECApproval === true)
                if (formValues?.hasMDApproval === true) {
                  formdata.append('finalApproval', true);
                } else {
                  formdata.append('finalApproval', false);
                }
              else {
                formdata.append('finalApproval', false);
              }
            } else if (formValues?.procuredHow === 'tpl') {
              if (formValues?.hasMDApproval === true) {
                formdata.append('finalApproval', true);
              } else if (formValues?.hasMDApproval === false) {
                formdata.append('finalApproval', false);
              }
            }
          } else if (
            giftValue < 1000 &&
            formValues?.giftReceiverSector === 'non-government'
          ) {
            formdata.append('finalApproval', true);
          } else if (giftValue > 5000) {
            formdata.append('finalApproval', false);
          } else {
            formdata.append('finalApproval', false);
          }
        } else if (formValues?.giftReceiverSector === 'government') {
          if ((giftValue >= 1000 && giftValue <= 5000) || giftValue < 1000) {
            if (formValues?.hasMDApproval === true) {
              formdata.append('finalApproval', true);
            } else {
              formdata.append('finalApproval', false);
            }
          } else {
            formdata.append('finalApproval', false);
          }
        } else {
          formdata.append('finalApproval', false);
        }
      } else {
        formdata.append('finalApproval', false);
      }
    } else if (user?.user?.roles[0] === 'cec-user') {
      if (giftType === 'non-prohibited' || giftName === 'Others') {
        if (
          giftValue < 1000 &&
          formValues?.giftReceiverSector === 'non-government'
        ) {
          formdata.append('finalApproval', true);
        } else if (giftValue > 5000) {
          formdata.append('finalApproval', false);
        } else {
          if (formValues?.hasMDApproval === true) {
            formdata.append('finalApproval', true);
          } else {
            formdata.append('finalApproval', false);
          }
        }
      } else {
        formdata.append('finalApproval', false);
      }
    } else if (user?.user?.roles[0] === 'md') {
      if (giftType === 'non-prohibited' || giftName === 'Others') {
        if (
          giftValue < 1000 &&
          formValues?.giftReceiverSector === 'non-government'
        ) {
          formdata.append('finalApproval', true);
        } else if (giftValue > 5000) {
          formdata.append('finalApproval', false);
        } else {
          if (formValues?.hasCECApproval === true) {
            formdata.append('finalApproval', true);
          } else {
            formdata.append('finalApproval', false);
          }
        }
      } else {
        formdata.append('finalApproval', false);
      }
    } else {
      /* if (user?.user?.roles[0] === 'cxo') {
      giftType === 'non-prohibited' || giftName === 'Others'
        ? giftValue >= 1000 && giftValue <= 5000
          ? formValues?.giftReceiverSector === 'non-government'
            ? formValues?.procuredHow === 'local'
              ? formValuesHasPreApproval === true
                ? formValues?.hasCECApproval === true
                  ? formdata.append('finalApproval', true)
                  : formValues?.hasCECApproval === false
                  ? formdata.append('finalApproval', false)
                  : formValues?.hasMDApproval === true
                  ? formdata.append('finalApproval', true)
                  : formdata.append('finalApproval', false)
                : formdata.append('finalApproval', false)
              : formValues?.procuredHow === 'tpl'
              ? formValuesHasPreApproval === true
                ? formdata.append('finalApproval', true)
                : formdata.append('finalApproval', false)
              : ''
            : formValuesHasPreApproval === true
            ? formValues?.hasMDApproval === true
              ? formdata.append('finalApproval', true)
              : formdata.append('finalApproval', false)
            : formdata.append('finalApproval', false)
          : giftValue < 1000
          ? formValues?.giftReceiverSector === 'non-government'
            ? formValuesHasPreApproval === undefined
              ? formdata.append('finalApproval', true)
              : formdata.append('finalApproval', false) //''
            : formValuesHasPreApproval === true
            ? formValues?.hasMDApproval === true
              ? formdata.append('finalApproval', true)
              : formdata.append('finalApproval', false)
            : formdata.append('finalApproval', false)
          : giftValue > 5000
          ? formValues?.giftReceiverSector === 'government'
            ? formValuesHasPreApproval === undefined
              ? formdata.append('finalApproval', false)
              : formdata.append('finalApproval', false) //''
            : formdata.append('finalApproval', false)
          : formdata.append('finalApproval', false)
        : formdata.append(
            'finalApproval',
            formValuesHasPreApproval === true &&
              formValues?.hasMDApproval === true
              ? true
              : false
          );
    }  */
      giftType === 'non-prohibited' || giftName === 'Others'
        ? giftValue >= 1000 && giftValue <= 5000
          ? formValues?.giftReceiverSector === 'non-government'
            ? formValues?.procuredHow === 'local'
              ? formValues?.hasPreApproval === true
                ? formValues?.hasCECApproval === true
                  ? formdata.append('finalApproval', true)
                  : formValues?.hasCECApproval === false
                  ? formdata.append('finalApproval', false)
                  : formValues?.hasMDApproval === true
                  ? formdata.append('finalApproval', true)
                  : formdata.append('finalApproval', false)
                : formdata.append('finalApproval', false)
              : formValues?.procuredHow === 'tpl'
              ? formValues?.hasPreApproval === true
                ? formdata.append('finalApproval', true)
                : formdata.append('finalApproval', false)
              : ''
            : formValues?.hasPreApproval === true
            ? formValues?.hasMDApproval === true
              ? formdata.append('finalApproval', true)
              : formdata.append('finalApproval', false)
            : formdata.append('finalApproval', false)
          : giftValue < 1000
          ? formValues?.giftReceiverSector === 'non-government'
            ? formValues?.hasPreApproval === undefined
              ? formdata.append('finalApproval', true)
              : formdata.append('finalApproval', false) //''
            : formValues?.hasPreApproval === true
            ? formValues?.hasMDApproval === true
              ? formdata.append('finalApproval', true)
              : formdata.append('finalApproval', false)
            : formdata.append('finalApproval', false)
          : giftValue > 5000
          ? formValues?.giftReceiverSector === 'government'
            ? formValues?.hasPreApproval === undefined
              ? formdata.append('finalApproval', false)
              : formdata.append('finalApproval', false) //''
            : formdata.append('finalApproval', false)
          : formdata.append('finalApproval', false)
        : formdata.append(
            'finalApproval',
            formValues?.hasPreApproval === true &&
              formValues?.hasMDApproval === true
              ? true
              : false
          );
    }
    /*  formdata.append(
      'finalApproval',
      formValues?.hasPreApproval === true && formValues?.hasMDApproval === true
        ? true
        : false
    ); */

    formdata.append(
      'wasThereOngoingTender',
      formValues?.wasThereOngoingTender === undefined
        ? ''
        : formValues?.wasThereOngoingTender
    );

    formValues?.wasThereOngoingTender === true
      ? formdata.append(
          'ongoingTenderDiscussion',
          formValues?.tenderDetails === undefined
            ? ''
            : formValues?.tenderDetails
        )
      : '';
    formValues?.wasThereOngoingTender === true
      ? formdata.append(
          'businessJustificationForWhyThisGiftWasGivenDuringOngoingTender',
          formValues?.tenderDetails2 === undefined
            ? ''
            : formValues?.tenderDetails2
        )
      : '';

    /* formdata.append(
      'additionalComments',
      formValues?.additionalComments === undefined
        ? ''
        : formValues?.additionalComments
    ); */

    dispatch(declare_gift_form(formdata)).then(function (response) {
      if (response?.payload?.success) {
        // message.success(response.payload.message);
        history.push('/view-past-gift');
        //setDraftLoading(false);
      } else {
        // message.error(response.payload.msg);
        //setDraftLoading(false);
      }
    });
  };

  const normFile = (e) => {
    setDoc(e?.fileList);
    console.log('setDoc', doc);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // console.log('fileList', fileList);
  // console.log('fileList2', fileList2);
  // console.log('fileListImage', fileListImage);
  //console.log('FileListLength', fileListLength);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const handleChange2 = ({ fileList: newFileList }) =>
    setFileList2(newFileList);

  const handleChangeImage = ({ fileList: newFileList }) => {
    setFileListImage(newFileList);
    setFileListImageLength(newFileList);
  };
  console.log('fileListImageLength@@@@@@@@@@', fileListImageLength);
  const uploadButton = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  );
  useEffect(() => {
    setFileListLength(fileList?.length);
  }, [fileList, uploadButton]);

  const uploadButton2 = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  );
  const uploadButtonImage = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
      {/* <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        SVG, PNG, JPG or GIF (max. 800x400px)
      </p> */}
    </div>
  );

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const onScroll = () => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    // This will calculate the final total of the percentage of how much the user has scrolled.
    const scrolled = (winScroll / height) * 100;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    // Fires when the document view has been scrolled
    window.addEventListener('scroll', onScroll);

    //
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClickNext = () => {
    if (formValues?.buId === undefined) {
      form.setFieldsValue({
        buId: userDetails?.BuMaster?.id
      });
    } else {
      form.setFieldsValue({
        buId: formValues?.buId
      });
    }
    form
      .validateFields()
      .then(() => {
        // Here make api call of something else
        setCurrent(current + 1);
        window?.scrollTo(0, 0);
      })
      .catch((err) => console.log(err));
  };
  const handleClickBack = () => {
    setCurrent(current - 1);
  };
  const handleValueOfGift = (e) => {
    setGiftValue(e.target.value);
  };

  return (
    <Spin spinning={get_userDetailsLoading}>
      <div className={`${styles.App}`}>
        <div className={`${styles.progressMainWrapper}`}>
          <div
            className={`${styles.progressMainStyle}`}
            style={{ width: `${scrollTop}%` }}
          ></div>
        </div>
        <div className={`${styles.container_stepper}`}>
          <div className={`${styles.container_stepper_div}`}>
            <Steps current={current} size="small">
              <Step key={0} title={`Recipient Details`} />
              <Step key={1} title="Gift Details" />
            </Steps>
          </div>
        </div>
        <div className={`${styles.container}`}>
          <div className={`${styles.title}`}>
            I want to declare a gift I gave
          </div>
          <div className={`${styles.subTitle}`}>
            Please fill the gift details below
          </div>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
            autoComplete="off"
          >
            <div
              style={{ marginTop: '30px', width: '100%', marginBottom: '20px' }}
            >
              {current === 0 && (
                <>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.title_section}`}>
                      {`Requester Details`}
                    </div>
                    <Divider className={`${styles.divider_margin}`} />
                    <Card className={`${styles.cardStyles}`}>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div>
                            Date of reporting of the gift:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.dateOfReporting === null
                                ? 'NA'
                                : moment(userDetails?.dateOfReporting).format(
                                    'DD MMM YYYY'
                                  )}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee name:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.givenName === null
                                ? 'NA'
                                : userDetails?.givenName}{' '}
                              {userDetails?.surname}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee email:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.email === null
                                ? 'NA'
                                : userDetails?.email}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee ID:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.empId === null
                                ? 'NA'
                                : userDetails?.empId}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee designation:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.designation === null
                                ? 'NA'
                                : userDetails?.designation}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            {' '}
                            Employee department/function:{' '}
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.BuMaster?.buName === null
                                ? 'NA'
                                : userDetails?.BuMaster?.buName}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Location:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.location === null
                                ? 'NA'
                                : userDetails?.location}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            {' '}
                            Employee manager:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.ReportingManager?.User
                                ?.givenName === null
                                ? 'NA'
                                : userDetails?.ReportingManager?.User
                                    ?.givenName}{' '}
                              {userDetails?.ReportingManager?.User?.surname}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="whom_create"
                          label={
                            <div className={`${styles.card_title}`}>
                              Are you creating this for yourself?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'whom_create',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Self</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>Someone else</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    {formValues?.whom_create === false ? (
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Please select the delegator from the list below
                              </div>
                            }
                            name="delegatorId"
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              className={`${styles.margin_Top}`}
                              style={{ marginBottom: '15px' }}
                              showSearch
                              allowClear={true}
                              optionFilterProp="children"
                              placeholder="Select delegator"
                              //onSearch={inputVenderName}
                              onSearch={debounceFetcherDeligatorsId}
                              onChange={(value) =>
                                handleFormDataOnChange('delegatorId', value)
                              }
                              filterOption={(input, option) => {
                                return (
                                  option.key
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option.title
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {deligatorsIdList?.map((item, index) => (
                                <Option
                                  title={item?.GiftEntryDelegator?.givenName}
                                  key={index}
                                  value={item?.GiftEntryDelegator?.id}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      textTransform: 'uppercase',
                                      fontFamily: 'SFProText Regular'
                                    }}
                                  >
                                    <span style={{ marginLeft: '10px' }}>
                                      {item?.GiftEntryDelegator?.givenName} (
                                      <span
                                        style={{ textTransform: 'lowercase' }}
                                      >
                                        {item?.GiftEntryDelegator?.email}
                                      </span>
                                      )
                                    </span>
                                  </span>
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </Card>
                    ) : (
                      ''
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="buInfo"
                          label={
                            <div className={`${styles.card_title}`}>
                              Is the above mentioned BU/Function information
                              correct?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange('buInfo', e.target.value)
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    <Spin spinning={set_BU_mappingLoading}>
                      {formValues?.buInfo === false ? (
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              label={
                                <div className={`${styles.card_title}`}>
                                  Please select the BU
                                </div>
                              }
                              name="buId"
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              <Spin spinning={get_BU_MasterLoading}>
                                <Select
                                  className={`${styles.margin_Top}`}
                                  style={{ marginBottom: '15px' }}
                                  showSearch
                                  allowClear={true}
                                  optionFilterProp="children"
                                  placeholder="Search and select your BU"
                                  onSearch={debounceFetcherAllUsers}
                                  //onChange={handlerSelectedBU}
                                  onChange={(value) =>
                                    handleFormDataOnChange('buId', value)
                                  }
                                  filterOption={(input, option) => {
                                    return (
                                      option.key
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0 ||
                                      option.title
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {bu_MasterList?.map((item, index) => (
                                    <Option
                                      title={item?.buName}
                                      key={index}
                                      value={item?.id}
                                    >
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          textTransform: 'uppercase',
                                          fontFamily: 'SFProText Regular'
                                        }}
                                      >
                                        <span style={{ marginLeft: '10px' }}>
                                          {item?.buName}
                                          {/*  (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  ) */}
                                        </span>
                                      </span>
                                    </Option>
                                  ))}
                                </Select>
                              </Spin>
                            </FormItem>
                            <a
                              onClick={handlerBU}
                              className={`${styles.buUpload}`}
                              disabled={toggleUpload === false}
                            >
                              {checked === false && toggleUpload === false ? (
                                <Tag color="default">UPDATE</Tag>
                              ) : checked === false && toggleUpload === true ? (
                                <Tag color="processing">UPDATE</Tag>
                              ) : (
                                <Tag
                                  icon={<CheckCircleOutlined />}
                                  color="success"
                                >
                                  UPDATED
                                </Tag>
                              )}
                            </a>
                            {/* {checked === true && (
                            <p
                              style={{
                                marginBottom: '20px',
                                float: 'right',
                                marginRight: '2px'
                              }}
                            >
                              <Checkbox
                                checked={checked}
                                //  onChange={onChange}
                              ></Checkbox>
                            </p>
                          )} */}
                          </div>
                        </Card>
                      ) : (
                        ''
                      )}
                    </Spin>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.title_section}`}>
                      {`Recipient Details`}
                    </div>
                    <Divider className={`${styles.divider_margin}`} />

                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="recipientName"
                          label={
                            <div className={`${styles.card_title}`}>
                              {`Recipient Full Name`}
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter the recipient full name'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the recipient full name"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'recipientName',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="organizationName"
                          label={
                            <div className={`${styles.card_title}`}>
                              {`Recipient Organization Full Name`}
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter the full name of the organization'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the full name of the organization"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'organizationName',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="designation"
                          label={
                            <div
                              className={`${styles.card_title}`}
                            >{`Recipient Designation`}</div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter the designation of the recipient'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the designation of the recipient"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'designation',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Recipient Stakeholder Type
                            </div>
                          }
                          name="recipientType"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select recipient stakeholder type"
                            //onSearch={inputVenderName}
                            onSearch={debounceFetcher}
                            onChange={(value) =>
                              handleFormDataOnChange('recipientType', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {recipientType?.map((item, index) => (
                              <Option
                                title={item.label}
                                key={index}
                                value={item?.value}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  <span style={{ marginLeft: '10px' }}>
                                    {item?.label}
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </div>
                    </Card>
                    {recipientTypeOther === 'other' && (
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="recipientType_other"
                            label={
                              <div className={`${styles.card_title}`}>
                                Other
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              },
                              {
                                validator: async (_, value) => {
                                  if (value?.trim()?.length !== 0) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      'Enter stakeholder type'
                                    );
                                  }
                                }
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              className={`${styles.margin_Top}`}
                              allowClear
                              placeholder="Enter stakeholder type"
                              onChange={handleOtherRecipientType}
                            />
                          </FormItem>
                        </div>
                      </Card>
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftReceiverSector"
                          label={
                            <div className={`${styles.card_title}`}>
                              Was the recipient a government official?
                              <a
                                onClick={() => setGovernmentOfficialModal(true)}
                              >
                                {/* <Popover
                                  content={`Definition of Government Official -
                                    A “Government Official” would include the following:
                                    i. any person holding a legislative, executive or administrative office of the government, or acting in the official
                                    capacity for or on behalf of a legislative, executive, or administrative office of the government, whether appointed or
                                    elected, whether permanent or temporary, whether paid or unpaid;
                                    ii. any person in the service or pay of the government or of a corporation established by or under a central, provincial
                                    or state statute, or an authority or a body owned or controlled or aided by the government or a government company
                                    or is remunerated by the government by fees or commission for the performance of any public duty;
                                    iii. any judge, including any person empowered by law to discharge, whether by himself/herself or as a member of any
                                    body of persons, any adjudicatory functions;
                                    iv. any person authorised by a court of justice to perform any duty, in connection with the administration of justice,
                                    including a liquidator, receiver or commissioner;
                                    v. any person who performs a public duty, including for a public agency or public enterprise, or provides a public
                                    service, as defined in the domestic law of the country and as applied in the pertinent area of law;
                                    vi. any elected or appointed officers or employees of public international or multilateral organizations, such as the
                                    United Nations; and
                                    vii. any other person who is considered as Government Official according to applicable laws and regulations.`}
                                  trigger="click"
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      marginLeft: '2px',
                                      fontSize: '16px'
                                    }}
                                  />
                                </Popover> */}

                                <InfoCircleOutlined
                                  style={{
                                    marginLeft: '2px',
                                    fontSize: '16px'
                                  }}
                                />
                              </a>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'giftReceiverSector',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={'government'}>
                                  {/* Government Official */}
                                  Yes
                                </Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={'non-government'}>
                                  {/* Non-Government Official */}
                                  No
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="wasThereOngoingTender"
                          label={
                            <div className={`${styles.card_title}`}>
                              {/* Was there an ongoing tender discussion when the gift
                            was given? */}
                              Was there an ongoing tender / discussion with the
                              recipient or its company at the time of this gift?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'wasThereOngoingTender',
                                e.target.value
                              )
                            }
                            //value={value}
                            className={`${styles.card_Radio}`}
                          >
                            <Row
                              className={`${styles.Radio_button_margin}`}
                              style={{ marginBottom: '10px' }}
                            >
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    {formValues?.wasThereOngoingTender === true ? (
                      <>
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              name="tenderDetails"
                              label={
                                <div className={`${styles.card_title}`}>
                                  {/* Enter details about the tender (500 characters) */}
                                  Provide details of the ongoing
                                  tender/discussion and the role played by the
                                  recipient of the proposed gift
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter details about the tender'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter details about the ongoing tender and the role played by the giver of this gift"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </div>
                        </Card>
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              name="tenderDetails2"
                              label={
                                <div className={`${styles.card_title}`}>
                                  {/* Enter details about the tender (500 characters) */}
                                  Please provide a business justification for
                                  why this gift was given during an ongoing
                                  tender/discussion.
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter details about the business justification'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter business justification for receiving this gift during an ongoing tender discussion"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails2',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </div>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftReceivedByFamily"
                          label={
                            <div className={`${styles.card_title}`}>
                              {/*  Who did you give this gift to? */}
                              Was this a gift to a family member of a supplier,
                              customer, or other business partner?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            // onChange={onChange}
                            // value={value}
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'giftReceivedByFamily',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>
                                  {/* 'Family-Person */}
                                  Yes
                                </Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>
                                  {/* To the person directly */}
                                  No
                                </Radio>
                                {/* Themselves */}
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                  </Card>
                </>
              )}

              {current === 1 && (
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.title_section}`}>Gift Details</div>
                  <Divider className={`${styles.divider_margin}`} />

                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the gift from the list below
                          </div>
                        }
                        name="giftId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select gift"
                          //onSearch={inputVenderName}
                          onSearch={debounceFetcher}
                          onChange={(value) =>
                            handleFormDataOnChange('giftId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {giftMasterOthers?.map((item, index) => (
                            <Option
                              title={item.title}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.title}
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  {giftName === 'Others' && (
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftName_other"
                          label={
                            <div className={`${styles.card_title}`}>Others</div>
                          }
                          rules={[
                            {
                              required: true,
                              message: '' // 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                /*  const pattern = /\b(cash|gold|money|amount)\b/;
                                    if (pattern.test(this.state.entry)) {
                                    console.log("Gift entry contains restricted keywords.");
                                    // Perform action to disable gift entry
                                    } else {
                                    console.log("Gift entry does not contain any restricted keywords.");
                                   // Perform desired action for valid entry
                                  } */
                                // const pattern =
                                //   /\b(cash|CASH|Cash|gold|GOLD|Gold|money|MONEY|Money|amount|AMOUNT|Amount)\b/;
                                const newValue = value
                                  ?.toString()
                                  ?.toLowerCase();
                                if (
                                  pattern.test(newValue)
                                  // || value?.trim()?.length === 0
                                ) {
                                  return Promise.reject(
                                    'The gift you have entered is from a restricted category. Please select it from the list above to proceed.'
                                  );
                                } else if (newValue?.trim()?.length === 0) {
                                  return Promise.reject(
                                    'This is a required field'
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                                /*  if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('Enter description');
                                } */
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter description..."
                            onChange={handleOtherDescription}
                          />
                        </FormItem>
                      </div>
                    </Card>
                  )}
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="giftDescription"
                        label={
                          <div className={`${styles.card_title}`}>
                            Describe the gift
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (value?.trim()?.length !== 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject('Enter description');
                              }
                            }
                          }
                        ]}
                        className={`${styles.textarea_styles}`}
                      >
                        <TextArea
                          className={`${styles.margin_Top}`}
                          // maxLength={500}
                          //rows={3}
                          allowClear
                          placeholder="Describe the gift you give"
                          size="small"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'giftDescription',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="isGiftEmbossed"
                        label={
                          <div className={`${styles.card_title}`}>
                            {/* Is the gift embossed with the logo of the gift-giving
                          company? */}
                            Was the gift taken from the stock of promotion items
                            and bears the company logo?
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'isGiftEmbossed',
                              e.target.value
                            )
                          }
                          className={`${styles.card_Radio}`}
                        >
                          <Row className={`${styles.Radio_button_margin}`}>
                            <Col span={12}>
                              <Radio value={true}>Yes</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={false}>No</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="procuredHow"
                        label={
                          <div className={`${styles.card_title}`}>
                            {/* How did you procure this gift? */}
                            Was the gift procured locally?
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (
                                formValues?.isGiftEmbossed === true &&
                                value === 'local'
                              ) {
                                return Promise.reject(
                                  'You cannot select `Yes` for both this question and the one above'
                                );
                              } else if (
                                formValues?.isGiftEmbossed === false &&
                                value === 'tpl'
                              ) {
                                return Promise.reject(
                                  'You cannot select `No` for both this question and the one above'
                                );
                              } else {
                                return Promise.resolve();
                              }
                            }
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'procuredHow',
                              e.target.value
                            )
                          }
                          //value={value}
                          className={`${styles.card_Radio}`}
                        >
                          <Row className={`${styles.Radio_button_margin}`}>
                            <Col span={12}>
                              <Radio value={'local'}>Yes</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={'tpl'}>No</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            {/* When did you give this gift? */}
                            When was the gift given?
                          </div>
                        }
                        name="dateOfGiftReceived"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.date_styles}`}
                      >
                        <DatePicker
                          className={`${styles.margin_Top}`}
                          format={'DD-MMM-YYYY'}
                          onChange={(_, dateValue) =>
                            handleFormDataOnChange(
                              'dateOfGiftReceived',
                              dateValue
                            )
                          }
                          disabledDate={(current) => {
                            return (
                              // moment().add(-1, 'days') >= current ||
                              moment().add(0, 'month') <= current
                            );
                          }}
                        ></DatePicker>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      {formValues?.giftId ===
                      'd0df4905-a386-4868-8ef5-213b014ab967' ? (
                        <FormItem
                          name="valueOfGift"
                          label={
                            <div className={`${styles.card_title}`}>
                              Value of the gift (in INR)
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#a6a6ac',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                (Note – If you are giving more than one item,
                                include the total value of the items)
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: false,
                              message: '' //'This is a required field'
                            }
                            /*  {
                              validator: async (_, value) => {
                                let num = 0;
                                if (num < value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('Enter valid number');
                                }
                              }
                            } */
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            step="0.01"
                            className={`${styles.margin_Top}`}
                            allowClear
                            type="number"
                            min={1}
                            placeholder="Enter value of the gift (in INR)"
                            onChange={handleValueOfGift}
                            onKeyPress={preventMinus}
                            disabled
                          />
                        </FormItem>
                      ) : (
                        <FormItem
                          name="valueOfGift"
                          label={
                            <div className={`${styles.card_title}`}>
                              Value of the gift (in INR)
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#a6a6ac',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                (Note – If you are giving more than one item,
                                include the total value of the items)
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: '' //'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                const isValid =
                                  /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(
                                    value
                                  );
                                if (parseFloat(value) <= 0) {
                                  return Promise.reject('Enter valid number');
                                } else if (isValid === value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            step="0.01"
                            className={`${styles.margin_Top}`}
                            allowClear
                            type="number"
                            min={0}
                            placeholder="Enter value of the gift (in INR)"
                            onChange={handleValueOfGift}
                            onKeyPress={preventMinus}
                          />
                        </FormItem>
                      )}
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="contextOrBusinessPurposeOfTheGift"
                        label={
                          <div className={`${styles.card_title}`}>
                            {/*  {`Enter the context or business purpose of the gift`} */}
                            Context/Business Purpose of the Gift
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (value?.trim()?.length !== 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject('Enter the context');
                              }
                            }
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the context"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'contextOrBusinessPurposeOfTheGift',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>

                  {user?.user?.roles[0] === 'cxo' ? (
                    <>
                      {' '}
                      {/* PerApproval_CEC_and_MD_Users */}
                      {/* Only for CXO user */}
                      <PerApproval_forCxoUser
                        giftValue={giftValue}
                        formValues={formValues}
                        user={user}
                        handleFormDataOnChange={handleFormDataOnChange}
                        //onChange={onChange}
                        setTimeout={setTimeout}
                        fileList={fileList}
                        handlePreview={handlePreview}
                        handleChange={handleChange}
                        previewOpen={previewOpen}
                        previewTitle={previewTitle}
                        handleCancel={handleCancel}
                        previewImage={previewImage}
                        fileListLength={fileListLength}
                        normFile={normFile}
                        uploadButton={uploadButton}
                        fileList2={fileList2}
                        handleChange2={handleChange2}
                        uploadButton2={uploadButton2}
                        fileListImage={fileListImage}
                        handleChangeImage={handleChangeImage}
                        uploadButtonImage={uploadButtonImage}
                        fileListImageLength={fileListImageLength}
                      />
                    </>
                  ) : user?.user?.roles[0] === 'sbg-head' ? (
                    <>
                      {/* Only for SBG-Head user */}
                      <PerApproval_sbgHead
                        giftValue={giftValue}
                        formValues={formValues}
                        user={user}
                        handleFormDataOnChange={handleFormDataOnChange}
                        //onChange={onChange}
                        setTimeout={setTimeout}
                        fileList={fileList}
                        handlePreview={handlePreview}
                        handleChange={handleChange}
                        previewOpen={previewOpen}
                        previewTitle={previewTitle}
                        handleCancel={handleCancel}
                        previewImage={previewImage}
                        fileListLength={fileListLength}
                        normFile={normFile}
                        uploadButton={uploadButton}
                        fileList2={fileList2}
                        handleChange2={handleChange2}
                        uploadButton2={uploadButton2}
                        fileListImage={fileListImage}
                        handleChangeImage={handleChangeImage}
                        uploadButtonImage={uploadButtonImage}
                        fileListImageLength={fileListImageLength}
                      />
                    </>
                  ) : user?.user?.roles[0] === 'cec-user' ||
                    user?.user?.roles[0] === 'md' ? (
                    <>
                      {/* Only for CEC-USER amd MD users */}
                      <PerApproval_CEC_and_MD_Users
                        giftValue={giftValue}
                        formValues={formValues}
                        user={user}
                        handleFormDataOnChange={handleFormDataOnChange}
                        //onChange={onChange}
                        setTimeout={setTimeout}
                        fileList={fileList}
                        handlePreview={handlePreview}
                        handleChange={handleChange}
                        previewOpen={previewOpen}
                        previewTitle={previewTitle}
                        handleCancel={handleCancel}
                        previewImage={previewImage}
                        fileListLength={fileListLength}
                        normFile={normFile}
                        uploadButton={uploadButton}
                        fileList2={fileList2}
                        handleChange2={handleChange2}
                        uploadButton2={uploadButton2}
                        fileListImage={fileListImage}
                        handleChangeImage={handleChangeImage}
                        uploadButtonImage={uploadButtonImage}
                        fileListImageLength={fileListImageLength}
                      />
                    </>
                  ) : giftValue < 1000 &&
                    formValues?.giftReceiverSector === 'non-government' ? (
                    ''
                  ) : formValues?.giftReceiverSector === 'non-government' &&
                    formValues?.procuredHow === 'local' ? (
                    <>
                      {/* If the user has selected non-govt official & Locally procured – Yes */}

                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="hasPreApproval"
                            label={
                              <div className={`${styles.card_title}`}>
                                {user?.user?.roles[0] === 'bu-head'
                                  ? 'Do you have pre-approval from your SBG/Functional Head?'
                                  : 'Do you have pre-approval from BU/Dept head?'}
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Radio.Group
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'hasPreApproval',
                                  e.target.value
                                )
                              }
                              //value={value}
                              className={`${styles.card_Radio}`}
                            >
                              <Row
                                className={`${styles.Radio_button_margin}`}
                                style={{ marginBottom: '10px' }}
                              >
                                <Col span={12}>
                                  <Radio value={true}>Yes</Radio>
                                </Col>
                                <Col span={12}>
                                  <Radio value={false}>No</Radio>
                                </Col>
                              </Row>
                            </Radio.Group>
                          </FormItem>
                        </div>
                      </Card>
                      {formValues?.hasPreApproval === true ? (
                        <>
                          <Card className={`${styles.cardStyles}`}>
                            <div className={`${styles.card_content}`}>
                              <FormItem
                                name="files"
                                label={
                                  <div className={`${styles.card_title}`}>
                                    <span className={`${styles.starStyles}`}>
                                      *
                                    </span>
                                    Upload proof of pre-approval
                                  </div>
                                }
                                rules={[
                                  /*  {
                                    required: fileListLength > 0 ? false : true,
                                    message: 'This is a required field'
                                  } */
                                  {
                                    validator: async (_, value) => {
                                      console.log(value);
                                      if (fileList.length > 0) {
                                        return Promise.resolve();
                                      } else {
                                        return Promise.reject(
                                          'This is a required field'
                                        );
                                      }
                                    }
                                  }
                                ]}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Dragger
                                  className={`${styles.margin_Top}`}
                                  beforeUpload={() => false}
                                  customRequest={({ onChange }) =>
                                    setTimeout(() => {
                                      onChange('ok', null);
                                    }, 0)
                                  }
                                  action="false"
                                  listType="picture-card"
                                  fileList={fileList}
                                  onPreview={handlePreview}
                                  onChange={handleChange}
                                >
                                  {fileList.length >= 8 ? null : uploadButton}
                                </Dragger>
                                <Modal
                                  open={previewOpen}
                                  title={previewTitle}
                                  footer={null}
                                  onCancel={handleCancel}
                                  width="800px"
                                >
                                  <img
                                    alt="example"
                                    style={{ width: '100%' }}
                                    src={previewImage}
                                  />
                                </Modal>
                              </FormItem>
                            </div>
                          </Card>
                          <Card className={`${styles.cardStyles}`}>
                            <div className={`${styles.card_content}`}>
                              <FormItem
                                name="hasCECApproval"
                                label={
                                  <div className={`${styles.card_title}`}>
                                    Do you have pre-approval from CEC?
                                  </div>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: 'This is a required field'
                                  }
                                ]}
                                className={`${styles.select_styles}`}
                              >
                                <Radio.Group
                                  onChange={(e) =>
                                    handleFormDataOnChange(
                                      'hasCECApproval',
                                      e.target.value
                                    )
                                  }
                                  //value={value}
                                  className={`${styles.card_Radio}`}
                                >
                                  <Row
                                    className={`${styles.Radio_button_margin}`}
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <Col span={12}>
                                      <Radio value={true}>Yes</Radio>
                                    </Col>
                                    <Col span={12}>
                                      <Radio value={false}>No</Radio>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                              </FormItem>
                            </div>
                          </Card>

                          {formValues?.hasCECApproval === true ? (
                            <Card className={`${styles.cardStyles}`}>
                              <div className={`${styles.card_content}`}>
                                <FormItem
                                  name="gift_image"
                                  label={
                                    <div className={`${styles.card_title}`}>
                                      <span className={`${styles.starStyles}`}>
                                        *
                                      </span>
                                      Upload proof of pre-approval
                                    </div>
                                  }
                                  rules={[
                                    /*  {
                                      required:
                                        fileListLength > 0 ? false : true,
                                      message: 'This is a required field'
                                    } */
                                    {
                                      validator: async (_, value) => {
                                        console.log(value);
                                        if (fileList.length > 0) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            'This is a required field'
                                          );
                                        }
                                      }
                                    }
                                  ]}
                                  valuePropName="fileList"
                                  getValueFromEvent={normFile}
                                >
                                  <Dragger
                                    className={`${styles.margin_Top}`}
                                    beforeUpload={() => false}
                                    customRequest={({ onChange }) =>
                                      setTimeout(() => {
                                        onChange('ok', null);
                                      }, 0)
                                    }
                                    action="false"
                                    listType="picture-card"
                                    fileList={fileListImage}
                                    onPreview={handlePreview}
                                    onChange={handleChangeImage}
                                    type="file"
                                    //accept="image/*"
                                  >
                                    {fileList.length >= 8
                                      ? null
                                      : uploadButtonImage}
                                  </Dragger>
                                  <Modal
                                    open={previewOpen}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                    //width="800px"
                                  >
                                    <img
                                      alt="example"
                                      style={{ width: '100%' }}
                                      src={previewImage}
                                    />
                                  </Modal>
                                </FormItem>
                              </div>
                            </Card>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : formValues?.giftReceiverSector === 'non-government' &&
                    formValues?.procuredHow === 'tpl' ? (
                    <>
                      {/* If the user has selected non-govt official & Locally procured – No */}

                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="hasPreApproval"
                            label={
                              <div className={`${styles.card_title}`}>
                                {user?.user?.roles[0] === 'bu-head'
                                  ? 'Do you have pre-approval from your SBG/Functional Head?'
                                  : 'Do you have pre-approval from BU/Dept head?'}
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Radio.Group
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'hasPreApproval',
                                  e.target.value
                                )
                              }
                              //value={value}
                              className={`${styles.card_Radio}`}
                            >
                              <Row
                                className={`${styles.Radio_button_margin}`}
                                style={{ marginBottom: '10px' }}
                              >
                                <Col span={12}>
                                  <Radio value={true}>Yes</Radio>
                                </Col>
                                <Col span={12}>
                                  <Radio value={false}>No</Radio>
                                </Col>
                              </Row>
                            </Radio.Group>
                          </FormItem>
                        </div>
                      </Card>
                      {formValues?.hasPreApproval === true ? (
                        <>
                          <Card className={`${styles.cardStyles}`}>
                            <div className={`${styles.card_content}`}>
                              <FormItem
                                name="files"
                                label={
                                  <div className={`${styles.card_title}`}>
                                    <span className={`${styles.starStyles}`}>
                                      *
                                    </span>
                                    Upload proof of pre-approval
                                  </div>
                                }
                                rules={[
                                  /* {
                                    required: fileListLength > 0 ? false : true,
                                    message: 'This is a required field'
                                  } */
                                  {
                                    validator: async (_, value) => {
                                      console.log(value);
                                      if (fileList.length > 0) {
                                        return Promise.resolve();
                                      } else {
                                        return Promise.reject(
                                          'This is a required field'
                                        );
                                      }
                                    }
                                  }
                                ]}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Dragger
                                  className={`${styles.margin_Top}`}
                                  beforeUpload={() => false}
                                  customRequest={({ onChange }) =>
                                    setTimeout(() => {
                                      onChange('ok', null);
                                    }, 0)
                                  }
                                  action="false"
                                  listType="picture-card"
                                  fileList={fileList}
                                  onPreview={handlePreview}
                                  onChange={handleChange}
                                >
                                  {fileList.length >= 8 ? null : uploadButton}
                                </Dragger>
                                <Modal
                                  open={previewOpen}
                                  title={previewTitle}
                                  footer={null}
                                  onCancel={handleCancel}
                                  width="800px"
                                >
                                  <img
                                    alt="example"
                                    style={{ width: '100%' }}
                                    src={previewImage}
                                  />
                                </Modal>
                              </FormItem>
                            </div>
                          </Card>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      {/* If the user has selected non-govt official & Locally procured – No */}

                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="hasPreApproval"
                            label={
                              <div className={`${styles.card_title}`}>
                                {user?.user?.roles[0] === 'bu-head'
                                  ? 'Do you have pre-approval from your SBG/Functional Head?'
                                  : 'Do you have pre-approval from BU/Dept head?'}
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Radio.Group
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'hasPreApproval',
                                  e.target.value
                                )
                              }
                              //value={value}
                              className={`${styles.card_Radio}`}
                            >
                              <Row
                                className={`${styles.Radio_button_margin}`}
                                style={{ marginBottom: '10px' }}
                              >
                                <Col span={12}>
                                  <Radio value={true}>Yes</Radio>
                                </Col>
                                <Col span={12}>
                                  <Radio value={false}>No</Radio>
                                </Col>
                              </Row>
                            </Radio.Group>
                          </FormItem>
                        </div>
                      </Card>
                      {formValues?.hasPreApproval === true ? (
                        <>
                          {/* ---------------------- */}
                          <Card className={`${styles.cardStyles}`}>
                            <div className={`${styles.card_content}`}>
                              <FormItem
                                name="files"
                                label={
                                  <div className={`${styles.card_title}`}>
                                    Upload proof of pre-approval
                                  </div>
                                }
                                rules={[
                                  {
                                    required: fileListLength > 0 ? false : true,
                                    message: 'This is a required field'
                                  }
                                ]}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Dragger
                                  className={`${styles.margin_Top}`}
                                  beforeUpload={() => false}
                                  customRequest={({ onChange }) =>
                                    setTimeout(() => {
                                      onChange('ok', null);
                                    }, 0)
                                  }
                                  action="false"
                                  listType="picture-card"
                                  fileList={fileList}
                                  onPreview={handlePreview}
                                  onChange={handleChange}
                                >
                                  {fileList.length >= 8 ? null : uploadButton}
                                </Dragger>
                                <Modal
                                  open={previewOpen}
                                  title={previewTitle}
                                  footer={null}
                                  onCancel={handleCancel}
                                  width="800px"
                                >
                                  <img
                                    alt="example"
                                    style={{ width: '100%' }}
                                    src={previewImage}
                                  />
                                </Modal>
                              </FormItem>
                            </div>
                          </Card>
                          <Card className={`${styles.cardStyles}`}>
                            <div className={`${styles.card_content}`}>
                              <FormItem
                                name="hasMDApproval"
                                label={
                                  <div className={`${styles.card_title}`}>
                                    Do you have pre-approval from MD/MD
                                    Delegate?
                                  </div>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: 'This is a required field'
                                  }
                                ]}
                                className={`${styles.select_styles}`}
                              >
                                <Radio.Group
                                  onChange={(e) =>
                                    handleFormDataOnChange(
                                      'hasMDApproval',
                                      e.target.value
                                    )
                                  }
                                  //value={value}
                                  className={`${styles.card_Radio}`}
                                >
                                  <Row
                                    className={`${styles.Radio_button_margin}`}
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <Col span={12}>
                                      <Radio value={true}>Yes</Radio>
                                    </Col>
                                    <Col span={12}>
                                      <Radio value={false}>No</Radio>
                                    </Col>
                                  </Row>
                                </Radio.Group>
                              </FormItem>
                            </div>
                          </Card>

                          {formValues?.hasMDApproval === true ? (
                            <Card className={`${styles.cardStyles}`}>
                              <div className={`${styles.card_content}`}>
                                <FormItem
                                  name="files2"
                                  label={
                                    <div className={`${styles.card_title}`}>
                                      <span className={`${styles.starStyles}`}>
                                        *
                                      </span>
                                      Upload proof of pre-approval
                                    </div>
                                  }
                                  /*  rules={[
                                    {
                                      required:
                                        fileList2.length > 0 ? false : true,
                                      message: 'This is a required field'
                                    }
                                  ]} */
                                  rules={[
                                    {
                                      validator: async (_, value) => {
                                        console.log(value);
                                        if (fileList2.length > 0) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            'This is a required field'
                                          );
                                        }
                                      }
                                    }
                                  ]}
                                  valuePropName="fileList"
                                  getValueFromEvent={normFile}
                                >
                                  <Dragger
                                    className={`${styles.margin_Top}`}
                                    beforeUpload={() => false}
                                    customRequest={({ onChange }) =>
                                      setTimeout(() => {
                                        onChange('ok', null);
                                      }, 0)
                                    }
                                    action="false"
                                    listType="picture-card"
                                    fileList={fileList2}
                                    onPreview={handlePreview}
                                    onChange={handleChange2}
                                  >
                                    {fileList2.length >= 8
                                      ? null
                                      : uploadButton2}
                                  </Dragger>
                                  <Modal
                                    open={previewOpen}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                    width="800px"
                                  >
                                    <img
                                      alt="example"
                                      style={{ width: '100%' }}
                                      src={previewImage}
                                    />
                                  </Modal>
                                </FormItem>
                              </div>
                            </Card>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Card>
              )}
              {current === 0 && (
                <Row>
                  <Button
                    className={`${styles.cancel_button}`}
                    htmlType="submit"
                    size="middle"
                    onClick={() => history.push('/declare-a-gift')}
                  >
                    Cancel
                  </Button>
                  <Button
                    //loading={declare_gift_formLoading}
                    // name="submit"
                    className={`${styles.submit_button}`}
                    // htmlType="submit"
                    size="middle"
                    onClick={handleClickNext}
                  >
                    Next
                  </Button>
                </Row>
              )}
              {current === 1 && (
                <Row>
                  <Button
                    className={`${styles.cancel_button}`}
                    // htmlType="submit"
                    size="middle"
                    onClick={handleClickBack}
                  >
                    Back
                  </Button>

                  <Button
                    loading={declare_gift_formLoading}
                    name="submit"
                    className={`${styles.submit_button}`}
                    htmlType="submit"
                    size="middle"
                    // onClick={submitHandler}
                  >
                    Submit
                  </Button>
                </Row>
              )}
            </div>
          </Form>
        </div>
        {governmentOfficialModal ? (
          <GovernmentOfficial_Modal
            governmentOfficialModal={governmentOfficialModal}
            setGovernmentOfficialModal={setGovernmentOfficialModal}
          />
        ) : (
          ''
        )}
      </div>
    </Spin>
  );
}

export default DeclareGiftFormPage;

import React, { useState, useEffect } from 'react'; //, useMemo
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Space, Row, Input } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import { create_ro_master } from '../../../../redux/slice';
//import { debounce } from 'lodash';
import FormData from 'form-data';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

//const { TextArea } = Input;
//const { Option } = Select;

function EditROMasterFlows() {
  TabTitle('Gift Register - Edit BU Master Flow');

  const params = useParams();

  const roRecord = LocalStorage.getItem('ro_record');
  console.log('roRecord%%%%%%%%%', roRecord);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  // const [allUsersSearch, setAllUsersSearch] = useState('');

  const { create_ro_masterLoading } = useSelector((state) => state.admin);
  //console.log('all_users_List', all_users_List);

  useEffect(() => {
    form.setFieldsValue({
      locationName: roRecord?.location,
      locationAddress: roRecord?.locationAddress,
      updatedAt: roRecord?.updatedAt,
      createdAt: roRecord?.createdAt,
      id: roRecord?.id
    });
  }, []);

  /* useEffect(() => {
    const payload = {};
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]); */

  /*  const debounceFetcherUserId = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []); */

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    let formdata = new FormData();
    formdata.append('id', params?.id);
    formdata.append(
      'locationName',
      formValues?.locationName === undefined
        ? roRecord?.location
        : formValues?.locationName
    );

    formdata.append(
      'locationAddress',
      formValues?.locationAddress === undefined
        ? roRecord?.locationAddress
        : formValues?.locationAddress
    );

    dispatch(create_ro_master(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/ro-master-flow');
      }
    });
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <Row className={`${styles.row_container_setting}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title_setting}`}>
              Edit RO Location
            </div>
          </div>
        </Row>{' '}
        <div className={`${styles.container}`}>
          <div className={`${styles.cardContainer}`}>
            <Card className={`${styles.cardStyles_Container}`}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className={`${styles.formstyles}`}
              >
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Enter the Location
                        </div>
                      }
                      name="locationName"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Input
                        className={`${styles.margin_Top}`}
                        allowClear
                        placeholder="Enter the Location"
                        onChange={(e) =>
                          handleFormDataOnChange('locationName', e.target.value)
                        }
                      />
                    </FormItem>
                  </div>
                </Card>

                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Enter the Location Address
                        </div>
                      }
                      name="locationAddress"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Input
                        className={`${styles.margin_Top}`}
                        allowClear
                        placeholder="Enter the Location Address"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'locationAddress',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </div>
                </Card>

                <div style={{ marginTop: '20px' }}>
                  <FormItem>
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        justifyItems: ' center'
                      }}
                    >
                      <Button
                        loading={create_ro_masterLoading}
                        className={`${styles.ExceptionalApproval_button}`}
                        htmlType="submit"
                        size="small"
                      >
                        Save
                      </Button>

                      <Button
                        className={`${styles.cancel_rejectModal_button}`}
                        size="small"
                        onClick={() => history.push('/ro-master-flow')}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </FormItem>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
}
export default EditROMasterFlows;

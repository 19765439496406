import React, { useState } from 'react';
import styles from './index.module.less';
import { Row, Col, Card, Empty, Tooltip, Space, Button } from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { EditOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import EditLimitValueModal from './edit_limit_value_Modal';
import AllExportReports from '../reports/AllExportReports';
//import moment from 'moment';
//import { Value_of_gift } from '../../../../utilities/helpers';

function LimitMasterFlowPage({
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  valueLimits_list,
  pagination,
  get_value_limits_listLoading,
  setSearchRequest,
  //showDisablePopup,
  //setShowDisablePopup,
  handleGetAllEntryDelegatorsList
  //handleDeleteRequest
}) {
  const [openLimitValueModal, setOpenLimitValueModal] = useState(false);
  const [limitValueData, setLimitValueData] = useState({});
  const valueLimits_list1 = valueLimits_list?.map((item) => ({
    key: item?.id,
    gift_limit: item?.label,
    value: item?.value,
    name: item?.name
  }));

  const [isAllExortModalOpen, setAllExortModalOpen] = useState(false);

  const columns = [
    {
      title: 'Gift Limit',
      dataIndex: 'gift_limit',
      key: 'gift_limit',
      width: 400,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.gift_limit?.localeCompare(b?.gift_limit),
      render: (gift_limit) => {
        return (
          <>
            <Tooltip
              title={gift_limit}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <a href={`view-gift-received-details/${record.id}`}> */}
              <span className={styles.text_transform}>
                {' '}
                {gift_limit === null ? '' : gift_limit}
              </span>
              {/*  </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Limit Value',
      dataIndex: 'value',
      key: 'value',
      //width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.value?.localeCompare(b?.value),
      render: (value) => {
        return (
          <>
            <Tooltip
              title={value}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {value === null ? '' : value}
            </Tooltip>
          </>
        );
      }
    },
    /*  {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      //width: '120px',
      ellipsis: true,
      //sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === true
              ? 'green'
              : record?.status === false
              ? 'red'
              : 'transparent'
          }
        >
          {record?.status === true ? 'ACTIVE' : 'DEACTIVATED'}
        </Tag>
      )
    }, */
    {
      title: 'Action',
      key: '',
      //width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <a
              className={`${styles.allocation_btn}`}
              style={{
                fontSize: '12px'
              }}
              type="link"
              onClick={() => {
                setOpenLimitValueModal(true);
                setLimitValueData(record);
              }}
              /*  handleDeleteRequest({
                  record: record
                });
              }} */
              /*  onConfirm={() => {
                handleDeleteRequest({
                  record: record
                });
              }} */
            >
              <Tooltip
                title="Edit Limit Value"
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                <EditOutlined className={`${styles.edit_icon}`} />
              </Tooltip>
            </a>
            {/*  <Popconfirm
              placement="top"
              title="Are you sure you want to edit this limit value?"
              open={visibility(record)}
              onConfirm={() => {
                handleDeleteRequest({
                  record: record
                });
              }}
              onCancel={() => {
                setShowDisablePopup({});
              }}
              okText="Deactivate"
              cancelText="No"
              okButtonProps={{ type: 'danger' }}
            >
              <a
                className={`${styles.allocation_btn}`}
                style={{
                  fontSize: '12px'
                }}
                type="link"
                disabled={record?.status === false}
              >
                Edit Limit Value
              </a>
            </Popconfirm> */}
          </>
        </Space>
      )
    }
  ];

  // const visibility = useCallback((record) => {
  //   return showDisablePopup[record.id];
  // }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title}`}>
              Configure Limit Master
            </div>
            <div className={`${styles.myGift_text}`}>
              View and manage limit value
            </div>
          </div>
          <div className={`${styles.declareGift_div}`}>
            <Row>
              <Button
                className={`${styles.declareGift_button}`}
                onClick={() => setAllExortModalOpen(true)}
              >
                Export
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                className={`${styles.declareGift_button}`}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push('/admin')}
              >
                Back
              </Button>
            </Row>
          </div>
        </Row>
        <div className={`${styles.tabs_container}`}>
          <Row>
            <Col span={8}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by gift limit'
                }}
              />
            </Col>
          </Row>
        </div>
        <Card className={`${styles.cardStyles}`}>
          <div className={`${styles.table_container}`}>
            <CustomTable
              showSorterTooltip={false}
              columns={columns}
              dataSource={valueLimits_list1}
              locale={locale}
              loading={get_value_limits_listLoading}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
        </Card>
        {openLimitValueModal ? (
          <EditLimitValueModal
            openLimitValueModal={openLimitValueModal}
            setOpenLimitValueModal={setOpenLimitValueModal}
            limitValueData={limitValueData}
            handleGetAllEntryDelegatorsList={handleGetAllEntryDelegatorsList}
          />
        ) : (
          ''
        )}
        {isAllExortModalOpen ? (
          <AllExportReports
            isAllExortModalOpen={isAllExortModalOpen}
            setAllExortModalOpen={setAllExortModalOpen}
            apiName={'limits_master'}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
export default LimitMasterFlowPage;

import apiClient from 'utilities/apiClient';

export const received_gifts_list_request = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.received_gifts_list_request}`,
    payload,
    true
  );
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.requester}/${url}`, payload, true);
};

export const delete_draft = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.requester}/${url}`, payload, true);
};

import { useState } from 'react';
import { Image, Layout, Menu } from 'antd';
import {
  HomeOutlined,
  GiftOutlined,
  // FileAddOutlined,
  // FileProtectOutlined,
  FileTextOutlined,
  DashboardOutlined,
  // MailOutlined,
  // SettingOutlined
  MenuOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { history } from 'app/history';
import styles from './index.module.less';
import UserMenu from './components/UserMenu';
import { getLoggedInUser } from '../../utilities/helpers';
import tataPro from './images/tatalogo.svg';
// import gift_requests from '../../assets/images/gift_requests.png';
// import gift_received from '../../assets/images/gift_received.png';
import gift_returns from '../../assets/images/gift_returns.png';
import settings from '../../assets/images/settings.png';
import LeftDrawer from './components/LeftDrawer';

const { Header, Content } = Layout;

/* const menuData = [
  {
    key: 'welcome',
    path: '/welcome',
    icon: <HomeOutlined />,
    label: 'Welcome'
  },
  {
    key: 'view-past-gift',
    path: '/view-past-gift',
    icon: <GiftOutlined />,
    label: 'Gift given requests' // 'My Gift Requests'
  },
  {
    key: 'view-gift-requests',
    path: '/view-gift-requests',
    icon: (
      <Image
        className={`${styles.gift_icon_size}`}
        alt="gift_requests-icon"
        src={gift_requests}
        preview={false}
      />
    ), //<GiftOutlined />,
    label: 'Review gift given requests' //'Gifts for Review'
  },
  {
    key: 'view-gift-received',
    path: '/view-gift-received',
    icon: (
      <Image
        className={`${styles.gift_received_size}`}
        alt="gift_received-icon"
        src={gift_received}
        preview={false}
      />
    ),
    label: 'Gift received requests' // 'Gifts Received'
  },
  {
    key: 'view-gift-received-request',
    path: '/view-gift-received-request',
    icon: (
      <Image
        className={`${styles.gift_icon_size}`}
        alt="gift_requests-icon"
        src={gift_requests}
        preview={false}
      />
    ),
    label: 'Review gift  received requests' // 'Review Received Gifts'
  },
  {
    key: 'view-gift-returns',
    path: '/view-gift-returns',
    icon: (
      <Image
        className={`${styles.gift_icon_size}`}
        alt="gift_returns-icon"
        src={gift_returns}
        preview={false}
      />
    ),
    label: 'Review  Gift Returns'
  }
]; */
/* const menuDataAdmin = [
  {
    key: 'admin',
    path: '/admin',
    icon: (
      <Image
        className={`${styles.gift_icon_size}`}
        alt="gift_requests-icon"
        src={settings}
        preview={false}
      />
    ),
    label: 'Settings'
  },
  {
    key: 'entry-delegator-flows',
    path: '/entry-delegator-flows',
    icon: <FileAddOutlined />,
    //  (
    //   <Image
    //     className={`${styles.gift_icon_size}`}
    //     alt="gift_requests-icon"
    //     src={entry_delegate}
    //     preview={false}
    //   />
    // ),
    label: 'Entry Delegates'
  },
  {
    key: 'review-entry-delegator-flows',
    path: '/review-entry-delegator-flows',
    icon: <FileProtectOutlined />,
    //  (
    //   <Image
    //     className={ `${styles.gift_icon_size}`}
    //     alt="gift_received-icon"
    //     src={review_delegate }
    //     preview={false}
    //   />
    // ),
    label: 'Review Delegates'
  },
  {
    key: 'reports',
    path: '/reports',
    icon: (
      <Image
        className={`${styles.gift_icon_size}`}
        alt="gift_requests-icon"
        src={settings}
        preview={false}
      />
    ),
    label: 'Reports'
  }
  
]; */
const CustomLayout = ({ children, header = true }) => {
  // const [selectedMenuKeys] = useState(
  //   history.location.pathname.replace('/', '')
  // );

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user?.roles[0];
  console.log('user_role@@@@@@@@@@@', user_role);
  const userType = user_role1?.user?.is_action_tab_visible;
  console.log('user_role@@@@@@@@@@@', user_role1);
  console.log('userType@@@@@@@@@@@', userType);

  const itemsAdmin = [
    {
      key: 'admin',
      path: '/admin',
      icon: (
        <Image
          className={`${styles.gift_icon_size}`}
          alt="gift_requests-icon"
          src={settings}
          preview={false}
        />
      ),
      label: 'Settings'
    },
    /* {
      key: 'entry-delegator-flows',
      path: '/entry-delegator-flows',
      icon: <FileAddOutlined />,
      label: 'Entry Delegates'
    },
    {
      key: 'review-entry-delegator-flows',
      path: '/review-entry-delegator-flows',
      icon: <FileProtectOutlined />,
      label: 'Review Delegates'
    }, */
    {
      key: 'reports',
      path: '/reports',
      icon: <FileTextOutlined />,
      label: 'Reports'
    },
    {
      key: 'dashboard',
      path: '/dashboard',
      icon: <DashboardOutlined />,
      label: 'Dashboard'
    }
  ];
  const items = [
    {
      key: 'welcome',
      path: '/welcome',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'My Requests',
      icon: <GiftOutlined />,
      label: 'My Requests',
      children: [
        {
          type: 'group',
          // label: 'My Requests',
          children: [
            {
              key: 'view-past-gift',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gifts given or to be given' // 'Giving Gifts'
            },
            /* {
              key: 'gift_yet_to_be_given',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gift Yet to be Given'
            }, */
            {
              key: 'view-gift-received',
              path: '/view-gift-received',
              icon: <GiftOutlined />,
              //  (
              //   <Image
              //     className={`${styles.gift_received_size}`}
              //     alt="gift_received-icon"
              //     src={gift_received}
              //     preview={false}
              //   />
              // ),
              label: 'Gifts received' // 'Receiving Gifts'
            }
          ]
        }
      ]
    },
    userType === true
      ? {
          key: 'For Action',
          icon: <GiftOutlined />,
          label: 'For Action',
          children: [
            {
              type: 'group',

              children: [
                {
                  key: 'view-gift-requests',
                  path: '/view-gift-requests',
                  icon: <GiftOutlined />,
                  //  (
                  //   <Image
                  //     className={`${styles.gift_icon_size}`}
                  //     alt="gift_requests-icon"
                  //     src={gift_requests}
                  //     preview={false}
                  //   />
                  // ),
                  label: 'Review gift given requests'
                },
                {
                  key: 'view-gift-received-request',
                  path: '/view-gift-received-request',
                  icon: <GiftOutlined />,
                  // (
                  //   <Image
                  //     className={`${styles.gift_icon_size}`}
                  //     alt="gift_requests-icon"
                  //     src={gift_requests}
                  //     preview={false}
                  //   />
                  // ),
                  label: 'Review gift received requests'
                }
              ]
            }
          ]
        }
      : ''
  ];
  const itemsCEC = [
    {
      key: 'welcome',
      path: '/welcome',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'My Requests',
      icon: <GiftOutlined />,
      label: 'My Requests',
      children: [
        {
          type: 'group',
          // label: 'My Requests',
          children: [
            {
              key: 'view-past-gift',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gifts given or to be given' // 'Giving Gifts'
            },
            /* {
              key: 'gift_yet_to_be_given',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gift Yet to be Given'
            }, */
            {
              key: 'view-gift-received',
              path: '/view-gift-received',
              icon: <GiftOutlined />,
              //  (
              //   <Image
              //     className={`${styles.gift_received_size}`}
              //     alt="gift_received-icon"
              //     src={gift_received}
              //     preview={false}
              //   />
              // ),
              label: 'Gifts received' // 'Receiving Gifts'
            }
          ]
        }
      ]
    },
    {
      key: 'For Action',
      icon: <GiftOutlined />,
      label: 'For Action',
      children: [
        {
          type: 'group',

          children: [
            {
              key: 'view-gift-requests',
              path: '/view-gift-requests',
              icon: <GiftOutlined />,
              //  (
              //   <Image
              //     className={`${styles.gift_icon_size}`}
              //     alt="gift_requests-icon"
              //     src={gift_requests}
              //     preview={false}
              //   />
              // ),
              label: 'Review gift given requests'
            },
            {
              key: 'view-gift-received-request',
              path: '/view-gift-received-request',
              icon: <GiftOutlined />,
              // (
              //   <Image
              //     className={`${styles.gift_icon_size}`}
              //     alt="gift_requests-icon"
              //     src={gift_requests}
              //     preview={false}
              //   />
              // ),
              label: 'Review gift received requests'
            }
          ]
        }
      ]
    },
    /*  {
      key: 'view-gift-returns',
      path: '/view-gift-returns',
      icon: (
        <Image
          className={`${styles.gift_icon_size}`}
          alt="gift_returns-icon"
          src={gift_returns}
          preview={false}
        />
      ),
      label: 'Review Gift Returns'
    }, */
    {
      key: 'admin',
      path: '/admin',
      icon: (
        <Image
          className={`${styles.gift_icon_size}`}
          alt="gift_requests-icon"
          src={settings}
          preview={false}
        />
      ),
      label: 'Settings'
    },
    {
      key: 'reports',
      path: '/reports',
      icon: <FileTextOutlined />,
      label: 'Reports'
    }
  ];
  const itemsRo = [
    {
      key: 'welcome',
      path: '/welcome',
      icon: <HomeOutlined />,
      label: 'Home'
    },
    {
      key: 'My Requests',
      icon: <GiftOutlined />,
      label: 'My Requests',
      children: [
        {
          type: 'group',
          // label: 'My Requests',
          children: [
            {
              key: 'view-past-gift',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gifts given or to be given' // 'Giving Gifts'
            },
            /* {
              key: 'gift_yet_to_be_given',
              path: '/view-past-gift',
              icon: <GiftOutlined />,
              label: 'Gift Yet to be Given'
            }, */
            {
              key: 'view-gift-received',
              path: '/view-gift-received',
              icon: <GiftOutlined />,
              //  (
              //   <Image
              //     className={`${styles.gift_received_size}`}
              //     alt="gift_received-icon"
              //     src={gift_received}
              //     preview={false}
              //   />
              // ),
              label: 'Gifts received' // 'Receiving Gifts'
            }
          ]
        }
      ]
    },
    {
      key: 'For Action',
      icon: <GiftOutlined />,
      label: 'For Action',
      children: [
        {
          type: 'group',

          children: [
            {
              key: 'view-gift-requests',
              path: '/view-gift-requests',
              icon: <GiftOutlined />,
              //  (
              //   <Image
              //     className={`${styles.gift_icon_size}`}
              //     alt="gift_requests-icon"
              //     src={gift_requests}
              //     preview={false}
              //   />
              // ),
              label: 'Review gift given requests'
            },
            {
              key: 'view-gift-received-request',
              path: '/view-gift-received-request',
              icon: <GiftOutlined />,
              // (
              //   <Image
              //     className={`${styles.gift_icon_size}`}
              //     alt="gift_requests-icon"
              //     src={gift_requests}
              //     preview={false}
              //   />
              // ),
              label: 'Review gift received requests'
            }
          ]
        }
      ]
    },
    {
      key: 'view-gift-returns',
      path: '/view-gift-returns',
      icon: (
        <Image
          className={`${styles.gift_icon_size}`}
          alt="gift_returns-icon"
          src={gift_returns}
          preview={false}
        />
      ),
      label: 'Review Gift Returns'
    }
  ];
  const [current, setCurrent] = useState(
    history.location.pathname.replace('/', '')
  );
  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);

  const onClick = (e) => {
    console.log('click ', e);
    if (user_role === 'admin' || user_role === 'sys-admin') {
      if (e?.key === 'admin') {
        history.push('/admin');
      } else if (e?.key === 'entry-delegator-flows') {
        history.push('/entry-delegator-flows');
      } else if (e?.key === 'review-entry-delegator-flows') {
        history.push('/review-entry-delegator-flows');
      } else if (e?.key === 'reports') {
        history.push('/reports');
      } else if (e?.key === 'dashboard') {
        history.push('/dashboard');
      }
    } else if (user_role === 'cec-user') {
      if (e?.key === 'welcome') {
        history.push('/welcome');
      } else if (e?.key === 'view-past-gift') {
        history.push('/view-past-gift');
      } else if (e?.key === 'gift_yet_to_be_given') {
        history.push('/view-past-gift');
      } else if (e?.key === 'view-gift-received') {
        history.push('/view-gift-received');
      } else if (e?.key === 'view-gift-requests') {
        history.push('/view-gift-requests');
      } else if (e?.key === 'view-gift-received-request') {
        history.push('/view-gift-received-request');
      } else if (e?.key === 'view-gift-returns') {
        history.push('/view-gift-returns');
      }
      if (e?.key === 'admin') {
        history.push('/admin');
      } else if (e?.key === 'reports') {
        history.push('/reports');
      }
    } else if (user_role === 'ro') {
      if (e?.key === 'welcome') {
        history.push('/welcome');
      } else if (e?.key === 'view-past-gift') {
        history.push('/view-past-gift');
      } else if (e?.key === 'gift_yet_to_be_given') {
        history.push('/view-past-gift');
      } else if (e?.key === 'view-gift-received') {
        history.push('/view-gift-received');
      } else if (e?.key === 'view-gift-requests') {
        history.push('/view-gift-requests');
      } else if (e?.key === 'view-gift-received-request') {
        history.push('/view-gift-received-request');
      } else if (e?.key === 'view-gift-returns') {
        history.push('/view-gift-returns');
      }
    } else {
      if (e?.key === 'welcome') {
        history.push('/welcome');
      } else if (e?.key === 'view-past-gift') {
        history.push('/view-past-gift');
      } else if (e?.key === 'gift_yet_to_be_given') {
        history.push('/view-past-gift');
      } else if (e?.key === 'view-gift-received') {
        history.push('/view-gift-received');
      } else if (e?.key === 'view-gift-requests') {
        history.push('/view-gift-requests');
      } else if (e?.key === 'view-gift-received-request') {
        history.push('/view-gift-received-request');
      }
    }
    setCurrent(e?.key);
  };
  return (
    <Layout className={styles.customlayout_container}>
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <Link to={'/welcome'}>
              <Image
                alt="tata-projects-logo"
                src={tataPro}
                preview={false}
                className={`${styles.header_tata_logo}`}
              />
            </Link>
            {/*  <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={['welcome']}
              selectedKeys={[selectedMenuKeys]}
              className={`${styles.menuItems}`}
            >
              {user_role === 'admin'
                ? menuDataAdmin.map((item) => {
                    let isSelected = item?.key === selectedMenuKeys;

                    return (
                      <Menu.Item
                        key={item.key}
                        className={` ${styles.icon_margin}`}
                      >
                        <Link
                          to={item.path}
                          className={
                            isSelected
                              ? `${styles.active}`
                              : `${styles.inactive}`
                          }
                        >
                          <span className={` ${styles.icon}`}>
                            {item.icon} {item.label}
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })
                : menuData.map((item) => {
                    let isSelected = item?.key === selectedMenuKeys;

                    return (
                      <Menu.Item
                        key={item.key}
                        className={` ${styles.icon_margin}`}
                      >
                        <Link
                          to={item.path}
                          className={
                            isSelected
                              ? `${styles.active}`
                              : `${styles.inactive}`
                          }
                        >
                          <span className={` ${styles.icon}`}>
                            {item.icon} {item.label}
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })}
            </Menu> */}

            {user_role === 'admin' || user_role === 'sys-admin' ? (
              <>
                <Menu
                  onClick={onClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                  items={itemsAdmin}
                  className={`${styles.menuDropdown}`}
                  //theme="light"
                />
                {/* <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={['welcome']}
                selectedKeys={[selectedMenuKeys]}
                className={`${styles.menuItems}`}
              >
                {menuDataAdmin.map((item) => {
                  let isSelected = item?.key === selectedMenuKeys;

                  return (
                    <Menu.Item
                      key={item.key}
                      className={` ${styles.icon_margin}`}
                    >
                      <Link
                        to={item.path}
                        className={
                          isSelected ? `${styles.active}` : `${styles.inactive}`
                        }
                      >
                        <span className={` ${styles.icon}`}>
                          {item.icon} {item.label}
                        </span>
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu> */}
              </>
            ) : user_role === 'cec-user' ? (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={itemsCEC}
                className={`${styles.menuDropdown}`}
                //theme="light"
              />
            ) : user_role === 'ro' ? (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={itemsRo}
                className={`${styles.menuDropdown}`}
                //theme="light"
              />
            ) : (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
                className={`${styles.menuDropdown}`}
                //theme="light"
              />
            )}

            <UserMenu />

            <div
              className={styles.hamburger_menu}
              onClick={() => setOpenLeftDrawer(true)}
            >
              <MenuOutlined className={styles.hamburger_menu_icon} />
            </div>
            {openLeftDrawer ? (
              <LeftDrawer
                open={openLeftDrawer}
                onClose={() => setOpenLeftDrawer(false)}
              />
            ) : null}
          </Header>
        ) : null}
        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;

import apiClient from 'utilities/apiClient';

export const dashboard_counts = (payload) => {
  console.log('payload', payload);
  return apiClient.get(`${apiClient.Urls.dashboard_counts}`, payload, true);
};

export const dashboard_api = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dashboard_api}/${url}`, payload, true);
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { past_gifts_list, delete_requests, delete_draft } from './api';

const initialState = {
  pastGiftsListLoading: false,
  getAllApproverLoading: false,
  pastGiftsList: [],
  pagination: [],
  CountsPastGiftsList: {}
};

const actions = {
  GET_PAST_GIFT_LIST: 'getPastGiftsList/GET_PAST_GIFT_LIST',
  DELETE_REQUEST: 'deleteRequest/DELETE_REQUEST',
  //SEARCH_TICKET: 'searchvalues/SEARCH_TICKET',
  DELETE_DRAFT: 'deleteDraft/DELETE_DRAFT'
};

export const getPastGiftsList = createAsyncThunk(
  actions.GET_PAST_GIFT_LIST,
  async (payload) => {
    const response = await past_gifts_list(payload);
    return response;
  }
);
export const deleteRequest = createAsyncThunk(
  actions.DELETE_REQUEST,
  async (payload) => {
    const response = await delete_requests(
      `delete/gatepass?gpid=${payload.gpid}`,
      payload
    );
    return response;
  }
);

export const deleteDraft = createAsyncThunk(
  actions.DELETE_DRAFT,
  async (payload) => {
    const response = await delete_draft(
      `draft?draft_id=${payload.gpid}`,
      payload
    );
    return response;
  }
);

/* export const searchvalues = createAsyncThunk(
  actions.SEARCH_TICKET,
  async (payload) => {
    const response = await requests(
      `gatepass?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
); */

export const pastGiftsListSlice = createSlice({
  name: 'pastGifts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPastGiftsList.pending, (state) => {
        state.pastGiftsListLoading = true;
      })
      .addCase(getPastGiftsList.fulfilled, (state, action) => {
        const { counts, data, pagination } = action.payload;
        state.pastGiftsListLoading = false;
        state.pastGiftsList = data ? data : [];
        state.CountsPastGiftsList = counts ? counts : {};
        state.pagination = pagination || null;
      })
      .addCase(getPastGiftsList.rejected, (state) => {
        state.pastGiftsListLoading = false;
      });

    builder
      .addCase(deleteRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success('record deleted successfully');
        }
      })
      .addCase(deleteRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(deleteDraft.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteDraft.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success('record deleted successfully');
        }
      })
      .addCase(deleteDraft.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    /* builder
      .addCase(searchvalues.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvalues.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(searchvalues.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      }); */
  }
});
export default pastGiftsListSlice.reducer;

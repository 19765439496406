import React from 'react';
//import styles from './index.module.less';
import { Modal, Button } from 'antd';

import { apiUrl } from 'utilities/apiClient';
//import { history } from 'app/history';
//import moment from 'moment';

const AllExportReports = ({
  isAllExortModalOpen,
  setAllExortModalOpen,
  apiName
}) => {
  const handleAllUsersExportasCsv = () => () => {
    const user = JSON.parse(localStorage.getItem('GiftReg_user'));

    const authToken = user.accessToken;
    console.log(authToken);
    setAllExortModalOpen(false);
    fetch(`${apiUrl}/export-masters?masters_name=${apiName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Export-${apiName}-reports.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <Modal
      title="Export data?"
      open={isAllExortModalOpen}
      //onOk={() => setAllExortModalOpen(false)}
      onCancel={() => setAllExortModalOpen(false)}
      footer={[
        <Button key="back" onClick={() => setAllExortModalOpen(false)}>
          Cancel
        </Button>,
        <Button key="link" type="primary" onClick={handleAllUsersExportasCsv()}>
          Download
        </Button>
      ]}
    >
      <h3>Do you want to download the data?</h3>
    </Modal>
  );
};

export default AllExportReports;

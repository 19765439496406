import React from 'react';
import styles from './index.module.less';
import {
  Radio,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  Tag
  // Popconfirm,
  // Space
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { history } from 'app/history';
//import moment from 'moment';
//import { Value_of_gift } from '../../../../utilities/helpers';

const filters = [
  /*  {
    name: 'Gift Received',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  } */
];

function UserManagementFlowPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  all_users_List,
  pagination,
  get_Gift_MasterLoading,
  setSearchRequest
  //showDisablePopup
  // setShowDisablePopup,
  // handleDeleteRequest
}) {
  const all_users_List1 = all_users_List?.map((item) => ({
    key: item?.id,
    status: item?.isActive, // === true ? 'ACTIVE' : 'INACTIVE',
    givenName: item?.givenName,
    surname: item?.surname,
    email: item?.email,
    Roles: item?.Roles?.length > 0 ? item?.Roles[0]?.role : ''
  }));
  console.log('all_users_List1', all_users_List1);
  const columns = [
    {
      title: 'User Name',
      dataIndex: 'givenName',
      key: 'givenName',
      //width: 400,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.givenName?.localeCompare(b?.givenName),
      render: (givenName) => {
        return (
          <>
            <Tooltip
              title={givenName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <a href={`view-gift-received-details/${record.id}`}> */}
              <span className={styles.text_transform}>
                {' '}
                {givenName === null ? 'NA' : givenName}
              </span>
              {/*  </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname',
      //width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.surname?.localeCompare(b?.surname),
      render: (surname) => {
        return (
          <>
            <Tooltip
              title={surname}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={styles.text_transform}>
                {' '}
                {surname === null ? 'NA' : surname}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // width: 400,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      render: (email) => {
        return (
          <>
            <Tooltip
              title={email}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <a href={`view-gift-received-details/${record.id}`}> */}
              {email === null ? 'NA' : email}
              {/*  </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Role',
      dataIndex: 'Roles',
      key: 'Roles',
      // width: 400,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.Roles?.localeCompare(b?.Roles),
      render: (Roles) => {
        return (
          <>
            <Tooltip
              title={Roles}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={styles.text_transform_role}>{Roles}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      //width: '120px',
      ellipsis: true,
      //sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            status === true ? 'green' : status === false ? 'red' : 'transparent'
          }
        >
          {status === true ? 'ACTIVE' : 'DEACTIVATED'}
        </Tag>
      )
    }
    /* {
      title: 'Action',
      key: '',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <Popconfirm
              placement="top"
              title="Are you sure you want to deactivate this delegate mapping?"
              visible={visibility(record)}
              onConfirm={() => {
                handleDeleteRequest({
                  record: record
                });
              }}
              onCancel={() => {
                setShowDisablePopup({});
              }}
              okText="Deactivate"
              cancelText="No"
              okButtonProps={{ type: 'danger' }}
            >
              <a
                className={`${styles.allocation_btn}`}
                style={{
                  fontSize: '12px'
                }}
                type="link"
                disabled={record?.status === false}
              >
                Deactivate
              </a>
            </Popconfirm>
          </>
        </Space>
      )
    } */
  ];

  // const visibility = useCallback((record) => {
  //   return showDisablePopup[record.id];
  // }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title}`}>Configure Users Flow</div>
            <div className={`${styles.myGift_text}`}>View and manage users</div>
          </div>
          <div className={`${styles.declareGift_div}`}>
            <Row>
              <Button
                className={`${styles.declareGift_button}`}
                icon={<PlusOutlined />}
                onClick={() => history.push('/assign-role-to-user')}
              >
                Assign new role
              </Button>{' '}
              <Button
                style={{ marginLeft: '20px' }}
                className={`${styles.declareGift_button}`}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push('/admin')}
              >
                Back
              </Button>
            </Row>
          </div>
        </Row>
        <div className={`${styles.tabs_container}`}>
          <Row>
            {/* className={`${styles.row_container}`} */}
            <Col span={16}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      //className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={8}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by user name'
                }}
              />
            </Col>
          </Row>
        </div>
        <Card className={`${styles.cardStyles}`}>
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={all_users_List1}
              locale={locale}
              loading={get_Gift_MasterLoading}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
        </Card>
      </div>
    </>
  );
}
export default UserManagementFlowPage;
{
  /* <Row className={`${styles.row_container_setting}`}>
        <div className={`${styles.gift_container}`}>
          <div className={`${styles.myGift_title_setting}`}>
            {' '}
            Settings  {user_role?.name}! 
          </div>
           <div className={`${styles.myGift_text}`}>
              View the list of gifts and their status
            </div> 
        </div>
      </Row> */
}

import apiClient from 'utilities/apiClient';

export const admin = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
};
export const admin_post = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.admin_post}/${url}`,
    payload,
    true,
    'file'
  );
};
export const admin_patch = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.admin_post}/${url}`,
    payload,
    true,
    'file'
  );
};
/*
export const gift_master = (payload) => {
  return apiClient.get(`${apiClient.Urls.gift_master}`, payload, true);
};
export const deligators_id = (payload) => {
  return apiClient.get(`${apiClient.Urls.deligators_id}`, payload, true);
}; */

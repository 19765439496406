import RequestGiftYtbForm from './components/requestGiftYtbForm';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';
//import Demo from './components/demo';

function RequestGiftYtb() {
  TabTitle('Gift Register - Declare Gift Form');
  console.log('RequestGiftYtb-1');
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <RequestGiftYtbForm />
      </div>
    </CustomLayout>
  );
}
export default RequestGiftYtb;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { gift_getbyId, exception_requests, reject_gift } from './api';

const initialState = {
  getGiftByIdLoading: false,
  exceptionRequestsLoading: false,
  rejectGiftLoading: false,
  getGiftDetailsById: []
};

const actions = {
  GET_GIFT_BY_ID: 'getGiftById/GET_GIFT_BY_ID',
  EXCEPTION_REQUESTS: 'exceptionRequests/EXCEPTION_REQUESTS',
  REJECT_REQUESTS: 'rejectRequests/REJECT_REQUESTS'
};

export const getGiftById = createAsyncThunk(
  actions.GET_GIFT_BY_ID,
  async (payload) => {
    const response = await gift_getbyId(
      `${payload?.id}?uniqueId=${payload?.uniqueId}&userId=${payload?.userId}&hashRoot=${payload?.hashRoot}&giftType=${payload?.giftType}`
    ); //payload
    return response;
  }
);

export const exceptionRequests = createAsyncThunk(
  actions.EXCEPTION_REQUESTS,
  async (payload) => {
    const response = await exception_requests(
      `${payload?.grId}?uniqueId=${payload?.uniqueId}&userId=${payload?.userId}&hashRoot=${payload?.hashRoot}&giftType=${payload?.giftType}`,
      payload
    );
    return response;
  }
);

export const rejectRequests = createAsyncThunk(
  actions.REJECT_REQUESTS,
  async (payload) => {
    const response = await reject_gift(
      `${payload?.grId}?uniqueId=${payload?.uniqueId}&userId=${payload?.userId}&hashRoot=${payload?.hashRoot}&giftType=${payload?.giftType}`,
      payload
    );
    return response;
  }
);

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    vehicleFormData: (state, action) => {
      state.vehicleData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGiftById.pending, (state) => {
        state.getGiftByIdLoading = true;
      })
      .addCase(getGiftById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getGiftByIdLoading = false;
        state.getGiftDetailsById = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getGiftById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getGiftByIdLoading = false;
        message.error(msg);
      });
    builder
      .addCase(exceptionRequests.pending, (state) => {
        state.exceptionRequestsLoading = true;
      })
      .addCase(exceptionRequests.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.exceptionRequestsLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(exceptionRequests.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.exceptionRequestsLoading = false;

        message.error(msg);
      });
    builder
      .addCase(rejectRequests.pending, (state) => {
        state.rejectGiftLoading = true;
      })
      .addCase(rejectRequests.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.rejectGiftLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(rejectRequests.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.rejectGiftLoading = false;

        message.error(msg);
      });
  }
});
export const { vehicleFormData } = viewSlice.actions;
export default viewSlice.reducer;

import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import {
  Input,
  Form,
  Radio,
  Button,
  Card,
  Row,
  Col,
  //Upload,
  //Modal,
  Select,
  //message,
  DatePicker,
  Divider,
  Steps,
  Spin,
  Tag
  //Checkbox
  // Tooltip
} from 'antd';
import FormItem from 'common/FormItem';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import FormData from 'form-data';
import {
  declare_gift_form,
  get_gift_master
} from '../../../declare_gift_form/redux/slice';
import {
  get_deligatorsId,
  get_userDetails
} from '../../../declare_gift_form/redux/slice';
import { history } from '@/app/history';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  recipientType,
  getLoggedInUser,
  pattern
} from '../../../../utilities/helpers';
import GovernmentOfficial_Modal from '../../../declare_gift_form/components/declareGiftFormPage/governmentOfficial_Modal';
import { get_BU_Master, set_BU_mapping } from '../../../admin/redux/slice';
import { sizeChecker } from 'utilities/helpers';

const { TextArea } = Input;
//const { Dragger } = Upload;
const { Option } = Select;
const { Step } = Steps;

/* const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }); */

function RequestGiftYtbForm() {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { declare_gift_formLoading, giftMaster } = useSelector(
    (state) => state.declareGiftForm
  );
  const { deligatorsIdList, userDetails, get_userDetailsLoading } = useSelector(
    (state) => state.declareGiftForm
  );

  const { bu_MasterList, set_BU_mappingLoading, get_BU_MasterLoading } =
    useSelector((state) => state.admin);

  //const [value, setValue] = useState(1);
  const [formValues, setFormValues] = useState({});
  // const [doc, setDoc] = useState([]);
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  // const [fileListImage, setFileListImage] = useState([]);
  // const [fileList, setFileList] = useState([]);
  const [otherGift, setOtherGift] = useState('');
  const [giftSearch, setGiftSearch] = useState('');
  const [giftName, setGiftName] = useState('');
  const [recipientTypeOther, setRecipientTypeOther] = useState('');
  const [otherRecipient, setOtherRecipient] = useState('');
  const [deligatorsIdSearch, setDeligatorsIdSearch] = useState('');
  //const [draftLoading, setDraftLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [current, setCurrent] = useState(0);
  const [giftValue, setGiftValue] = useState();
  const [giftMasterOthers, setGiftMasterOthers] = useState([]);
  const [governmentOfficialModal, setGovernmentOfficialModal] = useState(false);
  const [BUSearch, setBUSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [toggleUpload, setToggleUpload] = useState(false);
  const [recall, setReCall] = useState(false);

  const user = getLoggedInUser();

  //console.log('userDetails', userDetails);
  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 1000
    };
    if (BUSearch) payload['search'] = BUSearch;
    dispatch(get_BU_Master(payload));
  }, [BUSearch]);

  const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (BUSearch) => {
      setBUSearch(BUSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  useEffect(() => {
    const giftMaster1 = [...giftMaster, { title: 'Others', id: 'others' }];
    setGiftMasterOthers(giftMaster1);
  }, [giftMaster]);

  useEffect(() => {
    const payload = {};
    if (giftSearch) payload['name'] = giftSearch;
    dispatch(get_gift_master(payload));
  }, [giftSearch]);

  useEffect(() => {
    const payload = {
      userId:
        formValues?.whom_create === true
          ? user?.user?.id
          : formValues?.delegatorId === undefined
          ? user?.user?.id
          : formValues?.delegatorId
    };
    dispatch(get_userDetails(payload));
    if (formValues?.whom_create === true) {
      formValues['delegatorId'] = undefined;
    }
  }, [formValues?.delegatorId, formValues?.whom_create, recall]);

  useEffect(() => {
    const payload = {
      delegate_id: ''
    };
    if (deligatorsIdSearch) payload['search'] = deligatorsIdSearch;
    dispatch(get_deligatorsId(payload));
  }, [deligatorsIdSearch]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (giftSearch) => {
      setGiftSearch(giftSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherDeligatorsId = useMemo(() => {
    const loadOptions = (deligatorsIdSearch) => {
      setDeligatorsIdSearch(deligatorsIdSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleOtherDescription = (e) => {
    setOtherGift(e.target.value);
  };

  const handleOtherRecipientType = (e) => {
    setOtherRecipient(e.target.value);
  };

  console.log(
    ' userDetails?.BuMaster?.id@@@@@@@@@@t',
    userDetails?.BuMaster?.id
  );
  console.log('formValues?.buId@@@@@@@@@', formValues?.buId);
  useEffect(() => {
    let name = giftMasterOthers?.filter(
      (item) => item?.id === formValues?.giftId
    );
    setGiftName(name[0]?.title);
  }, [formValues?.giftId]);

  useEffect(() => {
    if (formValues?.recipientType === 'other') {
      setRecipientTypeOther(formValues?.recipientType);
    } else {
      setRecipientTypeOther('');
    }
  }, [formValues?.recipientType]);

  const handlerBU = () => {
    let formdata = new FormData();

    formdata.append('userId', user?.user?.id);
    formdata.append('buId', formValues?.buId);

    dispatch(set_BU_mapping(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        // toggleChecked();
        setChecked(true);
        setToggleUpload(false);
        setReCall(!recall);
      }
    });
  };

  const handleFormDataOnChange = (slug, value) => {
    //console.log('slug,value', slug, value);
    if (slug === 'buId') {
      setToggleUpload(true);
      setChecked(false);
    }
    if (slug === 'whom_create' && value === true) {
      form.setFieldsValue({
        delegatorId: undefined
      });
    }
    if (slug === 'isGiftEmbossed') {
      form.setFieldsValue({
        procuredHow: undefined
      });
    }

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = (values) => {
    console.log('valuesITEMS', values);
    //setDraftLoading(true);
    let formdata = new FormData();

    formdata.append('type', 'gytb');

    /*  for (let i in fileListImage) {
      formdata.append(
        'giftImages',
        fileListImage?.length === 0 ? '' : fileListImage[i]?.originFileObj
      );
    } */

    formdata.append(
      'BuMasterId',
      formValues?.buId === undefined
        ? userDetails?.BuMaster?.id
        : formValues?.buId
    );
    formdata.append(
      'delegatorId',
      formValues?.whom_create === true ? '' : formValues?.delegatorId
    );

    formdata.append(
      'giftReceiverSector',
      formValues?.giftReceiverSector === undefined
        ? ''
        : formValues?.giftReceiverSector
    );
    formdata.append(
      'recipientName',
      formValues?.recipientName === undefined ? '' : formValues?.recipientName
    );
    formdata.append(
      'organizationName',
      formValues?.organizationName === undefined
        ? ''
        : formValues?.organizationName
    );
    formdata.append(
      'designation',
      formValues?.designation === undefined ? '' : formValues?.designation
    );

    formdata.append(
      'recipientType',
      formValues?.recipientType === 'other'
        ? `Others - ${otherRecipient}`
        : formValues?.recipientType === undefined
        ? ''
        : formValues?.recipientType
    );
    /*  formdata.append(
      'noOfGiftArticles',
      formValues?.noOfGiftArticles === undefined
        ? ''
        : formValues?.noOfGiftArticles
    ); */

    formdata.append(
      'dateOfGiftReceived',
      formValues?.dateOfGiftReceived === undefined
        ? ''
        : moment(formValues?.dateOfGiftReceived).format()
    );

    formdata.append(
      'giftReceivedByFamily',
      formValues?.giftReceivedByFamily === undefined
        ? ''
        : formValues?.giftReceivedByFamily
    );
    formdata.append(
      'giftId',
      giftName === 'Others' ? 'others' : formValues?.giftId
    );
    formdata.append(
      'giftName',
      giftName === 'Others' ? `Others – ${otherGift}` : giftName
    );
    /* formdata.append(
      'valueOfGift',
      formValues?.valueOfGift === undefined ? '' : formValues?.valueOfGift
    ); */
    formdata.append(
      'valueOfGift',
      giftValue === undefined ||
        formValues?.giftId === 'd0df4905-a386-4868-8ef5-213b014ab967'
        ? 0
        : giftValue
    );
    formdata.append(
      'isGiftEmbossed',
      formValues?.isGiftEmbossed === undefined ? '' : formValues?.isGiftEmbossed
    );
    formdata.append(
      'contextOrBusinessPurposeOfTheGift',
      formValues?.contextOrBusinessPurposeOfTheGift === undefined
        ? ''
        : formValues?.contextOrBusinessPurposeOfTheGift
    );
    formdata.append(
      'giftDescription',
      formValues?.giftDescription === undefined
        ? ''
        : formValues?.giftDescription
    );
    formdata.append(
      'procuredHow',
      formValues?.procuredHow === undefined ? '' : formValues?.procuredHow
    );
    /*  formdata.append(
      'approval1',
      formValues?.hasPreApproval === undefined ? '' : formValues?.hasPreApproval
    );
    formdata.append(
      'approval2',
      formValues?.hasMDApproval === undefined ? '' : formValues?.hasMDApproval
    );
    formdata.append(
      'finalApproval',
      formValues?.hasPreApproval === true && formValues?.hasMDApproval === true
        ? true
        : false
    ); //'pre_approval_status', */
    formdata.append(
      'wasThereOngoingTender',
      formValues?.wasThereOngoingTender === undefined
        ? ''
        : formValues?.wasThereOngoingTender
    );

    formValues?.wasThereOngoingTender === true
      ? formdata.append(
          'ongoingTenderDiscussion',
          formValues?.tenderDetails === undefined
            ? ''
            : formValues?.tenderDetails
        )
      : '';
    formValues?.wasThereOngoingTender === true
      ? formdata.append(
          'businessJustificationForWhyThisGiftWasGivenDuringOngoingTender',
          formValues?.tenderDetails2 === undefined
            ? ''
            : formValues?.tenderDetails2
        )
      : '';
    /*  formdata.append(
      'additionalComments',
      formValues?.additionalComments === undefined
        ? ''
        : formValues?.additionalComments
    ); */
    /* for (let i in fileList) {
      formdata.append(
        'supportingDocs',
        fileList?.length === 0 ? '' : fileList[i]?.originFileObj
      );
    } */
    dispatch(declare_gift_form(formdata)).then(function (response) {
      if (response?.payload?.success) {
        history.push('/view-past-gift');
      } else {
        //message.error(response.payload.message);
      }
    });
  };

  /* const normFile = (e) => {
    setDoc(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  console.log('setDoc', doc);
  console.log('fileList', fileList);
  console.log('fileListImage', fileListImage);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleChangeImage = ({ fileList: newFileList }) =>
    setFileListImage(newFileList);
  const uploadButton = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  );
  const uploadButtonImage = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        SVG, PNG, JPG or GIF (max. 800x400px)
      </p>
    </div>
  );
 */
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const onScroll = () => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    // This will calculate the final total of the percentage of how much the user has scrolled.
    const scrolled = (winScroll / height) * 100;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    // Fires when the document view has been scrolled
    window.addEventListener('scroll', onScroll);

    //
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClickNext = () => {
    if (formValues?.buId === undefined) {
      form.setFieldsValue({
        buId: userDetails?.BuMaster?.id
      });
    } else {
      form.setFieldsValue({
        buId: formValues?.buId
      });
    }

    form
      .validateFields()
      .then(() => {
        // Here make api call of something else
        setCurrent(current + 1);
        window?.scrollTo(0, 0);
      })
      .catch((err) => console.log(err));
  };
  const handleClickBack = () => {
    setCurrent(current - 1);
  };

  const handleValueOfGift = (e) => {
    // console.log('e.target.value', e.target.value);
    setGiftValue(e.target.value);
  };
  return (
    <Spin spinning={get_userDetailsLoading}>
      <div className={`${styles.App}`}>
        <div className={`${styles.progressMainWrapper}`}>
          <div
            className={`${styles.progressMainStyle}`}
            style={{ width: `${scrollTop}%` }}
          ></div>
        </div>
        <div className={`${styles.container_stepper}`}>
          <div className={`${styles.container_stepper_div}`}>
            <Steps current={current} size="small">
              <Step key={0} title={`Recipient Details`} />
              <Step key={1} title="Gift Details" />
            </Steps>
          </div>
        </div>
        <div className={`${styles.container}`}>
          <div className={`${styles.title}`}>I want to give a gift</div>
          <div className={`${styles.subTitle}`}>
            Please fill the gift details below
          </div>
          <div className={`${styles.bulk_upload}`}>
            <a className={`${styles.bulk_upload_a}`} href="/bulk-upload">
              Bulk Upload
            </a>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
            autoComplete="off"
          >
            <div
              style={{ marginTop: '30px', width: '100%', marginBottom: '20px' }}
            >
              {current === 0 && (
                <>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.title_section}`}>
                      {`Requester Details`}
                    </div>
                    <Divider className={`${styles.divider_margin}`} />
                    <Card className={`${styles.cardStyles}`}>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div>
                            Date of reporting of the gift:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.dateOfReporting === null
                                ? 'NA'
                                : moment(userDetails?.dateOfReporting).format(
                                    'DD MMM YYYY'
                                  )}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee name:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.givenName === null
                                ? 'NA'
                                : userDetails?.givenName}{' '}
                              {userDetails?.surname}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee email:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.email === null
                                ? 'NA'
                                : userDetails?.email}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee ID:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.empId === null
                                ? 'NA'
                                : userDetails?.empId}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee designation:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.designation === null
                                ? 'NA'
                                : userDetails?.designation}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            {' '}
                            Employee department/function:{' '}
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.BuMaster?.buName === null
                                ? 'NA'
                                : userDetails?.BuMaster?.buName}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Location:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.location === null
                                ? 'NA'
                                : userDetails?.location}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            {' '}
                            Employee manager:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.ReportingManager?.User
                                ?.givenName === null
                                ? 'NA'
                                : userDetails?.ReportingManager?.User
                                    ?.givenName}{' '}
                              {userDetails?.ReportingManager?.User?.surname}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="whom_create"
                          label={
                            <div className={`${styles.card_title}`}>
                              Are you creating this for yourself?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'whom_create',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Self</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>Someone else</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    {formValues?.whom_create === false ? (
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Please select the delegator from the list below
                              </div>
                            }
                            name="delegatorId"
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              className={`${styles.margin_Top}`}
                              style={{ marginBottom: '15px' }}
                              showSearch
                              allowClear={true}
                              optionFilterProp="children"
                              placeholder="Select delegator"
                              //onSearch={inputVenderName}
                              onSearch={debounceFetcherDeligatorsId}
                              onChange={(value) =>
                                handleFormDataOnChange('delegatorId', value)
                              }
                              filterOption={(input, option) => {
                                return (
                                  option.key
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option.title
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {deligatorsIdList?.map((item, index) => (
                                <Option
                                  title={item?.GiftEntryDelegator?.givenName}
                                  key={index}
                                  value={item?.GiftEntryDelegator?.id}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      textTransform: 'uppercase',
                                      fontFamily: 'SFProText Regular'
                                    }}
                                  >
                                    <span style={{ marginLeft: '10px' }}>
                                      {item?.GiftEntryDelegator?.givenName} (
                                      <span
                                        style={{ textTransform: 'lowercase' }}
                                      >
                                        {item?.GiftEntryDelegator?.email}
                                      </span>
                                      )
                                    </span>
                                  </span>
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </Card>
                    ) : (
                      ''
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="buInfo"
                          label={
                            <div className={`${styles.card_title}`}>
                              Is the above mentioned BU/Function information
                              correct?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange('buInfo', e.target.value)
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    <Spin spinning={set_BU_mappingLoading}>
                      {formValues?.buInfo === false ? (
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              label={
                                <div className={`${styles.card_title}`}>
                                  Please select the BU
                                </div>
                              }
                              name="buId"
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              <Spin spinning={get_BU_MasterLoading}>
                                <Select
                                  className={`${styles.margin_Top}`}
                                  style={{ marginBottom: '15px' }}
                                  showSearch
                                  allowClear={true}
                                  optionFilterProp="children"
                                  placeholder="Search and select your BU"
                                  onSearch={debounceFetcherAllUsers}
                                  //onChange={handlerSelectedBU}
                                  onChange={(value) =>
                                    handleFormDataOnChange('buId', value)
                                  }
                                  filterOption={(input, option) => {
                                    return (
                                      option.key
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0 ||
                                      option.title
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {bu_MasterList?.map((item, index) => (
                                    <Option
                                      title={item?.buName}
                                      key={index}
                                      value={item?.id}
                                    >
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          textTransform: 'uppercase',
                                          fontFamily: 'SFProText Regular'
                                        }}
                                      >
                                        <span style={{ marginLeft: '10px' }}>
                                          {item?.buName}
                                          {/*  (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  ) */}
                                        </span>
                                      </span>
                                    </Option>
                                  ))}
                                </Select>
                              </Spin>
                            </FormItem>
                            <a
                              onClick={handlerBU}
                              className={`${styles.buUpload}`}
                              disabled={toggleUpload === false}
                            >
                              {checked === false && toggleUpload === false ? (
                                <Tag color="default">UPDATE</Tag>
                              ) : checked === false && toggleUpload === true ? (
                                <Tag color="processing">UPDATE</Tag>
                              ) : (
                                <Tag
                                  icon={<CheckCircleOutlined />}
                                  color="success"
                                >
                                  UPDATED
                                </Tag>
                              )}
                            </a>
                            {/*  {checked === true && (
                            <p
                              style={{
                                marginBottom: '20px',
                                float: 'right',
                                marginRight: '2px'
                              }}
                            >
                              <Checkbox
                                checked={checked}
                                //  onChange={onChange}
                              ></Checkbox>
                            </p>
                          )} */}
                          </div>
                        </Card>
                      ) : (
                        ''
                      )}
                    </Spin>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.title_section}`}>
                      {`Recipient Details`}
                    </div>
                    <Divider className={`${styles.divider_margin}`} />

                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="recipientName"
                          label={
                            <div className={`${styles.card_title}`}>
                              {`Recipient Full Name`}
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter Recipient Full Name'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            //allowClear
                            controls={true}
                            placeholder="Enter the recipient full name"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'recipientName',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="organizationName"
                          label={
                            <div className={`${styles.card_title}`}>
                              {`Recipient Organization Full Name`}
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter Recipient Organization Full Name'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the full name of the organization"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'organizationName',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="designation"
                          label={
                            <div
                              className={`${styles.card_title}`}
                            >{`Recipient Designation`}</div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter Recipient Designation'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the designation of the recipient"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'designation',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Recipient Stakeholder Type
                            </div>
                          }
                          name="recipientType"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select recipient stakeholder type"
                            //onSearch={inputVenderName}
                            onSearch={debounceFetcher}
                            onChange={(value) =>
                              handleFormDataOnChange('recipientType', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {recipientType?.map((item, index) => (
                              <Option
                                title={item.label}
                                key={index}
                                value={item?.value}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  <span style={{ marginLeft: '10px' }}>
                                    {item?.label}
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </div>
                    </Card>
                    {recipientTypeOther === 'other' && (
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="recipientType_other"
                            label={
                              <div className={`${styles.card_title}`}>
                                Other
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              },
                              {
                                validator: async (_, value) => {
                                  if (value?.trim()?.length !== 0) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      'Enter stakeholder type'
                                    );
                                  }
                                }
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              className={`${styles.margin_Top}`}
                              allowClear
                              placeholder="Enter stakeholder type"
                              onChange={handleOtherRecipientType}
                            />
                          </FormItem>
                        </div>
                      </Card>
                    )}

                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftReceiverSector"
                          label={
                            <div className={`${styles.card_title}`}>
                              {/* What best describes the recipient of this gift? */}
                              Is the recipient a government official?
                              <a
                                onClick={() => setGovernmentOfficialModal(true)}
                              >
                                <InfoCircleOutlined
                                  style={{
                                    marginLeft: '2px',
                                    fontSize: '16px'
                                  }}
                                />
                              </a>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'giftReceiverSector',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={'government'}>
                                  Yes {/* Government Official */}
                                </Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={'non-government'}>
                                  No {/* Non-Government Official */}
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="wasThereOngoingTender"
                          label={
                            <div className={`${styles.card_title}`}>
                              {/* Was there an ongoing tender discussion when the gift was given? */}
                              Is there an ongoing tender / discussion with the
                              recipient or its company at the time of this gift?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'wasThereOngoingTender',
                                e.target.value
                              )
                            }
                            //value={value}
                            className={`${styles.card_Radio}`}
                          >
                            <Row
                              className={`${styles.Radio_button_margin}`}
                              style={{ marginBottom: '10px' }}
                            >
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    {formValues?.wasThereOngoingTender === true ? (
                      <>
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              name="tenderDetails"
                              label={
                                <div className={`${styles.card_title}`}>
                                  {/* Enter details about the tender (500 characters) */}
                                  Provide details of the ongoing
                                  tender/discussion and the role played by the
                                  recipient of the proposed gift
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter details about the tender'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter details about the ongoing tender and the role played by the recipient of this gift"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </div>
                        </Card>
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              name="tenderDetails2"
                              label={
                                <div className={`${styles.card_title}`}>
                                  {/* Enter details about the tender (500 characters) */}
                                  Please provide a business justification for
                                  why this gift was given during an ongoing
                                  tender/discussion.
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter business justification for giving this gift during an ongoing tender discussion'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter business justification for giving this gift during an ongoing tender discussion"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails2',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </div>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftReceivedByFamily"
                          label={
                            <div className={`${styles.card_title}`}>
                              {/* Who did you give this gift to? */}
                              Is this a gift to a family member of a supplier,
                              customer, or other business partner?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            // onChange={onChange}
                            // value={value}
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'giftReceivedByFamily',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>
                                  {/* To the person directly */}
                                  No
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                  </Card>
                </>
              )}
              {current === 1 && (
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.title_section}`}>Gift Details</div>
                  <Divider className={`${styles.divider_margin}`} />
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the gift from the list below
                          </div>
                        }
                        name="giftId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select gift"
                          //onSearch={inputVenderName}
                          onSearch={debounceFetcher}
                          onChange={(value) =>
                            handleFormDataOnChange('giftId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {giftMasterOthers?.map((item, index) => (
                            <Option
                              title={item.title}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.title}
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  {giftName === 'Others' && (
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftName_other"
                          label={
                            <div className={`${styles.card_title}`}>Others</div>
                          }
                          rules={[
                            {
                              required: true,
                              message: '' // 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                // const pattern =
                                //   /\b(cash|CASH|Cash|gold|GOLD|Gold|money|MONEY|Money|amount|AMOUNT|Amount)\b/;
                                const newValue = value
                                  ?.toString()
                                  ?.toLowerCase();
                                if (
                                  pattern.test(newValue)
                                  // || value?.trim()?.length === 0
                                ) {
                                  return Promise.reject(
                                    'The gift you have entered is from a restricted category. Please select it from the list above to proceed.'
                                  );
                                } else if (newValue?.trim()?.length === 0) {
                                  return Promise.reject(
                                    'This is a required field'
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                                /*  if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('Enter description');
                                } */
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter description..."
                            onChange={handleOtherDescription}
                          />
                        </FormItem>
                      </div>
                    </Card>
                  )}
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="giftDescription"
                        label={
                          <div className={`${styles.card_title}`}>
                            Describe the gift
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (value?.trim()?.length !== 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject('Enter description');
                              }
                            }
                          }
                        ]}
                        className={`${styles.textarea_styles}`}
                      >
                        <TextArea
                          className={`${styles.margin_Top}`}
                          // maxLength={500}
                          // rows={3}
                          allowClear
                          placeholder="Describe the gift you have given"
                          size="small"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'giftDescription',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="isGiftEmbossed"
                        label={
                          <div className={`${styles.card_title}`}>
                            {/* Is the gift embossed with the logo of the gift-giving
                          company? */}
                            Was the gift taken from the stock of promotion items
                            and bears the company logo?
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'isGiftEmbossed',
                              e.target.value
                            )
                          }
                          className={`${styles.card_Radio}`}
                        >
                          <Row className={`${styles.Radio_button_margin}`}>
                            <Col span={12}>
                              <Radio value={true}>Yes</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={false}>No</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="procuredHow"
                        label={
                          <div className={`${styles.card_title}`}>
                            {/* How did you procure this gift? */}
                            Was the gift procured locally?
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (
                                formValues?.isGiftEmbossed === true &&
                                value === 'local'
                              ) {
                                return Promise.reject(
                                  'You cannot select `Yes` for both this question and the one above'
                                );
                              } else if (
                                formValues?.isGiftEmbossed === false &&
                                value === 'tpl'
                              ) {
                                return Promise.reject(
                                  'You cannot select `No` for both this question and the one above'
                                );
                              } else {
                                return Promise.resolve();
                              }
                            }
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'procuredHow',
                              e.target.value
                            )
                          }
                          //value={value}
                          className={`${styles.card_Radio}`}
                        >
                          <Row className={`${styles.Radio_button_margin}`}>
                            <Col span={12}>
                              <Radio value={'local'}>Yes</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={'tpl'}>No</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            {/*  When did you give this gift? */}
                            Proposed date of gift giving
                          </div>
                        }
                        name="dateOfGiftReceived"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.date_styles}`}
                      >
                        <DatePicker
                          className={`${styles.margin_Top}`}
                          format={'DD-MMM-YYYY'}
                          onChange={(_, dateValue) =>
                            handleFormDataOnChange(
                              'dateOfGiftReceived',
                              dateValue
                            )
                          }
                          disabledDate={(current) => {
                            return (
                              moment().add(-1, 'days') >= current //||
                              //moment().add(0, 'month') <= current
                            );
                          }}
                        ></DatePicker>
                      </FormItem>
                    </div>
                  </Card>
                  {/* <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      name="valueOfGift"
                      label={
                        <div className={`${styles.card_title}`}>
                          How much is the gift worth?
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Radio.Group
                        gap={100}
                        // value={value}
                        onChange={(e) =>
                          handleFormDataOnChange('valueOfGift', e.target.value)
                        }
                        className={`${styles.card_Radio}`}
                      >
                        <Row className={`${styles.Radio_button_margin}`}>
                          <Col span={8}>
                            <Radio value={'over_5000'}>Over 5000</Radio>
                          </Col>
                          <Col span={8}>
                            <Radio value={'between_1000_and_5000'}>
                              Between 1000 and 5000
                            </Radio>
                          </Col>
                          <Col span={8}>
                            <Radio value={'under_1000'}>Under 1000</Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </FormItem>
                  </div>
                </Card> */}
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      {formValues?.giftId ===
                      'd0df4905-a386-4868-8ef5-213b014ab967' ? (
                        <FormItem
                          name="valueOfGift"
                          label={
                            <div className={`${styles.card_title}`}>
                              Value of the gift (in INR)
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#a6a6ac',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                (Note – If you are giving more than one item,
                                include the total value of the items)
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: false,
                              message: '' //'This is a required field'
                            }
                            /*  {
                              validator: async (_, value) => {
                                let num = 0;
                                if (num < value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('Enter valid number');
                                }
                              }
                            } */
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            step="0.01"
                            className={`${styles.margin_Top}`}
                            allowClear
                            type="number"
                            min={0}
                            placeholder="Enter value of the gift (in INR)"
                            onChange={handleValueOfGift}
                            onKeyPress={preventMinus}
                            disabled
                          />
                        </FormItem>
                      ) : (
                        <FormItem
                          name="valueOfGift"
                          label={
                            <div className={`${styles.card_title}`}>
                              Value of the gift (in INR)
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#a6a6ac',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                (Note – If you are giving more than one item
                                then include the total value of the items)
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: '' //'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                const isValid =
                                  /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(
                                    value
                                  );
                                if (parseFloat(value) <= 0) {
                                  return Promise.reject('Enter valid number');
                                } else if (isValid === value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.resolve();
                                }
                                /*  let num = 0;
                                if (num < value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('Enter valid number');
                                } */
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            step="0.01"
                            className={`${styles.margin_Top}`}
                            allowClear
                            type="number"
                            min={0}
                            placeholder="Enter value of the gift (in INR)"
                            onChange={handleValueOfGift}
                            onKeyPress={preventMinus}
                          />
                        </FormItem>
                      )}
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="contextOrBusinessPurposeOfTheGift"
                        label={
                          <div className={`${styles.card_title}`}>
                            {/*  {`Enter the context or business purpose of the gift`} */}
                            What is the context/business purpose of the gift
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (value?.trim()?.length !== 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject('Enter the context');
                              }
                            }
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the context"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'contextOrBusinessPurposeOfTheGift',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  {/*  <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      name="noOfGiftArticles"
                      label={
                        <div className={`${styles.card_title}`}>
                          Enter the number of gifts
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: '' // 'This is a required field'
                        },
                        {
                          validator: async (_, value) => {
                            let num = 0;
                            if (
                              num < value &&
                              value === value.replace(/[^0-9]/g, '')
                            ) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject('Enter valid number');
                            }
                          }
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        className={`${styles.margin_Top}`}
                        allowClear
                        type="number"
                        min={1}
                        placeholder="Enter the number of gifts"
                        onKeyPress={preventMinus}
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'noOfGiftArticles',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </div>
                </Card> */}

                  {/* <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        // name="files"
                        name="gift_image"
                        label={
                          <div className={`${styles.card_title}`}>
                            Upload the image of the gift
                          </div>
                        }
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                      >
                        <Dragger
                          className={`${styles.margin_Top}`}
                          beforeUpload={() => false}
                          customRequest={({ onChange }) =>
                            setTimeout(() => {
                              onChange('ok', null);
                            }, 0)
                          }
                          action="false"
                          listType="picture-card"
                          fileList={fileListImage}
                          onPreview={handlePreview}
                          onChange={handleChangeImage}
                          type="file"
                          accept="image/*"
                        >
                          {fileListImage.length >= 8 ? null : uploadButtonImage}
                        </Dragger>
                        <Modal
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                          //width="800px"
                        >
                          <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                          />
                        </Modal>
                      </FormItem>
                    </div>
                  </div>
                </Card> */}

                  {/*  <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      name="files"
                      label={
                        <div className={`${styles.card_title}`}>
                          Upload supporting documents
                        </div>
                      }
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Dragger
                        className={`${styles.margin_Top}`}
                        beforeUpload={() => false}
                        customRequest={({ onChange }) =>
                          setTimeout(() => {
                            onChange('ok', null);
                          }, 0)
                        }
                        action="false"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                      >
                        {fileList.length >= 8 ? null : uploadButton}
                      </Dragger>
                      <Modal
                        visible={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                        width="800px"
                      >
                        <img
                          alt="example"
                          style={{ width: '100%' }}
                          src={previewImage}
                        />
                      </Modal>
                    </FormItem>
                  </div>
                </Card> */}

                  {/* <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      name="additionalComments"
                      label={
                        <div className={`${styles.card_title}`}>
                          Additional Comments (500 characters)
                        </div>
                      }
                      rules={[
                        {
                          required: false,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        className={`${styles.margin_Top}`}
                        maxLength={500}
                        rows={3}
                        allowClear
                        placeholder="Enter comments.."
                        size="large"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'additionalComments',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </div>
                </Card> */}
                </Card>
              )}
            </div>
            {current === 0 && (
              <Row>
                <Button
                  className={`${styles.cancel_button}`}
                  htmlType="submit"
                  size="middle"
                  onClick={() => history.push('/declare-a-gift')}
                >
                  Cancel
                </Button>
                <Button
                  //loading={declare_gift_formLoading}
                  // name="submit"
                  className={`${styles.submit_button}`}
                  // htmlType="submit"
                  size="middle"
                  onClick={handleClickNext}
                >
                  Next
                </Button>
              </Row>
            )}
            {current === 1 && (
              <Row>
                <Button
                  className={`${styles.cancel_button}`}
                  // htmlType="submit"
                  size="middle"
                  onClick={handleClickBack}
                >
                  Back
                </Button>
                <Button
                  loading={declare_gift_formLoading}
                  name="submit"
                  className={`${styles.submit_button}`}
                  htmlType="submit"
                  size="middle"
                  // onClick={submitHandler}
                >
                  Submit
                </Button>
              </Row>
            )}
          </Form>
        </div>
        {governmentOfficialModal ? (
          <GovernmentOfficial_Modal
            governmentOfficialModal={governmentOfficialModal}
            setGovernmentOfficialModal={setGovernmentOfficialModal}
          />
        ) : (
          ''
        )}
      </div>
    </Spin>
  );
}
export default RequestGiftYtbForm;
/* <Card className={`${styles.cardStyles}`}>
                    <Row className={`${styles.auto_card_content}`}>
                      <Col span={12}>
                        <Row>
                          <Col span={7}>Date of reporting:</Col>
                          <Col span={12}>
                            <Input
                              disabled
                              size="small"
                              placeholder="Enter Date of reporting"
                            ></Input>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Col span={7}>Employee name:</Col>
                          <Col span={12}>
                            <Input
                              disabled
                              size="small"
                              placeholder="Enter Employee name"
                            ></Input>{' '}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card> */

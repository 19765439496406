import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './index.module.less';

import microsoft from 'features/login/images/microsoft_login_button.png';
import { Message, Icon } from 'semantic-ui-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import { useLocation } from 'react-router-dom';
import tataPro from '../../images/tata_logo.svg';
import ladyWithGift from '../../images/ladyWithGift.svg';
import gift_leftBackground from '../../images/gift_leftBackground.svg';
import gift_tataLogo from '../../images/gift_tataLogo.svg';
import gift_rightBackground from '../../images/gift_rightBackground.svg';

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [errorMsg] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();

  return (
    <div className={`${styles.full_container_div}`}>
      <div>
        <img className={`${styles.rightTata_logo}`} src={gift_tataLogo} />
        <div className={`${styles.welcome_text}`}>
          <p className={`${styles.welcome_text_title}`}>
            Welcome to the Gift Register
          </p>
          <p className={`${styles.welcome_text_subtitle1}`}>
            Drive transparency by declaring gifts you want to give/have given or
            received.
          </p>

          <Button
            className={`${styles.login_button}`}
            src={microsoft}
            id="microsoft-login"
            centered="true"
            alt="Microsoft"
            onClick={() => {
              const user_details = LocalStorage.getItem('GiftReg_user');
              console.log(user_details ? user_details : 'No response');

              instance
                .loginPopup({
                  scopes: ['User.Read']
                })
                .then((result) => {
                  return dispatch(login({ token: result?.accessToken }));
                })
                .then((result) => {
                  console.log('result', result);
                  if (result?.payload?.success) {
                    LocalStorage.setItem(
                      'GiftReg_user',
                      JSON.stringify(result?.payload)
                    );

                    const user = result?.payload;
                    console.log('user', user?.user?.roles[0]);

                    if (
                      user?.user?.roles[0] === 'admin' ||
                      user?.user?.roles[0] === 'sys-admin'
                    ) {
                      location.state?.from?.pathname
                        ? history.push(`${location.state?.from?.pathname}`)
                        : history.push('/dashboard');
                    } else {
                      location.state?.from?.pathname
                        ? history.push(`${location.state?.from?.pathname}`)
                        : user?.user?.buMapped === true
                        ? // ||
                          //   user?.user?.roles[0] === 'marcom-user' ||
                          //   user?.user?.roles[0] === 'cxo' ||
                          //   user?.user?.roles[0] === 'cec-user' ||
                          //   user?.user?.roles[0] === 'md'
                          history.push('/welcome')
                        : history.push('/assign-BU');
                    }
                  } else {
                    console.log('$$$$$$$$$$$$$$$$$$$');
                  }
                })
                .catch((err) => {
                  console.log('noresponse', err, err);
                  isAuthenticated
                    ? instance.logoutRedirect({
                        account: LocalStorage.homeAccountId,
                        postLogoutRedirectUri: '/',
                        mainWindowRedirectUri: '/'
                      })
                    : '';
                });
            }}
          >
            <span className={`${styles.button_title}`}>Go to your Account</span>
          </Button>
        </div>
      </div>
      <div className={`${styles.rightBackground_div}`}>
        <img
          className={`${styles.gift_rightBackground_img}`}
          src={gift_rightBackground}
        />
      </div>
      <div className={`${styles.leftBackground_div}`}>
        <img
          className={`${styles.gift_leftBackground_img}`}
          src={gift_leftBackground}
        />
      </div>
      <div>
        <img className={`${styles.left_logo}`} src={tataPro} />
      </div>
      <div className={`${styles.boy_giftBox_div}`}>
        <img className={`${styles.boy_giftBox_img}`} src={ladyWithGift} />
      </div>

      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </div>
  );
};

export default LoginPage;

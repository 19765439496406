import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;
//export const apiUrl = 'https://gr-api.lexyslabs.com/api-v1';

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('GiftReg_user');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `${authToken}`;
  } else {
    return '';
  }
};
const apiClient = {
  Urls: {
    apiUrl,
    signin: `${apiUrl}/signin`,
    declare_a_gift: `${apiUrl}/declare-a-gift`,
    past_gifts_list: `${apiUrl}/gifts`,
    requested_gifts_list: `${apiUrl}/requested-gifts`,
    gift_getbyId: `${apiUrl}/gift`,
    gift_master: `${apiUrl}/gift-master`,
    exception_requests: `${apiUrl}/accept`,
    reject_gift: `${apiUrl}/reject`,
    declare_received_gift: `${apiUrl}/declare-a-received-gift`,
    received_gifts_list: `${apiUrl}/my-received-gifts`,
    receivedGift_getbyId: `${apiUrl}/received-gift`,
    received_gift_accept: `${apiUrl}/received-gift-accept`,
    received_gift_reject: `${apiUrl}/received-gift-reject`,
    roUsers_list: `${apiUrl}/ro-users`,
    returnGift_form: `${apiUrl}`,
    returnGift_list: `${apiUrl}/ro-gifts`,
    received_gifts_list_request: `${apiUrl}/requested-received-gifts`,
    roGift_getbyId: `${apiUrl}/ro-gift`,
    ro_gift_accept: `${apiUrl}/acknowledge-by-ro`,
    deligators_id: `${apiUrl}/gift-entry-delegators`,
    admin: `${apiUrl}`,
    admin_post: `${apiUrl}`,
    dashboard_counts: `${apiUrl}/dashboard-counts`,
    user_details: `${apiUrl}/user-details`,
    bulk_upload: `${apiUrl}/bulk-upload`,
    dashboard_api: `${apiUrl}`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;

import React, { useState, useCallback } from 'react';
import styles from './index.module.less';
import {
  Radio,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  //Tag,
  Popconfirm,
  Space
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import {
  PlusOutlined,
  EditOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { history } from 'app/history';
import LocalStorage from 'utilities/localStorage';
import AllExportReports from '../reports/AllExportReports';
//import moment from 'moment';
//import { Value_of_gift } from '../../../../utilities/helpers';

const filters = [
  /*  {
    name: 'Gift Received',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  } */
];

function ROMasterFlowsPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  ro_MasterList,
  pagination,
  get_RO_MasterLoading,
  setSearchRequest,
  showDisablePopup,
  setShowDisablePopup,
  handleDeleteRequest
}) {
  const ro_MasterList1 = ro_MasterList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    location: item?.location,
    locationAddress: item?.locationAddress,
    updatedAt: item?.updatedAt,
    buHeadId: item?.BuHead?.id,
    createdAt: item?.createdAt,
    isActive: item?.isActive
  }));
  console.log('ro_MasterList1', ro_MasterList1);
  const handleBU_record = (record) => {
    console.log('@@@@@@@@@@', record);
    history.push(`edit-ro-master-flow/${record.id}`);
    LocalStorage.setItem('ro_record', JSON.stringify(record));
  };

  const [isAllExortModalOpen, setAllExortModalOpen] = useState(false);

  const columns = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.location?.localeCompare(b?.location),
      render: (location) => {
        return (
          <>
            <Tooltip
              title={location}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={styles.text_transform}>
                {location === null ? '' : location}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Location Address',
      dataIndex: 'locationAddress',
      key: 'locationAddress',
      // width: 400,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.locationAddress?.localeCompare(b?.locationAddress),
      render: (locationAddress) => {
        return (
          <>
            <Tooltip
              title={locationAddress}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {locationAddress === null ? '' : locationAddress}
            </Tooltip>
          </>
        );
      }
    },

    /*  {
      title: 'Delegated On',
      dataIndex: 'delegated_date',
      key: 'delegated_date',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`delegated_date`]).unix() - moment(b[`delegated_date`]).unix(),
      render: (delegated_date) => (
        <>
          <Tooltip
            title={
              delegated_date === null
                ? ''
                : moment(delegated_date).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {delegated_date === null
              ? ''
              : moment(delegated_date).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    }, */

    {
      title: 'Action',
      key: '',
      width: 180,
      render: (record) => (
        <>
          <Space size="middle">
            <>
              <Popconfirm
                placement="top"
                title="Are you sure you want to delete this BU?"
                open={visibility(record)}
                onConfirm={() => {
                  handleDeleteRequest({
                    record: record
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Delete"
                cancelText="No"
                okButtonProps={{ type: 'danger' }}
              >
                <a
                  className={`${styles.allocation_btn}`}
                  style={{
                    fontSize: '12px'
                  }}
                  type="link"
                  disabled={record?.status === false}
                >
                  {/* <DeleteOutlined className={`${styles.delete_bu}`} /> */}
                </a>
              </Popconfirm>
            </>
          </Space>
          <EditOutlined
            className={`${styles.edit_bu}`}
            onClick={() => handleBU_record(record)}
            /*  onClick={() => history.push(`edit-bu-master-flow/${record.id}`)} */
          />
        </>
      )
    }
  ];

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no RO to display
        </div>
      </span>
    )
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title}`}>Configure RO Master</div>
            <div className={`${styles.myGift_text}`}>
              View and manage RO Master
            </div>
          </div>
          <div className={`${styles.declareGift_div}`}>
            <Row>
              <Button
                className={`${styles.export_button}`}
                onClick={() => setAllExortModalOpen(true)}
              >
                Export
              </Button>
              <Button
                className={`${styles.declareGift_button}`}
                icon={<PlusOutlined />}
                onClick={() => history.push('/create-ro-master-flow')}
              >
                Add New RO Location
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                className={`${styles.declareGift_button}`}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push('/admin')}
              >
                Back
              </Button>
            </Row>
          </div>
        </Row>
        <div className={`${styles.tabs_container}`}>
          <Row>
            {/* className={`${styles.row_container}`} */}
            <Col span={16}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      //className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={8}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by location'
                }}
              />
            </Col>
          </Row>
        </div>
        <Card className={`${styles.cardStyles}`}>
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={ro_MasterList1}
              locale={locale}
              loading={get_RO_MasterLoading}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
        </Card>
        {isAllExortModalOpen ? (
          <AllExportReports
            isAllExortModalOpen={isAllExortModalOpen}
            setAllExortModalOpen={setAllExortModalOpen}
            apiName={'ros_master'}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
export default ROMasterFlowsPage;
{
  /* <Row className={`${styles.row_container_setting}`}>
        <div className={`${styles.gift_container}`}>
          <div className={`${styles.myGift_title_setting}`}>
            {' '}
            Settings  {user_role?.name}! 
          </div>
           <div className={`${styles.myGift_text}`}>
              View the list of gifts and their status
            </div> 
        </div>
      </Row> */
}

import React, { useState, useEffect } from 'react';
import ReviewEntryDelegatorFlowsPage from './ReviewEntryDelegatorFlowsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAll_approval_delegatorsList,
  remove_approval_delegator
} from '../../../redux/slice';

function ReviewEntryDelegatorFlows() {
  TabTitle('Gift Register - Review Entry Delegator Flows');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    approval_delegatorsList,
    pagination,
    getAll_approval_delegatorsListLoading,
    remove_approval_delegatorLoading
  } = useSelector((state) => state.admin);

  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  //console.log('searchRequest', searchRequest);
  //console.log(triggerFilter);
  console.log('approval_delegatorsList', approval_delegatorsList);

  useEffect(() => {
    handleGetAllApprovalDelegatorsList();
  }, [page, pageSize]);
  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllApprovalDelegatorsList();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]); //page,

  const handleGetAllApprovalDelegatorsList = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };
    dispatch(getAll_approval_delegatorsList(payload));
  };

  /* const handleChange = (e) => {
    const value = { search: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  };*/
  const handleDeleteRequest = (record) => {
    console.log('record', record?.record);
    console.log('record?.record?.delegatorId', record?.record?.delegatorId);
    const payload = {
      delegator_id: record?.record?.delegatorId,
      delegate_id: record?.record?.DelegateId
    };
    console.log('payload@@@@@@@@', payload);
    dispatch(remove_approval_delegator(payload)).then(function (response) {
      if (response) {
        handleGetAllApprovalDelegatorsList();
      }
    });
  };
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ReviewEntryDelegatorFlowsPage
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          setTriggerFilter={setTriggerFilter}
          approval_delegatorsList={approval_delegatorsList}
          pagination={pagination}
          getAll_approval_delegatorsListLoading={
            getAll_approval_delegatorsListLoading
          }
          setSearchRequest={setSearchRequest}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          handleDeleteRequest={handleDeleteRequest}
          remove_approval_delegatorLoading={remove_approval_delegatorLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default ReviewEntryDelegatorFlows;

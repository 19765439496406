import React, { useState, useCallback } from 'react';
import styles from './index.module.less';
import {
  Radio,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  Tag,
  Popconfirm,
  Space
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import AllExportReports from '../reports/AllExportReports';
import { sizeChecker } from 'utilities/helpers';
//import { Value_of_gift } from '../../../../utilities/helpers';

const filters = [
  /*  {
    name: 'Gift Received',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  } */
];

function EntryDelegatorFlowsPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  entry_delegatorsList,
  pagination,
  getAll_Entry_delegatorsListLoading,
  setSearchRequest,
  showDisablePopup,
  setShowDisablePopup,
  handleDeleteRequest
}) {
  const isMobile = sizeChecker('xs');

  const entry_delegatorsList1 = entry_delegatorsList?.map((item) => ({
    key: item?.id,
    delegatorId: item?.GiftEntryDelegator?.id,
    DelegateId: item?.GiftEntryDelegate?.id,
    delegator_name: item?.GiftEntryDelegator?.givenName,
    delegate_name: item?.GiftEntryDelegate?.givenName,
    delegated_date: item?.createdAt,
    status: item?.isActive,
    updatedAt: item?.updatedAt
    //Status (isActive)
  }));

  const [isAllExortModalOpen, setAllExortModalOpen] = useState(false);

  const columns = [
    {
      title: 'Delegator Name',
      dataIndex: 'delegator_name',
      key: 'delegator_name',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.delegator_name?.localeCompare(b?.delegator_name),
      render: (delegator_name) => {
        return (
          <>
            <Tooltip
              title={delegator_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <a href={`view-gift-received-details/${record.id}`}> */}
              <span className={styles.text_transform}>
                {delegator_name === null ? '' : delegator_name}
              </span>
              {/*  </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Delegate Name',
      dataIndex: 'delegate_name',
      key: 'delegate_name',
      //width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.delegate_name?.localeCompare(b?.delegate_name),
      render: (delegate_name) => {
        return (
          <>
            <Tooltip
              title={delegate_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/*  <a href={`view-gift-received-details/${record.id}`}> */}
              {delegate_name === null ? '' : delegate_name}
              {/* </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Date of Delegation',
      dataIndex: 'delegated_date',
      key: 'delegated_date',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`delegated_date`]).unix() - moment(b[`delegated_date`]).unix(),
      render: (delegated_date) => (
        <>
          <Tooltip
            title={
              delegated_date === null
                ? ''
                : moment(delegated_date).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {delegated_date === null
              ? ''
              : moment(delegated_date).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      //width: '120px',
      ellipsis: true,
      //sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === true
              ? 'green'
              : record?.status === false
              ? 'red'
              : 'transparent'
          }
        >
          {record?.status === true ? 'ACTIVE' : 'DEACTIVATED'}
        </Tag>
      )
    },
    {
      title: 'Date of Deactivation',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`updatedAt`]).unix() - moment(b[`updatedAt`]).unix(),
      render: (updatedAt, record) => (
        <>
          <Tooltip
            title={
              updatedAt === null ? '' : moment(updatedAt).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {record?.status === false
              ? moment(updatedAt).format('DD MMM YYYY')
              : 'NA'}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Action',
      key: '',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <Popconfirm
              placement="top"
              title="Are you sure you want to deactivate this delegate mapping?"
              visible={visibility(record)}
              onConfirm={() => {
                handleDeleteRequest({
                  record: record
                });
              }}
              onCancel={() => {
                setShowDisablePopup({});
              }}
              okText="Deactivate"
              cancelText="No"
              okButtonProps={{ type: 'danger' }}
            >
              <a
                className={`${styles.allocation_btn}`}
                style={{
                  fontSize: '12px'
                }}
                type="link"
                disabled={record?.status === false}
              >
                Deactivate
              </a>

              {/* <a
                className={`${styles.delete_icon}`}
                onClick={() => {
                  setShowDisablePopup({ [record.id]: true });
                }}
              >
                <span className={`${styles.deactivate}`}>
                  {record?.status === true ? 'Deactivate' : 'Disabled'}
                </span>
              </a> */}
            </Popconfirm>
          </>
        </Space>
      )
    }
  ];

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no delegator to display
        </div>
      </span>
    )
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title}`}>
              Configure Entry Delegates
            </div>
            <div className={`${styles.myGift_text}`}>
              View and manage gift entry delegates
            </div>
          </div>
          <div className={`${styles.declareGift_div}`}>
            <Row className={`${styles.declareGift_buttons_container}`}>
              <Button
                className={`${styles.export_button}`}
                onClick={() => setAllExortModalOpen(true)}
              >
                Export
              </Button>
              <Button
                className={`${styles.declareGift_button}`}
                icon={<PlusOutlined />}
                onClick={() => history.push('/create-new-delegator-flow')}
              >
                Add New Delegate
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                className={`${styles.declareGift_button_back}`}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push('/admin')}
              >
                Back
              </Button>
            </Row>
          </div>
        </Row>
        <div className={`${styles.tabs_container}`}>
          <Row>
            {/* className={`${styles.row_container}`} */}
            <Col span={16}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      //className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={isMobile ? 24 : 8}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by delegator name'
                }}
              />
            </Col>
          </Row>
        </div>
        <Card className={`${styles.cardStyles}`}>
          <div className={`${styles.table_container}`}>
            <CustomTable
              showSorterTooltip={false}
              columns={columns}
              dataSource={entry_delegatorsList1}
              locale={locale}
              loading={getAll_Entry_delegatorsListLoading}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
              scroll={{ x: isMobile ? true : false }}
            />
          </div>
        </Card>
      </div>
      {isAllExortModalOpen ? (
        <AllExportReports
          isAllExortModalOpen={isAllExortModalOpen}
          setAllExortModalOpen={setAllExortModalOpen}
          apiName={'gift_entry_delegates_master'}
        />
      ) : (
        ''
      )}
    </>
  );
}
export default EntryDelegatorFlowsPage;
{
  /* <Row className={`${styles.row_container_setting}`}>
        <div className={`${styles.gift_container}`}>
          <div className={`${styles.myGift_title_setting}`}>
            {' '}
            Settings  {user_role?.name}! 
          </div>
           <div className={`${styles.myGift_text}`}>
              View the list of gifts and their status
            </div> 
        </div>
      </Row> */
}

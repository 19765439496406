import React, { useEffect } from 'react';
import ViewGiftReceivedDetailsPage from './components/viewGiftReceivedDetailsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReceivedGiftById } from './redux/slice';
import LocalStorage from 'utilities/localStorage';

function ViewGiftReceivedDetails() {
  TabTitle('Gift Register - View Gift Received Details');
  const dispatch = useDispatch();
  const { getReceivedGiftByIdLoading, receivedGiftDetailsById } = useSelector(
    (state) => state.receivedView
  );

  const giftType = LocalStorage.getItem('giftTypeGR');
  console.log('iftTypeGR%%%%%%%%%', giftType);

  const params = useParams();
  const url = window.location.search;
  const urlType = url.replace('?', '');
  console.log('urType', urlType);

  useEffect(() => {
    handleGetGiftById();
  }, []);
  const handleGetGiftById = () => {
    const payload = { id: params.id, giftType: giftType };
    dispatch(getReceivedGiftById(payload));
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftReceivedDetailsPage
          getGiftDetailsById={receivedGiftDetailsById}
          getGiftByIdLoading={getReceivedGiftByIdLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewGiftReceivedDetails;

import Gift_received_page_Form from './components/gift_received_page';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';

function GiftReceived_Form() {
  TabTitle('Gift Register - Declare Gift received');
  console.log('RequestGiftYtb-1');
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <Gift_received_page_Form />
      </div>
    </CustomLayout>
  );
}
export default GiftReceived_Form;

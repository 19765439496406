import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  declare_a_gift,
  gift_master,
  deligators_id,
  user_details,
  bulk_upload
} from './api';

const initialState = {
  loading: false,
  declare_gift_formLoading: false,
  GiftMasterLoading: false,
  get_deligatorsIdLoading: false,
  get_userDetailsLoading: false,
  bulk_upload_formLoading: false,
  pagination: [],
  giftMaster: [],
  deligatorsIdList: [],
  userDetails: []
};

const actions = {
  DECLARE_GIFT_FORM: 'declare_gift_form/DECLARE_GIFT_FORM',
  GET_GIFT_MASTER: 'get_gift_master/GET_GIFT_MASTER',
  GET_DELIGATORS_ID: 'get_deligatorsId/GET_DELIGATORS_ID',
  GET_USE_DETAILS: 'get_userDetails/GET_USE_DETAILS',
  BULK_UPLOAD_FORM: 'bulk_upload_form/BULK_UPLOAD_FORM'
};
export const bulk_upload_form = createAsyncThunk(
  actions.BULK_UPLOAD_FORM,
  async (payload) => {
    const response = await bulk_upload(payload);
    return response;
  }
);
export const get_userDetails = createAsyncThunk(
  actions.GET_USE_DETAILS,
  async (payload) => {
    const response = await user_details(payload);
    return response;
  }
);

export const declare_gift_form = createAsyncThunk(
  actions.DECLARE_GIFT_FORM,
  async (payload) => {
    const response = await declare_a_gift(payload);
    return response;
  }
);

export const get_gift_master = createAsyncThunk(
  actions.GET_GIFT_MASTER,
  async (payload) => {
    const response = await gift_master(payload);
    return response;
  }
);

export const get_deligatorsId = createAsyncThunk(
  actions.GET_DELIGATORS_ID,
  async (payload) => {
    const response = await deligators_id(payload);
    return response;
  }
);

export const declareGiftFormSlice = createSlice({
  name: 'declareGiftForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(get_userDetails.pending, (state) => {
        state.get_userDetailsLoading = true;
      })
      .addCase(get_userDetails.fulfilled, (state, action) => {
        const { success, data, msg, pagination } = action.payload;
        state.get_userDetailsLoading = false;
        state.userDetails = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_userDetails.rejected, (state, action) => {
        const { msg } = action.error;

        state.get_userDetailsLoading = false;

        message.error(msg);
      });
    builder
      .addCase(declare_gift_form.pending, (state) => {
        state.declare_gift_formLoading = true;
      })
      .addCase(declare_gift_form.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg } = action.payload;
        state.declare_gift_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(declare_gift_form.rejected, (state, action) => {
        const { msg } = action.error;

        state.declare_gift_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_gift_master.pending, (state) => {
        state.GiftMasterLoading = true;
      })
      .addCase(get_gift_master.fulfilled, (state, action) => {
        const { success, data, msg, pagination } = action.payload;
        state.GiftMasterLoading = false;
        state.giftMaster = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_gift_master.rejected, (state, action) => {
        const { msg } = action.error;

        state.GiftMasterLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_deligatorsId.pending, (state) => {
        state.get_deligatorsIdLoading = true;
      })
      .addCase(get_deligatorsId.fulfilled, (state, action) => {
        const { success, data, msg, pagination } = action.payload;
        state.get_deligatorsIdLoading = false;
        state.deligatorsIdList = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_deligatorsId.rejected, (state, action) => {
        const { msg } = action.error;

        state.get_deligatorsIdLoading = false;

        message.error(msg);
      });
    builder
      .addCase(bulk_upload_form.pending, (state) => {
        state.bulk_upload_formLoading = true;
      })
      .addCase(bulk_upload_form.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg } = action.payload;
        state.bulk_upload_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(bulk_upload_form.rejected, (state, action) => {
        const { msg } = action.error;

        state.bulk_upload_formLoading = false;

        message.error(msg);
      });
  }
});
export default declareGiftFormSlice.reducer;

import React from 'react';
import { Modal, Button } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Input, Space, Alert } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { receivedGiftReject } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';
import {
  numFormating,
  getLoggedInUser
} from '../../../../../utilities/helpers';
import LocalStorage from 'utilities/localStorage';

const { TextArea } = Input;

const RejectGift_Modal = ({
  rejectModal,
  setRejectModal,
  getGiftDetailsById,
  Id,
  type,
  hashRoot,
  uniqueId,
  userId,
  redirect_action
  // rejectGiftLoading
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { receivedGiftRejectLoading } = useSelector(
    (state) => state.receivedView
  );

  const user_role1 = getLoggedInUser();
  const userDetails = LocalStorage.getItem('GiftReg_user');

  const onFinish = (values) => {
    if (userDetails?.user === undefined || user_role1?.user === null) {
      //one-click-reject by email
      const payload = {
        grId: Id,
        giftType: type,
        hashRoot: hashRoot,
        uniqueId: uniqueId,
        userId: userId,
        approverComments: values?.reason_for_excemption
      };

      dispatch(receivedGiftReject(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);

          setRejectModal(false);
          history.push('/one-click-reject');
        }
      });
    } else {
      const payload = {
        grId: params.id,
        approverComments: values?.reason_for_excemption,
        giftType: getGiftDetailsById?.type,
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };

      {
        dispatch(receivedGiftReject(payload)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            // history.push('/view-gift-received-request');
            setRejectModal(false);
            redirect_action === 'home_action_gr'
              ? history.push('/view-gift-received-request')
              : redirect_action === 'dashboard_action'
              ? history.push('/action-required')
              : history.push('/view-gift-received-request');
          }
        });
      }
    }

    /* const payload = {
      grId: params.id,
      approverComments: values?.reason_for_excemption,
      giftType: getGiftDetailsById?.type
    };

    {
      dispatch(receivedGiftReject(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);
          history.push('/view-gift-received-request');
          setRejectModal(false);
        }
      });
    } */
  };

  return (
    <>
      <Modal
        title={<span>Please confirm</span>}
        open={rejectModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setRejectModal(false)}
      >
        <Alert
          message={`Value of Gifts Received this Fiscal: ₹${numFormating(
            getGiftDetailsById?.thisFiscalYearReceivedGiftsValue
          )}`}
          type="info"
          showIcon
          style={{ marginTop: '-20px', fontWeight: 600 }}
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <div className={`${styles.return_title_RO}`}>
            Are you sure you want to reject the gift request and ask the user to
            return the gift?
          </div>
          <div className={`${styles.return_subtitle_RO}`}>
            (This action cannot be undone)
          </div>
          <FormItem
            style={{
              marginTop: '-15px'
            }}
            label=""
            name="reason_for_excemption"
            rules={[
              {
                required: true,
                message: 'This is a required field'
              }
            ]}
            className={`${styles.textarea_styles}`}
          >
            <TextArea allowClear placeholder="Enter reason" />
          </FormItem>

          <FormItem className={`${styles.formItem_reject}`}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                justifyItems: ' center'
              }}
            >
              <Button
                loading={receivedGiftRejectLoading}
                className={`${styles.Goback_button_reject}`}
                htmlType="submit"
                size="small"
              >
                Reject
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setRejectModal(false)}
              >
                Cancel
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default RejectGift_Modal;

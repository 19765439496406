import { useState } from 'react';
import styles from './index.module.less';
import { Radio, Button, Row, Col, Card, Empty, Tooltip, Tag } from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { PlusOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import {
  getLoggedInUser,
  numIntStyleFormat
} from '../../../../utilities/helpers';
import Switcher from '../../../../common/Switcher';
import ReturnRO_Modal from '../../../view_gift_return/components/viewReturnGiftPage/returnRO_Modal';
import {
  returnGift_RO_form,
  returnGift_Giver_form
  //returnGift_CEC_form
} from '../../../view_gift_return/redux/slice';
import FormData from 'form-data';
import { useDispatch } from 'react-redux';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';
//import FormItem from 'common/FormItem';

const filters = [
  {
    name: 'Gift Received',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'To Be Returned',
    value: 'rejected'
  },
  {
    name: 'Returned',
    value: 'returned'
  }
];

function ViewReceivedGiftPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  pastGiftsList,
  pagination,
  pastGiftsListLoading,
  CountsReceivedGiftsList,
  setSearchRequest,
  onChange,
  handleGetPastGiftsList
}) {
  const isMobile = sizeChecker('xs');

  const [returnToROModal, setReturnToROModal] = useState(false);
  const [recordData, setRecordData] = useState({});

  // const [form] = Form.useForm();

  const dispatch = useDispatch();

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  //console.log('user_role1', user_role);

  const pastGiftsList1 = pastGiftsList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.giverName,
    ticket: item?.ticket,
    GiftType: item?.giftName, //    GiftMaster?.title,
    valueOfGift: item?.valueOfGift,
    dateOfGiftReceived: item?.dateOfGiftReceived,
    status: item?.status,
    message: item?.giftDescription,
    organizationName: item?.organizationName,
    designation: item?.designation,
    giftReceiverSector: item?.giftGiverSector,
    isTheGiftReturned: item?.isTheGiftReturned,
    isTheGiftSelfReturned: item?.isTheGiftSelfReturned,
    type: item?.type,
    ReceiverId: item?.ReceiverId,
    returnedToRo: item?.returnedToRo,
    returnedToGiver: item?.returnedToGiver,
    returnedToCEC: item?.returnedToCEC,
    giverType: item?.giverType,
    contextOrBusinessPurposeOfTheGift: item?.contextOrBusinessPurposeOfTheGift
  }));
  const handleReDirect = (record) => {
    console.log('record', record);
    //setRecordData(record);
    history.push(`view-gift-received-details/${record.id}`);

    //dispatch(giftTypes(record.type));
    LocalStorage.setItem('giftTypeGR', JSON.stringify(record?.type));
    LocalStorage.setItem('redirect', JSON.stringify('home_gr'));
  };
  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {ticket === null ? '' : ticket}
              </a>
              {/* <a
                href={`view-gift-received-details/${record.id}?${record.type}`}
              >
                {ticket === null ? '' : ticket}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Giver',
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 70,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.recipientName?.localeCompare(b?.recipientName),
      render: (recipientName, record) => {
        return (
          <>
            <Tooltip
              title={recipientName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {recipientName === null ? '' : recipientName}
              </a>
              {/* <a
                href={`view-gift-received-details/${record.id}?${record.type}`}
              >
                {recipientName === null ? '' : recipientName}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Organization'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Organization
        </Tooltip>
      ),
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a?.organizationName?.localeCompare(b?.organizationName),
      ellipsis: true,
      render: (organizationName) => {
        return (
          <>
            <Tooltip
              title={organizationName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {organizationName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Designation'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Designation
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'designation',
      key: 'designation',
      sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
      ellipsis: true,
      render: (designation) => {
        return (
          <>
            <Tooltip
              title={designation}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {designation}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Stakeholder Type'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Stakeholder Type
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'giverType',
      key: 'giverType',
      sorter: (a, b) => a?.giverType?.localeCompare(b?.giverType),
      ellipsis: true,
      width: '80px',
      render: (giverType) => {
        return (
          <>
            <Tooltip
              title={giverType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giverType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Government Official?'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Government Official?
        </Tooltip>
      ),
      dataIndex: 'giftReceiverSector',
      key: 'giftReceiverSector',
      sorter: (a, b) =>
        a?.giftReceiverSector?.localeCompare(b?.giftReceiverSector),
      ellipsis: true,
      width: '70px',
      render: (giftReceiverSector) => {
        return (
          <>
            <Tooltip
              title={giftReceiverSector}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giftReceiverSector === 'government' ? 'Yes' : 'No'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Context/Business Purpose of the Gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Context/Business Purpose of the Gift
        </Tooltip>
      ),
      dataIndex: 'contextOrBusinessPurposeOfTheGift',
      key: 'contextOrBusinessPurposeOfTheGift',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) =>
        a?.contextOrBusinessPurposeOfTheGift?.localeCompare(
          b?.contextOrBusinessPurposeOfTheGift
        ),
      render: (contextOrBusinessPurposeOfTheGift) => {
        return (
          <>
            <Tooltip
              title={
                contextOrBusinessPurposeOfTheGift === ''
                  ? 'NA'
                  : contextOrBusinessPurposeOfTheGift
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {contextOrBusinessPurposeOfTheGift === ''
                ? 'NA'
                : contextOrBusinessPurposeOfTheGift}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'GiftType',
      key: 'GiftType',
      sorter: (a, b) => a?.GiftType?.localeCompare(b?.GiftType),
      ellipsis: true,
      render: (GiftType) => {
        return (
          <>
            <Tooltip
              title={GiftType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {GiftType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value',
      dataIndex: 'valueOfGift',
      key: 'valueOfGift',
      ellipsis: {
        showTitle: false
      },
      width: 80,
      sorter: (a, b) => a?.valueOfGift - b?.valueOfGift,
      render: (valueOfGift) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(valueOfGift)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {numIntStyleFormat(valueOfGift)}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Date of gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Date of gift
        </Tooltip>
      ),
      dataIndex: 'dateOfGiftReceived',
      key: 'dateOfGiftReceived',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`dateOfGiftReceived`]).unix() -
        moment(b[`dateOfGiftReceived`]).unix(),
      render: (dateOfGiftReceived) => (
        <>
          <Tooltip
            title={
              dateOfGiftReceived === null
                ? ''
                : moment(dateOfGiftReceived).format('DD MMM YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {dateOfGiftReceived === null
              ? ''
              : moment(dateOfGiftReceived).format('DD MMM YY')}
          </Tooltip>
        </>
      )
    },
    /*  {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.message?.localeCompare(b?.message),
      render: (message) => {
        return (
          <>
            <Tooltip
              title={message === '' ? 'NA' : message}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {message === '' ? 'NA' : message}
            </Tooltip>
          </>
        );
      }
    }, */

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => {
        // console.log('RECORDDDDDDDD', record);
        return (
          <Tag
            className={`${styles.status_style}`}
            color={
              record?.status === null
                ? 'transparent'
                : (record?.status === 'rejected' &&
                    record?.isTheGiftReturned === true) ||
                  (record?.status === 'rejected' &&
                    record?.isTheGiftSelfReturned === true)
                ? 'magenta'
                : record?.status === 'pending'
                ? 'blue'
                : record?.status === 'accepted'
                ? 'green'
                : record?.status === 'returned'
                ? 'magenta'
                : 'red'
            }
          >
            {(record?.status === 'rejected' &&
              record?.isTheGiftReturned === true) ||
            (record?.status === 'rejected' &&
              record?.isTheGiftSelfReturned === true) ? (
              'RETURNED'
            ) : record?.status === 'rejected' ? (
              record?.ReceiverId === user_role?.id &&
              record?.returnedToRo === false &&
              record?.returnedToGiver === false &&
              record?.returnedToCEC === false ? (
                <>
                  <a
                    onClick={() => {
                      setReturnToROModal(true);
                      setRecordData(record);
                    }}
                    style={{ color: 'red' }}
                  >
                    TO BE RETURNED
                  </a>
                  {/* <a
                href={`view-gift-received-details/${record.id}?${record.type}`}
                style={{ color: 'red' }}
              >
                TO BE RETURNED
              </a> */}
                </>
              ) : (
                'TO BE RETURNED'
              )
            ) : (
              status
            )}
          </Tag>
        );
      }
    }
  ];

  const HandleReturnGift = (payload) => {
    console.log('pay####', payload);
    let formdata = new FormData();

    formdata.append('grId', recordData?.id);
    payload?.whom_return === true
      ? ''
      : formdata.append(
          'roId',
          payload?.roId === undefined ? '' : payload?.roId
        );

    for (let i in payload?.fileList) {
      formdata.append(
        'roDocument',
        payload?.fileList?.length === 0
          ? ''
          : payload?.fileList[i]?.originFileObj
      );
    }
    formdata.append('dateOfGiftReturned', payload?.dateOfGiftReturned);
    payload?.whom_return === 'over_RO'
      ? formdata.append('whyNotReturnedToGiver', payload?.whyNotReturnedToGiver)
      : '';
    formdata.append(
      'commentsOfReturn',
      payload?.commentsOfReturn === undefined ? '' : payload?.commentsOfReturn
    );
    payload?.whom_return === 'gift_giver'
      ? dispatch(returnGift_Giver_form(formdata)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            setReturnToROModal(false);
            handleGetPastGiftsList();
            // history.push('/view-gift-received');
          }
        })
      : payload?.whom_return === 'over_RO'
      ? dispatch(returnGift_RO_form(formdata)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            setReturnToROModal(false);
            handleGetPastGiftsList();
            // history.push('/view-gift-received');
          }
        })
      : ''; /* dispatch(returnGift_CEC_form(formdata)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            setReturnToROModal(false);
            history.push('/view-gift-received');
          }
        }); */
  };

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <div className={`${styles.container}`}>
      <Row className={`${styles.row_container}`}>
        <div className={`${styles.gift_container}`}>
          <div
            className={
              isMobile
                ? `${styles.myGift_title_isMobile}`
                : `${styles.myGift_title}`
            }
          >
            Gifts Received
          </div>
          <div
            className={
              isMobile
                ? `${styles.myGift_text_isMobile}`
                : `${styles.myGift_text}`
            }
          >
            {' '}
            View the status of requests made or make a new request.
          </div>
        </div>
        <div className={`${styles.declareGift_div}`}>
          <Switcher
            left={'My requests'}
            right={'Delegated requests'}
            onChange={onChange}
          />
        </div>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end'
        }}
        className={`${styles.declareGift_button_isMobile}`}
      >
        <div>
          <Button
            className={`${styles.declareGift_button}`}
            icon={<PlusOutlined />}
            onClick={() => history.push('/declare-gift-received')}
          >
            Declare a Gift
          </Button>
        </div>
      </Row>
      <div
        className={
          isMobile
            ? `${styles.tabs_container_isMobile}`
            : `${styles.tabs_container}`
        }
      >
        <Row className={`${styles.row_display_isMobile}`}>
          <Col span={16} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={
                isMobile
                  ? `${styles.button_import_style_isMobile}`
                  : `${styles.button_import_style}`
              }
            >
              {filters.map((item, index) => {
                return (
                  <Radio.Button
                    className={`${styles.radio_button}`}
                    value={item.value}
                    key={index}
                    onChange={() => {
                      window.localStorage.setItem(
                        'set_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name === 'Gift Received' ? (
                      <span>
                        {item.name} ({CountsReceivedGiftsList?.all})
                        {/*  <div>
                          {' '}
                          <Badge
                            count={CountsReceivedGiftsList?.all}
                            style={{
                              backgroundColor: '#2264ae'
                            }}
                          />
                        </div> */}
                      </span>
                    ) : item.name === 'Pending' ? (
                      <span>
                        {item.name} (
                        {CountsReceivedGiftsList?.waitingForApproval})
                      </span>
                    ) : item.name === 'Accepted' ? (
                      <span>
                        {item.name} ({CountsReceivedGiftsList?.accepted})
                      </span>
                    ) : item.name === 'To Be Returned' ? (
                      <span>
                        {item.name} ({CountsReceivedGiftsList?.rejected})
                      </span>
                    ) : item.name === 'Returned' ? (
                      <span>
                        {item.name} ({CountsReceivedGiftsList?.returned})
                      </span>
                    ) : (
                      item.name
                    )}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Col>
          <Col span={8} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <SearchBar
              onChange={(e) => {
                setSearchRequest(e.target.value);
                setTriggerFilter(true);
              }}
              className={
                isMobile
                  ? `${styles.searchbar_isMobile}`
                  : `${styles.searchbar}`
              }
              inputProps={{
                placeholder: 'Search by giver or request number#'
              }}
            />
          </Col>
        </Row>
      </div>
      <Card className={`${styles.cardStyles}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            showSorterTooltip={false}
            columns={columns}
            dataSource={pastGiftsList1}
            locale={locale}
            loading={pastGiftsListLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
        {returnToROModal ? (
          <ReturnRO_Modal
            returnToROModal={returnToROModal}
            setReturnToROModal={setReturnToROModal}
            getGiftDetailsById={pastGiftsList}
            HandleReturnGift={HandleReturnGift}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
      </Card>
    </div>
  );
}
export default ViewReceivedGiftPage;

import React, { useState } from 'react';
import styles from './index.module.less';
import {
  Radio,
  //Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  Tag,
  Button,
  message
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { CopyOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import { numIntStyleFormat } from '../../../../utilities/helpers';
import Switcher from '../../../../common/Switcher';
import LocalStorage from 'utilities/localStorage';
import Pending_Modal from './pending_Modal';
import { sizeChecker } from 'utilities/helpers';

const filters = [
  {
    name: 'Gift Requests',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  }
];

function ViewGiftRequestPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  pastGiftsList,
  pagination,
  pastGiftsListLoading,
  CountsPastGiftsList,
  setSearchRequest,
  onChange,
  reqAllocInvoiceKeys,
  setReqAllocInvoiceKeys,
  reqAllocInvoices,
  setReqAllocInvoices,
  handleGetPastGiftsList
}) {
  const isMobile = sizeChecker('xs');

  const [pendingApprovalModal, setPendingApprovalModal] = useState(false);

  const pastGiftsList1 = pastGiftsList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.recipientName,
    ticket: item?.ticket,
    GiftType: item?.giftName, //    GiftMaster?.title,
    valueOfGift: item?.valueOfGift,
    dateOfGiftReceived: item?.dateOfGiftReceived,
    //status: item?.status,
    status:
      item?.GivingFlowWorkings?.length > 0
        ? item?.GivingFlowWorkings[0]?.status
        : '',
    message: item?.giftDescription,
    organizationName: item?.organizationName,
    designation: item?.designation,
    giftReceiverSector: item?.giftReceiverSector,
    type: item?.type,
    recipientType: item?.recipientType,
    contextOrBusinessPurposeOfTheGift: item?.contextOrBusinessPurposeOfTheGift,
    requester: `${item?.Creator?.givenName} ${item?.Creator?.surname}`,
    bulkUploadGroupNumber: item?.bulkUploadGroupNumber
  }));

  const isArraySame = (arr) =>
    arr.every((item, index, array) => item === array[0]);

  const isArrayContainsNull = (arr) => arr.some((item) => item === null);

  const bulkUploadGroupNumberArray = reqAllocInvoices?.map(
    (item) => item?.bulkUploadGroupNumber
  );
  //console.log('bulkUploadGroupNumberArray1111111', bulkUploadGroupNumberArray);

  const bulkUploadAreAllSame = isArraySame(bulkUploadGroupNumberArray);

  // console.log('areAllSame', bulkUploadAreAllSame); // true

  const bulkUploadContainsNull = isArrayContainsNull(
    bulkUploadGroupNumberArray
  );

  // console.log('containsNull', bulkUploadContainsNull); // true

  const handleReDirect = (record) => {
    console.log('record', record);
    //setRecordData(record);
    history.push(`view-gift-request-details/${record.id}`);

    // dispatch(giftTypes(record.type));
    LocalStorage.setItem('giftTypeRequest', JSON.stringify(record?.type));

    LocalStorage.setItem('redirect_action', JSON.stringify('home_action_gag'));
  };

  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 100,
      ellipsis: true,
      // tooltip: false,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {' '}
              <Tooltip
                title={'bulk upload'}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {' '}
                {record?.bulkUploadGroupNumber === null ||
                record?.bulkUploadGroupNumber === '' ? (
                  ''
                ) : (
                  <CopyOutlined style={{ color: '#108ee9' }} />
                )}{' '}
              </Tooltip>
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {ticket === null ? '' : ticket}
              </a>
              {/* <a href={`view-gift-request-details/${record.id}?${record.type}`}>
                {ticket === null ? '' : ticket}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={`Requester Full Name`}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Requester Full Name
        </Tooltip>
      ),
      dataIndex: 'requester',
      key: 'requester',
      width: 90,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.requester?.localeCompare(b?.requester),
      render: (requester) => {
        return (
          <>
            <Tooltip
              title={requester}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {requester === null ? '' : requester}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={`Recipient Full Name`}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Full Name
        </Tooltip>
      ),
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 90,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.recipientName?.localeCompare(b?.recipientName),
      render: (recipientName, record) => {
        return (
          <>
            <Tooltip
              title={recipientName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {recipientName === null ? '' : recipientName}
              </a>
              {/* <a href={`view-gift-request-details/${record.id}?${record.type}`}>
                {recipientName === null ? '' : recipientName}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient Organization'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Organization
        </Tooltip>
      ),
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a?.organizationName?.localeCompare(b?.organizationName),
      ellipsis: true,
      render: (organizationName) => {
        return (
          <>
            <Tooltip
              title={organizationName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {organizationName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient Designation'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Designation
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'designation',
      key: 'designation',
      sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
      ellipsis: true,
      render: (designation) => {
        return (
          <>
            <Tooltip
              title={designation}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {designation}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient Stakeholder Type'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient Stakeholder Type
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'recipientType',
      key: 'recipientType',
      sorter: (a, b) => a?.recipientType?.localeCompare(b?.recipientType),
      ellipsis: true,
      render: (recipientType) => {
        return (
          <>
            <Tooltip
              title={recipientType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {recipientType}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: (
        <Tooltip
          title={'Government Official?'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Government Official?
        </Tooltip>
      ),
      dataIndex: 'giftReceiverSector',
      key: 'giftReceiverSector',
      sorter: (a, b) =>
        a?.giftReceiverSector?.localeCompare(b?.giftReceiverSector),
      ellipsis: true,
      width: 70,
      render: (giftReceiverSector) => {
        return (
          <>
            <Tooltip
              title={giftReceiverSector}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giftReceiverSector === 'government' ? 'Yes' : 'No'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Context/Business Purpose of the Gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Context/Business Purpose of the Gift
        </Tooltip>
      ),
      dataIndex: 'contextOrBusinessPurposeOfTheGift',
      key: 'contextOrBusinessPurposeOfTheGift',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) =>
        a?.contextOrBusinessPurposeOfTheGift?.localeCompare(
          b?.contextOrBusinessPurposeOfTheGift
        ),
      render: (contextOrBusinessPurposeOfTheGift) => {
        return (
          <>
            <Tooltip
              title={
                contextOrBusinessPurposeOfTheGift === ''
                  ? 'NA'
                  : contextOrBusinessPurposeOfTheGift
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {contextOrBusinessPurposeOfTheGift === ''
                ? 'NA'
                : contextOrBusinessPurposeOfTheGift}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'GiftType',
      key: 'GiftType',
      sorter: (a, b) => a?.GiftType?.localeCompare(b?.GiftType),
      ellipsis: true,
      render: (GiftType) => {
        return (
          <>
            <Tooltip
              title={GiftType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {GiftType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value',
      dataIndex: 'valueOfGift',
      key: 'valueOfGift',
      ellipsis: {
        showTitle: false
      },
      width: 90,
      sorter: (a, b) => a?.valueOfGift - b?.valueOfGift,
      render: (valueOfGift) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(valueOfGift)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {numIntStyleFormat(valueOfGift)}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Date of gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Date of gift
        </Tooltip>
      ),
      dataIndex: 'dateOfGiftReceived',
      key: 'dateOfGiftReceived',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`dateOfGiftReceived`]).unix() -
        moment(b[`dateOfGiftReceived`]).unix(),
      render: (dateOfGiftReceived) => (
        <>
          <Tooltip
            title={
              dateOfGiftReceived === null
                ? ''
                : moment(dateOfGiftReceived).format('DD MMM YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {dateOfGiftReceived === null
              ? ''
              : moment(dateOfGiftReceived).format('DD MMM YY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === null
              ? 'transparent'
              : record?.status === 'rejected'
              ? 'red'
              : record?.status === 'pending'
              ? 'blue'
              : record?.status === 'accepted'
              ? 'green'
              : record?.status === 'returned'
              ? 'magenta'
              : 'cyan'
          }
        >
          {record?.status === null
            ? ''
            : /*  record.status === 'pending' && reqAllocInvoices?.length !== 0 ? (
            <a
              // href={''}
              style={{ color: 'blue' }}
              onClick={() => setPendingApprovalModal(true)}
              //disabled={reqAllocInvoices?.length === 0}
            >
              {record.status}
            </a>
          ) : */ record.status}
        </Tag>
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.status === 'accepted' ||
        record.status === 'rejected' ||
        record.status === 'returned' //||
      // reqAllocInvoices.find(
      //   (item) => item.bulkUploadGroupNumber !== record.bulkUploadGroupNumber
      // )

      //name: record.bulkUploadGroupNumber
    })
  };
  //console.log('setReqAllocInvoices:', reqAllocInvoices);
  //console.log('setReqAllocInvoiceKeys:', reqAllocInvoiceKeys);
  return (
    <div className={`${styles.container}`}>
      <Row className={`${styles.row_container}`}>
        <div className={`${styles.gift_container}`}>
          <div
            className={
              isMobile
                ? `${styles.myGift_title_isMobile}`
                : `${styles.myGift_title}`
            }
          >
            Review – Gift yet to be given requests
          </div>
          <div
            className={
              isMobile
                ? `${styles.myGift_text_isMobile}`
                : `${styles.myGift_text}`
            }
          >
            Review gift requests made by others and approve or reject them
          </div>
        </div>
        <div className={`${styles.declareGift_div}`}>
          <Switcher
            left={'My requests'}
            right={'Delegated requests'}
            onChange={onChange}
          />
        </div>
      </Row>
      <div
        className={
          isMobile
            ? `${styles.tabs_container_isMobile}`
            : `${styles.tabs_container}`
        }
      >
        <Row className={`${styles.row_display_isMobile}`}>
          <Col span={14} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={
                isMobile
                  ? `${styles.button_import_style_isMobile}`
                  : `${styles.button_import_style}`
              }
            >
              {filters.map((item, index) => {
                return (
                  <Radio.Button
                    className={`${styles.radio_button}`}
                    value={item.value}
                    key={index}
                    onChange={() => {
                      window.localStorage.setItem(
                        'set_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name === 'Gift Requests' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.all})
                        {/*  <div>
                          {' '}
                          <Badge
                            count={CountsPastGiftsList?.all}
                            style={{
                              backgroundColor: '#2264ae'
                            }}
                          />
                        </div> */}
                      </span>
                    ) : item.name === 'Pending' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.actionRequired})
                      </span>
                    ) : item.name === 'Accepted' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.accepted})
                      </span>
                    ) : item.name === 'Rejected' ? (
                      <span>
                        {item.name} ({CountsPastGiftsList?.rejected})
                      </span>
                    ) : (
                      item.name
                    )}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Col>
          <Col span={10} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <Row className={`${styles.seatchbar_container}`}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={
                  isMobile
                    ? `${styles.searchbar_isMobile}`
                    : `${styles.searchbar}`
                }
                inputProps={{
                  placeholder: 'Search by recipient or request#'
                }}
              />
              <Button
                className={
                  isMobile
                    ? `${styles.actionButton_isMobile}`
                    : `${styles.actionButton}`
                }
                onClick={() => {
                  if (
                    bulkUploadContainsNull === false ||
                    (bulkUploadContainsNull === true &&
                      bulkUploadAreAllSame === true)
                  ) {
                    if (bulkUploadAreAllSame === true) {
                      setPendingApprovalModal(true);
                    } else if (bulkUploadAreAllSame === false) {
                      message.info(
                        <span style={{ fontSize: '14px', fontWeight: '600' }}>
                          {`You can't select requests from two bulk groups at the same time`}
                        </span>
                      );
                    }
                  } else if (
                    bulkUploadContainsNull === true &&
                    bulkUploadAreAllSame !== true
                  ) {
                    message.info(
                      <span style={{ fontSize: '14px', fontWeight: '600' }}>
                        {`You can't select bulk requests and individual requests together`}
                      </span>
                    );
                  }
                }}
                disabled={reqAllocInvoices?.length === 0}
              >
                Approve/Reject
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
      <Card className={`${styles.cardStyles}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            showSorterTooltip={false}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={pastGiftsList1}
            locale={locale}
            loading={pastGiftsListLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
      </Card>
      {pendingApprovalModal ? (
        <Pending_Modal
          pendingApprovalModal={pendingApprovalModal}
          setPendingApprovalModal={setPendingApprovalModal}
          /* getGiftDetailsById={getGiftDetailsById}
          Id={Id}
          type={type}
          hashRoot={hashRoot}
          uniqueId={uniqueId}
          userId={userId}
          redirect_action={redirect_action} */
          reqAllocInvoiceKeys={reqAllocInvoiceKeys}
          reqAllocInvoices={reqAllocInvoices}
          handleGetPastGiftsList={handleGetPastGiftsList}
        />
      ) : (
        ''
      )}
    </div>
  );
}
export default ViewGiftRequestPage;

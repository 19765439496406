import { React, useState } from 'react'; //, { useState, useEffect, useMemo }
import styles from './index.module.less';
import { Row, Card, Image, Modal, Button, Space, DatePicker } from 'antd';
import {
  FileAddOutlined,
  FileProtectOutlined,
  ArrowLeftOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import arrow from '../../../images/arrow.svg';
import FormItem from 'common/FormItem';
import { apiUrl } from 'utilities/apiClient';
// import entry_delegate from '../../../../assets/images/entry_delegate.png';
// import review_delegate from '../../../../assets/images/review_delegate.png';
//import { history } from 'app/history';
import moment from 'moment';
import CustomLayout from '@/common/Layout';
import { getLoggedInUser } from 'utilities/helpers';

const ReportPage = () => {
  const [isGRModalOpen, setIsGRModalOpen] = useState(false);
  const [isGAGModalOpen, setIsGAGModalOpen] = useState(false);
  const [isGYTBModalOpen, setIsGYTBModalOpen] = useState(false);
  const [isAllUsersModalOpen, setAllUsersModalOpen] = useState(false);
  //const { RangePicker } = DatePicker;
  const [filterPayload, setFilterPayload] = useState([moment(), moment()]); //useState({});

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user?.roles[0];

  const gytbModal = () => {
    setIsGYTBModalOpen(true);
  };

  const grModal = () => {
    setIsGRModalOpen(true);
  };

  const gagModal = () => {
    setIsGAGModalOpen(true);
  };

  const allUsersModal = () => {
    setAllUsersModalOpen(true);
  };

  const handleGROk = () => {
    setIsGRModalOpen(false);
  };

  const handleGYTBOk = () => {
    setIsGYTBModalOpen(false);
  };

  const handleGAGOk = () => {
    setIsGAGModalOpen(false);
  };

  const handleAllUsersOk = () => {
    setAllUsersModalOpen(false);
  };

  const handleGRCancel = () => {
    setIsGRModalOpen(false);
  };

  const handleGYTBCancel = () => {
    setIsGYTBModalOpen(false);
  };

  const handleGAGCancel = () => {
    setIsGAGModalOpen(false);
  };

  const handleAllUsersCancel = () => {
    setAllUsersModalOpen(false);
  };

  const handleGRExportasCsv = () => () => {
    const user = JSON.parse(localStorage.getItem('GiftReg_user'));

    const authToken = user.accessToken;

    const fromDate =
      moment(filterPayload[0]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');
    const toDate =
      moment(filterPayload[1]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');

    setIsGRModalOpen(false);
    fetch(
      `${apiUrl}/reports?giftType=gr&fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `GR-export.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleGagExportasCsv = () => () => {
    const fromDate =
      moment(filterPayload[0]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');
    const toDate =
      moment(filterPayload[1]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');

    // console.log('fromDate@@@@@@@@', fromDate);
    // console.log('toDate@@@@@@@', toDate);

    const user = JSON.parse(localStorage.getItem('GiftReg_user'));

    const authToken = user.accessToken;
    console.log(authToken);
    setIsGAGModalOpen(false);

    fetch(
      `${apiUrl}/reports?giftType=gag&fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `GAG-export.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleGytbExportasCsv = () => () => {
    const user = JSON.parse(localStorage.getItem('GiftReg_user'));

    const authToken = user.accessToken;
    console.log(authToken);

    const fromDate =
      moment(filterPayload[0]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');
    const toDate =
      moment(filterPayload[1]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');
    setIsGYTBModalOpen(false);

    fetch(
      `${apiUrl}/reports?giftType=gytb&fromDate=${fromDate}&toDate=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `GYTB-export.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleAllUsersExportasCsv = () => () => {
    const user = JSON.parse(localStorage.getItem('GiftReg_user'));

    const authToken = user.accessToken;
    console.log(authToken);

    // const fromDate =
    //   moment(filterPayload[0]?._d)?.format('YYYY-MM-DD') ||
    //   moment()?.format('YYYY-MM-DD');
    // const toDate =
    //   moment(filterPayload[1]?._d)?.format('YYYY-MM-DD') ||
    //   moment()?.format('YYYY-MM-DD');
    setAllUsersModalOpen(false);
    ////'https://giftregister-api.tataprojects.com/api-v1/export-all-users'
    fetch(`${apiUrl}/export-all-users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Export-all-users.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <Row className={`${styles.row_container_setting}`}>
          <div className={`${styles.myGift_title}`}>
            {' '}
            Reports {/* {user_role?.name}! */}
          </div>
          {user_role === 'cec-user' && (
            <div className={`${styles.back_btn}`}>
              <a href="/welcome">
                {' '}
                <ArrowLeftOutlined />
                <span style={{ marginLeft: '3px' }}>Switch to Home</span>
              </a>
            </div>
          )}
        </Row>
        <div className={`${styles.container}`}>
          <div className={`${styles.cardContainer}`}>
            <Card className={`${styles.cardStyles}`}>
              <Row className={`${styles.row_container}`}>
                <div className={`${styles.card_content}`}>
                  <Row>
                    <span className={`${styles.icon_size}`}>
                      <FileAddOutlined />
                    </span>{' '}
                    <span className={`${styles.card_content_text}`}>
                      Gift Already Given Requests
                    </span>
                  </Row>
                </div>
                <a>
                  <div>
                    <Image
                      className={`${styles.gift_icon_size}`}
                      alt="gift_requests-icon"
                      src={arrow}
                      preview={false}
                      // onClick={() => setEntryDelegatorModal(true)}//
                      onClick={gagModal}
                    />
                    <Modal
                      title="Select date range for the report"
                      open={isGAGModalOpen}
                      onOk={handleGAGOk}
                      onCancel={handleGAGCancel}
                      footer={[
                        <Button key="back" onClick={handleGAGCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="link"
                          type="primary"
                          onClick={
                            handleGagExportasCsv()
                            /* new Date(filterPayload['start_date'])
                              .toLocaleDateString()
                              .replace(/\//g, '-'),
                            new Date(filterPayload['end_date'])
                              .toLocaleDateString()
                              .replace(/\//g, '-') */
                          }
                        >
                          Download
                        </Button>
                      ]}
                    >
                      <Space direction="horizontal">
                        {/*  <FormItem label="Select Date Range">
                          <RangePicker
                            value={[
                              filterPayload['start_date'],
                              filterPayload['end_date']
                            ]}
                            onChange={(e) => {
                              setFilterPayload({
                                ...filterPayload,
                                start_date: e && e.length > 0 ? e[0] : null,
                                end_date: e && e.length > 1 ? e[1] : null
                              });
                            }}
                            disabledDate={(current) => {
                              return (
                                // moment().add(-1, 'days') >= current //||
                                moment().add(0, 'month') <= current
                              );
                            }}
                          />
                        </FormItem> */}
                        <FormItem
                          style={{ fontWeight: '800' }}
                          label="Select Date Range"
                          name="fromDate"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter date'
                            }
                          ]}
                        >
                          <DatePicker.RangePicker
                            format={'DD-MMM-YYYY'}
                            onChange={(x) => {
                              setFilterPayload(x);
                            }}
                            disabledDate={(current) => {
                              return current && current.valueOf() > Date.now();
                            }}
                            value={filterPayload}
                            // separator={'-'}
                            // style={{ width: '100%' }}
                          />
                        </FormItem>
                      </Space>
                    </Modal>
                  </div>
                </a>
              </Row>
            </Card>
            <Card className={`${styles.cardStyles}`}>
              <Row className={`${styles.row_container}`}>
                <div className={`${styles.card_content}`}>
                  <Row>
                    <span className={`${styles.icon_size}`}>
                      <FileDoneOutlined />
                    </span>{' '}
                    <span className={`${styles.card_content_text}`}>
                      Gift Yet to be given Requests
                    </span>
                  </Row>
                </div>
                <a>
                  <div>
                    <Image
                      className={`${styles.gift_icon_size}`}
                      alt="gift_requests-icon"
                      src={arrow}
                      preview={false}
                      onClick={gytbModal}
                    />
                    <Modal
                      title="Select date range for the report"
                      open={isGYTBModalOpen}
                      onOk={handleGYTBOk}
                      onCancel={handleGYTBCancel}
                      footer={[
                        <Button key="back" onClick={handleGYTBCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="link"
                          type="primary"
                          onClick={handleGytbExportasCsv()}
                        >
                          Download
                        </Button>
                      ]}
                    >
                      <Space direction="horizontal">
                        <FormItem
                          style={{ fontWeight: '800' }}
                          label="Select Date Range"
                          name="fromDate"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter date'
                            }
                          ]}
                        >
                          <DatePicker.RangePicker
                            format={'DD-MMM-YYYY'}
                            onChange={(x) => {
                              setFilterPayload(x);
                            }}
                            disabledDate={(current) => {
                              return current && current.valueOf() > Date.now();
                            }}
                            value={filterPayload}
                            // separator={'-'}
                            // style={{ width: '100%' }}
                          />
                        </FormItem>
                      </Space>
                    </Modal>
                  </div>
                </a>
              </Row>
            </Card>
            <Card className={`${styles.cardStyles}`}>
              <Row className={`${styles.row_container}`}>
                <div className={`${styles.card_content}`}>
                  <Row>
                    <span className={`${styles.icon_size}`}>
                      <FileProtectOutlined />
                    </span>{' '}
                    <span className={`${styles.card_content_text}`}>
                      Gifts Received Requests
                    </span>
                  </Row>
                </div>
                <a>
                  <div>
                    <Image
                      className={`${styles.gift_icon_size}`}
                      alt="gift_requests-icon"
                      src={arrow}
                      preview={false}
                      onClick={grModal}
                    />
                    <Modal
                      title="Select date range for the report"
                      open={isGRModalOpen}
                      onOk={handleGROk}
                      onCancel={handleGRCancel}
                      footer={[
                        <Button key="back" onClick={handleGRCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="link"
                          type="primary"
                          // onClick={handleGROk}
                          onClick={handleGRExportasCsv()}
                        >
                          Download
                        </Button>
                      ]}
                    >
                      <Space direction="horizontal">
                        <FormItem
                          style={{ fontWeight: '800' }}
                          label="Select Date Range"
                          name="fromDate"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter date'
                            }
                          ]}
                        >
                          <DatePicker.RangePicker
                            format={'DD-MMM-YYYY'}
                            onChange={(x) => {
                              setFilterPayload(x);
                            }}
                            disabledDate={(current) => {
                              return current && current.valueOf() > Date.now();
                            }}
                            value={filterPayload}
                            // separator={'-'}
                            // style={{ width: '100%' }}
                          />
                        </FormItem>
                      </Space>
                    </Modal>
                  </div>
                </a>
              </Row>
            </Card>
            <Card className={`${styles.cardStyles}`}>
              <Row className={`${styles.row_container}`}>
                <div className={`${styles.card_content}`}>
                  <Row>
                    <span className={`${styles.icon_size}`}>
                      <FileProtectOutlined />
                    </span>{' '}
                    <span className={`${styles.card_content_text}`}>
                      Export All Users Report
                    </span>
                  </Row>
                </div>
                <a>
                  <div>
                    <Image
                      className={`${styles.gift_icon_size}`}
                      alt="gift_requests-icon"
                      src={arrow}
                      preview={false}
                      onClick={allUsersModal}
                    />
                    <Modal
                      title="Export all user details"
                      open={isAllUsersModalOpen}
                      onOk={handleAllUsersOk}
                      onCancel={handleAllUsersCancel}
                      footer={[
                        <Button key="back" onClick={handleAllUsersCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="link"
                          type="primary"
                          // onClick={handleGROk}
                          onClick={handleAllUsersExportasCsv()}
                        >
                          Download
                        </Button>
                      ]}
                    >
                      <h3>Do you want to download all user details?</h3>
                      {/*  <Space direction="horizontal">
                        <FormItem
                          style={{ fontWeight: '800' }}
                          label="Select Date Range"
                          name="fromDate"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter date'
                            }
                          ]}
                        >
                          <DatePicker.RangePicker
                            format={'DD-MMM-YYYY'}
                            onChange={(x) => {
                              setFilterPayload(x);
                            }}
                            disabledDate={(current) => {
                              return current && current.valueOf() > Date.now();
                            }}
                            value={filterPayload}
                            // separator={'-'}
                            // style={{ width: '100%' }}
                          />
                        </FormItem>
                      </Space> */}
                    </Modal>
                  </div>
                </a>
              </Row>
            </Card>
          </div>
          {/*  {entryDelegatorModal && ( 
          <EntryDelegatorModal  
            entryDelegatorModal={entryDelegatorModal}
            setEntryDelegatorModal={setEntryDelegatorModal}
            get_all_usersLoading={get_all_usersLoading}
            all_users_List={all_users_List}
            debounceFetcherAllUsers={debounceFetcherAllUsers}
          />
        )} */}
        </div>
      </div>
    </CustomLayout>
  );
};

export default ReportPage;

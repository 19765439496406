import React from 'react';
import BulkUploadPage from './components/bulkUploadPage';
import CustomLayout from '@/common/Layout';
import { getLoggedInUser, TabTitle } from '../../utilities/helpers';

const BulkUpload = () => {
  TabTitle('Gift Register-Bulk Upload');
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;

  return (
    <>
      <CustomLayout>
        <div style={{ marginTop: '50px' }}>
          <BulkUploadPage user_role={user_role} />
        </div>
      </CustomLayout>
    </>
  );
};

export default BulkUpload;

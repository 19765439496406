import React, { useState, useEffect } from 'react';
import CEC_Users_FlowsPage from './CEC_Users_FlowsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_Designated_Authorities,
  remove_designated_authorities
} from '../../../redux/slice';
import FormData from 'form-data';

function CEC_Users_Flows() {
  TabTitle('Gift Register - Entry Delegator Flows');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    designated_Authorities_List,
    pagination,
    get_Designated_AuthoritiesLoading,
    remove_entry_delegatorLoading
  } = useSelector((state) => state.admin);

  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  //console.log('searchRequest', searchRequest);
  //console.log(triggerFilter);
  console.log('designated_Authorities_List', designated_Authorities_List);

  useEffect(() => {
    handleGetAllEntryDelegatorsList();
  }, [page, pageSize]);
  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllEntryDelegatorsList();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]); //page,

  const handleGetAllEntryDelegatorsList = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest,
      role: 'cec-user'
    };
    dispatch(get_Designated_Authorities(payload));
  };

  /* const handleChange = (e) => {
    const value = { search: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  };*/
  const handleDeleteRequest = (record) => {
    let formdata = new FormData();
    //console.log('recordRRRRRRRRRRRRRR', record?.record);
    record?.record?.status === false
      ? formdata.append('isActive', true)
      : formdata.append('isActive', false);
    formdata.append('id', record?.record?.key);
    dispatch(remove_designated_authorities(formdata)).then(function (response) {
      if (response) {
        handleGetAllEntryDelegatorsList();
      }
    });
  };
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <CEC_Users_FlowsPage
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          setTriggerFilter={setTriggerFilter}
          designated_Authorities_List={designated_Authorities_List}
          pagination={pagination}
          get_Designated_AuthoritiesLoading={get_Designated_AuthoritiesLoading}
          setSearchRequest={setSearchRequest}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          handleDeleteRequest={handleDeleteRequest}
          remove_entry_delegatorLoading={remove_entry_delegatorLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default CEC_Users_Flows;

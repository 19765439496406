//import OneClickPublicAccessPage from './components/welcomePage';
import CustomLayoutPublic from '@/common/LayoutPublic';
//import { getLoggedInUser, TabTitle } from '../../utilities/helpers';

const OneClickReject = () => {
  //   TabTitle('OneClickPublicAccess to the Gift Register');
  //   const user_role1 = getLoggedInUser();
  //   const user_role = user_role1?.user;
  return (
    <>
      <CustomLayoutPublic>
        <div style={{ marginTop: '50px', textAlign: 'center' }}>
          <div
            style={{ fontSize: '20px', fontWeight: '600', marginTop: '100px' }}
          >
            Gift rejected successfully!
          </div>
        </div>
      </CustomLayoutPublic>
    </>
  );
};

export default OneClickReject;

import React, { useState } from 'react';
import styles from './index.module.less';
import {
  Button,
  Col,
  Row,
  Form,
  Card,
  Typography,
  Spin,
  Tooltip,
  Divider,
  Steps,
  Tag
} from 'antd';
import FormItem from 'common/FormItem';
import { history } from 'app/history';
import ExceptionalApproval_Modal from './exceptional_approval_Modal';
import RejectGift_Modal from './rejectGift_Modal';
import {
  getLoggedInUser,
  numIntStyleFormat,
  Recipient_Type
} from '../../../../utilities/helpers';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';
// import FormData from 'form-data';
// import {
//   returnGift_RO_form,
//   returnGift_Giver_form
// } from '../../../view_gift_return/redux/slice';
// import { useDispatch } from 'react-redux';

const { Text } = Typography;

function ViewGiftRequestDetailsPage({
  getGiftByIdLoading,
  getGiftDetailsById,
  Id,
  type,
  hashRoot,
  uniqueId,
  userId
  //exceptionRequestsLoading
}) {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();

  //const dispatch = useDispatch();

  const [ellipsis] = useState(true);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  console.log('user_role1', user_role);

  const redirect_action = LocalStorage.getItem('redirect_action');
  console.log('redirect_action@@@@@@', redirect_action);

  console.log('getGiftDetailsById', getGiftDetailsById);

  const getGiftDetailsById_Status =
    getGiftDetailsById?.GivingFlowWorkings?.length > 0
      ? getGiftDetailsById?.GivingFlowWorkings[0]?.status
      : '';

  const [exceptionalApprovalModal, setExceptionalApprovalModal] =
    useState(false);
  //const [returnToROModal, setReturnToROModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const onFinish = (values) => {
    console.log('Values', values);
  };
  // const HandleReturnGift = (payload) => {
  //   console.log('pay####', payload);
  //   let formdata = new FormData();

  //   formdata.append('grId', payload?.grId);
  //   payload?.whom_return === true
  //     ? ''
  //     : formdata.append(
  //         ' roId',
  //         payload?.roId === undefined ? '' : payload?.roId
  //       );

  //   for (let i in payload?.fileList) {
  //     formdata.append(
  //       'roDocument',
  //       payload?.fileList?.length === 0
  //         ? ''
  //         : payload?.fileList[i]?.originFileObj
  //     );
  //   }
  //   formdata.append(
  //     'commentsOfReturn',
  //     payload?.commentsOfReturn === undefined ? '' : payload?.commentsOfReturn
  //   );
  //   payload?.whom_return === true
  //     ? dispatch(returnGift_Giver_form(formdata)).then(function (response) {
  //         if (response?.payload?.success) {
  //           console.log(response);
  //           setReturnToROModal(false);
  //           history.push('/view-gift-requests');
  //         }
  //       })
  //     : dispatch(returnGift_RO_form(formdata)).then(function (response) {
  //         if (response?.payload?.success) {
  //           console.log(response);
  //           setReturnToROModal(false);
  //           history.push('/view-gift-requests');
  //         }
  //       });
  // };

  const items =
    getGiftDetailsById?.GivingFlow?.length > 0
      ? getGiftDetailsById?.GivingFlow?.map((item) => {
          return {
            title:
              item?.status === 'accepted' ? (
                <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
              ) : item?.status === 'pending' ? (
                <span style={{ color: '#2264ae' }}>Pending</span>
              ) : item?.status === 'rejected' ? (
                'Rejected'
              ) : item?.status === null ? (
                <span style={{ color: ' rgba(0, 0, 0, 0.45)' }}>Waiting</span>
              ) : (
                ''
              ),
            status:
              item?.status === 'accepted'
                ? 'finish'
                : item?.status === 'pending'
                ? 'process'
                : item?.status === 'rejected'
                ? 'error'
                : item?.status === null
                ? 'wait'
                : 'wait',

            description:
              item?.status === 'accepted' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Approved by:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      {' '}
                      Approved on:
                    </span>{' '}
                    {moment(item?.updatedAt).format('DD MMM YYYY')}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>Notes:</span>
                    <Tooltip
                      title={
                        item?.approverComment === null
                          ? 'NA'
                          : item?.approverComment
                      }
                      color={'white'}
                      overlayInnerStyle={{ color: '#32324D', fontSize: '11px' }}
                      arrowPointAtCenter="false"
                    >
                      {item?.approverComment === null ? (
                        ' NA'
                      ) : (
                        <Text
                          className={`${styles.ant_collapse_header}`}
                          //  ellipsis={true}
                        >
                          {item?.approverComment}
                        </Text>
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : item?.status === 'rejected' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Rejected by:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Rejected on:
                    </span>{' '}
                    {moment(item?.updatedAt).format('DD MMM YYYY')}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>Notes:</span>
                    <Tooltip
                      title={
                        item?.approverComment === null
                          ? 'NA'
                          : item?.approverComment
                      }
                      color={'white'}
                      overlayInnerStyle={{ color: '#32324D', fontSize: '11px' }}
                      arrowPointAtCenter="false"
                    >
                      {item?.approverComment === null ? (
                        ' NA'
                      ) : (
                        <Text
                          className={`${styles.ant_collapse_header}`}
                          // ellipsis={true}
                        >
                          {item?.approverComment}
                        </Text>
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : item?.status === 'pending' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      waiting for approval:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                </div>
              ) : item?.status === null ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Waiting for Approval:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                </div>
              ) : (
                ''
              )
            //subTitle: item?.updatedAt
          };
        })
      : '';

  const handleFetchDocument = (fileName) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };
  return (
    <Spin spinning={getGiftByIdLoading}>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <Col span={19} className={`${styles.gift_container}`}>
            <div
              className={
                isMobile
                  ? `${styles.myGift_title_isMobile}`
                  : `${styles.myGift_title}`
              }
            >
              {getGiftDetailsById?.ticket}
              <span style={{ textTransform: 'uppercase' }}>
                -{getGiftDetailsById?.type}
              </span>
            </div>
            <div
              className={
                isMobile
                  ? `${styles.myGift_text_isMobile}`
                  : `${styles.myGift_text}`
              }
            >
              View the details of the gift
            </div>
          </Col>
          {/* <Col span={14} className={`${styles.mySubGift_title}`}>
            Details of the Gift for Review
          </Col> */}
        </Row>
        <Card className={`${styles.card_styles}`}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
          >
            <Typography className={`${styles.CardTitleText}`}>
              {`Requestor Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.givenName}{' '}
                    {getGiftDetailsById?.Creator?.surname}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.designation === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.designation}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Location
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.location === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.location}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Email id
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <a>
                      {getGiftDetailsById?.Creator?.email === null
                        ? 'NA'
                        : getGiftDetailsById?.Creator?.email}
                    </a>
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Department/Function
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Line Manager
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.ReportingManager?.User
                      ?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.ReportingManager?.User
                          ?.givenName}{' '}
                    {
                      getGiftDetailsById?.Creator?.ReportingManager?.User
                        ?.surname
                    }
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Employee id
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.empId === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.empId}{' '}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Business Unit (BU)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Creator?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of reporting of the gift
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Creator?.dateOfReporting === null
                      ? 'NA'
                      : moment(getGiftDetailsById?.dateOfReporting).format(
                          'DD MMM YYYY'
                        )}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Typography className={`${styles.CardTitleText}`}>
              {`Recipient Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />

            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Full Name of the Recipient
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.recipientName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Type of Recipient
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.recipientType}
                  </Typography>
                </FormItem>
              </Col>
              {getGiftDetailsById?.wasThereOngoingTender === true ? (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide details of the ongoing tender/discussion and the
                    role played by the recipient of the proposed gift
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.ongoingTenderDiscussion === null ||
                      getGiftDetailsById?.ongoingTenderDiscussion === ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.ongoingTenderDiscussion}
                    </Typography>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Recipient Organization Full Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.organizationName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Is it a Govt. Official
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {Recipient_Type(getGiftDetailsById?.giftReceiverSector)}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation of the Recipient
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.designation : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <>
                  <div className={`${styles.titleText}`}>
                    Is there any ongoing tender/discussion with the company at
                    time of this gift?
                  </div>
                  <Typography
                    className={`${styles.valueText} ${styles.responsive_margin}`}
                  >
                    {' '}
                    {getGiftDetailsById?.wasThereOngoingTender === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </>
                {/* <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      <Tooltip
                        title={`Is there any ongoing tender/discussion with the
                          company at time of this gift?`}
                        color={'white'}
                        overlayInnerStyle={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: 600
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header_label}`}
                          ellipsis={true}
                        >
                          Is there any ongoing tender/discussion with the
                          company at time of this gift?
                        </Text>
                      </Tooltip>
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {getGiftDetailsById?.wasThereOngoingTender === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </FormItem> */}
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <>
                  <div className={`${styles.titleText}`}>
                    Is this a gift to a family member of a supplier, customer,
                    or other partner?
                  </div>
                  <Typography
                    className={`${styles.valueText} ${styles.responsive_margin}`}
                  >
                    {' '}
                    {getGiftDetailsById?.giftReceivedByFamily === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </>
                {/*  <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      <Tooltip
                        title={`Is this a gift to a family member of the 
                        recipient or its business partner? `}
                        color={'white'}
                        overlayInnerStyle={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: 600
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header_label}`}
                          ellipsis={true}
                        >
                          Is this a gift to a family member of the recipient or
                          its business partner?
                        </Text>
                      </Tooltip>

                     
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {getGiftDetailsById?.giftReceivedByFamily === true
                      ? 'Family Person'
                      : 'Business Partner'}
                  </Typography>
                </FormItem> */}
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              {getGiftDetailsById?.wasThereOngoingTender === true ? (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide a business justification for why this gift was given
                    during an ongoing tender/discussion
                  </div>
                  <div>
                    <Typography
                      className={`${styles.valueText} ${styles.responsive_margin}`}
                    >
                      {getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        null ||
                      getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender}
                    </Typography>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <div className={`${styles.responsive_margin_GiftDetails}`}>
              <Row className={`${styles.responsive_row}`}>
                <Typography
                  className={`${styles.CardTitleText}`}
                  style={{ marginTop: '10px' }}
                >
                  {`Gift Details`}
                </Typography>
              </Row>
            </div>
            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  // label="Gift Type"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Gift Type
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.giftName : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular',
                        marginTop: isMobile ? '-20px' : ''
                      }}
                    >
                      Gift being procured locally?
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.procuredHow === 'tpl' ? 'No' : 'Yes'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontWeight: 600,
                    fontSize: '13px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Context/business purpose of the gift
                </div>
                <div style={{ marginBottom: isMobile ? '10px' : '' }}>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.contextOrBusinessPurposeOfTheGift ===
                    null
                      ? 'NA'
                      : getGiftDetailsById?.contextOrBusinessPurposeOfTheGift}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Description of Gift
                </div>
                <div style={{ marginBottom: isMobile ? '10px' : '' }}>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giftDescription === ''
                      ? 'Not applicable'
                      : getGiftDetailsById?.giftDescription}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Date of gift received"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of gift giving
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {moment(getGiftDetailsById?.dateOfGiftReceived).format(
                      'DD MMM YYYY'
                    )}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                marginBottom: isMobile ? '0px' : '20px',
                marginTop: isMobile ? '0px' : '10px'
              }}
              className={`${styles.row_display_isMobile}`}
            >
              <Col span={isMobile ? 24 : 8}>
                <>
                  <div className={`${styles.titleText1}`}>
                    Taken from the stock of promotion items from Marketing
                    bearing the Company Logo
                  </div>
                  <Typography
                    className={`${styles.valueText} ${styles.responsive_margin}`}
                  >
                    {getGiftDetailsById?.isGiftEmbossed === true
                      ? 'Yes'
                      : getGiftDetailsById?.isGiftEmbossed === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </>
                {/*  <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      <Tooltip
                        title={` Taken from the stock of promotion items from Marketing
                        bearing the Company Logo `}
                        color={'white'}
                        overlayInnerStyle={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: 600
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header_label}`}
                          ellipsis={true}
                        >
                          Taken from the stock of promotion items from Marketing
                          bearing the Company Logo
                        </Text>
                      </Tooltip>
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.isGiftEmbossed === true
                      ? 'Yes'
                      : getGiftDetailsById?.isGiftEmbossed === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </FormItem> */}
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Value of the gift (in INR)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {numIntStyleFormat(getGiftDetailsById?.valueOfGift)}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            {getGiftDetailsById?.type === 'gag' ? (
              <Row gutter={16}>
                {user_role?.roles[0] === 'cxo' ? (
                  ''
                ) : (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      //label="Number of gift articles"
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          {user_role?.roles[0] === 'bu-head'
                            ? 'Pre-approval from your SBG/Functional Head'
                            : 'BU/Department Head Pre-approval'}
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        {' '}
                        {getGiftDetailsById?.approval1 === true
                          ? 'Yes'
                          : getGiftDetailsById?.approval1 === false
                          ? 'No'
                          : 'Not applicable'}
                      </Typography>
                    </FormItem>
                  </Col>
                )}
                {user_role?.roles[0] === 'cxo' ? (
                  ''
                ) : (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          {user_role?.roles[0] === 'bu-head'
                            ? 'Pre-approval documents by SBG/Functional Head'
                            : 'Pre-approval documents by BU Head'}
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        <div
                          style={{
                            marginTop: '0px',
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          {getGiftDetailsById?.PreApprovals?.length === 0
                            ? 'Not applicable'
                            : getGiftDetailsById?.PreApprovals?.map(
                                (item, index) => (
                                  <Tooltip
                                    title={item?.originalName}
                                    key={index}
                                  >
                                    <Text
                                      style={
                                        ellipsis
                                          ? {
                                              width: 80
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        ellipsis
                                          ? {
                                              tooltip: ''
                                            }
                                          : false
                                      }
                                    >
                                      {/*  <a
                                        href={item?.fileUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item?.originalName}
                                      </a> */}
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          handleFetchDocument(item?.fileName);
                                        }}
                                      >
                                        {item?.originalName}
                                      </Button>
                                    </Text>
                                  </Tooltip>
                                )
                              )}
                        </div>
                      </Typography>
                    </FormItem>
                  </Col>
                )}
                {getGiftDetailsById?.approval1 === true &&
                getGiftDetailsById?.giftReceiverSector === 'non-government' &&
                getGiftDetailsById?.procuredHow === 'local' ? (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      // label="Value of gift (INR)"
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          CEC Delegate Pre-approval
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        {getGiftDetailsById?.hasCECApproval === true
                          ? 'Yes'
                          : getGiftDetailsById?.hasCECApproval === false
                          ? 'No'
                          : 'Not applicable'}
                      </Typography>
                    </FormItem>
                  </Col>
                ) : (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      // label="Value of gift (INR)"
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          MD/MD Delegate Pre-approval
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        {getGiftDetailsById?.approval2 === true
                          ? 'Yes'
                          : getGiftDetailsById?.approval2 === false
                          ? 'No'
                          : 'Not applicable'}
                      </Typography>
                    </FormItem>
                  </Col>
                )}
              </Row>
            ) : (
              ''
            )}
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              {getGiftDetailsById?.type === 'gag' ? (
                getGiftDetailsById?.hasCECApproval === true ? (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          Pre-approval documents by CEC Delegate
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        <div
                          style={{
                            marginTop: '0px',
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          {getGiftDetailsById?.CECApprovalDocs?.length === 0
                            ? 'Not applicable'
                            : getGiftDetailsById?.CECApprovalDocsF?.map(
                                (item, index) => (
                                  <Tooltip
                                    title={item?.originalName}
                                    key={index}
                                  >
                                    <Text
                                      style={
                                        ellipsis
                                          ? {
                                              width: 80
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        ellipsis
                                          ? {
                                              tooltip: ''
                                            }
                                          : false
                                      }
                                    >
                                      {/* <a
                                        href={item?.fileUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item?.originalName}
                                      </a> */}
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          handleFetchDocument(item?.fileName);
                                        }}
                                      >
                                        {item?.originalName}
                                      </Button>
                                    </Text>
                                  </Tooltip>
                                )
                              )}
                        </div>
                      </Typography>
                    </FormItem>
                  </Col>
                ) : (
                  <Col span={isMobile ? 24 : 8}>
                    <FormItem
                      label={
                        <span
                          style={{
                            color: '#344054',
                            fontWeight: 600,
                            fontSize: '14px',
                            fontFamily: 'SFProText Regular'
                          }}
                        >
                          Pre-approval documents by MD Delegate
                        </span>
                      }
                      className={`${styles.select_styles}`}
                    >
                      <Typography className={`${styles.valueText}`}>
                        <div
                          style={{
                            marginTop: '0px',
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          {getGiftDetailsById?.ApprovalDocs?.length === 0
                            ? 'Not applicable'
                            : getGiftDetailsById?.ApprovalDocs?.map(
                                (item, index) => (
                                  <Tooltip
                                    title={item?.originalName}
                                    key={index}
                                  >
                                    <Text
                                      style={
                                        ellipsis
                                          ? {
                                              width: 80
                                            }
                                          : undefined
                                      }
                                      ellipsis={
                                        ellipsis
                                          ? {
                                              tooltip: ''
                                            }
                                          : false
                                      }
                                    >
                                      {/* <a
                                        href={item?.fileUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item?.originalName}
                                      </a> */}
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          handleFetchDocument(item?.fileName);
                                        }}
                                      >
                                        {item?.originalName}
                                      </Button>
                                    </Text>
                                  </Tooltip>
                                )
                              )}
                        </div>
                      </Typography>
                    </FormItem>
                  </Col>
                )
              ) : (
                ''
              )}
            </Row>

            <Typography
              className={`${styles.CardTitleText}`}
              style={{ marginTop: '10px' }}
            >
              {`Review Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />

            {getGiftDetailsById?.CreatorId === user_role?.id ? (
              getGiftDetailsById?.status === 'accepted' ? (
                <Tag className={`${styles.status_style}`} color={'green'}>
                  Approved
                </Tag>
              ) : getGiftDetailsById?.status === 'rejected' ? (
                <>
                  <Tag className={`${styles.status_style}`} color={'red'}>
                    Rejected
                  </Tag>
                  <div className={`${styles.reasonForReject_text}`}>
                    {getGiftDetailsById?.reasonForReject}
                  </div>
                </>
              ) : getGiftDetailsById?.status === 'pending' ? (
                <Tag className={`${styles.status_style}`} color={'blue'}>
                  Pending
                </Tag>
              ) : (
                ''
              )
            ) : getGiftDetailsById?.GivingFlow?.length > 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row'
                  }}
                >
                  <Steps
                    className={`${styles.steps_style}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      color: '#2264ae'
                    }}
                    size="small"
                    current={getGiftDetailsById?.GivingFlow?.length}
                    items={items}
                    //progressDot={customDot}
                    responsive={true}
                  />
                </div>{' '}
              </>
            ) : getGiftDetailsById_Status === 'accepted' ? (
              <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
            ) : getGiftDetailsById_Status === 'rejected' ? (
              <span style={{ color: '#B71212' }}>
                Rejected
                {getGiftDetailsById?.GivingFlow?.length === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      justifyItems: 'flex-start',
                      flexDirection: 'column',
                      fontSize: '11px',
                      color: ' rgba(0, 0, 0, 0.45)'
                    }}
                  >
                    <div>
                      <Tooltip
                        title={getGiftDetailsById?.reasonForReject}
                        color={'white'}
                        overlayInnerStyle={{
                          color: '#32324D',
                          fontSize: '11px'
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header1}`}
                          ellipsis={true}
                        >
                          {getGiftDetailsById?.reasonForReject}
                        </Text>
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </span>
            ) : (
              ''
            )}
            <Divider />

            {getGiftDetailsById_Status === 'pending' ? (
              <Row style={{ marginTop: '20px' }}>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button1}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => {
                      redirect_action === 'home_action_gag'
                        ? history.push('/view-gift-requests')
                        : redirect_action === 'dashboard_action'
                        ? history.push('/action-required')
                        : '';
                    }}
                  >
                    Go back
                  </Button>
                </span>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => setExceptionalApprovalModal(true)}
                  >
                    Approve
                  </Button>
                </span>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button_reject}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => setRejectModal(true)}
                  >
                    Reject
                  </Button>
                </span>
              </Row>
            ) : (
              <div style={{ marginTop: '20px' }}>
                <Button
                  className={`${styles.Goback_button}`}
                  htmlType="submit"
                  size="small"
                  onClick={() => {
                    redirect_action === 'home_action_gag'
                      ? history.push('/view-gift-requests')
                      : redirect_action === 'dashboard_action'
                      ? history.push('/action-required')
                      : history.push('/view-gift-requests');
                  }}
                >
                  Go back
                </Button>
              </div>
            )}
          </Form>
        </Card>
        {exceptionalApprovalModal ? (
          <ExceptionalApproval_Modal
            exceptionalApprovalModal={exceptionalApprovalModal}
            setExceptionalApprovalModal={setExceptionalApprovalModal}
            getGiftDetailsById={getGiftDetailsById}
            Id={Id}
            type={type}
            hashRoot={hashRoot}
            uniqueId={uniqueId}
            userId={userId}
            redirect_action={redirect_action}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
        {rejectModal ? (
          <RejectGift_Modal
            rejectModal={rejectModal}
            setRejectModal={setRejectModal}
            getGiftDetailsById={getGiftDetailsById}
            Id={Id}
            type={type}
            hashRoot={hashRoot}
            uniqueId={uniqueId}
            userId={userId}
            redirect_action={redirect_action}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
        {/*  {returnToROModal ? (
          <ReturnRO_Modal
            returnToROModal={returnToROModal}
            setReturnToROModal={setReturnToROModal}
            getGiftDetailsById={getGiftDetailsById}
            HandleReturnGift={HandleReturnGift}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )} */}
      </div>
    </Spin>
  );
}
export default ViewGiftRequestDetailsPage;
/* : getGiftDetailsById?.status === 'rejected' ? (
                <Row style={{ marginTop: '20px' }}>
                  <span style={{ marginRight: '10px' }}>
                    <Button
                      className={`${styles.Goback_button1}`}
                      htmlType="submit"
                      size="small"
                      onClick={() => history.push('/view-gift-requests')}
                    >
                      Go back
                    </Button>
                  </span>
                  <span style={{ marginRight: '10px' }}>
                    <Button
                      className={`${styles.Goback_button}`}
                      htmlType="submit"
                      size="small"
                      onClick={() => setReturnToROModal(true)}
                    >
                      Return
                    </Button>
                  </span>
                </Row>
              ) */

import React from 'react';
import { Modal, Button } from 'antd';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { roGiftAccept } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';

//const { TextArea } = Input;

const Approval_Modal = ({
  exceptionalApprovalModal,
  setExceptionalApprovalModal
  //getGiftDetailsById,
  // exceptionRequestsLoading
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { roGiftAcceptLoading } = useSelector((state) => state.receivedView);

  const onFinish = () => {
    const payload = {
      grId: params.id
      //approverComments: values?.reason_for_excemption
    };

    dispatch(roGiftAccept(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);

        setExceptionalApprovalModal(false);
        history.push('/view-gift-returns');
      }
    });
  };

  return (
    <>
      <Modal
        title={<span>Please confirm{/* Exception approval */}</span>}
        visible={exceptionalApprovalModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setExceptionalApprovalModal(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <div className={`${styles.return_title_RO}`}>
            Are you sure do you want to confirm Gift returned?
          </div>
          <div className={`${styles.return_subtitle_RO}`}>
            (This action cannot be undone)
          </div>
          <FormItem>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                justifyItems: ' center',
                marginBottom: '-45px'
              }}
            >
              <Button
                loading={roGiftAcceptLoading}
                className={`${styles.ExceptionalApproval_button}`}
                htmlType="submit"
                size="small"
              >
                Confirm Return
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setExceptionalApprovalModal(false)}
              >
                Cancel
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default Approval_Modal;

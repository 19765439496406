import React, { useState, useEffect } from 'react';
import ViewGiftRequestPage from './components/viewGiftRequestPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getPastGiftsList } from './redux/slice';

function ViewGiftRequest() {
  TabTitle('Gift Register - View Gift Request Status');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    pastGiftsList,
    pagination,
    pastGiftsListLoading,
    CountsPastGiftsList
  } = useSelector((state) => state.pastGifts);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [status, setStatus] = useState();
  const [isSwitch, setIsSwitch] = useState(true);
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);

  // console.log('searchRequest', searchRequest);
  // //console.log(triggerFilter);
  // console.log('pastGiftsList', pastGiftsList);

  useEffect(() => {
    handleGetPastGiftsList();
  }, [page, pageSize]);
  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetPastGiftsList();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest, isSwitch]); //page,

  const handleGetPastGiftsList = () => {
    setReqAllocInvoiceKeys([]);
    setReqAllocInvoices([]);
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest,
      delegation_type: isSwitch === true ? 'self' : 'delegated'
    };
    dispatch(getPastGiftsList(payload));
  };

  useEffect(() => {
    if (pastGiftsList?.GivingFlowWorkings?.length > 0) {
      let sta = pastGiftsList?.GivingFlowWorkings[0]?.status;
      setStatus(sta);
    }
  }, [pastGiftsList]);

  const onChange = () => {
    setTriggerFilter(true);
    setIsSwitch(!isSwitch);
  };
  /* const handleChange = (e) => {
    const value = { search: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  }; */

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftRequestPage
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          setTriggerFilter={setTriggerFilter}
          pastGiftsList={pastGiftsList}
          pagination={pagination}
          pastGiftsListLoading={pastGiftsListLoading}
          CountsPastGiftsList={CountsPastGiftsList}
          setSearchRequest={setSearchRequest}
          status={status}
          onChange={onChange}
          reqAllocInvoiceKeys={reqAllocInvoiceKeys}
          setReqAllocInvoiceKeys={setReqAllocInvoiceKeys}
          reqAllocInvoices={reqAllocInvoices}
          setReqAllocInvoices={setReqAllocInvoices}
          handleGetPastGiftsList={handleGetPastGiftsList}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewGiftRequest;

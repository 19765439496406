import React, { useState, useEffect, useMemo } from 'react'; //, useMemo
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Space, Row, Input, Select } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  update_ROUsers_master,
  // get_all_users,
  get_RO_Master
} from '../../../../redux/slice';
import { debounce } from 'lodash';
//import FormData from 'form-data';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

//const { TextArea } = Input;
const { Option } = Select;

function Edit_roUsersFlows() {
  TabTitle('Gift Register - Maping Ro Users Flow');

  const params = useParams();

  const roRecord = LocalStorage.getItem('roUsers_record');
  //console.log('buRecord%%%%%%%%%', roRecord);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  //const [allUsersSearch, setAllUsersSearch] = useState('');
  const [allLocationSearch, setAllLocationSearch] = useState('');
  //const [allLocationAddress, setAllLocationAddress] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');

  const { update_ROUsers_masterLoading, ro_MasterList } = useSelector(
    (state) => state.admin
  );
  //console.log('all_users_List', all_users_List);

  useEffect(() => {
    form.setFieldsValue({
      locationName: roRecord?.location,
      locationAddress: roRecord?.locationAddress,
      id: roRecord?.id
    });
  }, []);

  useEffect(() => {
    let locationValues = ro_MasterList?.filter(
      (item) => item?.id === formValues?.locationName
    );
    console.log('locationValues', locationValues);
    setLocationName(locationValues[0]?.location);
    setLocationAddress(locationValues[0]?.locationAddress);
  }, [formValues?.locationName]);

  console.log('LocationName', locationName);
  console.log('LocationAddress', locationAddress);
  console.log('roRecord?.locationAddress', roRecord?.roAddress);

  /*  useEffect(() => {
    const payload = {};
    if (allLocationSearch) payload['search'] = allLocationSearch;
    dispatch(update_ROUsers_master(payload));
  }, [allLocationSearch]); */

  /* useEffect(() => {
    const payload = {};
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]); */

  useEffect(() => {
    const payload = {};
    if (allLocationSearch) payload['search'] = allLocationSearch;
    dispatch(get_RO_Master(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log('response?.payload?.data', response?.payload?.data);
        /*  setAllLocationAddress(response?.payload?.data);
        form.setFieldsValue({
          locationAddress: response?.payload?.data?.locationAddress
        }); */
      }
    });
  }, [allLocationSearch]);
  //console.log('setAllLocationAddress', allLocationAddress);
  const debounceFetcherUserId = useMemo(() => {
    const loadOptions = (allLocationSearch) => {
      setAllLocationSearch(allLocationSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    //let formdata = new FormData();
    /*  formdata.append('id', params?.id);
    formdata.append(
      'locationName',
      formValues?.locationName === undefined
        ? roRecord?.location
        : formValues?.locationName
    );

    formdata.append(
      'locationAddress',
      formValues?.locationAddress === undefined
        ? roRecord?.locationAddress
        : formValues?.locationAddress
    ); */
    const payload = {
      id: params?.id,
      locationName:
        locationName === undefined ? roRecord?.location : locationName,
      locationAddress:
        locationAddress === undefined ? roRecord?.roAddress : locationAddress
    };
    dispatch(update_ROUsers_master(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/roUsers-flow');
      }
    });
  };
  console.log('ro_MasterList', ro_MasterList);
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <Row className={`${styles.row_container_setting}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title_setting}`}>
              Maping RO Users
            </div>
          </div>
        </Row>{' '}
        <div className={`${styles.container}`}>
          <div className={`${styles.cardContainer}`}>
            <Card className={`${styles.cardStyles_Container}`}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className={`${styles.formstyles}`}
              >
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Enter the RO User Name
                        </div>
                      }
                      //name="oldUserId"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Input
                        disabled
                        value={roRecord?.userName}
                        className={`${styles.margin_Top}`}
                        allowClear
                        placeholder="Enter the RO User name"
                        onChange={(e) =>
                          handleFormDataOnChange('oldUserId', e.target.value)
                        }
                      />
                    </FormItem>
                  </div>
                </Card>
                {/* <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Enter the Location
                        </div>
                      }
                      name="locationName"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Input
                        className={`${styles.margin_Top}`}
                        allowClear
                        placeholder="Enter the Location"
                        onChange={(e) =>
                          handleFormDataOnChange('locationName', e.target.value)
                        }
                      />
                    </FormItem>
                  </div>
                </Card> */}
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Select the Location
                        </div>
                      }
                      name="locationName"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        className={`${styles.margin_Top}`}
                        style={{ marginBottom: '15px' }}
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        placeholder="Enter the Location"
                        onSearch={debounceFetcherUserId}
                        onChange={(e) =>
                          handleFormDataOnChange('locationName', e)
                        }
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {ro_MasterList?.map((item, index) => (
                          <Option
                            title={item?.location}
                            key={index}
                            value={item?.id}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                textTransform: 'uppercase',
                                fontFamily: 'SFProText Regular'
                              }}
                            >
                              {item?.location}
                            </span>
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </div>
                </Card>
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Enter the Location Address
                        </div>
                      }
                      //name="locationAddress"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Input
                        value={
                          locationAddress === undefined
                            ? roRecord?.roAddress
                            : locationAddress
                        }
                        className={`${styles.margin_Top}`}
                        allowClear
                        placeholder="Enter the Location Address"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'locationAddress',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </div>
                </Card>

                <div style={{ marginTop: '20px' }}>
                  <FormItem>
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        justifyItems: ' center'
                      }}
                    >
                      <Button
                        loading={update_ROUsers_masterLoading}
                        className={`${styles.ExceptionalApproval_button}`}
                        htmlType="submit"
                        size="small"
                      >
                        Save
                      </Button>

                      <Button
                        className={`${styles.cancel_rejectModal_button}`}
                        size="small"
                        onClick={() => history.push('/roUsers-flow')}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </FormItem>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </CustomLayout>
  );
}
export default Edit_roUsersFlows;

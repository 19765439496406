import apiClient from 'utilities/apiClient';

export const past_gifts_list = (payload) => {
  return apiClient.get(`${apiClient.Urls.past_gifts_list}`, payload, true);
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.requester}/${url}`, payload, true);
};

export const delete_draft = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.requester}/${url}`, payload, true);
};

import styles from './index.module.less';
import {
  Radio,
  //Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  //Badge,
  Tag
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
//import { CopyOutlined } from '@ant-design/icons';
// import { history } from 'app/history';
import moment from 'moment';
import { numIntStyleFormat } from '../../../../utilities/helpers';
import { sizeChecker } from 'utilities/helpers';
//import Switcher from '../../../../common/Switcher';

const filters = [
  {
    name: 'Gift Returns',
    value: ''
  },
  {
    name: 'To be Confirmed',
    value: 'pending'
  },
  {
    name: 'Confirmed',
    value: 'received'
  }
  // {
  //   name: 'Accepted',
  //   value: 'accepted'
  // },
  // {
  //   name: 'Rejected',
  //   value: 'rejected'
  // }
];

function ViewReturnGiftPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  pastGiftsList,
  pagination,
  pastGiftsListLoading,
  CountsReturnGiftsList,
  setSearchRequest
  //onChange
}) {
  const isMobile = sizeChecker('xs');
  const pastGiftsList1 = pastGiftsList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.giverName,
    ticket: item?.ticket,
    GiftType: item?.giftName, //    GiftMaster?.title,
    valueOfGift: item?.valueOfGift,
    dateOfGiftReceived: item?.dateOfGiftReceived,
    organizationName: item?.organizationName,
    designation: item?.designation,
    giftReceiverSector: item?.giftReceiverSector,
    status: item?.status,
    message: item?.giftDescription,
    type: item?.type,
    receivedByRo: item?.receivedByRo,
    contextOrBusinessPurposeOfTheGift: item?.contextOrBusinessPurposeOfTheGift,
    bulkUploadGroupNumber: item?.bulkUploadGroupNumber
  }));
  //console.log('pastGiftsList1@@@@@@@', pastGiftsList1);

  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <Tooltip
                title={'bulk upload'}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {' '}
                {record?.bulkUploadGroupNumber === null ||
                record?.bulkUploadGroupNumber === '' ? (
                  ''
                ) : (
                  <CopyOutlined style={{ color: '#108ee9' }} />
                )}{' '}
              </Tooltip> */}
              <a href={`view-gift-return-details/${record.id}?${record.type}`}>
                {ticket === null ? '' : ticket}
              </a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Giver',
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 150,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.recipientName?.localeCompare(b?.recipientName),
      render: (recipientName, record) => {
        return (
          <>
            <Tooltip
              title={recipientName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a href={`view-gift-return-details/${record.id}?${record.type}`}>
                {recipientName === null ? '' : recipientName}
              </a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Organization'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Organization
        </Tooltip>
      ),
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a?.organizationName?.localeCompare(b?.organizationName),
      ellipsis: true,
      render: (organizationName) => {
        return (
          <>
            <Tooltip
              title={organizationName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {organizationName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Giver Designation'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Giver Designation
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'designation',
      key: 'designation',
      sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
      ellipsis: true,
      render: (designation) => {
        return (
          <>
            <Tooltip
              title={designation}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {designation}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Government Official?'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Government Official?
        </Tooltip>
      ),
      dataIndex: 'giftReceiverSector',
      key: 'giftReceiverSector',
      sorter: (a, b) =>
        a?.giftReceiverSector?.localeCompare(b?.giftReceiverSector),
      ellipsis: true,
      render: (giftReceiverSector) => {
        return (
          <>
            <Tooltip
              title={giftReceiverSector}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giftReceiverSector === 'government' ? 'Yes' : 'No'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Context/Business Purpose of the Gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Context/Business Purpose of the Gift
        </Tooltip>
      ),
      dataIndex: 'contextOrBusinessPurposeOfTheGift',
      key: 'contextOrBusinessPurposeOfTheGift',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) =>
        a?.contextOrBusinessPurposeOfTheGift?.localeCompare(
          b?.contextOrBusinessPurposeOfTheGift
        ),
      render: (contextOrBusinessPurposeOfTheGift) => {
        return (
          <>
            <Tooltip
              title={
                contextOrBusinessPurposeOfTheGift === ''
                  ? 'NA'
                  : contextOrBusinessPurposeOfTheGift
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {contextOrBusinessPurposeOfTheGift === ''
                ? 'NA'
                : contextOrBusinessPurposeOfTheGift}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'GiftType',
      key: 'GiftType',
      sorter: (a, b) => a?.GiftType?.localeCompare(b?.GiftType),
      ellipsis: true,
      render: (GiftType) => {
        return (
          <>
            <Tooltip
              title={GiftType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {GiftType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value',
      dataIndex: 'valueOfGift',
      key: 'valueOfGift',
      ellipsis: {
        showTitle: false
      },
      width: 90,
      sorter: (a, b) => a?.valueOfGift - b?.valueOfGift,
      render: (valueOfGift) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(valueOfGift)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {numIntStyleFormat(valueOfGift)}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Received on',
      dataIndex: 'dateOfGiftReceived',
      key: 'dateOfGiftReceived',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`dateOfGiftReceived`]).unix() -
        moment(b[`dateOfGiftReceived`]).unix(),
      render: (dateOfGiftReceived) => (
        <>
          <Tooltip
            title={
              dateOfGiftReceived === null
                ? ''
                : moment(dateOfGiftReceived).format('DD MMM YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {dateOfGiftReceived === null
              ? ''
              : moment(dateOfGiftReceived).format('DD MMM YY')}
          </Tooltip>
        </>
      )
    },

    // {
    //   title: 'Message',
    //   dataIndex: 'message',
    //   key: 'message',
    //   ellipsis: {
    //     showTitle: false
    //   },
    //   sorter: (a, b) => a?.message?.localeCompare(b?.message),
    //   render: (message) => {
    //     return (
    //       <>
    //         <Tooltip
    //           title={message === '' ? 'NA' : message}
    //           color={'white'}
    //           overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
    //           arrowPointAtCenter="false"
    //           placement="left"
    //         >
    //           {message === '' ? 'NA' : message}
    //         </Tooltip>
    //       </>
    //     );
    //   }
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => {
        console.log(
          'status, record, receivedByRo@@@@@@@@',
          record?.receivedByRo
        );
        return (
          <Tag
            className={`${styles.status_style}`}
            color={
              record?.status === null
                ? 'transparent'
                : record?.status === 'rejected' &&
                  record?.receivedByRo === false
                ? 'blue'
                : record?.status === 'pending'
                ? 'blue'
                : record?.status === 'accepted'
                ? 'green'
                : record?.status === 'returned'
                ? 'magenta'
                : record?.status === 'rejected' && record?.receivedByRo === true
                ? 'green'
                : 'cyan'
            }
          >
            {record?.status === 'rejected' && record?.receivedByRo === false ? (
              <a
                href={`view-gift-return-details/${record.id}?${record.type}`}
                style={{ color: 'blue' }}
              >
                TO BE CONFIRMED
              </a>
            ) : record?.status === 'rejected' &&
              record?.receivedByRo === true ? (
              <a
                href={`view-gift-return-details/${record.id}?${record.type}`}
                style={{ color: 'green' }}
              >
                CONFIRMED
              </a>
            ) : (
              status
            )}
          </Tag>
          /*  <Badge
          className={
            record?.status === 'Rejected'
              ? `${styles.badgeRejStyles}`
              : // : record.status === 'Cancelled'
              // ? `${styles.badgeCanStyles}`
              record.status === 'Pending'
              ? `${styles.badgeReqStyles}`
              : record.status === 'Approved'
              ? `${styles.badgeGpGenStyles}`
              : record.status === 'Returned'
              ? `${styles.badgePartRecivStyles}`
              : // : record.status === 'Printed'
                // ? `${styles.badgeGpPriStyles}`
                // : record.status === 'Closed'
                // ? `${styles.badgeCloseStyles}`
                `${styles.badgeCloseStyles}`
          }
          color={
            record?.status === 'Rejected' || record?.status === 'Rejected'
              ? 'red'
              : record?.status === 'Pending'
              ? 'blue'
              : // : record?.status === 'Cancelled'
              // ? '#820b03'
              record?.status === 'Approved'
              ? ' #059720'
              : record?.status === 'Returned'
              ? '#C11574'
              : // : record?.status === 'Printed'
                // ? '#344054'
                // : record?.status === 'Closed'
                // ? '#18068b' 'tranparent'
                'tranparent'
          }
          text={
            record?.status === 'Rejected'
              ? record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
              : record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
          }
        /> */
        );
      }
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <div className={`${styles.container}`}>
      <Row className={`${styles.row_container}`}>
        <div className={`${styles.gift_container}`}>
          <div
            className={
              isMobile
                ? `${styles.myGift_title_isMobile}`
                : `${styles.myGift_title}`
            }
          >
            Review Gift Returns
          </div>
          <div
            className={
              isMobile
                ? `${styles.myGift_text_isMobile}`
                : `${styles.myGift_text}`
            }
          >
            View and acknowledge gift returns
          </div>
        </div>
        {/* <div className={`${styles.declareGift_div}`}>
          <Switcher
            left={'Self requests'}
            right={'Delegated requests'}
            onChange={onChange}
          />
        </div> */}
      </Row>
      <div
        className={
          isMobile
            ? `${styles.tabs_container_isMobile}`
            : `${styles.tabs_container}`
        }
      >
        <Row className={`${styles.row_display_isMobile}`}>
          <Col span={16} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={
                isMobile
                  ? `${styles.button_import_style_isMobile}`
                  : `${styles.button_import_style}`
              }
            >
              {filters.map((item, index) => {
                return (
                  <Radio.Button
                    className={`${styles.radio_button}`}
                    value={item.value}
                    key={index}
                    onChange={() => {
                      window.localStorage.setItem(
                        'set_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name === 'Gift Returns' ? (
                      <span>
                        {item.name} ({CountsReturnGiftsList?.all})
                      </span>
                    ) : item.name === 'To be Confirmed' ? (
                      <span>
                        {item.name} ({CountsReturnGiftsList?.actionRequired})
                      </span>
                    ) : item.name === 'Confirmed' ? (
                      <span>
                        {item.name} ({CountsReturnGiftsList?.accepted})
                      </span>
                    ) : (
                      item.name
                    )}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Col>
          <Col span={8} style={{ maxWidth: isMobile ? '100%' : '' }}>
            <SearchBar
              onChange={(e) => {
                setSearchRequest(e.target.value);
                setTriggerFilter(true);
              }}
              className={
                isMobile
                  ? `${styles.searchbar_isMobile}`
                  : `${styles.searchbar}`
              }
              inputProps={{
                placeholder: 'Search by giver or request number#'
              }}
            />
          </Col>
        </Row>
      </div>
      <Card className={`${styles.cardStyles}`}>
        <div className={`${styles.table_container}`}>
          <CustomTable
            showSorterTooltip={false}
            columns={columns}
            dataSource={pastGiftsList1}
            locale={locale}
            loading={pastGiftsListLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: pagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
            scroll={{ x: isMobile ? true : false }}
          />
        </div>
      </Card>
    </div>
  );
}
export default ViewReturnGiftPage;

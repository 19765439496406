import apiClient from 'utilities/apiClient';

export const gift_getbyId = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.gift_getbyId}/${url}`, payload, true);
};

export const exception_requests = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.exception_requests}/${url}`,
    payload,
    true
  );
};

export const reject_gift = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.reject_gift}/${url}`, payload, true);
};

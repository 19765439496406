import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'features/login/redux/slice';
import pastGiftsListReducer from 'features/view_past_gift/redux/slice';
import declareGiftFormReducer from 'features/declare_gift_form/redux/slice';
import viewReducer from 'features/view_gift_details/redux/slice';
import receivedGiftsListReducer from 'features/view_gift_received/redux/slice';
import receivedViewReducer from 'features/view_gift_received_details/redux/slice';
import returnGiftsListReducer from 'features/view_gift_return/redux/slice';
import receivedGiftsListRequestReducer from 'features/view_gift_received_request/redux/slice';
import roViewReducer from 'features/view_gift_return_details/redux/slice';
import declareReceivedGiftFormReducer from 'features/gift_received_form/redux/slice';
import receivedRequestViewReducer from 'features/view_gift_received_request_details/redux/slice';
import adminReducer from 'features/admin/redux/slice';
import dashboardReducer from 'features/welcome/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    pastGifts: pastGiftsListReducer,
    declareGiftForm: declareGiftFormReducer,
    view: viewReducer,
    receivedGifts: receivedGiftsListReducer,
    receivedView: receivedViewReducer,
    returnGifts: returnGiftsListReducer,
    receivedGiftsRequest: receivedGiftsListRequestReducer,
    roView: roViewReducer,
    declareReceivedGiftForm: declareReceivedGiftFormReducer,
    receivedRequestView: receivedRequestViewReducer,
    admin: adminReducer,
    dashboard: dashboardReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

/* import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const rootReducer = combineReducers({
  login: loginReducer,
  pastGifts: pastGiftsListReducer,
  declareGiftForm: declareGiftFormReducer,
  view: viewReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
  // reducer: {
  //   login: loginReducer,
  //   pastGifts: pastGiftsListReducer,
 //    declareGiftForm: declareGiftFormReducer,
  //   view: viewReducer
  // }
}); */

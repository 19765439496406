import styles from './index.module.less';
import { Space, Image, Row, Col } from 'antd'; //, Card
import { FormOutlined } from '@ant-design/icons'; // ClockCircleOutlined,
// SafetyCertificateOutlined,
// CloseSquareOutlined,
// RollbackOutlined

import { numFormating } from 'utilities/helpers';
import LocalStorage from 'utilities/localStorage';
// import pendingIcon from '../../../../welcome/image/pendingIcon.svg';
// import approvedIcon from '../../../../welcome/image/approvedIcon.svg';
import rejectedIcon from '../../../../welcome/image/rejectedIcon.svg';
import returnedIcon from '../../../../welcome/image/returnedIcon.svg';
import { sizeChecker } from 'utilities/helpers';
// import allocated_icon from '../images/allocated_icon.svg';

export default function CardStatus2({
  pendingCount,
  prohibitedCount,
  rejectedCount,
  returnedCount
}) {
  const isMobile = sizeChecker('xs');

  const handleReDirect = (status) => {
    LocalStorage.setItem('dashboardCard_status', JSON.stringify(status));
  };
  const handleReDirectReceivedGifts = (stutus) => {
    console.log('stutus', stutus);
    LocalStorage.setItem(
      'dashboardCard_ReceivedGifts_status',
      JSON.stringify(stutus)
    );
  };
  const cardContent = [
    {
      id: 1,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('PENDING')}
        >
          PENDING
        </a>
      ),
      value: pendingCount,
      icon: (
        <a
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('PENDING')}
        >
          <FormOutlined className={`${styles.gift_antd_icon_size}`} />
        </a>
      ) //<ClockCircleOutlined style={{ color: '#455A64' }} />
    },
    {
      id: 2,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('PROHIBITED')}
        >
          {' '}
          PROHIBITED{' '}
        </a>
      ),
      value: prohibitedCount,
      icon: (
        <a
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('PROHIBITED')}
        >
          <Image
            className={`${styles.gift_icon_size}`}
            alt="rejectedIcon"
            src={rejectedIcon}
            preview={false}
          />
        </a>
      )
    },
    {
      id: 3,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('REJECTED')}
        >
          REJECTED
        </a>
      ),
      value: rejectedCount,
      icon: (
        <a
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('REJECTED')}
        >
          <Image
            className={`${styles.gift_icon_size}`}
            alt="rejectedIcon"
            src={rejectedIcon}
            preview={false}
          />
        </a>
      ) // <SafetyCertificateOutlined style={{ color: '#18AB27' }} /> // initiated_icon //<QuestionCircleOutlined />
    },

    {
      id: 4,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftReceived-link"
          onClick={() => handleReDirectReceivedGifts('RETURNED')}
        >
          RETURNED
        </a>
      ),
      value: returnedCount,
      icon: (
        <a
          href="/adminDashboard-giftReceived-link"
          onClick={() => handleReDirectReceivedGifts('RETURNED')}
        >
          <Image
            className={`${styles.gift_icon_size}`}
            alt="returnedIcon"
            src={returnedIcon}
            preview={false}
          />
        </a>
      )
    }
  ];

  return (
    <div>
      <div className={`${styles.card_container} mb-3 `}>
        <Space
          className={`${styles.card_space_between}`}
          direction={isMobile ? 'vertical' : 'horizontal'}
          size="middle"
          gap={26}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {cardContent?.map((item, index) => (
            <div key={index} className={`${styles.card_content_style}`}>
              <Row
                style={{
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <Col>
                  <div className={`${styles.card_icon_div}`}>
                    <span className={`${styles.card_icon_style}`}>
                      {item?.icon}
                    </span>
                  </div>
                </Col>
                <Col>
                  <span className={`${styles.card_title_style}`}>
                    {item?.title}
                  </span>
                </Col>
              </Row>
              <Row className={`${styles.card_amount_style}`}>
                {numFormating(item?.value)}
              </Row>
            </div>
          ))}
        </Space>
      </div>
    </div>
  );
}

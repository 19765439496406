import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space, Input } from 'antd';
import {
  exceptionRequests,
  rejectRequests
} from '../../../../view_gift_request_details/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';
// import { getLoggedInUser } from 'utilities/helpers';
// import LocalStorage from 'utilities/localStorage';

const { TextArea } = Input;

const Pending_Modal = ({
  reqAllocInvoiceKeys,
  //reqAllocInvoices,
  pendingApprovalModal,
  setPendingApprovalModal,
  handleGetPastGiftsList
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  // const user_role1 = getLoggedInUser();
  // //const user_role = user_role1?.user?.roles[0];

  const [formData, setFormData] = useState({});

  const { exceptionRequestsLoading, rejectGiftLoading } = useSelector(
    (state) => state.view
  );

  // const onFinish = (values) => {
  //   console.log('VALUES:', values);
  // };
  // console.log('Approver Reason11111', formData?.reason);
  //console.log('Approver reqAllocInvoices1111111', reqAllocInvoices);
  // console.log('Approver reqAllocInvoiceKeys11111111', reqAllocInvoiceKeys);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    setFormData({
      ...formData,
      [slug]: value
    });
  };
  const approveHandler = () => {
    if (formData?.reason !== undefined) {
      const payload = {
        grId: reqAllocInvoiceKeys, //params.id,
        approverComments: formData?.reason, //values?.reason_for_excemption,
        giftType: 'gytb', // getGiftDetailsById?.type,
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };

      dispatch(exceptionRequests(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);
          history.push('/view-gift-requests');
          setPendingApprovalModal(false);
          handleGetPastGiftsList();
        }
      });
    }
  };

  const rejectHandler = () => {
    if (formData?.reason !== undefined) {
      const payload = {
        grId: reqAllocInvoiceKeys, //params.id,
        approverComments: formData?.reason, //values?.reason_for_excemption,
        giftType: 'gytb',
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };

      dispatch(rejectRequests(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);
          history.push('/view-gift-requests');
          setPendingApprovalModal(false);
          handleGetPastGiftsList();
        }
      });
    }
  };
  return (
    <>
      <Modal
        title={<span>Please confirm</span>}
        open={pendingApprovalModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setPendingApprovalModal(false)}
      >
        <Form
          form={form}
          layout="vertical"
          //onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <div className={`${styles.return_title_RO}`}>
            Are you sure do you want to approve/reject this gift?
          </div>
          <div className={`${styles.return_subtitle_RO}`}>
            (This action cannot be undone)
          </div>
          <FormItem
            style={{
              marginTop: '-15px'
            }}
            label=""
            name="reason"
            rules={[
              {
                required: true,
                message: 'This is a required field'
              }
            ]}
            className={`${styles.textarea_styles}`}
          >
            <TextArea
              allowClear
              placeholder="Enter reason"
              onChange={(e) => handleFormDataOnChange('reason', e.target.value)}
            />
          </FormItem>

          <FormItem className={`${styles.formItem_approval}`}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                justifyItems: ' center'
                // marginBottom: '-45px',
                // marginTop: '-15px'
              }}
            >
              <Button
                loading={exceptionRequestsLoading}
                className={`${styles.ExceptionalApproval_button}`}
                htmlType="submit"
                size="small"
                onClick={() => approveHandler()}
                // disabled={reqAllocInvoices?.length === 0}
                disabled={rejectGiftLoading === true}
              >
                Approve
              </Button>
              <Button
                loading={rejectGiftLoading}
                className={`${styles.Goback_button_reject}`}
                htmlType="submit"
                size="small"
                onClick={() => rejectHandler()}
                disabled={exceptionRequestsLoading === true}
                //disabled={reqAllocInvoices?.length === 0}
              >
                Reject
              </Button>
              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setPendingApprovalModal(false)}
                disabled={
                  exceptionRequestsLoading === true ||
                  rejectGiftLoading === true
                }
              >
                Go back
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default Pending_Modal;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { receivedGift_getbyId } from './api';

const initialState = {
  getReceivedGiftByIdLoading: false,
  receivedGiftDetailsById: []
};

const actions = {
  GET_RECEIVED_GIFT_BY_ID: 'getReceivedGiftById/GET_RECEIVED_GIFT_BY_ID'
};

export const getReceivedGiftById = createAsyncThunk(
  actions.GET_RECEIVED_GIFT_BY_ID,
  async (payload) => {
    const response = await receivedGift_getbyId(
      `${payload?.id}?giftType=${payload?.giftType}`
    ); //payload
    return response;
  }
);

export const receivedViewSlice = createSlice({
  name: 'receivedView',
  initialState,
  reducers: {
    vehicleFormData: (state, action) => {
      state.vehicleData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReceivedGiftById.pending, (state) => {
        state.getReceivedGiftByIdLoading = true;
      })
      .addCase(getReceivedGiftById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getReceivedGiftByIdLoading = false;
        state.receivedGiftDetailsById = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getReceivedGiftById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getReceivedGiftByIdLoading = false;
        message.error(msg);
      });
  }
});
export const { vehicleFormData } = receivedViewSlice.actions;
export default receivedViewSlice.reducer;

import React from 'react';
import WelcomePage from './components/welcomePage';
import CustomLayout from '@/common/Layout';
import { getLoggedInUser, TabTitle } from '../../utilities/helpers';

const Welcome = () => {
  TabTitle('Welcome to the Gift Register');
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  return (
    <>
      <CustomLayout>
        <div style={{ marginTop: '50px' }}>
          <WelcomePage user_role={user_role} />
        </div>
      </CustomLayout>
    </>
  );
};

export default Welcome;

import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import { numIntStyleFormat } from '../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getWaitingForOthers, giftTypes } from '../../../redux/slice';
import styles from './index.module.less';
import {
  //Radio,
  //Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  Tag
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { CopyOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';

//import Switcher from '../../../../../common/Switcher';

/* const filters = [
  {
    name: 'My Gifts',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  }
]; */

function PendingApprovals() {
  const isMobile = sizeChecker('xs');

  const dispatch = useDispatch();

  const { waitingForOthersList, getWaitingForOthersLoading } = useSelector(
    (state) => state.dashboard
  );

  const [radiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  ); //, setRadiofilter
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  //const [recordData, setRecordData] = useState({});

  console.log(triggerFilter);
  console.log('waitingForOthersList', waitingForOthersList);

  useEffect(() => {
    handleGetWaitingForOthers();
  }, []);
  useEffect(() => {
    if (triggerFilter) {
      handleGetWaitingForOthers();
    }
  }, [radiofilter, searchRequest]); //page,, isSwitch

  const handleGetWaitingForOthers = () => {
    let payload = {
      status: radiofilter,
      search: searchRequest
      // delegation_type: isSwitch === true ? 'self' : 'delegated'
    };
    dispatch(getWaitingForOthers(payload));
  };

  // console.log('setIsSwitch', isSwitch);

  //   const onChange = () => {
  //     setTriggerFilter(true);
  //     setIsSwitch(!isSwitch);
  //     // window.localStorage.setItem('set_Toogle', JSON?.stringify(!isSwitch));
  //   };

  const waitingForOthersList1 = waitingForOthersList?.map((item) => ({
    key: item?.id,
    id: item?.id,
    recipientName: item?.type === 'gr' ? item?.giverName : item?.recipientName,
    // item?.recipientName === undefined ? item?.giverName : item?.recipientName,
    ticket: item?.ticket,
    GiftType: item?.giftName, //    GiftMaster?.title,
    valueOfGift: item?.valueOfGift,
    dateOfGiftReceived: item?.dateOfGiftReceived,
    status: item?.status,
    message: item?.giftDescription,
    organizationName: item?.organizationName,
    designation: item?.designation,
    giftReceiverSector: item?.giftReceiverSector,
    type: item?.type,
    giverName: item?.giverName,
    recipientType: item?.recipientType,
    contextOrBusinessPurposeOfTheGift: item?.contextOrBusinessPurposeOfTheGift,
    bulkUploadGroupNumber: item?.bulkUploadGroupNumber
  }));
  const handleReDirect = (record) => {
    console.log('record', record);
    //setRecordData(record);
    if (record?.type === 'gr') {
      history.push(`view-gift-received-details/${record.id}`);
      LocalStorage.setItem('giftTypeGR', JSON.stringify(record?.type));
      LocalStorage.setItem('redirect', JSON.stringify('dashboard_pending'));
    } else {
      history.push(`view-gift-details/${record.id}`);
      LocalStorage.setItem('giftType', JSON.stringify(record?.type));
      LocalStorage.setItem('redirect', JSON.stringify('dashboard_pending'));
    }
    dispatch(giftTypes(record.type));
  };
  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <Tooltip
                title={'bulk upload'}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {' '}
                {record?.bulkUploadGroupNumber === null ||
                record?.bulkUploadGroupNumber === '' ? (
                  ''
                ) : (
                  <CopyOutlined style={{ color: '#108ee9' }} />
                )}{' '}
              </Tooltip>
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {ticket === null ? '' : ticket}
              </a>
              {/* <a href={`view-gift-details/${record.id}`}>
                {ticket === null ? '' : ticket}
              </a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={`Recipient/Giver Full Name`}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient/Giver Full Name
        </Tooltip>
      ), //'Recipient/Giver Full Name',
      dataIndex: 'recipientName',
      key: 'recipientName',
      width: 80,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.recipientName?.localeCompare(b?.recipientName),
      render: (recipientName, record) => {
        return (
          <>
            <Tooltip
              title={recipientName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a onClick={() => handleReDirect(record)}>
                {' '}
                {recipientName === null ? '' : recipientName}
              </a>
              {/* <a href={`view-gift-details/${record.id}`}>{recipientName}</a> */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient/Giver Organization Full Name'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient/Giver Organization Full Name
        </Tooltip>
      ),
      dataIndex: 'organizationName',
      key: 'organizationName',
      sorter: (a, b) => a?.organizationName?.localeCompare(b?.organizationName),
      ellipsis: true,
      render: (organizationName) => {
        return (
          <>
            <Tooltip
              title={organizationName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {organizationName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient/Giver Designation'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient/Giver Designation
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'designation',
      key: 'designation',
      sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
      ellipsis: true,
      render: (designation) => {
        return (
          <>
            <Tooltip
              title={designation}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {designation}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Recipient/Giver Stakeholder Type'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Recipient/Giver Stakeholder Type
        </Tooltip>
      ), //'Recipient designation,',
      dataIndex: 'recipientType',
      key: 'recipientType',
      sorter: (a, b) => a?.recipientType?.localeCompare(b?.recipientType),
      ellipsis: true,
      width: 90,
      render: (recipientType) => {
        return (
          <>
            <Tooltip
              title={recipientType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {recipientType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Government Official?'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Government Official?
        </Tooltip>
      ),
      dataIndex: 'giftReceiverSector',
      key: 'giftReceiverSector',
      sorter: (a, b) =>
        a?.giftReceiverSector?.localeCompare(b?.giftReceiverSector),
      ellipsis: true,
      width: 60,
      render: (giftReceiverSector) => {
        return (
          <>
            <Tooltip
              title={giftReceiverSector}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giftReceiverSector === 'government' ? 'Yes' : 'No'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Context/Business Purpose of the Gift'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Context/Business Purpose of the Gift
        </Tooltip>
      ),
      dataIndex: 'contextOrBusinessPurposeOfTheGift',
      key: 'contextOrBusinessPurposeOfTheGift',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) =>
        a?.contextOrBusinessPurposeOfTheGift?.localeCompare(
          b?.contextOrBusinessPurposeOfTheGift
        ),
      render: (contextOrBusinessPurposeOfTheGift) => {
        return (
          <>
            <Tooltip
              title={
                contextOrBusinessPurposeOfTheGift === ''
                  ? 'NA'
                  : contextOrBusinessPurposeOfTheGift
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {contextOrBusinessPurposeOfTheGift === ''
                ? 'NA'
                : contextOrBusinessPurposeOfTheGift}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'GiftType',
      key: 'GiftType',
      sorter: (a, b) => a?.GiftType?.localeCompare(b?.GiftType),
      ellipsis: true,
      render: (GiftType) => {
        return (
          <>
            <Tooltip
              title={GiftType}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {GiftType}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Value',
      dataIndex: 'valueOfGift',
      key: 'valueOfGift',
      ellipsis: {
        showTitle: false
      },
      width: 90,
      sorter: (a, b) => a?.Type?.localeCompare(b?.Type),
      render: (valueOfGift) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(valueOfGift)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {numIntStyleFormat(valueOfGift)}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Date of gift',
      dataIndex: 'dateOfGiftReceived',
      key: 'dateOfGiftReceived',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`dateOfGiftReceived`]).unix() -
        moment(b[`dateOfGiftReceived`]).unix(),
      render: (dateOfGiftReceived) => (
        <>
          <Tooltip
            title={
              dateOfGiftReceived === null
                ? ''
                : moment(dateOfGiftReceived).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {dateOfGiftReceived === null
              ? ''
              : moment(dateOfGiftReceived).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    },
    /*  {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.message?.localeCompare(b?.message),
      render: (message) => {
        return (
          <>
            <Tooltip
              title={message === '' ? 'NA' : message}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="left"
            >
              {message === '' ? 'NA' : message}
            </Tooltip>
          </>
        );
      }
    }, */

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === null
              ? 'transparent'
              : record?.status === 'rejected'
              ? 'red'
              : record?.status === 'pending'
              ? 'blue'
              : record?.status === 'accepted'
              ? 'green'
              : record?.status === 'returned'
              ? 'magenta'
              : 'cyan'
          }
        >
          {status === null ? '' : status}
        </Tag>
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gift to display
        </div>
      </span>
    )
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <div className={`${styles.container}`}>
          <Row className={`${styles.row_container}`}>
            <div className={`${styles.gift_container}`}>
              <div
                className={
                  isMobile
                    ? `${styles.myGift_title_isMobile}`
                    : `${styles.myGift_title}`
                }
              >
                Pending Approvals
              </div>
              <div
                className={
                  isMobile
                    ? `${styles.myGift_text_isMobile}`
                    : `${styles.myGift_text}`
                }
              >
                View the status of pending approvals for gift given/to be given
              </div>
            </div>
            {/* <div className={`${styles.declareGift_div}`}>
              <Switcher
                left={'Self requests'}
                right={'Delegated requests'}
                onChange={onChange}
              />
            </div> */}
          </Row>
          {/*  <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Button
                className={`${styles.declareGift_button}`}
                icon={<PlusOutlined />}
                onClick={() => history.push('declare-a-gift')}
              >
                Declare a Gift
              </Button>
            </div>
          </Row> */}
          <br />
          <div className={`${styles.tabs_container}`}>
            <Row
              className={isMobile ? `${styles.row_display_isMobile}` : ''}
              style={{ maxWidth: isMobile ? '100%' : '' }}
            >
              <Col span={isMobile ? 24 : 8}>
                <SearchBar
                  onChange={(e) => {
                    setSearchRequest(e.target.value);
                    setTriggerFilter(true);
                  }}
                  className={
                    isMobile
                      ? `${styles.searchbar_isMobile}`
                      : `${styles.searchbar}`
                  }
                  inputProps={{
                    placeholder: 'Search by recipient or request number#'
                  }}
                />
              </Col>
            </Row>
          </div>
          <Card className={`${styles.cardStyles}`}>
            <div className={`${styles.table_container}`}>
              <CustomTable
                showSorterTooltip={false}
                columns={columns}
                dataSource={waitingForOthersList1}
                locale={locale}
                loading={getWaitingForOthersLoading}
                scroll={{ x: isMobile ? true : false }}
              />
            </div>
          </Card>
        </div>
      </div>
    </CustomLayout>
  );
}
export default PendingApprovals;

import React, { useState, useEffect, useMemo } from 'react'; //, useMemo
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Space, Row, Spin, Input, Select } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
//import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import {
  create_bu_master,
  get_all_users,
  get_all_sbg
} from '../../../../redux/slice';
import { debounce } from 'lodash';
import FormData from 'form-data';
import { useParams } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

//const { TextArea } = Input;
const { Option } = Select;

function EditBuMsterFlow() {
  TabTitle('Gift Register - Edit BU Master Flow');

  const params = useParams();

  const buRecord = LocalStorage.getItem('bu_record');
  console.log('buRecord%%%%%%%%%', buRecord);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({});
  const [allUsersSearch, setAllUsersSearch] = useState('');
  const [allSbgSearch, setAllSbgSearch] = useState('');

  const {
    get_all_usersLoading,
    all_users_List,
    create_bu_masterLoading,
    all_sbg_List,
    get_all_sbgLoading
  } = useSelector((state) => state.admin);
  console.log('all_users_List', all_users_List);
  console.log('all_sbg_List', all_sbg_List);
  console.log('allUsersSearch', allUsersSearch);
  console.log('allSbgSearch', allSbgSearch);

  useEffect(() => {
    form.setFieldsValue({
      buName: buRecord?.buName,
      userId: buRecord?.buHead,
      sbgName: buRecord?.sbgName,
      sbgId: buRecord?.sbgHead
    });
  }, []);

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 1000
    };
    if (allUsersSearch) payload['search'] = allUsersSearch;
    dispatch(get_all_users(payload));
  }, [allUsersSearch]);

  useEffect(() => {
    const payload = {};
    if (allSbgSearch) payload['search'] = allSbgSearch;
    dispatch(get_all_sbg(payload));
  }, [allSbgSearch]);

  const debounceFetcherUserId = useMemo(() => {
    const loadOptions = (allUsersSearch) => {
      setAllUsersSearch(allUsersSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherSbgId = useMemo(() => {
    const loadOptions = (allSbgSearch) => {
      setAllSbgSearch(allSbgSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const onFinish = () => {
    let formdata = new FormData();
    formdata.append('id', params?.id);
    formdata.append(
      'buName',
      formValues?.buName === undefined ? buRecord?.buName : formValues?.buName
    );

    formdata.append(
      'buHeadId',
      formValues?.userId === undefined ? buRecord?.buHeadId : formValues?.userId
    );

    formdata.append('isActive', true);

    formdata.append(
      'sbgName',
      formValues?.sbgName === undefined
        ? buRecord?.sbgName
        : formValues?.sbgName
    );
    formdata.append(
      'sbgHeadId',
      formValues?.sbgId === undefined ? buRecord?.SbgHeadId : formValues?.sbgId
    );
    dispatch(create_bu_master(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        history.push('/bu-master-flow');
      }
    });
  };

  return (
    <CustomLayout>
      <Spin spinning={get_all_usersLoading}>
        <div style={{ marginTop: '50px' }}>
          <Row className={`${styles.row_container_setting}`}>
            <div className={`${styles.gift_container}`}>
              <div className={`${styles.myGift_title_setting}`}>
                Edit BU and BU Head
              </div>
            </div>
          </Row>{' '}
          <div className={`${styles.container}`}>
            <div className={`${styles.cardContainer}`}>
              <Card className={`${styles.cardStyles_Container}`}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className={`${styles.formstyles}`}
                >
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Enter the BU full name
                          </div>
                        }
                        name="buName"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Input
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the BU full name"
                          onChange={(e) =>
                            handleFormDataOnChange('buName', e.target.value)
                          }
                        />
                        {/* <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select delegator"
                          onSearch={debounceFetcherAllUsers}
                          onChange={(value) =>
                            handleFormDataOnChange('delegatorId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select> */}
                      </FormItem>
                    </div>
                  </Card>

                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the BU head from the list below
                          </div>
                        }
                        name="userId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select BU Head"
                          onSearch={debounceFetcherUserId}
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('userId', value)
                          }
                        >
                          {all_users_List?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {`${item?.givenName} (${item.email})`}
                              </Option>
                            );
                          })}
                        </Select>
                        {/*  <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select BU Head"
                          onSearch={debounceFetcherUserId}
                          onChange={(value) =>
                            handleFormDataOnChange('userId', value)
                          }
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                        >
                          {all_users_List?.map((item, index) => (
                            <Option
                              title={item?.givenName}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.givenName} {''} {item?.surname}(
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  )
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select> */}
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Enter the SBG full name
                          </div>
                        }
                        name="sbgName"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Input
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the SBG full name"
                          onChange={(e) =>
                            handleFormDataOnChange('sbgName', e.target.value)
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  <Spin spinning={get_all_sbgLoading}>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Please select the SBG head from the list below
                            </div>
                          }
                          name="sbgId"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select SBG Head"
                            onSearch={debounceFetcherSbgId}
                            onChange={(value) =>
                              handleFormDataOnChange('sbgId', value)
                            }
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                          >
                            {all_sbg_List?.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item?.givenName} (${item.email})`}
                                </Option>
                              );
                            })}
                          </Select>
                          {/* <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select SBG Head"
                            onSearch={debounceFetcherSbgId}
                            onChange={(value) =>
                              handleFormDataOnChange('sbgId', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {all_sbg_List?.map((item, index) => (
                              <Option
                                title={item?.givenName}
                                key={index}
                                value={item?.id}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  <span style={{ marginLeft: '10px' }}>
                                    {item?.givenName} {''} {item?.surname}(
                                    <span
                                      style={{ textTransform: 'lowercase' }}
                                    >
                                      {item?.email}
                                    </span>
                                    )
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select> */}
                        </FormItem>
                      </div>
                    </Card>
                  </Spin>
                  <div style={{ marginTop: '20px' }}>
                    <FormItem>
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          justifyItems: ' center'
                        }}
                      >
                        <Button
                          loading={create_bu_masterLoading}
                          className={`${styles.ExceptionalApproval_button}`}
                          htmlType="submit"
                          size="small"
                        >
                          Save
                        </Button>

                        <Button
                          className={`${styles.cancel_rejectModal_button}`}
                          size="small"
                          onClick={() => history.push('/bu-master-flow')}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </FormItem>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Spin>
    </CustomLayout>
  );
}
export default EditBuMsterFlow;

import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import { history } from 'app/history';
import {
  message,
  Form,
  Button,
  Card,
  Row,
  Input,
  Radio,
  Col,
  Select
} from 'antd';
import FormData from 'form-data';
import FormItem from 'common/FormItem';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import { bulk_upload_form } from '../../../declare_gift_form/redux/slice';
import { useDispatch } from 'react-redux';
//import moment from 'moment';
import { ExcelDateToJSDate, pattern } from '../../../../utilities/helpers';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { get_deligatorsId } from '../../../declare_gift_form/redux/slice';

//import Template_Bulk_Upload from '../../../../assets/Template_Bulk_Upload.xlsx';

const { Option } = Select;

const BulkUploadPage = () => {
  const [formValues, setFormValues] = useState({});
  const [fileValue, setFileValue] = useState('');
  const [fileName, setFileName] = useState(null);
  const [itemsJsonData, setItemsJsonData] = useState(['']);
  const [formJsonData, setFormJsonData] = useState([]);
  const [government_official, setGovernment_official] = useState([]);
  const [equalResult, setEqualResult] = useState();
  const [equalNotification, setEqualNotification] = useState();
  const [numberNotification, setNumberNotification] = useState([]);
  const [company_Logo, setCompany_Logo] = useState([]);
  const [procured_locally, setProcured_locally] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [giftOthers, setGiftOthers] = useState([]);
  const [deligatorsIdSearch, setDeligatorsIdSearch] = useState('');

  //const [formJsonDataLength, setFormJsonDataLength] = useState();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { deligatorsIdList, bulk_upload_formLoading } = useSelector(
    (state) => state.declareGiftForm
  );

  useEffect(() => {
    const payload = {
      delegate_id: ''
    };
    if (deligatorsIdSearch) payload['search'] = deligatorsIdSearch;
    dispatch(get_deligatorsId(payload));
  }, [deligatorsIdSearch]);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value', slug, value);

    if (slug === 'whom_create' && value === true) {
      form.setFieldsValue({
        delegatorId: undefined
      });
    }
    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const debounceFetcherDeligatorsId = useMemo(() => {
    const loadOptions = (deligatorsIdSearch) => {
      setDeligatorsIdSearch(deligatorsIdSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleItemsTemplate = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet);
    console.log('jsonData', jsonData);

    const emptyCellFound = jsonData.some((row) => {
      console.log('row.columnName', row.columnName);
      // Modify the condition based on the structure of your Excel sheet
      return (
        row?.Full_Name_of_Recipeint === undefined ||
        row?.Full_Name_of_Recipeint === '' ||
        row?.Full_Company_name_of_recipient === undefined ||
        row?.Designation_of_the_recipient_in_his_company === undefined ||
        row?.Type_of_recipient === undefined ||
        row?.Is_the_recipient_Govt_Official === undefined ||
        row?.Is_there_any_ongoing_tender_or_discussion_with_the_Company_at_the_time_of_this_gift ===
          undefined ||
        // row?.Provide_details_of_the_ongoing_tender_or_discussion_and_the_role_played_by_the_recipient_of_the_proposed_gift ===
        //   undefined ||
        // row?.Please_provide_a_business_justification_for_why_this_gift_was_given_during_an_ongoing_tender_or_discussion ===
        //   undefined ||
        row?.Is_this_a_gift_to_a_family_member_of_a_supplier_customer_or_other_business_partner ===
          undefined ||
        row?.Classification_of_the_gift === undefined ||
        // row?.If_others_please_specify_the_gift === undefined ||
        row?.Description_of_gift === undefined ||
        row?.Taken_from_the_stock_of_promotion_items_from_Marketing_bearing_the_Company_Logo ===
          undefined ||
        row?.Gift_being_procured_locally === undefined ||
        row?.Date_of_gift_giving === undefined ||
        row?.Value_of_the_gift === undefined ||
        row?.Context_or_business_purpose_of_the_gift === undefined
      );
    });

    if (emptyCellFound) {
      message.error(
        'There are mandatory cells left blank in the Excel sheet. Please make sure cells contain data.'
      );

      setButtonDisable(true);
    } else {
      setItemsJsonData(jsonData);
    }
    //setItemsJsonData(jsonData);
    console.log(fileName);
  };
  //console.log('itemsJsonData', itemsJsonData);

  useEffect(() => {
    if (itemsJsonData?.length > 1 && itemsJsonData?.length <= 9) {
      message.error('The number of gifts should be 10 or more than that');
    } else {
      const allData = itemsJsonData?.map((item) => ({
        type: 'gytb',

        recipientName: item?.Full_Name_of_Recipeint,
        //organizationName: item?.Recipient_Organization_Name,
        organizationName: item?.Full_Company_name_of_recipient,
        recipientType:
          item?.Type_of_recipient === 'Others'
            ? `Others - ${item?.If_others_please_specify}`
            : item?.Type_of_recipient, //item?.Recipient_Stakeholder_Type, //'customer'
        designation: item?.Designation_of_the_recipient_in_his_company, //item?.Recipient_Designation
        // item?.Is_the_recipient_Govt_Official?.toString()?.toLowerCase() ===
        giftReceiverSector:
          item?.Is_the_recipient_Govt_Official?.toString()?.toLowerCase() ===
          'yes'
            ? 'government'
            : 'non-government',
        wasThereOngoingTender:
          item?.Is_there_any_ongoing_tender_or_discussion_with_the_Company_at_the_time_of_this_gift?.toString()?.toLowerCase() ===
          'yes'
            ? true
            : false,

        ongoingTenderDiscussion:
          item?.Is_there_any_ongoing_tender_or_discussion_with_the_Company_at_the_time_of_this_gift?.toString()?.toLowerCase() ===
          'yes'
            ? item?.Provide_details_of_the_ongoing_tender_or_discussion_and_the_role_played_by_the_recipient_of_the_proposed_gift //item?.If_yes_provide_the_details
            : null,

        businessJustificationForWhyThisGiftWasGivenDuringOngoingTender:
          item?.Is_there_any_ongoing_tender_or_discussion_with_the_Company_at_the_time_of_this_gift?.toString()?.toLowerCase() ===
          'yes'
            ? item?.Please_provide_a_business_justification_for_why_this_gift_was_given_during_an_ongoing_tender_or_discussion //item?.If_yes_provide_a_business_justification_for_why_this_gift_was_given_during_an_ongoing_tender_discussion
            : null,
        giftReceivedByFamily:
          item?.Is_this_a_gift_to_a_family_member_of_a_supplier_customer_or_other_business_partner?.toString()?.toLowerCase() ===
          'yes'
            ? true
            : false,
        giftName:
          item?.Classification_of_the_gift === 'Others'
            ? `Others - ${item?.If_others_please_specify_the_gift}`
            : item?.Classification_of_the_gift, //'Flowers',
        giftDescription: item?.Description_of_gift,
        isGiftEmbossed:
          item?.Taken_from_the_stock_of_promotion_items_from_Marketing_bearing_the_Company_Logo?.toString()?.toLowerCase() ===
          'yes'
            ? true
            : false,

        contextOrBusinessPurposeOfTheGift:
          item?.Context_or_business_purpose_of_the_gift,

        procuredHow:
          item?.Gift_being_procured_locally?.toString()?.toLowerCase() === 'yes'
            ? 'local'
            : 'tpl', //'local', //
        // dateOfGiftReceived: moment(item?.Date_of_gift_giving?._d).format('YYYY MM DD'), //"2023-03-17",//.format()
        dateOfGiftReceived:
          typeof item?.Date_of_gift_giving === 'number'
            ? ExcelDateToJSDate(item?.Date_of_gift_giving)
            : item?.Date_of_gift_giving,
        valueOfGift: item?.Value_of_the_gift,
        // typeof item?.Value_of_the_gift === 'number'
        //   ? item?.Value_of_the_gift
        //   : '',
        reasonForReject: item?.Reason_For_Reject
      }));
      setFormJsonData(allData);
      const governmentOfficial = itemsJsonData?.map((item) =>
        item?.Is_the_recipient_Govt_Official?.toLowerCase()
      );
      setGovernment_official(governmentOfficial);

      const giftsValue = itemsJsonData?.map((item) => item?.Value_of_the_gift);
      setNumberNotification(giftsValue);

      const companyLogo = itemsJsonData?.map(
        (item) =>
          item?.Taken_from_the_stock_of_promotion_items_from_Marketing_bearing_the_Company_Logo
      );
      setCompany_Logo(companyLogo);

      const procuredLocally = itemsJsonData?.map(
        (item) => item?.Gift_being_procured_locally
      );
      setProcured_locally(procuredLocally);

      const gift_others = itemsJsonData?.map(
        (item) => item?.If_others_please_specify_the_gift
      );
      setGiftOthers(gift_others);
    }
  }, [itemsJsonData]);

  console.log('FormJsonData', formJsonData);
  // console.log('FormJsonDataLength', itemsJsonData?.length);

  useEffect(() => {
    for (let i = 0; i < giftOthers?.length; i++) {
      if (pattern.test(giftOthers[i]?.toString()?.toLowerCase())) {
        message.error(
          `Gifts entered in 'Others' column are contains in the restricted categoty`
        );
        setButtonDisable(true);
        break;
      }
    }
  }, [giftOthers]);

  useEffect(() => {
    for (let i = 0; i < procured_locally?.length; i++) {
      if (
        procured_locally[i]?.toString()?.toLowerCase() === 'yes' &&
        company_Logo[i]?.toString()?.toLowerCase() === 'yes'
      ) {
        message.error(
          'A gift can either be procured locally or taken from the stock of promotional items of marketing. So, Both questions cannot be “Yes”.'
        );
        setButtonDisable(true);
        break;
      } else if (procured_locally[i] === 'No' && company_Logo[i] === 'No') {
        message.error(
          'A gift can either be procured locally or taken from the stock of promotional items of marketing. So, Both questions cannot be “No”.'
        );
        setButtonDisable(true);
        break;
      }
    }
  }, [procured_locally]);

  useEffect(() => {
    for (let i = 1; i < numberNotification?.length; i++) {
      if (typeof numberNotification[i] !== 'number') {
        message.error('The value of gifts should be a number only');
        setButtonDisable(true);
        break;
      }
    }
  }, [numberNotification]);

  const allEqual = (arr) => arr.every((v) => v === 'no');

  //-------------------------------Validation for Government Official--------------------------------
  useEffect(() => {
    if (government_official[0] !== undefined) {
      const result = allEqual(government_official); // true
      result === true ? setEqualResult(true) : setEqualResult(false);
      //console.log('result=', result);
    }
  }, [government_official]);

  useEffect(() => {
    if (equalResult !== undefined) {
      // console.log('equalResult!== undefined', equalResult);
      if (equalResult === true) {
        setEqualNotification(true);
      } else {
        setEqualNotification(false);
      }
    }
  }, [equalResult]);

  // useEffect(() => {
  //   if (itemsJsonData?.length >= 10) {
  //     message.error('The Gifts should not less then 10');
  //   }
  // }, [itemsJsonData]);

  useEffect(() => {
    if (equalNotification !== undefined) {
      //console.log('equalNotification11111', equalNotification);
      if (equalNotification === false) {
        message.error('The recipient should be non-government official');
      }
    }
  }, [equalNotification]);

  // const handleFormDataOnChange = (slug, value) => {
  //   console.log('slug,value', slug, value);
  //   // if (slug === 'isGiftEmbossed') {
  //   //   form.setFieldsValue({
  //   //     procuredHow: undefined
  //   //   });
  //   // }
  //   setFormValues({
  //     ...formValues,
  //     [slug]: value
  //   });
  // };

  const onFinish = () => {
    //  console.log('valuesITEMS', values);
    //setDraftLoading(true);
    let formdata = new FormData();

    formdata.append(
      'delegatorId',
      formValues?.whom_create ? '' : formValues?.delegatorId
    );

    formdata.append(
      'values',
      formJsonData === undefined ? '' : JSON.stringify(formJsonData)
    );

    // formdata.append('type', 'gytb');

    /*  for (let i in fileListImage) {
      formdata.append(
        'giftImages',
        fileListImage?.length === 0 ? '' : fileListImage[i]?.originFileObj
      );
    } */

    // formdata.append(
    //   'dateOfGiftReceived',
    //   formValues?.dateOfGiftReceived === undefined

    //     ? ''
    //     : moment(formValues?.dateOfGiftReceived).format()
    // );

    // formdata.append(
    //   'giftReceivedByFamily',
    //   formValues?.giftReceivedByFamily === undefined
    //     ? ''
    //     : formValues?.giftReceivedByFamily
    // );

    dispatch(bulk_upload_form(formdata)).then(function (response) {
      if (response?.payload?.success) {
        history.push('/view-past-gift');
      }
    });
  };

  const downloadTemplate = () => {
    const downloadUrl = '/Template_Bulk_Upload.xlsx'; // '/templates/template.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Template_Bulk_Upload.xlsx'; //'template.xlsx';
    link.click();
  };

  return (
    <>
      <div>
        <div className={`${styles.container_bulk_upload}`}>
          <div className={`${styles.title}`}>Bulk Upload</div>
          {/* <Button
            className={`${styles.user_name_button1}`}
            onClick={() => history.push('/request-gift-ytb')}
          >
            Back
          </Button> */}
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
            autoComplete="off"
          >
            <div
              style={{ marginTop: '30px', width: '100%', marginBottom: '20px' }}
            >
              {' '}
              {/*  <Card className={`${styles.cardStyles}`}>
                <div className={`${styles.card_content}`}>
                  <FormItem
                    name="giftReceiverSector"
                    label={
                      <div className={`${styles.card_title}`}>
                        Is the recipient a government official?
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'This is a required field'
                      }
                    ]}
                    className={`${styles.select_styles}`}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        handleFormDataOnChange(
                          'giftReceiverSector',
                          e.target.value
                        )
                      }
                      className={`${styles.card_Radio}`}
                    >
                      <Row className={`${styles.Radio_button_margin}`}>
                        <Col span={12}>
                          <Radio value={'government'}>
                            Yes 
                          </Radio>
                        </Col>
                        <Col span={12}>
                          <Radio value={'non-government'}>
                            No 
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </FormItem>
                </div>
              </Card> */}
              <Card className={`${styles.cardStyles}`}>
                <div className={`${styles.card_content}`}>
                  <FormItem
                    name="whom_create"
                    label={
                      <div className={`${styles.card_title}`}>
                        Are you creating this for yourself?
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'This is a required field'
                      }
                    ]}
                    className={`${styles.select_styles}`}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        handleFormDataOnChange('whom_create', e.target.value)
                      }
                      className={`${styles.card_Radio}`}
                    >
                      <Row className={`${styles.Radio_button_margin}`}>
                        <Col span={12}>
                          <Radio value={true}>Self</Radio>
                        </Col>
                        <Col span={12}>
                          <Radio value={false}>Someone else</Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </FormItem>
                </div>
              </Card>
              {formValues?.whom_create === false ? (
                <Card className={`${styles.cardStyles}`}>
                  <div className={`${styles.card_content}`}>
                    <FormItem
                      label={
                        <div className={`${styles.card_title}`}>
                          Please select the delegator from the list below
                        </div>
                      }
                      name="delegatorId"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        className={`${styles.margin_Top}`}
                        style={{ marginBottom: '15px' }}
                        showSearch
                        allowClear={true}
                        optionFilterProp="children"
                        placeholder="Select delegator"
                        //onSearch={inputVenderName}
                        onSearch={debounceFetcherDeligatorsId}
                        onChange={(value) =>
                          handleFormDataOnChange('delegatorId', value)
                        }
                        filterOption={(input, option) => {
                          return (
                            option.key
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.title
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {deligatorsIdList?.map((item, index) => (
                          <Option
                            title={item?.GiftEntryDelegator?.givenName}
                            key={index}
                            value={item?.GiftEntryDelegator?.id}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                textTransform: 'uppercase',
                                fontFamily: 'SFProText Regular'
                              }}
                            >
                              <span style={{ marginLeft: '10px' }}>
                                {item?.GiftEntryDelegator?.givenName} (
                                <span style={{ textTransform: 'lowercase' }}>
                                  {item?.GiftEntryDelegator?.email}
                                </span>
                                )
                              </span>
                            </span>
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </div>
                </Card>
              ) : (
                ''
              )}
              <Card className={`${styles.cardStyles}`}>
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span style={{ marginRight: '15px' }}>
                    {/* <a
                      href={
                        //'https://1drv.ms/x/s!AsoL48RdL4ndoZhJJhywkypbLDrxfQ?e=Wk5kTV'
                        'https://lexyslabs2-my.sharepoint.com/:x:/g/personal/laxmi_swetha_lexyslabs_com/EeSZ5O9frUdDoJfIAIi8LZUBNb3_89EHvgV8gP7BiQgQKg'
                      }
                      target="_blank"
                      rel="noreferrer"
                      download="ItemsTemplate.xlsx"
                      className={`${styles.items_title1}`}
                    >
                      <span className={`${styles.items_import}`}>
                        Download template for Bulk Upload
                      </span>
                    </a> */}
                    <a onClick={downloadTemplate}>
                      Download template for Bulk Upload
                    </a>
                  </span>
                  <span className={`${styles.browse_container}`}>
                    <label
                      htmlFor="file-upload"
                      className={styles.custom_file_upload}
                    >
                      <UploadOutlined /> Browse
                    </label>
                    <Input
                      value={fileValue}
                      accept="*"
                      className={styles.upload}
                      type="file"
                      onChange={(e) => {
                        setFileValue('');
                        handleItemsTemplate(e);
                      }}
                      id="file-upload"
                    />
                    {fileName && (
                      <div>
                        <DeleteOutlined
                          className={`${styles.delete_uploaded_file}`}
                          onClick={() => window?.location?.reload()}
                        />
                        <span
                          style={{
                            fontSize: '12px',
                            fontFamily: 'SFProText Regular',
                            color: '#9E9E9E'
                          }}
                        >
                          {fileName}
                        </span>
                      </div>
                    )}
                  </span>
                </Row>
              </Card>
              <Row style={{ marginTop: '20px' }}>
                <Button
                  className={`${styles.cancel_button}`}
                  // htmlType="submit"
                  size="middle"
                  onClick={() => history.push('/request-gift-ytb')}
                >
                  Back
                </Button>
                <Button
                  loading={bulk_upload_formLoading}
                  name="submit"
                  className={`${styles.submit_button}`}
                  htmlType="submit"
                  size="middle"
                  // onClick={submitHandler}'
                  // disabled={formValues?.giftReceiverSector === 'government'}
                  disabled={
                    equalResult === false ||
                    itemsJsonData?.length < 10 ||
                    buttonDisable === true
                  }
                >
                  Submit
                </Button>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BulkUploadPage;

import React, { useState } from 'react';
import styles from './index.module.less';
import {
  Button,
  Col,
  Row,
  Form,
  Card,
  Typography,
  Spin,
  //Tooltip,
  Tag,
  Divider
  //Steps
} from 'antd';
import FormItem from 'common/FormItem';
import { history } from 'app/history';
import {
  getLoggedInUser,
  Recipient_Type,
  numIntStyleFormat
} from '../../../../utilities/helpers';
import moment from 'moment';
import ReturnRO_Modal from '../../../view_gift_return/components/viewReturnGiftPage/returnRO_Modal';
import FormData from 'form-data';
import {
  returnGift_RO_form,
  returnGift_Giver_form
  //returnGift_CEC_form
} from '../../../view_gift_return/redux/slice';
import { useDispatch } from 'react-redux';
import LocalStorage from 'utilities/localStorage';
import { sizeChecker } from 'utilities/helpers';

//const { Text } = Typography;

function ViewGiftReceivedDetailsPage({
  getGiftByIdLoading,
  getGiftDetailsById
}) {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();

  const redirect = LocalStorage.getItem('redirect');
  console.log('redirect@@@@@@', redirect);

  const dispatch = useDispatch();

  //const [ellipsis] = useState(true);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user;
  console.log('user_role1', user_role);

  const [returnToROModal, setReturnToROModal] = useState(false);

  console.log('getGiftDetailsById', getGiftDetailsById);

  const onFinish = (values) => {
    console.log('Values', values);
  };

  const HandleReturnGift = (payload) => {
    console.log('pay####', payload);
    let formdata = new FormData();

    formdata.append('grId', payload?.grId);
    payload?.whom_return === true
      ? ''
      : formdata.append(
          'roId',
          payload?.roId === undefined ? '' : payload?.roId
        );

    for (let i in payload?.fileList) {
      formdata.append(
        'roDocument',
        payload?.fileList?.length === 0
          ? ''
          : payload?.fileList[i]?.originFileObj
      );
    }
    formdata.append('dateOfGiftReturned', payload?.dateOfGiftReturned);
    payload?.whom_return === 'over_RO'
      ? formdata.append('whyNotReturnedToGiver', payload?.whyNotReturnedToGiver)
      : '';
    formdata.append(
      'commentsOfReturn',
      payload?.commentsOfReturn === undefined ? '' : payload?.commentsOfReturn
    );
    payload?.whom_return === 'gift_giver'
      ? dispatch(returnGift_Giver_form(formdata)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            setReturnToROModal(false);
            history.push('/view-gift-received');
          }
        })
      : payload?.whom_return === 'over_RO'
      ? dispatch(returnGift_RO_form(formdata)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            setReturnToROModal(false);
            history.push('/view-gift-received');
          }
        })
      : ''; /* dispatch(returnGift_CEC_form(formdata)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response);
            setReturnToROModal(false);
            history.push('/view-gift-received');
          }
        }); */
  };
  /*   const items =
    getGiftDetailsById?.ReceivingFlow?.length > 0
      ? getGiftDetailsById?.ReceivingFlow?.map((item) => {
          return {
            title:
              item?.status === 'accepted' ? (
                <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
              ) : item?.status === 'pending' ? (
                <span style={{ color: '#2264ae' }}>Pending</span>
              ) : item?.status === 'rejected' ? (
                'Rejected'
              ) : item?.status === null ? (
                'Waiting'
              ) : (
                'Waiting'
              ),
            status:
              item?.status === 'accepted'
                ? 'finish'
                : item?.status === 'pending'
                ? 'process'
                : item?.status === 'rejected'
                ? 'error'
                : item?.status === null
                ? 'wait'
                : 'wait',

            description:
              item?.status === 'accepted' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Approved by:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      {' '}
                      Approved on:
                    </span>{' '}
                    {moment(item?.updatedAt).format('DD MMM YYYY')}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>Notes:</span>
                    <Tooltip
                      title={
                        item?.approverComment === null
                          ? 'NA'
                          : item?.approverComment
                      }
                      color={'white'}
                      overlayInnerStyle={{ color: '#32324D', fontSize: '11px' }}
                      arrowPointAtCenter="false"
                    >
                      {item?.approverComment === null ? (
                        ' NA'
                      ) : (
                        <Text
                          className={`${styles.ant_collapse_header}`}
                          // ellipsis={true}
                        >
                          {item?.approverComment}
                        </Text>
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : item?.status === 'rejected' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      Rejected by:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      {' '}
                      Rejected on:
                    </span>{' '}
                    {moment(item?.updatedAt).format('DD MMM YYYY')}
                  </div>
                  <div>
                    <span className={`${styles.steper_comments}`}> Notes:</span>
                    <Tooltip
                      title={
                        item?.approverComment === null
                          ? 'NA'
                          : item?.approverComment
                      }
                      color={'white'}
                      overlayInnerStyle={{ color: '#32324D', fontSize: '11px' }}
                      arrowPointAtCenter="false"
                    >
                      {item?.approverComment === null ? (
                        ' NA'
                      ) : (
                        <Text
                          className={`${styles.ant_collapse_header}`}
                          //ellipsis={true}
                        >
                          {item?.approverComment}
                        </Text>
                      )}
                    </Tooltip>
                  </div>
                </div>
              ) : item?.status === 'pending' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>
                    <span className={`${styles.steper_comments}`}>
                      waiting for approval:
                    </span>{' '}
                    {item?.User?.givenName}
                  </div>
                </div>
              ) : item?.status === null ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    justifyItems: 'flex-start',
                    flexDirection: 'column',
                    fontSize: '11px',
                    color: ' rgba(0, 0, 0, 0.45)'
                  }}
                >
                  <div>Waiting for Approval: {item?.User?.givenName}</div>
                </div>
              ) : (
                ''
              )
            //subTitle: item?.updatedAt
          };
        })
      : ''; */
  return (
    <Spin spinning={getGiftByIdLoading}>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <Col span={19} className={`${styles.gift_container}`}>
            <div
              className={
                isMobile
                  ? `${styles.myGift_title_isMobile}`
                  : `${styles.myGift_title}`
              }
            >
              {getGiftDetailsById?.ticket}
            </div>
            <div
              className={
                isMobile
                  ? `${styles.myGift_text_isMobile}`
                  : `${styles.myGift_text}`
              }
            >
              View the details of the gift
            </div>
          </Col>
          {/* <Col span={12} className={`${styles.mySubGift_title}`}>
            Details of the Gift Received
          </Col> */}
        </Row>
        <div className={`${styles.return_title_div}`}>
          {getGiftDetailsById?.status === 'rejected' ? (
            getGiftDetailsById?.status === 'rejected' &&
            getGiftDetailsById?.returnedToRo === false &&
            getGiftDetailsById?.returnedToGiver === false &&
            getGiftDetailsById?.returnedToCEC === false ? (
              ''
            ) : (
              <Tag color="blue" style={{ fontSize: '14px', fontWeight: 600 }}>
                Returned
              </Tag>
            )
          ) : (
            ''
          )}
        </div>
        <Card className={`${styles.card_styles}`}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
          >
            <Typography className={`${styles.CardTitleText}`}>
              {`Requestor Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.givenName}{' '}
                    {getGiftDetailsById?.Receiver?.surname}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.designation === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.designation}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Location
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.location === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.location}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Email id
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    <a>
                      {getGiftDetailsById?.Receiver?.email === null
                        ? 'NA'
                        : getGiftDetailsById?.Receiver?.email}
                    </a>
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Department/Function
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Line Manager
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.ReportingManager?.User
                      ?.givenName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.ReportingManager?.User
                          ?.givenName}{' '}
                    {
                      getGiftDetailsById?.Receiver?.ReportingManager?.User
                        ?.surname
                    }
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Employee id
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.empId === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.empId}{' '}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Business Unit (BU)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.Receiver?.BuMaster?.buName === null
                      ? 'NA'
                      : getGiftDetailsById?.Receiver?.BuMaster?.buName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of reporting of the gift
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.dateOfReporting === null
                      ? 'NA'
                      : moment(getGiftDetailsById?.dateOfReporting).format(
                          'DD MMM YYYY'
                        )}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Typography className={`${styles.CardTitleText}`}>
              {`Gift giver Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />

            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Full Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giverName}{' '}
                    {/*  {getGiftDetailsById?.Receiver?.surname} */}
                  </Typography>
                </FormItem>
              </Col>

              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Reporting Manager"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Type of Giver
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giverType}
                  </Typography>
                </FormItem>
              </Col>
              {getGiftDetailsById?.wasThereOngoingTender === true && (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.isMobile ? 24 : 85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide details of the ongoing tender/discussion and the
                    role played by the giver of the gift
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.ongoingTenderDiscussion === null ||
                      getGiftDetailsById?.ongoingTenderDiscussion === ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.ongoingTenderDiscussion}
                    </Typography>
                  </div>
                </Col>
              )}
              {/*  <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      <Tooltip
                        title={`Provide details of the ongoing tender/discussion and the role played by the giver of the gift`}
                        color={'white'}
                        overlayInnerStyle={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: 600
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header_label}`}
                          ellipsis={true}
                        >
                          Provide details of the ongoing tender/discussion and
                          the role played by the giver of the gift
                        </Text>
                      </Tooltip>
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Tooltip
                    title={
                      getGiftDetailsById?.ongoingTenderDiscussion === null ||
                      getGiftDetailsById?.ongoingTenderDiscussion === ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.ongoingTenderDiscussion
                    }
                    color={'white'}
                    overlayInnerStyle={{
                      color: 'black',
                      fontSize: '12px',
                      fontWeight: 600
                    }}
                    arrowPointAtCenter="false"
                  >
                    <Typography className={`${styles.valueText}`}>
                      <Text
                        className={`${styles.ant_collapse_header_label}`}
                        ellipsis={true}
                      >
                        {getGiftDetailsById?.ongoingTenderDiscussion === null ||
                        getGiftDetailsById?.ongoingTenderDiscussion === ''
                          ? 'Not applicable'
                          : getGiftDetailsById?.ongoingTenderDiscussion}
                      </Text>
                    </Typography>
                  </Tooltip>
                </FormItem>
              </Col> */}
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Giver Organization Full Name
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.organizationName}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  //label="Vendor Name/s (gift givers)"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Was it a Govt. Official
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {Recipient_Type(getGiftDetailsById?.giftGiverSector)}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Designation of Gift giver
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.designation : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.isMobile ? 24 : 85)',
                    fontWeight: 600,
                    fontSize: '13px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Was there any ongoing tender/discussion with the company at
                  the time of this gift?
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.wasThereOngoingTender === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </div>
              </Col>
              {/*  <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      <Tooltip
                        title={`Was there any ongoing
                        tender/discussion with the
                        company at the time of this gift?`}
                        color={'white'}
                        overlayInnerStyle={{
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: 600
                        }}
                        arrowPointAtCenter="false"
                      >
                        <Text
                          className={`${styles.ant_collapse_header_label}`}
                          ellipsis={true}
                        >
                          Was there any ongoing tender/discussion with the
                          company at the time of this gift?
                        </Text>
                      </Tooltip>
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {getGiftDetailsById?.wasThereOngoingTender === true
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </FormItem>
              </Col> */}
              {getGiftDetailsById?.wasThereOngoingTender === true && (
                <Col span={isMobile ? 24 : 8}>
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.isMobile ? 24 : 85)',
                      fontWeight: 600,
                      fontSize: '13px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide a business justification for this gift during an
                    ongoing tender/discussion
                  </div>
                  <div>
                    <Typography className={`${styles.valueText}`}>
                      {getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        null ||
                      getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender ===
                        ''
                        ? 'Not applicable'
                        : getGiftDetailsById?.businessJustificationForWhyThisGiftWasGivenDuringOngoingTender}
                    </Typography>
                  </div>
                </Col>
              )}
            </Row>

            <Typography className={`${styles.CardTitleText}`}>
              {`Gift Details`}
            </Typography>

            <Divider className={`${styles.divider_margin}`} />

            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  // label="Gift Type"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Gift Type
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById ? getGiftDetailsById?.giftName : ''}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Date of receipt of gift
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {' '}
                    {moment(getGiftDetailsById?.dateOfGiftReceived).format(
                      'DD MMM YYYY'
                    )}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Whether the gift has been accepted or returned?
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.isTheGiftReturned === false
                      ? 'Accepted'
                      : 'Returned'}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.row_display_isMobile}`}>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Description of Gift
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.giftDescription === ''
                      ? 'Not applicable'
                      : getGiftDetailsById?.giftDescription}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      Approximate value of the gift (in INR)
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {numIntStyleFormat(getGiftDetailsById?.valueOfGift)}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                {getGiftDetailsById?.isTheGiftReturned === false ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide the reason for acceptance of the gift
                  </div>
                ) : getGiftDetailsById?.isTheGiftReturned === true &&
                  getGiftDetailsById?.returnedToGiver === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide the reason for returned of the gift by Giver
                  </div>
                ) : getGiftDetailsById?.isTheGiftReturned === true &&
                  getGiftDetailsById?.returnedToRo === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Provide the reason for returned of the gift by RO
                  </div>
                ) : (
                  ''
                )}

                <Typography className={`${styles.valueText}`}>
                  {getGiftDetailsById?.isTheGiftReturned === false
                    ? getGiftDetailsById?.commentsOfAccept
                    : getGiftDetailsById?.commentsOfReturn}
                </Typography>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{ marginTop: '10px' }}
              className={`${styles.row_display_isMobile}`}
            >
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: '#344054',
                    fontWeight: 600,
                    fontSize: '14px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  Was the gift embossed with the logo of the gift-giving
                  company?
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.isGiftEmbossed === true
                      ? 'Yes'
                      : getGiftDetailsById?.isGiftEmbossed === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                <div
                  style={{
                    color: 'rgba(0, 0, 0, 0.isMobile ? 24 : 85)',
                    fontWeight: 600,
                    fontSize: '13px',
                    fontFamily: 'SFProText Regular',
                    marginBottom: '2px'
                  }}
                >
                  The context/business purpose of this gift
                </div>
                <div>
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.contextOrBusinessPurposeOfTheGift ===
                      null ||
                    getGiftDetailsById?.contextOrBusinessPurposeOfTheGift === ''
                      ? 'NA'
                      : getGiftDetailsById?.contextOrBusinessPurposeOfTheGift}
                  </Typography>
                </div>
              </Col>
              <Col span={isMobile ? 24 : 8}>
                {getGiftDetailsById?.isTheGiftReturned === true &&
                getGiftDetailsById?.returnedToGiver === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Date of return to the gift giver
                  </div>
                ) : getGiftDetailsById?.isTheGiftReturned === true &&
                  getGiftDetailsById?.returnedToRo === true ? (
                  <div
                    style={{
                      color: '#344054',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'SFProText Regular',
                      marginBottom: '2px'
                    }}
                  >
                    Date of the gift handed over to the RO
                  </div>
                ) : (
                  ''
                )}
                <Typography className={`${styles.valueText}`}>
                  {getGiftDetailsById?.isTheGiftReturned === true
                    ? getGiftDetailsById?.dateOfReporting === null
                      ? 'NA'
                      : moment(getGiftDetailsById?.dateOfGiftReturned).format(
                          'DD MMM YYYY'
                        )
                    : ''}
                </Typography>
              </Col>
            </Row>

            {/*  {getGiftDetailsById?.ReceivingFlow?.length !== 0 && ( */}
            <>
              {' '}
              <Divider />{' '}
              <Typography className={`${styles.CardTitleText}`}>
                {`Review Details`}
              </Typography>
            </>
            {/*    )} */}
            <Divider className={`${styles.divider_margin}`} />
            {/*  {getGiftDetailsById?.ReceiverId === user_role?.id ? ( */}
            {getGiftDetailsById?.status === 'accepted' ? (
              <Tag className={`${styles.status_style}`} color={'green'}>
                APPROVED
              </Tag>
            ) : getGiftDetailsById?.status === 'rejected' &&
              getGiftDetailsById?.isTheGiftReturned === true &&
              getGiftDetailsById?.isTheGiftSelfReturned === true ? (
              <>
                <Tag className={`${styles.status_style}`} color={'magenta'}>
                  RETURNED
                </Tag>
                <div className={`${styles.reasonForReject_text}`}>
                  {getGiftDetailsById?.commentsOfReturn}
                </div>
              </>
            ) : getGiftDetailsById?.status === 'rejected' &&
              getGiftDetailsById?.ReceiverId === user_role?.id &&
              getGiftDetailsById?.returnedToRo === false &&
              getGiftDetailsById?.returnedToGiver === false &&
              getGiftDetailsById?.returnedToCEC === false ? (
              <>
                <Tag className={`${styles.status_style}`} color={'red'}>
                  TO BE RETURNED
                </Tag>
                <div className={`${styles.reasonForReject_text}`}>
                  {getGiftDetailsById?.reasonForReject === null ||
                  getGiftDetailsById?.reasonForReject === ''
                    ? 'NA'
                    : getGiftDetailsById?.reasonForReject}
                </div>
              </>
            ) : getGiftDetailsById?.status === 'pending' ? (
              <Tag className={`${styles.status_style}`} color={'blue'}>
                PENDING
              </Tag>
            ) : (
              <>
                <Tag className={`${styles.status_style}`} color={'magenta'}>
                  RETURNED
                </Tag>
                <div className={`${styles.reasonForReject_text}`}>
                  {getGiftDetailsById?.reasonForReject === null ||
                  getGiftDetailsById?.reasonForReject === ''
                    ? 'NA'
                    : getGiftDetailsById?.commentsOfReturn}
                </div>
              </>
            )}
            {/*  ) : getGiftDetailsById?.ReceivingFlow?.length > 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row'
                  }}
                >
                  <Steps
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      color: '#2264ae'
                    }}
                    size="small"
                    current={getGiftDetailsById?.ReceivingFlow?.length}
                    items={items}
                    //progressDot={customDot}
                    responsive={true}
                  />
                </div>{' '}
              </>
            ) : getGiftDetailsById?.status === 'accepted' ? (
              <span style={{ color: 'rgb(3, 150, 3)' }}>Approved</span>
            ) : (
               ''
            )} */}
            <Divider />

            {getGiftDetailsById?.ReceiverId === user_role?.id &&
            getGiftDetailsById?.status === 'rejected' &&
            getGiftDetailsById?.returnedToRo === false &&
            getGiftDetailsById?.returnedToGiver === false &&
            getGiftDetailsById?.returnedToCEC === false ? (
              <Row style={{ marginTop: '20px' }}>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button1}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => history.push('/view-gift-received')}
                  >
                    Go back
                  </Button>
                </span>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    className={`${styles.Goback_button}`}
                    htmlType="submit"
                    size="small"
                    onClick={() => setReturnToROModal(true)}
                  >
                    Return
                  </Button>
                </span>
              </Row>
            ) : (
              <div style={{ marginTop: '20px' }}>
                <Button
                  className={`${styles.Goback_button}`}
                  htmlType="submit"
                  size="small"
                  // history.push('/view-gift-received')
                  onClick={() => {
                    redirect === 'home_gr'
                      ? history.push('/view-gift-received')
                      : redirect === 'dashboard_approved'
                      ? history.push('/approved')
                      : redirect === 'dashboard_rejected'
                      ? history.push('/rejected')
                      : redirect === 'dashboard_return'
                      ? history.push('/returned')
                      : redirect === 'dashboard_pending'
                      ? history.push('/pending-approvals')
                      : '';
                  }}
                >
                  Go back
                </Button>
              </div>
            )}
            {/* <div style={{ marginTop: '20px' }}>
              <Button
                className={`${styles.Goback_button}`}
                htmlType="submit"
                size="small"
                onClick={() => history.push('/view-gift-received')}
              >
                Go back
              </Button>
            </div> */}
          </Form>
        </Card>
        {returnToROModal ? (
          <ReturnRO_Modal
            returnToROModal={returnToROModal}
            setReturnToROModal={setReturnToROModal}
            getGiftDetailsById={getGiftDetailsById}
            HandleReturnGift={HandleReturnGift}
            //exceptionRequestsLoading={exceptionRequestsLoading}
            // user={user}
          />
        ) : (
          ''
        )}
      </div>
    </Spin>
  );
}
export default ViewGiftReceivedDetailsPage;

import React from 'react';
import { Modal, Button, Alert } from 'antd'; // message,, Typography
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space, Input } from 'antd'; // Input,
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { receivedGiftAccept } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';
import {
  numFormating,
  getLoggedInUser
} from '../../../../../utilities/helpers';
import LocalStorage from 'utilities/localStorage';

const { TextArea } = Input;

const Approval_Modal = ({
  exceptionalApprovalModal,
  setExceptionalApprovalModal,
  getGiftDetailsById,
  Id,
  type,
  hashRoot,
  uniqueId,
  userId,
  redirect_action
  // exceptionRequestsLoading
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const user_role1 = getLoggedInUser();
  //const user_role = user_role1?.user?.roles[0];
  console.log('user_role1QQQQQQQQQQQ', user_role1?.user);

  const userDetails = LocalStorage.getItem('GiftReg_user');

  const { receivedGiftAcceptLoading } = useSelector(
    (state) => state.receivedView
  );

  const onFinish = (values) => {
    if (userDetails?.user === undefined || user_role1?.user === null) {
      //one-click-approved by email
      const payload = {
        grId: Id,
        giftType: type,
        hashRoot: hashRoot,
        uniqueId: uniqueId,
        userId: userId,
        approverComments: values?.reason_for_excemption
      };

      dispatch(receivedGiftAccept(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);

          setExceptionalApprovalModal(false);
          history.push('/one-click-public-access');
        }
      });
    } else {
      const payload = {
        grId: params.id,
        approverComments: values?.reason_for_excemption,
        giftType: getGiftDetailsById?.type,
        hashRoot: '',
        uniqueId: '',
        userId: ''
      };

      dispatch(receivedGiftAccept(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response);

          setExceptionalApprovalModal(false);
          redirect_action === 'home_action_gr'
            ? history.push('/view-gift-received-request')
            : redirect_action === 'dashboard_action'
            ? history.push('/action-required')
            : history.push('/view-gift-received-request');
          // history.push('/view-gift-received-request');
        }
      });
    }
  };

  return (
    <>
      <Modal
        title={<span>Please confirm{/* Exception approval */}</span>}
        open={exceptionalApprovalModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setExceptionalApprovalModal(false)}
      >
        <Alert
          message={`Value of Gifts Received this Fiscal: ₹${numFormating(
            getGiftDetailsById?.thisFiscalYearReceivedGiftsValue
          )}`}
          type="info"
          showIcon
          style={{ marginTop: '-20px', fontWeight: 600 }}
        />
        <div className={`${styles.return_title_RO}`}>
          Are you sure you want to approve this gift request?
        </div>
        <div className={`${styles.return_subtitle_RO}`}>
          (This action cannot be undone)
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            style={{
              marginTop: '-15px'
            }}
            label="" //"Enter reasons for making an exception approval"
            name="reason_for_excemption"
            rules={[
              {
                required: true,
                message: 'This is a required field'
              }
            ]}
            className={`${styles.textarea_styles}`}
          >
            <TextArea allowClear placeholder="Enter reason" />
          </FormItem>

          <FormItem className={`${styles.formItem_approval}`}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'end',
                justifyItems: ' center'
                // marginBottom: '-45px',
                // marginTop: '-15px'
              }}
            >
              <Button
                loading={receivedGiftAcceptLoading}
                className={`${styles.ExceptionalApproval_button}`}
                htmlType="submit"
                size="small"
              >
                Approve
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setExceptionalApprovalModal(false)}
              >
                Cancel
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default Approval_Modal;

import React from 'react';
import { Modal, List } from 'antd'; // message,, Typography
import styles from './index.module.less';

const GovernmentOfficial_Modal = ({
  governmentOfficialModal,
  setGovernmentOfficialModal
}) => {
  const textItem = [
    {
      key: 1,
      label: `any person holding a legislative, executive or administrative
  office of the government, or acting in the official capacity for or on
  behalf of a legislative, executive, or administrative office of the
  government, whether appointed or elected, whether permanent or
  temporary, whether paid or unpaid`
    },
    {
      key: 2,
      label: `any person in the service or
      pay of the government or of a corporation established by or under a
      central, provincial or state statute, or an authority or a body owned
      or controlled or aided by the government or a government company or is
      remunerated by the government by fees or commission for the
      performance of any public duty`
    },
    {
      key: 3,
      label: `any judge, including any person
      empowered by law to discharge, whether by himself/herself or as a
      member of any body of persons, any adjudicatory functions`
    },
    {
      key: 4,
      label: ` any person authorised by a court of justice to perform any duty, in
      connection with the administration of justice, including a liquidator,
      receiver or commissioner`
    },
    {
      key: 5,
      label: `any person who performs a public duty,
      including for a public agency or public enterprise, or provides a
      public service, as defined in the domestic law of the country and as
      applied in the pertinent area of law`
    },
    {
      key: 6,
      label: `any elected or appointed
      officers or employees of public international or multilateral
      organizations, such as the United Nations`
    },
    {
      key: 7,
      label: `any elected or appointed
      officers or employees of public international or multilateral
      organizations, such as the United Nations`
    }
  ];
  return (
    <>
      <Modal
        title={<span>Definition of a government official</span>}
        open={governmentOfficialModal}
        footer={false}
        className={`${styles.government_official_size}`}
        bodyStyle={{ overflowY: 'scroll' }}
        width={'700px'}
        //height={'300px'}
        onCancel={() => setGovernmentOfficialModal(false)}
      >
        <div>
          <span>A “Government Official” would include the following:</span>
          <List
            itemLayout="horizontal"
            dataSource={textItem}
            renderItem={(item) => (
              <List.Item>
                <span style={{ fontWeight: '600' }}>{item.key}.</span>{' '}
                <span style={{ fontSize: '12px' }}>{item.label}</span>
              </List.Item>
            )}
          />
          {/* <div>
            {textItem.map((item) => {
              return (
                <div key={item.key} className={` ${styles.icon_margin}`}>
                  <span className={` ${styles.icon}`}>
                    {item.key} {item.label}
                  </span>
                </div>
              );
            })}
          </div> */}
          {/*   {` i. any person holding a legislative, executive or administrative
          office of the government, or acting in the official capacity for or on
          behalf of a legislative, executive, or administrative office of the
          government, whether appointed or elected, whether permanent or
          temporary, whether paid or unpaid;
           ii. any person in the service or
          pay of the government or of a corporation established by or under a
          central, provincial or state statute, or an authority or a body owned
          or controlled or aided by the government or a government company or is
          remunerated by the government by fees or commission for the
          performance of any public duty;
           iii. any judge, including any person
          empowered by law to discharge, whether by himself/herself or as a
          member of any body of persons, any adjudicatory functions;
           iv. any
          person authorised by a court of justice to perform any duty, in
          connection with the administration of justice, including a liquidator,
          receiver or commissioner;
           v. any person who performs a public duty,
          including for a public agency or public enterprise, or provides a
          public service, as defined in the domestic law of the country and as
          applied in the pertinent area of law; 
          vi. any elected or appointed
          officers or employees of public international or multilateral
          organizations, such as the United Nations; and 
          vii. any other person
          who is considered as Government Official according to applicable laws
          and regulations.`} */}
        </div>
      </Modal>
    </>
  );
};

export default GovernmentOfficial_Modal;

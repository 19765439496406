import React from 'react'; //, { useState, useEffect, useMemo }
import styles from './index.module.less';
import { Row, Card, Image } from 'antd';
import {
  FileAddOutlined,
  FileProtectOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import arrow from '../../images/arrow.svg';
// import entry_delegate from '../../../../assets/images/entry_delegate.png';
// import review_delegate from '../../../../assets/images/review_delegate.png';
import { history } from 'app/history';
import { getLoggedInUser } from 'utilities/helpers';

const AdminPage = () => {
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user?.roles[0];
  return (
    <>
      <Row className={`${styles.row_container_setting}`}>
        <div className={`${styles.myGift_title}`}>
          {' '}
          Settings {/* {user_role?.name}! */}
        </div>
        {user_role === 'cec-user' && (
          <div className={`${styles.back_btn}`}>
            <a href="/welcome">
              {' '}
              <ArrowLeftOutlined />
              <span style={{ marginLeft: '3px' }}>Switch to Home</span>
            </a>
          </div>
        )}
      </Row>
      <div className={`${styles.container}`}>
        <div className={`${styles.cardContainer}`}>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    {/*  <Image
                    // className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={entry_delegate}
                    preview={false}
                  />{' '} */}

                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Entry Delegates
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/entry-delegator-flows')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    {/* <Image
                    // className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={review_delegate}
                    preview={false}
                  /> */}
                    <FileProtectOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Review Delegates
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    onClick={() =>
                      history.push('/review-entry-delegator-flows')
                    }
                  />
                </div>
              </a>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    {/*  <Image
                    // className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={entry_delegate}
                    preview={false}
                  />{' '} */}

                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure BU/Function Master
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/bu-master-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    {/*  <Image
                    // className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={entry_delegate}
                    preview={false}
                  />{' '} */}

                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Gift Master
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/gift-master-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>

          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure CEC Users
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/cec-users-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure MD Users
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/md-users-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure CXO Users
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/cxo-users-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          {/* <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Designated Authorities
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/designated-authorities-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card> */}
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    {/*  <Image
                    // className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={entry_delegate}
                    preview={false}
                  />{' '} */}

                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Limit Master
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/limit_master_flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          {/*  <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Users
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/user_management_flow')}
                  />
                </div>
              </a>
            </Row>
          </Card> */}
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure RO Master
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/ro-master-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    {/*  <Image
                    // className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={entry_delegate}
                    preview={false}
                  />{' '} */}

                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure RO-Users
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/roUsers-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card>
          {/*  <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.row_container}`}>
              <div className={`${styles.card_content}`}>
                <Row>
                  <span className={`${styles.icon_size}`}>
                    
                    <FileAddOutlined />
                  </span>{' '}
                  <span className={`${styles.card_content_text}`}>
                    Configure Senior Management
                  </span>
                </Row>
              </div>
              <a>
                <div>
                  <Image
                    className={`${styles.gift_icon_size}`}
                    alt="gift_requests-icon"
                    src={arrow}
                    preview={false}
                    // onClick={() => setEntryDelegatorModal(true)}//
                    onClick={() => history.push('/senior-management-flow')}
                  />
                </div>
              </a>
            </Row>
          </Card> */}
        </div>
        {/*  {entryDelegatorModal && (
          <EntryDelegatorModal
            entryDelegatorModal={entryDelegatorModal}
            setEntryDelegatorModal={setEntryDelegatorModal}
            get_all_usersLoading={get_all_usersLoading}
            all_users_List={all_users_List}
            debounceFetcherAllUsers={debounceFetcherAllUsers}
          />
        )} */}
      </div>
    </>
  );
};

export default AdminPage;

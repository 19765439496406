import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import {
  Input,
  Form,
  Radio,
  Button,
  Card,
  Row,
  Col,
  Upload,
  Modal,
  Select,
  //message,
  DatePicker,
  Divider,
  Steps,
  Spin,
  Tag
  //Checkbox
  //Popover
} from 'antd';
import FormItem from 'common/FormItem';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import FormData from 'form-data';
import { declare_received_gift_form, get_gift_master } from '../../redux/slice';
import {
  get_deligatorsId,
  get_userDetails
} from '../../../declare_gift_form/redux/slice';
import { history } from '@/app/history';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  recipientType,
  getLoggedInUser,
  pattern
} from '../../../../utilities/helpers';
import GovernmentOfficial_Modal from '../../../declare_gift_form/components/declareGiftFormPage/governmentOfficial_Modal';
import { get_BU_Master, set_BU_mapping } from '../../../admin/redux/slice';
import { sizeChecker } from 'utilities/helpers';
import { getROUsersList } from '../../../view_gift_return/redux/slice';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;
const { Step } = Steps;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function Gift_received_page_Form() {
  const isMobile = sizeChecker('xs');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { declare_received_gift_formLoading, giftMaster } = useSelector(
    (state) => state.declareReceivedGiftForm
  );
  const { deligatorsIdList, userDetails, get_userDetailsLoading } = useSelector(
    (state) => state.declareGiftForm
  );

  const { bu_MasterList, set_BU_mappingLoading, get_BU_MasterLoading } =
    useSelector((state) => state.admin);

  const { roUsersList } = useSelector((state) => state.returnGifts);

  const [formValues, setFormValues] = useState({});
  // const [doc, setDoc] = useState([]);
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');
  //const [fileListImage, setFileListImage] = useState([]);
  //const [fileList] = useState([]); //, setFileList
  const [otherGift, setOtherGift] = useState('');
  const [giftSearch, setGiftSearch] = useState('');
  const [giftName, setGiftName] = useState('');
  const [recipientTypeOther, setRecipientTypeOther] = useState('');
  const [otherRecipient, setOtherRecipient] = useState('');
  const [giftValue, setGiftValue] = useState();
  const [deligatorsIdSearch, setDeligatorsIdSearch] = useState('');
  const [scrollTop, setScrollTop] = useState(0);
  const [current, setCurrent] = useState(0);
  const [giftMasterOthers, setGiftMasterOthers] = useState([]);
  const [whomToReturnedValue, setWhomToReturnedValue] = useState('');
  const [governmentOfficialModal, setGovernmentOfficialModal] = useState(false);
  const [BUSearch, setBUSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [toggleUpload, setToggleUpload] = useState(false);
  const [recall, setReCall] = useState(false);
  const [doc, setDoc] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  const user = getLoggedInUser();

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 1000
    };
    if (BUSearch) payload['search'] = BUSearch;
    dispatch(get_BU_Master(payload));
  }, [BUSearch]);

  const debounceFetcherAllUsers = useMemo(() => {
    const loadOptions = (BUSearch) => {
      setBUSearch(BUSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  useEffect(() => {
    dispatch(getROUsersList({}));
  }, []);
  console.log('roUsersList:', roUsersList);

  useEffect(() => {
    const payload = {
      userId:
        formValues?.whom_create === true
          ? user?.user?.id
          : formValues?.delegatorId === undefined
          ? user?.user?.id
          : formValues?.delegatorId
    };
    dispatch(get_userDetails(payload));
    if (formValues?.whom_create === true) {
      formValues['delegatorId'] = undefined;
    }
  }, [formValues?.delegatorId, formValues?.whom_create, recall]);

  useEffect(() => {
    const giftMaster1 = [...giftMaster, { title: 'Others', id: 'others' }];
    setGiftMasterOthers(giftMaster1);
  }, [giftMaster]);

  useEffect(() => {
    const payload = {};
    if (giftSearch) payload['name'] = giftSearch;
    dispatch(get_gift_master(payload));
  }, [giftSearch]);

  useEffect(() => {
    const payload = {
      delegate_id: ''
    };
    if (deligatorsIdSearch) payload['search'] = deligatorsIdSearch;
    dispatch(get_deligatorsId(payload));
  }, [deligatorsIdSearch]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (giftSearch) => {
      setGiftSearch(giftSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherDeligatorsId = useMemo(() => {
    const loadOptions = (deligatorsIdSearch) => {
      setDeligatorsIdSearch(deligatorsIdSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleOtherDescription = (e) => {
    setOtherGift(e.target.value);
  };

  const handleOtherRecipientType = (e) => {
    setOtherRecipient(e.target.value);
  };
  const handleValueOfGift = (e) => {
    setGiftValue(e.target.value);
  };

  useEffect(() => {
    let name = giftMasterOthers?.filter(
      (item) => item?.id === formValues?.giftId
    );
    setGiftName(name[0]?.title);
  }, [formValues?.giftId]);

  useEffect(() => {
    if (formValues?.recipientType === 'other') {
      setRecipientTypeOther(formValues?.recipientType);
    } else {
      setRecipientTypeOther('');
    }
  }, [formValues?.recipientType]);

  const handlerBU = () => {
    let formdata = new FormData();

    formdata.append('userId', user?.user?.id);
    formdata.append('buId', formValues?.buId);

    dispatch(set_BU_mapping(formdata)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        // toggleChecked();
        setChecked(true);
        setToggleUpload(false);
        setReCall(!recall);
      }
    });
  };

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    if (slug === 'buId') {
      setToggleUpload(true);
      setChecked(false);
    }
    if (slug === 'whom_create' && value === true) {
      form.setFieldsValue({
        delegatorId: undefined
      });
    }

    if (slug === 'whetherGiftAcceptedReturned') {
      setWhomToReturnedValue('');
      form.setFieldsValue({
        reasonForAcceptance: undefined,
        whomToReturned: undefined,
        dateOfReturnedGiver: undefined,
        detailsReasonForReturned: undefined,
        dateOfReturnedCEC: undefined,
        detailsReasonForReturnedCEC: undefined
      });
    }
    if (slug === 'whomToReturned') {
      form.setFieldsValue({
        reasonForAcceptance: undefined,
        dateOfReturnedGiver: undefined,
        detailsReasonForReturned: undefined,
        dateOfReturnedCEC: undefined,
        detailsReasonForReturnedCEC: undefined
      });
    }
    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const handleWhomToReturned = (e) => {
    e.preventDefault();
    setWhomToReturnedValue(e.target.value);
  };
  console.log(
    'whetherGiftAcceptedReturned',
    formValues?.whetherGiftAcceptedReturned
  );
  console.log('whomToReturnedValue', whomToReturnedValue);

  const onFinish = (values) => {
    console.log('valuesITEMS', values);
    let formdata = new FormData();

    formdata.append('type', 'gr');
    /*  for (let i in fileListImage) {
      formdata.append(
        'giftImages',
        fileListImage?.length === 0 ? '' : fileListImage[i]?.originFileObj
      );
    } */
    for (let i in fileList) {
      formdata.append(
        'roDocument',
        fileList?.length === 0 ? '' : fileList[i]?.originFileObj
      );
    }
    /* formdata.append(
      'BuMasterId',
      formValues?.buId === undefined
        ? userDetails?.BuMaster?.id
        : formValues?.buId
    ); */
    formdata.append(
      'delegatorId',
      formValues?.whom_create === true ? '' : formValues?.delegatorId
    );

    formdata.append(
      'giftGiverSector',
      formValues?.giftReceiverSector === undefined
        ? ''
        : formValues?.giftReceiverSector
    );
    formdata.append(
      'giverName',
      formValues?.recipientName === undefined ? '' : formValues?.recipientName
    );
    formdata.append(
      'organizationName',
      formValues?.organizationName === undefined
        ? ''
        : formValues?.organizationName
    );
    formdata.append(
      'designation',
      formValues?.designation === undefined ? '' : formValues?.designation
    );

    formdata.append(
      'giverType',
      formValues?.recipientType === 'other'
        ? `Others - ${otherRecipient}`
        : formValues?.recipientType === undefined
        ? ''
        : formValues?.recipientType
    );
    /* formdata.append(
      'noOfGiftArticles',
      formValues?.noOfGiftArticles === undefined
        ? ''
        : formValues?.noOfGiftArticles
    ); */

    formdata.append(
      'dateOfGiftReceived',
      formValues?.dateOfGiftReceived === undefined
        ? ''
        : moment(formValues?.dateOfGiftReceived).format()
    );

    /*  formdata.append(
      'giftReceivedByFamily',
      formValues?.giftReceivedByFamily === undefined
        ? ''
        : formValues?.giftReceivedByFamily
    ); */
    formdata.append('giftId', formValues?.giftId);
    formdata.append(
      'giftName',
      giftName === 'Others' ? `Others – ${otherGift}` : giftName
    );
    // formdata.append('valueOfGift', giftValue === undefined ? '' : giftValue);
    formdata.append(
      'valueOfGift',
      giftValue === undefined ||
        formValues?.giftId === 'd0df4905-a386-4868-8ef5-213b014ab967'
        ? 0
        : giftValue
    );
    formdata.append(
      'isGiftEmbossed',
      formValues?.isGiftEmbossed === undefined ? '' : formValues?.isGiftEmbossed
    );
    formdata.append(
      'contextOrBusinessPurposeOfTheGift',
      formValues?.contextOrBusinessPurposeOfTheGift === undefined
        ? ''
        : formValues?.contextOrBusinessPurposeOfTheGift
    );
    formdata.append(
      'giftDescription',
      formValues?.giftDescription === undefined
        ? ''
        : formValues?.giftDescription
    );
    // formdata.append(
    //   'procuredHow',
    //   formValues?.procuredHow === undefined ? '' : formValues?.procuredHow
    // );
    formdata.append(
      'wasThereOngoingTender',
      formValues?.wasThereOngoingTender === undefined
        ? ''
        : formValues?.wasThereOngoingTender
    );

    formValues?.wasThereOngoingTender === true
      ? formdata.append(
          'ongoingTenderDiscussion',
          formValues?.tenderDetails === undefined
            ? ''
            : formValues?.tenderDetails
        )
      : '';
    formValues?.wasThereOngoingTender === true
      ? formdata.append(
          'businessJustificationForWhyThisGiftWasGivenDuringOngoingTender',
          formValues?.tenderDetails2 === undefined
            ? ''
            : formValues?.tenderDetails2
        )
      : '';
    formdata.append(
      'additionalComments',
      formValues?.additionalComments === undefined
        ? ''
        : formValues?.additionalComments
    );
    formdata.append(
      'isTheGiftReturned',
      formValues?.whetherGiftAcceptedReturned === 'accepted'
        ? false
        : formValues?.whetherGiftAcceptedReturned === 'returned'
        ? true
        : false
    );
    if (formValues?.whetherGiftAcceptedReturned === 'accepted') {
      formdata.append(
        'commentsOfAccept',
        formValues?.reasonForAcceptance === undefined
          ? ''
          : formValues?.reasonForAcceptance
      );
    }

    formValues?.whetherGiftAcceptedReturned === 'returned'
      ? whomToReturnedValue === 'returnedGiver'
        ? formdata.append('returnedToGiver', true)
        : whomToReturnedValue === 'returnedCEC'
        ? formdata.append('returnedToRo', true)
        : ''
      : '';

    whomToReturnedValue === 'returnedGiver'
      ? formdata.append(
          'dateOfGiftReturned',
          formValues?.dateOfReturnedGiver === undefined
            ? ''
            : moment(formValues?.dateOfReturnedGiver).format()
        )
      : '';
    formValues?.whetherGiftAcceptedReturned === 'returned' &&
    whomToReturnedValue === 'returnedGiver'
      ? formdata.append(
          'commentsOfReturn',
          formValues?.detailsReasonForReturned === undefined
            ? ''
            : formValues?.detailsReasonForReturned
        )
      : '';

    formValues?.whetherGiftAcceptedReturned === 'returned' &&
    whomToReturnedValue === 'returnedCEC'
      ? formdata.append(
          'dateOfGiftReturned',
          formValues?.dateOfReturnedCEC === undefined
            ? ''
            : moment(formValues?.dateOfReturnedCEC).format()
        )
      : '';

    formValues?.whetherGiftAcceptedReturned === 'returned' &&
    whomToReturnedValue === 'returnedCEC'
      ? formdata.append(
          'commentsOfReturn',
          formValues?.detailsReasonForReturnedCEC === undefined
            ? ''
            : formValues?.detailsReasonForReturnedCEC
        )
      : '';
    formValues?.whetherGiftAcceptedReturned === 'returned' &&
    whomToReturnedValue === 'returnedCEC'
      ? formdata.append(
          'roId',
          formValues?.roId === undefined ? '' : formValues?.roId
        )
      : '';
    formValues?.whetherGiftAcceptedReturned === 'returned' &&
    whomToReturnedValue === 'returnedCEC'
      ? formdata.append(
          'whyNotReturnedToGiver',
          values?.not_returned_giver === undefined
            ? ''
            : values?.not_returned_giver
        )
      : '';

    dispatch(declare_received_gift_form(formdata)).then(function (response) {
      if (response?.payload?.success) {
        history.push('/view-gift-received');
      } else {
        // message.error(response.payload.message);
      }
    });
  };

  const normFile = (e) => {
    setDoc(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  console.log('setDoc', doc);
  console.log('fileList', fileList);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  );
  /*  const normFile = (e) => {
    setDoc(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  console.log('setDoc', doc);
  console.log('fileListImage', fileListImage);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };
 */
  //const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  /*  const uploadButton = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  ); */

  /* const handleChangeImage = ({ fileList: newFileList }) =>
  setFileListImage(newFileList);
  const uploadButtonImage = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        SVG, PNG, JPG or GIF (max. 800x400px)
      </p>
    </div>
  ); */

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  //-----------------------------------

  const onScroll = () => {
    // This will calculate how many pixels the page is vertically
    const winScroll = document.documentElement.scrollTop;
    // This is responsible for subtracticing the total height of the page - where the users page is scrolled to
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    // This will calculate the final total of the percentage of how much the user has scrolled.
    const scrolled = (winScroll / height) * 100;

    setScrollTop(scrolled);
  };

  useEffect(() => {
    // Fires when the document view has been scrolled
    window.addEventListener('scroll', onScroll);

    //
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClickNext = () => {
    if (formValues?.buId === undefined) {
      form.setFieldsValue({
        buId: userDetails?.BuMaster?.id
      });
    } else {
      form.setFieldsValue({
        buId: formValues?.buId
      });
    }
    form
      .validateFields()
      .then(() => {
        // Here make api call of something else
        setCurrent(current + 1);
        window?.scrollTo(0, 0);
      })
      .catch((err) => console.log(err));
  };
  const handleClickBack = () => {
    setCurrent(current - 1);
  };
  return (
    <Spin spinning={get_userDetailsLoading}>
      <div className={`${styles.App}`}>
        <div className={`${styles.progressMainWrapper}`}>
          <div
            className={`${styles.progressMainStyle}`}
            style={{ width: `${scrollTop}%` }}
          ></div>
        </div>
        <div className={`${styles.container_stepper}`}>
          <div className={`${styles.container_stepper_div}`}>
            <Steps current={current} size="small">
              <Step key={0} title={`Giver Details`} />
              <Step key={1} title="Gift Details" />
            </Steps>
          </div>
        </div>
        <div className={`${styles.container}`}>
          <div className={`${styles.title}`}>
            I want to declare a gift I received
          </div>
          <div className={`${styles.subTitle}`}>
            Please fill the gift details below
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
            autoComplete="off"
          >
            <div
              style={{ marginTop: '30px', width: '100%', marginBottom: '20px' }}
            >
              {current === 0 && (
                <>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.title_section}`}>
                      {`Requester Details`}
                    </div>
                    <Divider className={`${styles.divider_margin}`} />
                    <Card className={`${styles.cardStyles}`}>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div>
                            Date of reporting of the gift:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.dateOfReporting === null
                                ? 'NA'
                                : moment(userDetails?.dateOfReporting).format(
                                    'DD MMM YYYY'
                                  )}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee name:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.givenName === null
                                ? 'NA'
                                : userDetails?.givenName}{' '}
                              {userDetails?.surname}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee email:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.email === null
                                ? 'NA'
                                : userDetails?.email}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee ID:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.empId === null
                                ? 'NA'
                                : userDetails?.empId}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Employee designation:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.designation === null
                                ? 'NA'
                                : userDetails?.designation}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            {' '}
                            Employee department/function:{' '}
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.BuMaster?.buName === null
                                ? 'NA'
                                : userDetails?.BuMaster?.buName}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className={`${styles.auto_card_content}`}>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            Location:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.location === null
                                ? 'NA'
                                : userDetails?.location}
                            </span>
                          </div>
                        </Col>
                        <Col
                          span={isMobile ? 24 : 12}
                          className={`${styles.req_details_margin}`}
                        >
                          <div className={`${styles.card_content}`}>
                            {' '}
                            Employee manager:
                            <span
                              style={{ marginLeft: '4px' }}
                              className={`${styles.email_margin}`}
                            >
                              {userDetails?.ReportingManager?.User
                                ?.givenName === null
                                ? 'NA'
                                : userDetails?.ReportingManager?.User
                                    ?.givenName}{' '}
                              {userDetails?.ReportingManager?.User?.surname}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="whom_create"
                          label={
                            <div className={`${styles.card_title}`}>
                              Are you creating this for yourself?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'whom_create',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Self</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>Someone else</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    {formValues?.whom_create === false ? (
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Please select the delegator from the list below
                              </div>
                            }
                            name="delegatorId"
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              className={`${styles.margin_Top}`}
                              style={{ marginBottom: '15px' }}
                              showSearch
                              allowClear={true}
                              optionFilterProp="children"
                              placeholder="Select delegator"
                              //onSearch={inputVenderName}
                              onSearch={debounceFetcherDeligatorsId}
                              onChange={(value) =>
                                handleFormDataOnChange('delegatorId', value)
                              }
                              filterOption={(input, option) => {
                                return (
                                  option.key
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option.title
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {deligatorsIdList?.map((item, index) => (
                                <Option
                                  title={item?.GiftEntryDelegator?.givenName}
                                  key={index}
                                  value={item?.GiftEntryDelegator?.id}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      textTransform: 'uppercase',
                                      fontFamily: 'SFProText Regular'
                                    }}
                                  >
                                    <span style={{ marginLeft: '10px' }}>
                                      {item?.GiftEntryDelegator?.givenName} (
                                      <span
                                        style={{ textTransform: 'lowercase' }}
                                      >
                                        {item?.GiftEntryDelegator?.email}
                                      </span>
                                      )
                                    </span>
                                  </span>
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </Card>
                    ) : (
                      ''
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="buInfo"
                          label={
                            <div className={`${styles.card_title}`}>
                              Is the above mentioned BU/Function information
                              correct?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange('buInfo', e.target.value)
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    <Spin spinning={set_BU_mappingLoading}>
                      {formValues?.buInfo === false ? (
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              label={
                                <div className={`${styles.card_title}`}>
                                  Please select the BU
                                </div>
                              }
                              name="buId"
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              <Spin spinning={get_BU_MasterLoading}>
                                <Select
                                  className={`${styles.margin_Top}`}
                                  style={{ marginBottom: '15px' }}
                                  showSearch
                                  allowClear={true}
                                  optionFilterProp="children"
                                  placeholder="Search and select your BU"
                                  onSearch={debounceFetcherAllUsers}
                                  //onChange={handlerSelectedBU}
                                  onChange={(value) =>
                                    handleFormDataOnChange('buId', value)
                                  }
                                  filterOption={(input, option) => {
                                    return (
                                      option.key
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0 ||
                                      option.title
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }}
                                >
                                  {bu_MasterList?.map((item, index) => (
                                    <Option
                                      title={item?.buName}
                                      key={index}
                                      value={item?.id}
                                    >
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          textTransform: 'uppercase',
                                          fontFamily: 'SFProText Regular'
                                        }}
                                      >
                                        <span style={{ marginLeft: '10px' }}>
                                          {item?.buName}
                                          {/*  (
                                  <span style={{ textTransform: 'lowercase' }}>
                                    {item?.email}
                                  </span>
                                  ) */}
                                        </span>
                                      </span>
                                    </Option>
                                  ))}
                                </Select>
                              </Spin>
                            </FormItem>
                            <a
                              onClick={handlerBU}
                              className={`${styles.buUpload}`}
                              disabled={toggleUpload === false}
                            >
                              {checked === false && toggleUpload === false ? (
                                <Tag color="default">UPDATE</Tag>
                              ) : checked === false && toggleUpload === true ? (
                                <Tag color="processing">UPDATE</Tag>
                              ) : (
                                <Tag
                                  icon={<CheckCircleOutlined />}
                                  color="success"
                                >
                                  UPDATED
                                </Tag>
                              )}
                            </a>
                            {/*  {checked === true && (
                            <p
                              style={{
                                marginBottom: '20px',
                                float: 'right',
                                marginRight: '2px'
                              }}
                            >
                              <Checkbox
                                checked={checked}
                                //  onChange={onChange}
                              ></Checkbox>
                            </p>
                          )} */}
                          </div>
                        </Card>
                      ) : (
                        ''
                      )}
                    </Spin>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div
                      className={`${styles.title_section}`}
                    >{`Giver Details`}</div>
                    <Divider className={`${styles.divider_margin}`} />
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="recipientName"
                          label={
                            <div className={`${styles.card_title}`}>
                              {`Full Name of the Giver`}
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter the full name of the giver'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the full name of the giver"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'recipientName',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="organizationName"
                          label={
                            <div className={`${styles.card_title}`}>
                              {`Giver Organization Full Name`}
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter the full name of the givers organization'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the full name of the giver`s organization"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'organizationName',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="designation"
                          label={
                            <div
                              className={`${styles.card_title}`}
                            >{`Designation of the Giver`}</div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter the designation of the giver'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter the designation of the giver"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'designation',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          label={
                            <div className={`${styles.card_title}`}>
                              Giver Stakeholder Type
                            </div>
                          }
                          name="recipientType"
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            className={`${styles.margin_Top}`}
                            style={{ marginBottom: '15px' }}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select giver`s stakeholder type"
                            //onSearch={inputVenderName}
                            onSearch={debounceFetcher}
                            onChange={(value) =>
                              handleFormDataOnChange('recipientType', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {recipientType?.map((item, index) => (
                              <Option
                                title={item.label}
                                key={index}
                                value={item?.value}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  <span style={{ marginLeft: '10px' }}>
                                    {item?.label}
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </div>
                    </Card>
                    {recipientTypeOther === 'other' && (
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="recipientType_other"
                            label={
                              <div className={`${styles.card_title}`}>
                                Other
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              },
                              {
                                validator: async (_, value) => {
                                  if (value?.trim()?.length !== 0) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      'Enter stakeholder type'
                                    );
                                  }
                                }
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              className={`${styles.margin_Top}`}
                              allowClear
                              placeholder="Enter stakeholder type"
                              onChange={handleOtherRecipientType}
                            />
                          </FormItem>
                        </div>
                      </Card>
                    )}
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftReceiverSector"
                          label={
                            <div className={`${styles.card_title}`}>
                              Was the gift giver a government official?
                              <a
                                onClick={() => setGovernmentOfficialModal(true)}
                              >
                                <InfoCircleOutlined
                                  style={{
                                    marginLeft: '2px',
                                    fontSize: '16px'
                                  }}
                                />
                              </a>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'giftReceiverSector',
                                e.target.value
                              )
                            }
                            className={`${styles.card_Radio}`}
                          >
                            <Row className={`${styles.Radio_button_margin}`}>
                              <Col span={12}>
                                <Radio value={'government'}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={'non-government'}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="wasThereOngoingTender"
                          label={
                            <div className={`${styles.card_title}`}>
                              Was there any ongoing tender/discussion with the
                              company at the time of acceptance of this gift?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'wasThereOngoingTender',
                                e.target.value
                              )
                            }
                            //value={value}
                            className={`${styles.card_Radio}`}
                          >
                            <Row
                              className={`${styles.Radio_button_margin}`}
                              style={{ marginBottom: '10px' }}
                            >
                              <Col span={12}>
                                <Radio value={true}>Yes</Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={false}>No</Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                    {formValues?.wasThereOngoingTender === true ? (
                      <>
                        <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              name="tenderDetails"
                              label={
                                <div className={`${styles.card_title}`}>
                                  Provide details of the ongoing
                                  tender/discussion and the role played by the
                                  giver of the gift
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter details about the tender'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter details about the ongoing tender and the role played by the giver of this gift"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                            <FormItem
                              name="tenderDetails2"
                              label={
                                <div className={`${styles.card_title}`}>
                                  Please provide a business justification for
                                  this gift during an ongoing tender/discussion
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter details about the tender'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter business justification for receiving this gift during an ongoing tender discussion"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails2',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </div>
                        </Card>
                        {/*  <Card className={`${styles.cardStyles}`}>
                          <div className={`${styles.card_content}`}>
                            <FormItem
                              name="tenderDetails2"
                              label={
                                <div className={`${styles.card_title}`}>
                                  Please provide a business justification for
                                  why this gift was given during an ongoing
                                  tender/discussion.
                                </div>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field'
                                },
                                {
                                  validator: async (_, value) => {
                                    if (value?.trim()?.length !== 0) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        'Enter details about the business justification'
                                      );
                                    }
                                  }
                                }
                              ]}
                              className={`${styles.textarea_styles}`}
                            >
                              <TextArea
                                className={`${styles.margin_Top}`}
                                // maxLength={500}
                                // rows={3}
                                allowClear
                                placeholder="Enter details about the business justification"
                                size="small"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'tenderDetails2',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </div>
                        </Card> */}
                      </>
                    ) : (
                      ''
                    )}
                  </Card>
                </>
              )}

              {current === 1 && (
                <Card className={`${styles.cardStyles}`}>
                  <div
                    className={`${styles.title_section}`}
                  >{`Gift Details`}</div>
                  <Divider className={`${styles.divider_margin}`} />

                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Please select the gift from the list below
                          </div>
                        }
                        name="giftId"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          className={`${styles.margin_Top}`}
                          style={{ marginBottom: '15px' }}
                          showSearch
                          allowClear={true}
                          optionFilterProp="children"
                          placeholder="Select gift"
                          //onSearch={inputVenderName}
                          onSearch={debounceFetcher}
                          onChange={(value) =>
                            handleFormDataOnChange('giftId', value)
                          }
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {giftMasterOthers?.map((item, index) => (
                            <Option
                              title={item.title}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                <span style={{ marginLeft: '10px' }}>
                                  {item?.title}
                                </span>
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </div>
                  </Card>
                  {giftName === 'Others' && (
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="giftName_other"
                          label={
                            <div className={`${styles.card_title}`}>Others</div>
                          }
                          rules={[
                            {
                              required: true,
                              message: '' // 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                // const pattern =
                                //   /\b(cash|CASH|Cash|gold|GOLD|Gold|money|MONEY|Money|amount|AMOUNT|Amount)\b/;
                                const newValue = value
                                  ?.toString()
                                  ?.toLowerCase();
                                if (pattern.test(newValue)) {
                                  return Promise.reject(
                                    'The gift you have entered is from a restricted category. Please select it from the list above to proceed.'
                                  );
                                } else if (newValue?.trim()?.length === 0) {
                                  return Promise.reject(
                                    'This is a required field'
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                                /*  if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject('Enter description');
                                } */
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            className={`${styles.margin_Top}`}
                            allowClear
                            placeholder="Enter description..."
                            onChange={handleOtherDescription}
                          />
                        </FormItem>
                      </div>
                    </Card>
                  )}
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="giftDescription"
                        label={
                          <div className={`${styles.card_title}`}>
                            Description of the gift
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.textarea_styles}`}
                      >
                        <TextArea
                          className={`${styles.margin_Top}`}
                          // maxLength={500}
                          // rows={3}
                          allowClear
                          placeholder="Describe the gift you have received"
                          size="small"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'giftDescription',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="isGiftEmbossed"
                        label={
                          <div className={`${styles.card_title}`}>
                            Was the gift embossed with the logo of the
                            gift-giving company?
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'isGiftEmbossed',
                              e.target.value
                            )
                          }
                          className={`${styles.card_Radio}`}
                        >
                          <Row className={`${styles.Radio_button_margin}`}>
                            <Col span={12}>
                              <Radio value={true}>Yes</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={false}>No</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        label={
                          <div className={`${styles.card_title}`}>
                            Date of receipt of this gift?
                          </div>
                        }
                        name="dateOfGiftReceived"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.date_styles}`}
                      >
                        <DatePicker
                          className={`${styles.margin_Top}`}
                          format={'DD-MMM-YYYY'}
                          onChange={(_, dateValue) =>
                            handleFormDataOnChange(
                              'dateOfGiftReceived',
                              dateValue
                            )
                          }
                          disabledDate={(current) => {
                            return (
                              // moment().add(-1, 'days') >= current //||
                              moment().add(0, 'month') <= current
                            );
                          }}
                        ></DatePicker>
                      </FormItem>
                    </div>
                  </Card>
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      {formValues?.giftId ===
                      'd0df4905-a386-4868-8ef5-213b014ab967' ? (
                        <FormItem
                          name="valueOfGift"
                          label={
                            <div className={`${styles.card_title}`}>
                              Approximate value of the gift (in INR)
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#a6a6ac',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                {/* (Note – If you are giving more than one item then
                              include the total value of the items) */}
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: false,
                              message: '' //'This is a required field'
                            }
                            /*  {
                              validator: async (_, value) => {
                                const isValid =
                                  /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(
                                    value
                                  );
                                if (parseFloat(value) <= 0) {
                                  return Promise.reject('Enter valid number');
                                } else if (isValid === value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            } */
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            disabled
                            step="0.01"
                            className={`${styles.margin_Top}`}
                            allowClear
                            type="number"
                            min={1}
                            placeholder="Enter approximate value of the gift (in INR)"
                            onChange={handleValueOfGift}
                            onKeyPress={preventMinus}
                            //onKeyDown={preventNegativeValues}
                          />
                        </FormItem>
                      ) : (
                        <FormItem
                          name="valueOfGift"
                          label={
                            <div className={`${styles.card_title}`}>
                              Approximate value of the gift (in INR)
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#a6a6ac',
                                  fontFamily: 'SFProText Regular'
                                }}
                              >
                                {/* (Note – If you are giving more than one item then
                           include the total value of the items) */}
                              </div>
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: '' //'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                /* same validation of code
                                // const isValid =
                                //   /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(
                                //     value
                                //   ); */
                                const parsedValue = parseFloat(value);
                                const roundedValue = isNaN(parsedValue)
                                  ? 0
                                  : parseFloat(parsedValue.toFixed(2));
                                if (parseFloat(roundedValue) <= 0) {
                                  return Promise.reject('Enter valid number');
                                } else if (roundedValue === value) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.resolve();
                                }
                              }
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            step="0.01"
                            className={`${styles.margin_Top}`}
                            allowClear
                            type="number"
                            min={0}
                            placeholder="Enter approximate value of the gift (in INR)"
                            onChange={handleValueOfGift}
                            onKeyPress={preventMinus}
                            //onKeyDown={preventNegativeValues}
                          />
                        </FormItem>
                      )}
                    </div>
                  </Card>

                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="contextOrBusinessPurposeOfTheGift"
                        label={
                          <div className={`${styles.card_title}`}>
                            {`What is the context/business purpose of this gift?`}
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          },
                          {
                            validator: async (_, value) => {
                              if (value?.trim()?.length !== 0) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  'Enter the context/business purpose of this gift'
                                );
                              }
                            }
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          className={`${styles.margin_Top}`}
                          allowClear
                          placeholder="Enter the context/business purpose of this gift"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'contextOrBusinessPurposeOfTheGift',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card>
                  {/* <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          // name="files"
                          name="gift_image"
                          label={
                            <div className={`${styles.card_title}`}>
                              Upload the image of the gift
                            </div>
                          }
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Dragger
                            className={`${styles.margin_Top}`}
                            beforeUpload={() => false}
                            customRequest={({ onChange }) =>
                              setTimeout(() => {
                                onChange('ok', null);
                              }, 0)
                            }
                            action="false"
                            listType="picture-card"
                            fileList={fileListImage}
                            onPreview={handlePreview}
                            onChange={handleChangeImage}
                            type="file"
                            accept="image/*"
                          >
                            {fileListImage.length >= 8
                              ? null
                              : uploadButtonImage}
                          </Dragger>
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                            //width="800px"
                          >
                            <img
                              alt="example"
                              style={{ width: '100%' }}
                              src={previewImage}
                            />
                          </Modal>
                        </FormItem>
                      </div>
                    </div>
                  </Card> */}

                  {/* <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="additionalComments"
                        label={
                          <div className={`${styles.card_title}`}>
                            Additional Comments
                          </div>
                        }
                        rules={[
                          {
                            required: false,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.textarea_styles}`}
                      >
                        <TextArea
                          className={`${styles.margin_Top}`}
                          // maxLength={500}
                          // rows={3}
                          allowClear
                          placeholder="Enter comments.."
                          size="small"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'additionalComments',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </div>
                  </Card> */}
                  <Card className={`${styles.cardStyles}`}>
                    <div className={`${styles.card_content}`}>
                      <FormItem
                        name="whetherGiftAcceptedReturned"
                        label={
                          <div className={`${styles.card_title}`}>
                            Has the gift been accepted or returned?
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'whetherGiftAcceptedReturned',
                              e.target.value
                            )
                          }
                          className={`${styles.card_Radio}`}
                        >
                          <Row
                            className={`${styles.Radio_button_margin}`}
                            style={{ marginBottom: '10px' }}
                          >
                            <Col span={12}>
                              <Radio value={'accepted'}>Accepted</Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={'returned'}>Returned</Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </Card>
                  {formValues?.whetherGiftAcceptedReturned === 'accepted' ? (
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="reasonForAcceptance"
                          label={
                            <div className={`${styles.card_title}`}>
                              Provide the reason for acceptance of the gift
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            },
                            {
                              validator: async (_, value) => {
                                if (value?.trim()?.length !== 0) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Enter reason for acceptance'
                                  );
                                }
                              }
                            }
                          ]}
                          className={`${styles.textarea_styles}`}
                        >
                          <TextArea
                            className={`${styles.margin_Top}`}
                            // maxLength={500}
                            // rows={3}
                            allowClear
                            placeholder="Enter reason for acceptance"
                            size="small"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'reasonForAcceptance',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </div>
                    </Card>
                  ) : formValues?.whetherGiftAcceptedReturned === 'returned' ? (
                    <Card className={`${styles.cardStyles}`}>
                      <div className={`${styles.card_content}`}>
                        <FormItem
                          name="whomToReturned"
                          label={
                            <div className={`${styles.card_title}`}>
                              Who did you return this gift to?
                            </div>
                          }
                          rules={[
                            {
                              required: true,
                              message: 'This is a required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Radio.Group
                            onChange={handleWhomToReturned}
                            /*  onChange={(e) =>
                             handleFormDataOnChange(
                                'whomToReturned',
                                e.target.value
                              )
                            } */
                            className={`${styles.card_Radio}`}
                          >
                            <Row
                              className={`${styles.Radio_button_margin}`}
                              style={{ marginBottom: '10px' }}
                            >
                              <Col span={12}>
                                <Radio value={'returnedGiver'}>
                                  Returned to gift giver
                                </Radio>
                              </Col>
                              <Col span={12}>
                                <Radio value={'returnedCEC'}>
                                  Returned to RO
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </Card>
                  ) : (
                    ''
                  )}
                  {formValues?.whetherGiftAcceptedReturned === 'returned' &&
                  whomToReturnedValue === 'returnedGiver' ? (
                    <>
                      {' '}
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Date of return to the gift giver
                              </div>
                            }
                            name="dateOfReturnedGiver"
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.date_styles}`}
                          >
                            <DatePicker
                              className={`${styles.margin_Top}`}
                              format={'DD-MMM-YYYY'}
                              onChange={(_, dateValue) =>
                                handleFormDataOnChange(
                                  'dateOfReturnedGiver',
                                  dateValue
                                )
                              }
                              disabledDate={(current) => {
                                return (
                                  // moment().add(-1, 'days') >= current //||
                                  moment().add(0, 'month') <= current
                                );
                              }}
                            ></DatePicker>
                          </FormItem>
                        </div>
                      </Card>
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="detailsReasonForReturned"
                            label={
                              <div className={`${styles.card_title}`}>
                                Details of gift returned
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              },
                              {
                                validator: async (_, value) => {
                                  if (value?.trim()?.length !== 0) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      'Enter reason for return'
                                    );
                                  }
                                }
                              }
                            ]}
                            className={`${styles.textarea_styles}`}
                          >
                            <TextArea
                              className={`${styles.margin_Top}`}
                              // maxLength={500}
                              // rows={3}
                              allowClear
                              placeholder="Enter reason for return"
                              size="small"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'detailsReasonForReturned',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        </div>
                      </Card>
                    </>
                  ) : formValues?.whetherGiftAcceptedReturned === 'returned' &&
                    whomToReturnedValue === 'returnedCEC' ? (
                    <>
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Select RO name
                              </div>
                            }
                            name="roId"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles} ${styles.marginBottom} `}
                          >
                            <Select
                              // style={{ marginBottom: '15px' }}
                              showSearch
                              allowClear={true}
                              optionFilterProp="children"
                              placeholder="Select RO name"
                              //onSearch={inputVenderName}
                              //onSearch={debounceFetcher}
                              onChange={(value) =>
                                handleFormDataOnChange('roId', value)
                              }
                              filterOption={(input, option) => {
                                return (
                                  option.key
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                  option.title
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {roUsersList?.map((item, index) => (
                                <Option
                                  title={item.givenName}
                                  key={index}
                                  value={item?.id}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      textTransform: 'uppercase',
                                      fontFamily: 'SFProText Regular'
                                    }}
                                  >
                                    <span style={{ marginLeft: '10px' }}>
                                      {item?.givenName}{' '}
                                      <span
                                        style={{ textTransform: 'lowercase' }}
                                      >
                                        ({item?.email})
                                      </span>
                                      (
                                      {item?.location === null
                                        ? 'NA'
                                        : item?.location}
                                      )
                                    </span>
                                  </span>
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                      </Card>
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Why the gift was not returned to the giver?
                              </div>
                            }
                            name="not_returned_giver"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.textarea_styles}`}
                            //className={`${styles.select_styles}`}
                          >
                            <TextArea
                              allowClear
                              placeholder="Enter reasons for not returned to the giver"
                            />
                          </FormItem>
                        </div>
                      </Card>
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                What date was the gift sent to RO?
                              </div>
                            }
                            name="dateOfReturnedCEC"
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              }
                            ]}
                            className={`${styles.date_styles}`}
                          >
                            <DatePicker
                              className={`${styles.margin_Top}`}
                              format={'DD-MMM-YYYY'}
                              onChange={(_, dateValue) =>
                                handleFormDataOnChange(
                                  'dateOfReturnedCEC',
                                  dateValue
                                )
                              }
                              disabledDate={(current) => {
                                return (
                                  // moment().add(-1, 'days') >= current //||
                                  moment().add(0, 'month') <= current
                                );
                              }}
                            ></DatePicker>
                          </FormItem>
                        </div>
                      </Card>
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            name="detailsReasonForReturnedCEC"
                            label={
                              <div className={`${styles.card_title}`}>
                                Details of gift returned
                              </div>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field'
                              },
                              {
                                validator: async (_, value) => {
                                  if (value?.trim()?.length !== 0) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(
                                      'Enter reason for return'
                                    );
                                  }
                                }
                              }
                            ]}
                            className={`${styles.textarea_styles}`}
                          >
                            <TextArea
                              className={`${styles.margin_Top}`}
                              // maxLength={500}
                              // rows={3}
                              allowClear
                              placeholder="Enter reason for return"
                              size="small"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'detailsReasonForReturnedCEC',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        </div>
                      </Card>
                      <Card className={`${styles.cardStyles}`}>
                        <div className={`${styles.card_content}`}>
                          <FormItem
                            label={
                              <div className={`${styles.card_title}`}>
                                Upload documents
                                <div
                                  style={{
                                    fontSize: '12px',
                                    color: '#a6a6ac',
                                    fontFamily: 'SFProText Regular'
                                  }}
                                >
                                  (Attach evidence relating to the return of the
                                  gift - e.g. Acknowledgment of receipt or
                                  courier receipt, etc.)
                                </div>
                              </div>
                            } //"Enter reasons for making an exception approval"
                            name="roDocument"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            // rules={[
                            //   {
                            //     required: false,
                            //     message: 'Required field'
                            //   }
                            // ]}
                            className={`${styles.upload_styles} ${styles.marginBottom}`}
                          >
                            <Dragger
                              className={`${styles.margin_Top}`}
                              beforeUpload={() => false}
                              customRequest={({ onChange }) =>
                                setTimeout(() => {
                                  onChange('ok', null);
                                }, 0)
                              }
                              action="false"
                              listType="picture-card"
                              fileList={fileList}
                              onPreview={handlePreview}
                              onChange={handleChange}
                            >
                              {fileList.length >= 8 ? null : uploadButton}
                            </Dragger>
                            <Modal
                              visible={previewOpen}
                              title={previewTitle}
                              footer={null}
                              onCancel={handleCancel}
                              width="800px"
                            >
                              <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewImage}
                              />
                            </Modal>
                          </FormItem>
                        </div>
                      </Card>
                    </>
                  ) : (
                    ''
                  )}
                </Card>
              )}
            </div>
            {current === 0 && (
              <Row>
                <Button
                  className={`${styles.cancel_button}`}
                  htmlType="submit"
                  size="middle"
                  onClick={() => history.push('/declare-a-gift')}
                >
                  Cancel
                </Button>
                <Button
                  className={`${styles.submit_button}`}
                  size="middle"
                  onClick={handleClickNext}
                >
                  Next
                </Button>
              </Row>
            )}
            {current === 1 && (
              <Row>
                <Button
                  className={`${styles.cancel_button}`}
                  // htmlType="submit"
                  size="middle"
                  onClick={handleClickBack}
                >
                  Back
                </Button>
                <Button
                  loading={declare_received_gift_formLoading}
                  name="submit"
                  className={`${styles.submit_button}`}
                  htmlType="submit"
                  size="middle"
                  // onClick={submitHandler}
                >
                  Submit
                </Button>
              </Row>
            )}
          </Form>
        </div>
        {governmentOfficialModal ? (
          <GovernmentOfficial_Modal
            governmentOfficialModal={governmentOfficialModal}
            setGovernmentOfficialModal={setGovernmentOfficialModal}
          />
        ) : (
          ''
        )}
      </div>
    </Spin>
  );
}
export default Gift_received_page_Form;

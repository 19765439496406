import React, { useState, useCallback } from 'react';
import styles from './index.module.less';
import {
  Radio,
  Button,
  Row,
  Col,
  Card,
  Empty,
  Tooltip,
  Tag,
  Popconfirm,
  Space
} from 'antd';
//import plus from '../../images/plus.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import moment from 'moment';
import AllExportReports from '../reports/AllExportReports';
//import { Value_of_gift } from '../../../../utilities/helpers';

const filters = [
  /*  {
    name: 'Gift Received',
    value: ''
  },
  {
    name: 'Pending',
    value: 'pending'
  },
  {
    name: 'Accepted',
    value: 'accepted'
  },
  {
    name: 'Rejected',
    value: 'rejected'
  } */
];

function CXO_Users_FlowsPage({
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  page,
  setPage,
  pageSize,
  setPageSize,
  designated_Authorities_List,
  pagination,
  get_Designated_AuthoritiesLoading,
  setSearchRequest,
  showDisablePopup,
  setShowDisablePopup,
  handleDeleteRequest
}) {
  const designated_Authorities_List1 = designated_Authorities_List?.map(
    (item) => ({
      key: item?.id,
      // delegatorId: item?.GiftEntryDelegator?.id,
      // DelegateId: item?.GiftEntryDelegate?.id,
      // role: item?.GiftEntryDelegator?.givenName,
      // giverName: item?.GiftEntryDelegate?.givenName,
      // delegated_date: item?.createdAt,
      status: item?.isActive,
      buId: item?.buId,
      hasMultipleUsers: item?.hasMultipleUsers,
      role: item?.Roles[0]?.role,
      userId: item?.userId,
      userEmail: item?.User?.email,
      giverName: item?.User?.givenName,
      surname: item?.surname,
      userName: `${item?.givenName}${' '}${item?.surname}${' '}${'('}${
        item?.email
      }${')'}`,
      updatedAt: item?.updatedAt
    })
  );
  console.log('designated_Authorities_List1', designated_Authorities_List1);

  const [isAllExortModalOpen, setAllExortModalOpen] = useState(false);

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.role?.localeCompare(b?.role),
      render: (role) => {
        return (
          <>
            <Tooltip
              title={role}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* <a href={`view-gift-received-details/${record.id}`}> */}
              <span className={styles.text_transform}>
                {' '}
                {role === null ? '' : role}
              </span>
              {/*  </a> */}
            </Tooltip>
          </>
        );
      }
    },
    /*  {
      title: 'Giver Name',
      dataIndex: 'giverName',
      key: 'giverName',
      //width: 100,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.giverName?.localeCompare(b?.giverName),
      render: (giverName) => {
        return (
          <>
            <Tooltip
              title={giverName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {giverName === null ? '' : giverName}
            </Tooltip>
          </>
        );
      }
    }, */
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      width: 400,
      ellipsis: true,
      // sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
      render: (userName) => {
        return (
          <>
            <Tooltip
              title={userName}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {userName}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      //width: '120px',
      ellipsis: true,
      //sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status, record) => (
        <Tag
          className={`${styles.status_style}`}
          color={
            record?.status === true
              ? 'green'
              : record?.status === false
              ? 'red'
              : 'transparent'
          }
        >
          {record?.status === true ? 'ACTIVE' : 'DEACTIVATED'}
        </Tag>
      )
    },
    {
      title: 'Date of Deactivation',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`updatedAt`]).unix() - moment(b[`updatedAt`]).unix(),
      render: (updatedAt, record) => (
        <>
          <Tooltip
            title={
              updatedAt === null ? '' : moment(updatedAt).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {record?.status === false
              ? moment(updatedAt).format('DD MMM YYYY')
              : 'NA'}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Action',
      key: '',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            {record?.status === true ? (
              <Popconfirm
                placement="top"
                title="Are you sure you want to deactivate this CXO User?"
                open={visibility(record)}
                onConfirm={() => {
                  handleDeleteRequest({
                    record: record
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Deactivate"
                cancelText="No"
                okButtonProps={{ type: 'danger' }}
              >
                <a
                  className={`${styles.allocation_btn}`}
                  style={{
                    fontSize: '12px'
                  }}
                  type="link"
                  // disabled={record?.status === false}
                >
                  Deactivate
                </a>

                {/* <a
                className={`${styles.delete_icon}`}
                onClick={() => {
                  setShowDisablePopup({ [record.id]: true });
                }}
              >
                <span className={`${styles.deactivate}`}>
                  {record?.status === true ? 'Deactivate' : 'Disabled'}
                </span>
              </a> */}
              </Popconfirm>
            ) : (
              <Popconfirm
                placement="top"
                title="Are you sure you want to Activate this CXO User?"
                open={visibility(record)}
                onConfirm={() => {
                  handleDeleteRequest({
                    record: record
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Activate"
                cancelText="No"
                okButtonProps={{
                  type: 'primary',
                  background: 'green !important'
                }}
              >
                <a
                  className={`${styles.allocation_btn}`}
                  style={{
                    fontSize: '12px'
                  }}
                  type="link"
                  // disabled={record?.status === false}
                >
                  Activate
                </a>

                {/* <a
                className={`${styles.delete_icon}`}
                onClick={() => {
                  setShowDisablePopup({ [record.id]: true });
                }}
              >
                <span className={`${styles.deactivate}`}>
                  {record?.status === true ? 'Deactivate' : 'Disabled'}
                </span>
              </a> */}
              </Popconfirm>
            )}
          </>
        </Space>
      )
    }
  ];

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no delegator to display
        </div>
      </span>
    )
  };

  return (
    <>
      <div className={`${styles.container}`}>
        <Row className={`${styles.row_container}`}>
          <div className={`${styles.gift_container}`}>
            <div className={`${styles.myGift_title}`}>Configure CXO Users</div>
            <div className={`${styles.myGift_text}`}>
              View and manage CXO Users
            </div>
          </div>
          <div className={`${styles.declareGift_div}`}>
            <Row>
              <Button
                className={`${styles.export_button}`}
                onClick={() => setAllExortModalOpen(true)}
              >
                Export
              </Button>
              <Button
                className={`${styles.declareGift_button}`}
                icon={<PlusOutlined />}
                onClick={() => history.push('/create_cxo_users')}
              >
                Add CXO User
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                className={`${styles.declareGift_button}`}
                icon={<ArrowLeftOutlined />}
                onClick={() => history.push('/admin')}
              >
                Back
              </Button>
            </Row>
          </div>
        </Row>
        <div className={`${styles.tabs_container}`}>
          <Row>
            {/* className={`${styles.row_container}`} */}
            <Col span={16}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      //className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={8}>
              <SearchBar
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by role'
                }}
              />
            </Col>
          </Row>
        </div>
        <Card className={`${styles.cardStyles}`}>
          <div className={`${styles.table_container}`}>
            <CustomTable
              showSorterTooltip={false}
              columns={columns}
              dataSource={designated_Authorities_List1}
              locale={locale}
              loading={get_Designated_AuthoritiesLoading}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
        </Card>
        {isAllExortModalOpen ? (
          <AllExportReports
            isAllExortModalOpen={isAllExortModalOpen}
            setAllExortModalOpen={setAllExortModalOpen}
            apiName={'cxo_users_master'}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}
export default CXO_Users_FlowsPage;
{
  /* <Row className={`${styles.row_container_setting}`}>
        <div className={`${styles.gift_container}`}>
          <div className={`${styles.myGift_title_setting}`}>
            {' '}
            Settings  {user_role?.name}! 
          </div>
           <div className={`${styles.myGift_text}`}>
              View the list of gifts and their status
            </div> 
        </div>
      </Row> */
}

import { Avatar, Dropdown, Menu, Typography, Tooltip } from 'antd';
import {
  CaretDownOutlined,
  LogoutOutlined
  //ToolOutlined
} from '@ant-design/icons';
import styles from './index.module.less';
import { logout } from 'features/login/redux/slice';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
//import { Link } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

const { Text } = Typography;

const UserMenu = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const userDetails = LocalStorage.getItem('GiftReg_user');
  //console.log('userDetails', userDetails);
  const userFullName = userDetails?.user?.name ? (
    <span>{userDetails.user?.name.slice(0, 1)}</span>
  ) : (
    'U'
  );

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log('url', url);
        // Return false if you would like to stop navigation after local logout
        dispatch(logout());
        return true;
      }
    });
  };
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => signOutClickHandler()}
        key="1"
        icon={<LogoutOutlined />}
      >
        <Text>Logout</Text>
      </Menu.Item>

      {/* <Menu>
      <Menu.Item key="1" icon={<LogoutOutlined />}>
        <Text onClick={() => dispatch(logout())}>Logout</Text>
      </Menu.Item>
      <Menu.Item key="2" icon={<ToolOutlined />}>
        <Link to="/changepassword">Change Password</Link>
      </Menu.Item>
    </Menu> */}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <div className={`df-jb-ac ${styles.user_menu_container}`}>
        <Tooltip
          title={
            <span>
              {userDetails.user?.name}
              <div>Role: {userDetails.user?.roles[0]}</div>
            </span>
          }
          placement="left"
          color={'white'}
          overlayInnerStyle={{
            color: '#32324D',
            fontSize: '12px',
            fontFamily: 'SFProText Regular'
          }}
        >
          <Avatar className={styles.user_menu_avatar}>{userFullName}</Avatar>
        </Tooltip>
        <CaretDownOutlined />
      </div>
    </Dropdown>
  );
};

export default UserMenu;

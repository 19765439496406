import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { declare_received_gift, gift_master } from './api';

const initialState = {
  loading: false,
  declare_received_gift_formLoading: false,
  GiftMasterLoading: false,
  pagination: [],
  giftMaster: []
};

const actions = {
  GET_GIFT_MASTER: 'get_gift_master/GET_GIFT_MASTER',
  DECLARE_RECEIVED_GIFT_FORM:
    'declare_received_gift_form/DECLARE_RECEIVED_GIFT_FORM'
};

export const declare_received_gift_form = createAsyncThunk(
  actions.DECLARE_RECEIVED_GIFT_FORM,
  async (payload) => {
    const response = await declare_received_gift(payload);
    return response;
  }
);

export const get_gift_master = createAsyncThunk(
  actions.GET_GIFT_MASTER,
  async (payload) => {
    const response = await gift_master(payload);
    return response;
  }
);

export const declareReceivedGiftFormSlice = createSlice({
  name: 'declareReceivedGiftForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(declare_received_gift_form.pending, (state) => {
        state.declare_received_gift_formLoading = true;
      })
      .addCase(declare_received_gift_form.fulfilled, (state, action) => {
        //console.log('declareGiftFormSlice', action.payload);
        const { success, msg } = action.payload;
        state.declare_received_gift_formLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(declare_received_gift_form.rejected, (state, action) => {
        const { msg } = action.error;

        state.declare_received_gift_formLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_gift_master.pending, (state) => {
        state.GiftMasterLoading = true;
      })
      .addCase(get_gift_master.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.GiftMasterLoading = false;
        state.giftMaster = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(get_gift_master.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.GiftMasterLoading = false;

        message.error(msg);
      });
  }
});
export default declareReceivedGiftFormSlice.reducer;

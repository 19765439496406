import React from 'react';
import styles from './index.module.less';
import { Col, Row, Typography } from 'antd';
import FormItem from 'common/FormItem';

//const { Text } = Typography;

function View_CEC_and_MD_preApproval({ getGiftDetailsById, user_role }) {
  // const [ellipsis] = useState(true);
  return (
    <>
      {user_role?.roles[0] === 'cec-user' ? (
        getGiftDetailsById?.type === 'gag' &&
        getGiftDetailsById?.valueOfGift < 1000 &&
        getGiftDetailsById?.giftReceiverSector === 'non-government' ? (
          ''
        ) : (
          <>
            <Row gutter={16}>
              <Col span={8}>
                <FormItem
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      MD/MD Delegate Pre-approval
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.approval2 === true
                      ? 'Yes'
                      : getGiftDetailsById?.approval2 === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </FormItem>
              </Col>
              {/*  {getGiftDetailsById?.approval2 === true ? (
                <Col span={8}>
                  <FormItem
                    label={
                      <span
                        style={{
                          color: '#344054',
                          fontWeight: 600,
                          fontSize: '14px',
                          fontFamily: 'SFProText Regular'
                        }}
                      >
                        Pre-approval documents by MD Delegate
                      </span>
                    }
                    className={`${styles.select_styles}`}
                  >
                    <Typography className={`${styles.valueText}`}>
                      <div
                        style={{
                          marginTop: '0px',
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        {getGiftDetailsById?.ApprovalDocs?.length === 0
                          ? 'Not applicable'
                          : getGiftDetailsById?.ApprovalDocs?.map(
                              (item, index) => (
                                <Tooltip title={item?.originalName} key={index}>
                                  <Text
                                    style={
                                      ellipsis
                                        ? {
                                            width: 80
                                          }
                                        : undefined
                                    }
                                    ellipsis={
                                      ellipsis
                                        ? {
                                            tooltip: ''
                                          }
                                        : false
                                    }
                                  >
                                    <a
                                      href={item?.fileUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item?.originalName}
                                    </a>
                                  </Text>
                                </Tooltip>
                              )
                            )}
                      </div>
                    </Typography>
                  </FormItem>
                </Col>
              ) : (
                ''
              )} */}
            </Row>
          </>
        )
      ) : user_role?.roles[0] === 'md' ? (
        getGiftDetailsById?.type === 'gag' &&
        getGiftDetailsById?.valueOfGift < 1000 &&
        getGiftDetailsById?.giftReceiverSector === 'non-government' ? (
          ''
        ) : (
          <>
            <Row gutter={16}>
              <Col span={8}>
                <FormItem
                  // label="Value of gift (INR)"
                  label={
                    <span
                      style={{
                        color: '#344054',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'SFProText Regular'
                      }}
                    >
                      CEC Delegate Pre-approval
                    </span>
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.valueText}`}>
                    {getGiftDetailsById?.hasCECApproval === true
                      ? 'Yes'
                      : getGiftDetailsById?.hasCECApproval === false
                      ? 'No'
                      : 'Not applicable'}
                  </Typography>
                </FormItem>
              </Col>
              {/*  {getGiftDetailsById?.hasCECApproval === true ? (
                <Col span={8}>
                  <FormItem
                    label={
                      <span
                        style={{
                          color: '#344054',
                          fontWeight: 600,
                          fontSize: '14px',
                          fontFamily: 'SFProText Regular'
                        }}
                      >
                        Pre-approval documents by CEC Delegate
                      </span>
                    }
                    className={`${styles.select_styles}`}
                  >
                    <Typography className={`${styles.valueText}`}>
                      <div
                        style={{
                          marginTop: '0px',
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        {getGiftDetailsById?.CECApprovalDocs?.length === 0
                          ? 'Not applicable'
                          : getGiftDetailsById?.CECApprovalDocs?.map(
                              (item, index) => (
                                <Tooltip title={item?.originalName} key={index}>
                                  <Text
                                    style={
                                      ellipsis
                                        ? {
                                            width: 80
                                          }
                                        : undefined
                                    }
                                    ellipsis={
                                      ellipsis
                                        ? {
                                            tooltip: ''
                                          }
                                        : false
                                    }
                                  >
                                    <a
                                      href={item?.fileUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item?.originalName}
                                    </a>
                                  </Text>
                                </Tooltip>
                              )
                            )}
                      </div>
                    </Typography>
                  </FormItem>
                </Col>
              ) : (
                ''
              )} */}
            </Row>
          </>
        )
      ) : (
        ''
      )}
    </>
  );
}
export default View_CEC_and_MD_preApproval;

import React, { useState, useEffect } from 'react';
import RoUsersFlowsPage from './RoUsersFlowsPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../../../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_ROUsers_Master,
  //remove_bu_master,
  update_ROUsers_master
} from '../../../redux/slice';
//import FormData from 'form-data';

function RoUsersFlows() {
  TabTitle('Gift Register - Entry Delegator Flows');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    roUsers_MasterList,
    pagination,
    get_ROUsers_MasterLoading,
    remove_entry_delegatorLoading
  } = useSelector((state) => state.admin);

  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  //console.log('searchRequest', searchRequest);
  //console.log(triggerFilter);
  console.log('roUsers_MasterList', roUsers_MasterList);

  useEffect(() => {
    handleGetAllEntryDelegatorsList();
  }, [page, pageSize]);
  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllEntryDelegatorsList();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]); //page,

  const handleGetAllEntryDelegatorsList = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };
    dispatch(get_ROUsers_Master(payload));
  };

  /* const handleChange = (e) => {
    const value = { search: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  };*/
  const handleDeleteRequest = (record) => {
    // let formdata = new FormData();
    // console.log('record@@@@@@', record?.record);
    // formdata.append('deactivate', true);
    // formdata.append('id', record?.record?.id);
    const payload = {
      id: record?.record?.id,
      deactivate: true
    };

    dispatch(update_ROUsers_master(payload)).then(function (response) {
      if (response) {
        handleGetAllEntryDelegatorsList();
      }
    });
  };
  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <RoUsersFlowsPage
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          setTriggerFilter={setTriggerFilter}
          roUsers_MasterList={roUsers_MasterList}
          pagination={pagination}
          get_ROUsers_MasterLoading={get_ROUsers_MasterLoading}
          setSearchRequest={setSearchRequest}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          handleDeleteRequest={handleDeleteRequest}
          remove_entry_delegatorLoading={remove_entry_delegatorLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default RoUsersFlows;

import React, { useState, useEffect } from 'react';
import ViewReceivedGift_RequestPage from './components/viewReceivedGift_RequestPage';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getReceivedGifts_requestList } from './redux/slice';

function ViewReceivedGift_Request() {
  TabTitle('Gift Register - View Gift Request Status');

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    receivedGiftsRequestList,
    pagination,
    receivedGiftsLoading,
    countsReceivedGiftsRequestList
  } = useSelector((state) => state.receivedGiftsRequest);

  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  const [isSwitch, setIsSwitch] = useState(true);

  //console.log('searchRequest', searchRequest);
  //console.log(triggerFilter);
  console.log('receivedGiftsRequestList', receivedGiftsRequestList);

  useEffect(() => {
    handleGetPastGiftsList();
  }, [page, pageSize]);
  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetPastGiftsList();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest, isSwitch]); //page,

  const handleGetPastGiftsList = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest,
      delegation_type: isSwitch === true ? 'self' : 'delegated'
    };
    dispatch(getReceivedGifts_requestList(payload));
  };
  const onChange = () => {
    setTriggerFilter(true);
    setIsSwitch(!isSwitch);
  };
  /* const handleChange = (e) => {
    const value = { search: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  }; */

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewReceivedGift_RequestPage
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          setTriggerFilter={setTriggerFilter}
          pastGiftsList={receivedGiftsRequestList}
          pagination={pagination}
          pastGiftsListLoading={receivedGiftsLoading}
          countsReceivedGiftsRequestList={countsReceivedGiftsRequestList}
          setSearchRequest={setSearchRequest}
          onChange={onChange}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewReceivedGift_Request;

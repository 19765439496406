import React, { useState } from 'react';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import {
  Form,
  Space,
  Input,
  Select,
  Upload,
  Modal,
  Button,
  Radio,
  Row,
  Col,
  DatePicker,
  Alert
} from 'antd';
import { useParams } from 'react-router-dom';
import { getROUsersList } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
//import { history } from '@/app/history';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
//import { debounce } from 'lodash';

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ReturnRO_Modal = ({
  returnToROModal,
  setReturnToROModal,
  HandleReturnGift
  //getGiftDetailsById,
}) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const { roUsersList, returnGift_formLoading } = useSelector(
    (state) => state.returnGifts
  );

  const [doc, setDoc] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    dispatch(getROUsersList({}));
  }, []);
  console.log('roUsersList:', roUsersList);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug,value:', slug, value);

    setFormValues({
      ...formValues,
      [slug]: value
    });
  };

  const normFile = (e) => {
    setDoc(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  console.log('setDoc', doc);
  console.log('fileList', fileList);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <UploadOutlined />
      <p
        className="ant-upload-text"
        style={{ color: '#667085', fontSize: '11px' }}
      >
        Click or drag file to this area to upload
      </p>
    </div>
  );
  const onFinish = (values) => {
    const payload = {
      grId: params.id,
      roId: formValues?.roId,
      //commentsOfReturn: values?.commentsOfReturn,
      fileList: fileList,
      whom_return: formValues?.whom_return,
      dateOfGiftReturned: formValues?.dateOfGiftReturn,
      whyNotReturnedToGiver: values?.not_returned_giver
    };
    HandleReturnGift(payload);
  };

  return (
    <>
      <Modal
        title={
          <span
            style={{
              fontSize: '20px',
              fontFamily: 'SFProText Regular',
              fontWeight: 600
            }}
          >
            Please confirm
          </span>
        }
        open={returnToROModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'700px'}
        onCancel={() => setReturnToROModal(false)}
      >
        <div
          style={{
            fontWeight: 600,
            marginTop: '-10px',
            marginBottom: '20px',
            fontFamily: 'SFProText Regular',
            fontSize: '14px'
          }}
        >
          <Alert message="This action cannot be undone." type="warning" />
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            name="whom_return"
            label="Whom do you want to return this gift?"
            rules={[
              {
                required: true,
                message: 'This is a required field'
              }
            ]}
            //className={`${styles.radio_styles}`}
            className={`${styles.select_styles} ${styles.marginBottom} `}
          >
            <Radio.Group
              onChange={(e) =>
                handleFormDataOnChange('whom_return', e.target.value)
              }
              //value={value}
              className={`${styles.card_Radio}`}
            >
              <Row className={`${styles.radio_label_fontsize}`}>
                <Col span={12}>
                  <Radio value={'gift_giver'}>Return to gift giver</Radio>
                </Col>
                <Col span={12}>
                  <Radio value={'over_RO'}>
                    Handed over to CEC delegates at RO/HO
                  </Radio>
                </Col>
                {/*  <Col span={8}>
                  <Radio value={'over_CEC'}>Handed over to CEC</Radio>
                </Col> */}
              </Row>
            </Radio.Group>
          </FormItem>

          {formValues?.whom_return === 'over_RO' ? (
            <>
              <FormItem
                label="Select RO name" //"Enter reasons for making an exception approval"
                name="roId"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles} ${styles.marginBottom} `}
              >
                <Select
                  // style={{ marginBottom: '15px' }}
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder="Select RO name"
                  //onSearch={inputVenderName}
                  //onSearch={debounceFetcher}
                  onChange={(value) => handleFormDataOnChange('roId', value)}
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                >
                  {roUsersList?.map((item, index) => (
                    <Option title={item.givenName} key={index} value={item?.id}>
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase',
                          fontFamily: 'SFProText Regular'
                        }}
                      >
                        <span style={{ marginLeft: '10px' }}>
                          {item?.givenName}{' '}
                          <span style={{ textTransform: 'lowercase' }}>
                            ({item?.email})
                          </span>
                          ({item?.location === null ? 'NA' : item?.location})
                        </span>
                      </span>
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label="Why the gift was 
                not returned to the giver?" //"Enter reasons for making an exception approval"
                name="not_returned_giver"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.textarea_styles}`}
                //className={`${styles.select_styles}`}
              >
                <TextArea
                  allowClear
                  placeholder="Enter reasons for not returned to the giver"
                />
              </FormItem>
              <FormItem
                label={
                  <div className={`${styles.card_title}`}>
                    Date when the gift is handed over to CEC delegates at RO/HO
                    {/*  Date when the gift is returned to CEC */}
                  </div>
                }
                name="dateOfGiftReturn"
                rules={[
                  {
                    required: true,
                    message: 'This is a required field'
                  }
                ]}
                className={`${styles.date_styles}`}
              >
                <DatePicker
                  className={`${styles.margin_Top}`}
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) =>
                    handleFormDataOnChange('dateOfGiftReturn', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      // moment().add(-1, 'days') >= current //||
                      moment().add(0, 'month') <= current
                    );
                  }}
                ></DatePicker>
              </FormItem>
            </>
          ) : (
            ''
          )}
          {formValues?.whom_return === 'gift_giver' ? (
            <>
              <FormItem
                label={
                  <div className={`${styles.card_title}`}>
                    Date of return to the gift giver
                  </div>
                }
                name="dateOfGiftReturn"
                rules={[
                  {
                    required: true,
                    message: 'This is a required field'
                  }
                ]}
                className={`${styles.date_styles}`}
              >
                <DatePicker
                  className={`${styles.margin_Top}`}
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) =>
                    handleFormDataOnChange('dateOfGiftReturn', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      // moment().add(-1, 'days') >= current //||
                      moment().add(0, 'month') <= current
                    );
                  }}
                ></DatePicker>
              </FormItem>
            </>
          ) : (
            ''
          )}
          {formValues?.whom_return === 'over_CEC' ? (
            <>
              <FormItem
                label={
                  <div className={`${styles.card_title}`}>
                    What date was the gift handed over to the CEC?
                  </div>
                }
                name="dateOfGiftReturn"
                rules={[
                  {
                    required: true,
                    message: 'This is a required field'
                  }
                ]}
                className={`${styles.date_styles}`}
              >
                <DatePicker
                  className={`${styles.margin_Top}`}
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) =>
                    handleFormDataOnChange('dateOfGiftReturn', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      // moment().add(-1, 'days') >= current //||
                      moment().add(0, 'month') <= current
                    );
                  }}
                ></DatePicker>
              </FormItem>
            </>
          ) : (
            ''
          )}
          <FormItem
            label={
              <div className={`${styles.card_title}`}>
                Upload documents
                <div
                  style={{
                    fontSize: '12px',
                    color: '#a6a6ac',
                    fontFamily: 'SFProText Regular'
                  }}
                >
                  (Attach evidence relating to the return of the gift - e.g.
                  Acknowledgment of receipt or courier receipt, etc.)
                </div>
              </div>
            } //"Enter reasons for making an exception approval"
            name="roDocument"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // rules={[
            //   {
            //     required: false,
            //     message: 'Required field'
            //   }
            // ]}
            className={`${styles.upload_styles} ${styles.marginBottom}`}
          >
            <Dragger
              className={`${styles.margin_Top}`}
              beforeUpload={() => false}
              customRequest={({ onChange }) =>
                setTimeout(() => {
                  onChange('ok', null);
                }, 0)
              }
              action="false"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Dragger>
            <Modal
              visible={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
              width="800px"
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </FormItem>
          {/*  <FormItem
            label="Details of gift returned" //"Enter reasons for making an exception approval"
            name="commentsOfReturn"
            rules={[
              {
                required: false,
                message: 'Required field'
              }
            ]}
            className={`${styles.textarea_styles}`}
            //className={`${styles.select_styles}`}
          >
            <TextArea allowClear placeholder="Enter reasons for return" />
          </FormItem> */}
          <FormItem>
            <Space>
              <Button
                loading={returnGift_formLoading}
                className={`${styles.ExceptionalApproval_button}`}
                htmlType="submit"
                size="small"
              >
                Return
              </Button>

              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setReturnToROModal(false)}
              >
                Cancel
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default ReturnRO_Modal;

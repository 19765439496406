import styles from './index.module.less';
import { Space, Image, Row, Col } from 'antd'; //, Card
//import { FormOutlined } from '@ant-design/icons'; // ClockCircleOutlined,
// SafetyCertificateOutlined,
// CloseSquareOutlined,
// RollbackOutlined

import { numFormating } from 'utilities/helpers';
import LocalStorage from 'utilities/localStorage';
//import pendingIcon from '../../../../welcome/image/pendingIcon.svg';
import approvedIcon from '../../../../welcome/image/approvedIcon.svg';
// import rejectedIcon from '../../../../welcome/image/rejectedIcon.svg';
// import returnedIcon from '../../../../welcome/image/returnedIcon.svg';
// import allocated_icon from '../images/allocated_icon.svg';
import { sizeChecker } from 'utilities/helpers';

export default function CardStatus1({
  acceptedCount,
  giftsGiven,
  giftsReceived,
  numberOfRequests
}) {
  const isMobile = sizeChecker('xs');
  /* <a href={`view-gift-details/${record.id}`}>
                {ticket === null ? '' : ticket}
              </a> 
              
              <a href={itm.link} target="_blank" rel="noreferrer">
                        <ArrowRightOutlined />
                      </a>*/
  const handleReDirect = (stutus) => {
    console.log('stutus', stutus);
    LocalStorage.setItem('dashboardCard_status', JSON.stringify(stutus));
  };
  const handleReDirectReceivedGifts = (stutus) => {
    console.log('stutus', stutus);
    LocalStorage.setItem(
      'dashboardCard_ReceivedGifts_status',
      JSON.stringify(stutus)
    );
  };
  const cardContent = [
    {
      id: 1,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('ACCEPTED')}
        >
          {' '}
          ACCEPTED
        </a>
      ), //'acceptedCount',
      value: acceptedCount,
      icon: (
        <a
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('ACCEPTED')}
        >
          {/* <Image
            className={`${styles.gift_pending_icon_size}`}
            alt="pending-icon"
            src={approvedIcon}
            preview={false}
          /> */}
          <Image
            className={`${styles.gift_icon_size}`}
            alt="approvedIcon"
            src={approvedIcon}
            preview={false}
          />
        </a>
      )
    },
    {
      id: 2,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('')}
        >
          GIFTS GIVEN
        </a>
      ), //'giftsGiven',
      value: giftsGiven,
      icon: (
        <a
          href="/adminDashboard-giftGiven-link"
          onClick={() => handleReDirect('ACCEPTED')}
        >
          <Image
            className={`${styles.gift_icon_size}`}
            alt="approvedIcon"
            src={approvedIcon}
            preview={false}
          />
        </a>
      )
    },
    {
      id: 3,
      title: (
        <a
          className={`${styles.cardLink_title}`}
          href="/adminDashboard-giftReceived-link"
          onClick={() => handleReDirectReceivedGifts('')}
        >
          GIFTS RECEIVED
        </a>
      ), //giftsReceived',
      value: giftsReceived,
      icon: (
        <a
          href="/adminDashboard-giftReceived-link"
          onClick={() => handleReDirectReceivedGifts('')}
        >
          <Image
            className={`${styles.gift_icon_size}`}
            alt="approvedIcon"
            src={approvedIcon}
            preview={false}
          />
        </a>
      ) // <SafetyCertificateOutlined style={{ color: '#18AB27' }} /> // initiated_icon //<QuestionCircleOutlined />
    },

    {
      id: 4,
      title: 'TOTAL REQUESTS', //'numberOfRequests',
      value: numberOfRequests,
      icon: (
        <a href="">
          <Image
            className={`${styles.gift_icon_size}`}
            alt="approvedIcon"
            src={approvedIcon}
            preview={false}
          />
        </a>
      ) // <CloseSquareOutlined style={{ color: '#B71212' }} /> // proposed_icon //<QuestionCircleOutlined />
    }
  ];
  return (
    <div>
      <div className={`${styles.card_container} mb-3 `}>
        <Space
          className={`${styles.card_space_between}`}
          direction={isMobile ? 'vertical' : 'horizontal'}
          size="middle"
          gap={26}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {cardContent?.map((item, index) => (
            <div key={index} className={`${styles.card_content_style}`}>
              <Row
                style={{
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <Col>
                  <div className={`${styles.card_icon_div}`}>
                    <span className={`${styles.card_icon_style}`}>
                      {item?.icon}
                    </span>
                  </div>
                </Col>
                <Col>
                  <span className={`${styles.card_title_style}`}>
                    {item?.title}
                  </span>
                </Col>
              </Row>
              <Row className={`${styles.card_amount_style}`}>
                {numFormating(item?.value)}
              </Row>
            </div>
          ))}
        </Space>
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import ViewGiftReturnDetailsPage from './components/viewGiftReturnDetails';
import CustomLayout from '@/common/Layout';
import { TabTitle } from '../../utilities/helpers';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRoGiftById } from './redux/slice';

function ViewGiftReturnDetails() {
  TabTitle('Gift Register - View Gift Received Details');
  const dispatch = useDispatch();
  const { getRoGiftByIdLoading, roGiftDetailsById } = useSelector(
    (state) => state.roView
  );

  const params = useParams();
  const url = window.location.search;
  const urlType = url.replace('?', '');
  console.log('urType', urlType);

  useEffect(() => {
    handleGetGiftById();
  }, []);
  const handleGetGiftById = () => {
    const payload = { id: params.id, urlType };
    dispatch(getRoGiftById(payload));
  };

  return (
    <CustomLayout>
      <div style={{ marginTop: '50px' }}>
        <ViewGiftReturnDetailsPage
          getGiftDetailsById={roGiftDetailsById}
          getGiftByIdLoading={getRoGiftByIdLoading}
        />
      </div>
    </CustomLayout>
  );
}
export default ViewGiftReturnDetails;
